import {
  LabelledInput,
  MetricBar,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  DatePickerWithRange
} from '@frontend/ui'
import {
  createGroupFromList,
  formatMetric,
  sportTypes,
  useFormattedTeam,
  useLanguage,
  useSessionFilter,
  useTeams,
  useUnitsSystem
} from '@frontend/sportable'
import { CollapsibleTeamSelectHeader } from '../../app/header'
import { useEffect, useMemo, useState } from 'react'
import { addDays } from 'date-fns'
import { TopPerformerCard } from '../../components/top-performer-card'
import { TopPerformersCard } from '../../components/top-performers-card'

export type AggregateMetricData = {
  average: number
  min: number
  max: number
  total: number
}

export type AggregateMetricReponse = {
  distance: {
    values: AggregateMetricData
    players: {
      player: string
      values: AggregateMetricData
    }[]
  }
}

const textData = {
  greeting: {
    en: 'Hi Tom,',
    fr: 'Bonjour Tom,'
  },
  welcomeMessage: {
    en: 'Welcome to your reports dashboard',
    fr: 'Bienvenue sur votre tableau de bord de rapports'
  },
  showStatsBy: {
    en: 'Show stats by',
    fr: 'Afficher les statistiques par'
  },
  lastSession: {
    en: 'Last session',
    fr: 'Dernière session'
  },
  customTimeframe: {
    en: 'Custom timeframe',
    fr: 'Plage horaire personnalisée'
  },
  specificSession: {
    en: 'Specific session',
    fr: 'Session spécifique'
  },
  sessionName: {
    en: 'Session name',
    fr: 'Nom de la session'
  },
  timeframe: {
    en: 'Timeframe',
    fr: 'Plage horaire'
  },
  teamPerformanceTitle: {
    en: 'Team performance against season average',
    fr: "Performance de l'équipe par rapport à la moyenne de la saison"
  }
}

const dummyTopPerformersData = {
  soccer: {
    totalDistance: {
      value: 147.34,
      players: [
        { player: 'player-1', value: 90.35203652147399 },
        { player: 'player-2', value: 76.02725163960042 },
        { player: 'player-4', value: 70.01879386859255 },
        { player: 'player-3', value: 67.97083661936492 },
        { player: 'player-5', value: 60.7153476904042 }
      ]
    },
    averageDistance: {
      value: 147.34,
      players: [
        { player: 'player-one', value: 90.35203652147399 },
        { player: 'player-2', value: 76.02725163960042 },
        { player: 'player-3', value: 70.01879386859255 },
        { player: 'player-3', value: 67.97083661936492 },
        { player: 'player-5', value: 60.7153476904042 }
      ]
    },
    averageMaxSpeed: {
      value: 150.7,
      players: [
        { player: 'player-2', value: 115.61975526987636 },
        { player: 'player-1', value: 92.23443258784714 },
        { player: 'player-3', value: 88.56597440456846 },
        { player: 'player-4', value: 85.91917645408768 },
        { player: 'player-5', value: 69.59816666618116 }
      ]
    },
    xGImpacts: {
      value: 0.6,
      players: [
        { player: 'player-2', value: 0.9017801600645473 },
        { player: 'player-4', value: 0.829355768145166 },
        { player: 'player-5', value: 0.7341044165189828 },
        { player: 'player-1', value: 0.22814659083938027 },
        { player: 'player-3', value: 0.13359955491518283 }
      ]
    },
    avgInvolvements: {
      value: 100.23,
      players: [
        { player: 'player-5', value: 144.49523267336704 },
        { player: 'player-4', value: 123.97396144485657 },
        { player: 'player-2', value: 91.13495225550993 },
        { player: 'player-1', value: 57.091512518625734 },
        { player: 'player-3', value: 47.222887858409095 }
      ]
    },
    totalCarries: {
      value: 155.8,
      players: [
        { player: 'player-2', value: 131.37098280012776 },
        { player: 'player-5', value: 116.85319198083613 },
        { player: 'player-3', value: 110.33224739758234 },
        { player: 'player-4', value: 89.9280993714219 },
        { player: 'player-1', value: 68.05285562950891 }
      ]
    },
    completedPasses: {
      value: 68.234,
      players: [
        { player: 'player-1', value: 128.00971333678456 },
        { player: 'player-4', value: 96.2477096634608 },
        { player: 'player-2', value: 63.54565218704976 },
        { player: 'player-3', value: 59.53282230279976 },
        { player: 'player-5', value: 49.88707784168854 }
      ]
    },
    totalShots: {
      value: 200,
      players: [
        { player: 'player-5', value: 50.339283086524865 },
        { player: 'player-3', value: 47.76621532460985 },
        { player: 'player-2', value: 36.93439244048246 },
        { player: 'player-1', value: 24.743389536158386 },
        { player: 'player-4', value: 10.439941544659812 }
      ]
    }
  },
  americanFootball: {
    totalPassCompletion: {
      value: 147.34,
      players: [
        { player: 'player-13', value: 90.35203652147399 },
        { player: 'player-14', value: 76.02725163960042 },
        { player: 'player-15', value: 70.01879386859255 },
        { player: 'player-16', value: 67.97083661936492 },
        { player: 'player-17', value: 60.7153476904042 }
      ]
    },
    averageThrowDistance: {
      value: 147.34,
      players: [
        { player: 'player-14', value: 90.35203652147399 },
        { player: 'player-16', value: 76.02725163960042 },
        { player: 'player-13', value: 70.01879386859255 },
        { player: 'player-12', value: 67.97083661936492 },
        { player: 'player-19', value: 60.7153476904042 }
      ]
    },
    averageSpeed: {
      value: 150.7,
      players: [
        { player: 'player-14', value: 115.61975526987636 },
        { player: 'player-16', value: 92.23443258784714 },
        { player: 'player-17', value: 88.56597440456846 },
        { player: 'player-18', value: 85.91917645408768 },
        { player: 'player-5', value: 69.59816666618116 }
      ]
    },
    averageSpin: {
      value: 0.6,
      players: [
        { player: 'player-14', value: 0.9017801600645473 },
        { player: 'player-15', value: 0.829355768145166 },
        { player: 'player-16', value: 0.7341044165189828 },
        { player: 'player-17', value: 0.22814659083938027 },
        { player: 'player-18', value: 0.13359955491518283 }
      ]
    },
    averageEfficiency: {
      value: 100.23,
      players: [
        { player: 'player-18', value: 144.49523267336704 },
        { player: 'player-17', value: 123.97396144485657 },
        { player: 'player-14', value: 91.13495225550993 },
        { player: 'player-16', value: 57.091512518625734 },
        { player: 'player-15', value: 47.222887858409095 }
      ]
    }
  }
}

const dummyPerformanceAgainstAverageDataOne = {
  soccer: {
    totalRuns: {
      value: 3,
      average: 10,
      max: 12
    },
    totalDistance: {
      value: 73.63163573588689,
      average: 78.99646889535411,
      max: 100
    },
    totalRunDistance: {
      value: 73.63163573588689,
      average: 78.99646889535411,
      max: 100
    },
    averageMaxSpeed: {
      value: 83.06482025073912,
      average: 84.03662196011447,
      max: 110
    },
    xGImpacts: {
      value: 0.4390397834237193,
      average: 0.5818882875019541,
      max: 1
    },
    avgInvolvements: {
      value: 143.02413167301768,
      average: 138.2443610844769,
      max: 150
    },
    totalCarries: {
      value: 135.01494122827512,
      average: 128.8386253910251,
      max: 160
    },
    completedPasses: {
      value: 130.92243020628715,
      average: 122.10085975399065,
      max: 160
    },
    totalShots: {
      value: 127.39982439251474,
      average: 120.75764559768429,
      max: 150
    },
    totalPasses: {
      value: 66.34921828898625,
      average: 60.83138897507247,
      max: 90
    },
    totalPassCompletion: {
      value: 117.60395240200285,
      average: 112.69550776117246,
      max: 130
    },
    totalForwardPasses: {
      value: 143.70664575645932,
      average: 136.7067563380661,
      max: 160
    },
    totalBackwardPasses: {
      value: 114.81266109634159,
      average: 114.2015063668999,
      max: 130
    },
    totalSidewaysPasses: {
      value: 144.9398074955385,
      average: 135.6557825156265,
      max: 160
    },
    totalShotsAtGoal: {
      value: 106.39392693581352,
      average: 106.53752719232746,
      max: 120
    },
    totalGoalsScored: {
      value: 121.99557228525046,
      average: 125.71266543762394,
      max: 140
    },
    averageRecycleTime: {
      value: 92.4625466945691,
      average: 98.77449961278944,
      max: 120
    },
    averageTimeOnBall: {
      value: 68.29842646283706,
      average: 70.91797029898436,
      max: 85
    },
    shotsOnTargetRatio: {
      value: 50,
      average: 60,
      max: 100
    },
    shotConversion: {
      value: 60,
      average: 70,
      max: 100
    },
    runIntensity: {
      value: 90,
      average: 80,
      max: 100
    }
  },

  americanFootball: {
    involvements: {
      value: 3,
      average: 10,
      max: 12
    },
    involvementsPerMinute: {
      value: 73.63163573588689,
      average: 78.99646889535411,
      max: 100
    },
    distance: {
      value: 73.63163573588689,
      average: 78.99646889535411,
      max: 100
    },
    distancePerMin: {
      value: 83.06482025073912,
      average: 84.03662196011447,
      max: 110
    },
    highSpeedRunningDistance: {
      value: 0.4390397834237193,
      average: 0.5818882875019541,
      max: 1
    },
    highSpeedRunningDistancePerMin: {
      value: 143.02413167301768,
      average: 138.2443610844769,
      max: 150
    },
    accelCount: {
      value: 135.01494122827512,
      average: 128.8386253910251,
      max: 160
    },
    decelCount: {
      value: 130.92243020628715,
      average: 122.10085975399065,
      max: 160
    },
    totalWork: {
      value: 127.39982439251474,
      average: 120.75764559768429,
      max: 150
    },
    totalPassCompletion: {
      value: 66.34921828898625,
      average: 60.83138897507247,
      max: 90
    },
    averageThrowDistance: {
      value: 117.60395240200285,
      average: 112.69550776117246,
      max: 130
    },
    averageSpeed: {
      value: 143.70664575645932,
      average: 136.7067563380661,
      max: 160
    },
    averageSpin: {
      value: 114.81266109634159,
      average: 114.2015063668999,
      max: 130
    },
    averageEfficiency: {
      value: 144.9398074955385,
      average: 135.6557825156265,
      max: 160
    }
  }
}

const dummyPerformanceAgainstAverageDataTwo = {
  totalRuns: {
    value: 3.3, // Increased by 10%
    average: 10,
    max: 12
  },
  totalDistance: {
    value: 66.268, // Decreased by 10%
    average: 78.99646889535411,
    max: 100
  },
  totalRunDistance: {
    value: 80.994, // Increased by 10%
    average: 78.99646889535411,
    max: 100
  },
  averageMaxSpeed: {
    value: 75.303, // Decreased by 10%
    average: 84.03662196011447,
    max: 110
  },
  xGImpacts: {
    value: 0.39, // Decreased by 10%
    average: 0.5818882875019541,
    max: 1
  },
  avgInvolvements: {
    value: 157.326, // Increased by 10%
    average: 138.2443610844769,
    max: 150
  },
  totalCarries: {
    value: 148.516, // Increased by 10%
    average: 128.8386253910251,
    max: 160
  },
  completedPasses: {
    value: 121.828, // Decreased by 10%
    average: 122.10085975399065,
    max: 160
  },
  totalShots: {
    value: 141.14, // Increased by 10%
    average: 120.75764559768429,
    max: 150
  },
  totalPasses: {
    value: 59.715, // Decreased by 10%
    average: 60.83138897507247,
    max: 90
  },
  totalPassCompletion: {
    value: 108.843, // Decreased by 10%
    average: 112.69550776117246,
    max: 130
  },
  totalForwardPasses: {
    value: 158.077, // Increased by 10%
    average: 136.7067563380661,
    max: 160
  },
  totalBackwardPasses: {
    value: 126.293, // Increased by 10%
    average: 114.2015063668999,
    max: 130
  },
  totalSidewaysPasses: {
    value: 130.945, // Decreased by 10%
    average: 135.6557825156265,
    max: 160
  },
  totalShotsAtGoal: {
    value: 96.09, // Decreased by 10%
    average: 106.53752719232746,
    max: 120
  },
  totalGoalsScored: {
    value: 113.795, // Decreased by 10%
    average: 125.71266543762394,
    max: 140
  },
  averageRecycleTime: {
    value: 102.708, // Increased by 10%
    average: 98.77449961278944,
    max: 120
  },
  averageTimeOnBall: {
    value: 75.487, // Increased by 10%
    average: 70.91797029898436,
    max: 85
  },
  shotsOnTargetRatio: {
    value: 55, // Increased by 10%
    average: 60,
    max: 100
  },
  shotConversion: {
    value: 54, // Decreased by 10%
    average: 70,
    max: 100
  },
  runIntensity: {
    value: 81, // Decreased by 10%
    average: 80,
    max: 100
  }
}

const dummyPerformanceAgainstAverageDataTwoAmFootball = {
  involvements: {
    value: 3,
    average: 10,
    max: 12
  },
  involvementsPerMinute: {
    value: 73.63163573588689,
    average: 78.99646889535411,
    max: 100
  },
  distance: {
    value: 73.63163573588689,
    average: 78.99646889535411,
    max: 100
  },
  distancePerMin: {
    value: 83.06482025073912,
    average: 84.03662196011447,
    max: 110
  },
  highSpeedRunningDistance: {
    value: 0.4390397834237193,
    average: 0.5818882875019541,
    max: 1
  },
  highSpeedRunningDistancePerMin: {
    value: 143.02413167301768,
    average: 138.2443610844769,
    max: 150
  },
  accelCount: {
    value: 135.01494122827512,
    average: 128.8386253910251,
    max: 160
  },
  decelCount: {
    value: 130.92243020628715,
    average: 122.10085975399065,
    max: 160
  },
  totalWork: {
    value: 127.39982439251474,
    average: 120.75764559768429,
    max: 150
  },
  totalPassCompletion: {
    value: 66.34921828898625,
    average: 60.83138897507247,
    max: 90
  },
  averageThrowDistance: {
    value: 117.60395240200285,
    average: 112.69550776117246,
    max: 130
  },
  averageSpeed: {
    value: 143.70664575645932,
    average: 136.7067563380661,
    max: 160
  },
  averageSpin: {
    value: 114.81266109634159,
    average: 114.2015063668999,
    max: 130
  },
  averageEfficiency: {
    value: 144.9398074955385,
    average: 135.6557825156265,
    max: 160
  }
}

export function Dashboard({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const teamsState = useTeams()

  const [showBy, setShowBy] = useState('lastSession')

  const formattedTeam = useFormattedTeam(teamsState.selectedTeam)

  const sport = formattedTeam?.sport || sportTypes.items.soccer

  const unitSystem = useUnitsSystem(sport)
  const language = useLanguage()

  const [
    dummyPerformanceAgainstAverageData,
    setDummyPerformanceAgainstAverageData
  ] = useState(dummyPerformanceAgainstAverageDataOne[sport.key] || {})

  useEffect(() => {
    setDummyPerformanceAgainstAverageData(
      dummyPerformanceAgainstAverageDataOne[sport.key] || {}
    )
  }, [sport])

  const {
    sortedSessions: allTeamSessions,
    updateFilterValue: updateAllTeamSessionsFilter
  } = useSessionFilter({
    team: {
      options: teamsState.options,
      value: teamsState.selectedTeam
    },
    sportType: {
      options: [],
      value: sport.value
    }
  })

  const {
    sortedSessions: metricSessions,
    updateFilterValue: updateMetricSessionsFilterValue
  } = useSessionFilter({
    team: {
      options: teamsState.options,
      value: teamsState.selectedTeam
    },
    sportType: {
      options: [],
      value: sport.value
    }
  })

  const [customDateRange, setCustomDateRange] = useState({
    from: addDays(new Date(), -7),
    to: new Date()
  })

  const [selectedSessionId, setSelectedSessionId] = useState(null)

  useEffect(() => {
    if (formattedTeam) {
      updateAllTeamSessionsFilter('team', teamsState.selectedTeam)
      updateAllTeamSessionsFilter('sportType', sport.value)
      updateMetricSessionsFilterValue('team', teamsState.selectedTeam)
      updateMetricSessionsFilterValue('sportType', sport.value)
    }
  }, [formattedTeam])

  useEffect(() => {
    if (showBy === 'lastSession') {
      updateMetricSessionsFilterValue('limitQuantity', 1)
      updateMetricSessionsFilterValue('startTime', null)
      updateMetricSessionsFilterValue('endTime', null)
      updateMetricSessionsFilterValue('sessionId', null)
    } else if (showBy === 'customTimeframe') {
      updateMetricSessionsFilterValue('limitQuantity', null)
      updateMetricSessionsFilterValue(
        'startTime',
        customDateRange.from.getTime() / 1000
      )
      updateMetricSessionsFilterValue(
        'endTime',
        customDateRange.to
          ? customDateRange.to.getTime() / 1000
          : new Date().getTime() / 1000
      )
      updateMetricSessionsFilterValue('sessionId', null)
    } else if (showBy === 'specificSession') {
      updateMetricSessionsFilterValue('limitQuantity', null)
      updateMetricSessionsFilterValue('startTime', null)
      updateMetricSessionsFilterValue('endTime', null)
      updateMetricSessionsFilterValue('sessionId', selectedSessionId)
    }
  }, [
    showBy,
    selectedSessionId,
    customDateRange,
    updateMetricSessionsFilterValue
  ])

  const allTeamSessionOptions = useMemo(() => {
    return allTeamSessions.map((session) => ({
      name: session.name,
      value: session.id
    }))
  }, [allTeamSessions])

  useEffect(() => {
    if (
      !allTeamSessions.some((session) => {
        return session.id === selectedSessionId
      })
    ) {
      setSelectedSessionId(allTeamSessions[0]?.id)
    }
  }, [allTeamSessions])

  const formattedPerformanceAgainstAverageMetrics = useMemo(() => {
    if (!sport.props.reportMetricConfig.dashboardMetrics) return []
    return sport.props.reportMetricConfig.dashboardMetrics.performanceAgainstAverage.map(
      (metricTypeKey) => {
        const metricType = sport.props.metrics.items[metricTypeKey]
        if (!metricType) return

        const formattedPerformanceAgainstAverageMetric = {}

        for (const key in dummyPerformanceAgainstAverageData[metricType.key]) {
          const value = dummyPerformanceAgainstAverageData[metricType.key][key]
          const formattedMetric = formatMetric(
            metricType,
            {
              [metricType.key]: value
            },
            createGroupFromList([formattedTeam], 'id', 'id'),
            formattedTeam.players,
            unitSystem,
            false,
            false,
            language
          )

          formattedPerformanceAgainstAverageMetric[key] = formattedMetric
        }

        return formattedPerformanceAgainstAverageMetric
      }
    ) as {
      value: ReturnType<typeof formatMetric>
      average: ReturnType<typeof formatMetric>
      max: ReturnType<typeof formatMetric>
    }[]
  }, [sport, dummyPerformanceAgainstAverageData])

  const formatTopPerformersMetrics = (
    metricTypeKeys: (typeof sport.props.reportMetricConfig.dashboardMetrics.cardGrid)[0],
    sport,
    formattedTeam: ReturnType<typeof useFormattedTeam>
  ): {
    value: ReturnType<typeof formatMetric>
    players: {
      player: (typeof formattedTeam.players.list)[number]
      value: ReturnType<typeof formatMetric>
    }[]
  }[] => {
    return metricTypeKeys.map((metricTypeKey) => {
      const metricType = sport.props.metrics.items[metricTypeKey]
      if (!metricType) return

      const formattedTopPerformerMetric = {
        value: null,
        players: []
      }

      for (const key in dummyTopPerformersData[sport.key][metricType.key]) {
        const value = dummyTopPerformersData[sport.key][metricType.key].value
        const formattedMetric = formatMetric(
          metricType,
          {
            [metricType.key]: value
          },
          createGroupFromList([formattedTeam], 'id', 'id'),
          formattedTeam.players,
          unitSystem,
          false,
          false,
          language
        )

        formattedTopPerformerMetric.value = formattedMetric

        // Get players //
        formattedTopPerformerMetric.players = dummyTopPerformersData[sport.key][
          metricType.key
        ].players.map((playerData) => {
          // Get Player //
          const formattedPlayer = formattedTeam.players.map[playerData.player]

          // Get Player Value //
          const formattedMetric = formatMetric(
            metricType,
            {
              [metricType.key]: playerData.value
            },
            createGroupFromList([formattedTeam], 'id', 'id'),
            formattedTeam.players,
            unitSystem,
            false,
            false,
            language
          )

          return { player: formattedPlayer, value: formattedMetric }
        })
      }

      return formattedTopPerformerMetric
    })
  }

  const topPerformerMetricsRowOne = useMemo(() => {
    if (!sport.props.reportMetricConfig.dashboardMetrics) return []
    return formatTopPerformersMetrics(
      sport.props.reportMetricConfig.dashboardMetrics.cardGrid[0],
      sport,
      formattedTeam
    )
  }, [sport, formattedTeam])

  const topPerformerMetricsRowTwo = useMemo(() => {
    if (!sport.props.reportMetricConfig.dashboardMetrics) return []
    return formatTopPerformersMetrics(
      sport.props.reportMetricConfig.dashboardMetrics.cardGrid[1],
      sport,
      formattedTeam
    )
  }, [sport, formattedTeam])

  return (
    <>
      <CollapsibleTeamSelectHeader
        header={textData.greeting[language]}
        message={textData.welcomeMessage[language]}
      />
      <div className='flex-1 space-y-4 p-8 bg-muted'>
        <div className='flex'>
          {/* Stats By Select */}
          <LabelledInput
            label={textData.showStatsBy[language]}
            className='mr-16'
          >
            <Select value={showBy} onValueChange={(value) => setShowBy(value)}>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='lastSession'>
                  {textData.lastSession[language]}
                </SelectItem>
                <SelectItem value='customTimeframe'>
                  {textData.customTimeframe[language]}
                </SelectItem>
                <SelectItem value='specificSession'>
                  {textData.specificSession[language]}
                </SelectItem>
              </SelectContent>
            </Select>
          </LabelledInput>
          {/* Session Select */}
          {showBy === 'specificSession' && (
            <LabelledInput
              label={textData.sessionName[language]}
              className='mr-6'
            >
              <Select
                value={selectedSessionId || '0'}
                onValueChange={(value) => setSelectedSessionId(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder='' />
                </SelectTrigger>
                <SelectContent>
                  {allTeamSessionOptions.map((session) => (
                    <SelectItem key={session.value} value={session.value}>
                      {session.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </LabelledInput>
          )}
          {showBy === 'customTimeframe' && (
            <LabelledInput
              label={textData.timeframe[language]}
              className='mr-6'
            >
              <DatePickerWithRange
                dateRange={customDateRange}
                onChange={(dateRange) => {
                  if (!dateRange) return
                  setCustomDateRange(dateRange)
                }}
              />
            </LabelledInput>
          )}
        </div>
      </div>
      <div className='flex-1 space-y-4 p-8 pt-16 pb-4'>
        <Card>
          <CardHeader>
            <CardTitle>{textData.teamPerformanceTitle[language]}</CardTitle>
          </CardHeader>
          <CardContent className='grid gap-4'>
            <div className='flex gap-4'>
              {formattedPerformanceAgainstAverageMetrics.map(
                (formattedPerformanceAgainstAverageMetric) => {
                  if (!formattedPerformanceAgainstAverageMetric?.value) return
                  return (
                    <div
                      className='flex-1 px-2'
                      key={formattedPerformanceAgainstAverageMetric.value.key}
                    >
                      <MetricBar
                        value={
                          formattedPerformanceAgainstAverageMetric.value
                            .value as number
                        }
                        markerValue={
                          formattedPerformanceAgainstAverageMetric.average
                            .value as number
                        }
                        maxValue={
                          formattedPerformanceAgainstAverageMetric.max
                            .value as number
                        }
                        label={`${
                          formattedPerformanceAgainstAverageMetric.value.name
                        } ${
                          formattedPerformanceAgainstAverageMetric.value.units
                            ? `(${formattedPerformanceAgainstAverageMetric.value.units})`
                            : ''
                        }`}
                        displayValue={
                          formattedPerformanceAgainstAverageMetric.value
                            .formattedValue as string
                        }
                      />
                    </div>
                  )
                }
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <div className='flex-1 py-4 p-8'>
        <div className='flex gap-8'>
          {topPerformerMetricsRowOne.map((topPerformerMetric) => {
            const topPerformer = topPerformerMetric.players[0]

            return (
              <TopPerformerCard
                key={topPerformerMetric.value.name as string}
                value={topPerformerMetric.value.formattedValue as number}
                label={`${topPerformerMetric.value.name} ${
                  topPerformerMetric.value.units
                    ? `(${topPerformerMetric.value.units})`
                    : ''
                }`}
                topPerformerName={topPerformer.player?.fullName}
                topPerformerValue={topPerformer.value.formattedValue as string}
                topPerformerAvatar={topPerformer.player?.img}
              />
            )
          })}
        </div>
      </div>
      <div className='flex-1 pt-4 px-8 pb-8'>
        <div className='flex gap-8'>
          {topPerformerMetricsRowTwo.map((topPerformerMetric) => {
            return (
              <TopPerformersCard
                label={`${topPerformerMetric.value.name} ${
                  topPerformerMetric.value.units
                    ? `(${topPerformerMetric.value.units})`
                    : ''
                }`}
                topPerformers={topPerformerMetric.players
                  .filter((_, index) => index < 3)
                  .map((topPerformer) => {
                    return {
                      name: topPerformer.player?.fullName,
                      value: topPerformer.value.formattedValue as number,
                      avatar: topPerformer.player?.img
                    }
                  })}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
