'use client'

import * as React from 'react'
import { ChevronDown } from 'lucide-react'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from './collapsible'

import { cn } from '../../lib/utils'

interface CollapsibleSectionProps {
  children: React.ReactNode
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export function CollapsibleSection({
  children,
  isOpen,
  setIsOpen
}: CollapsibleSectionProps) {
  const contentRef = React.useRef<HTMLDivElement>(null)

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className='w-full'>
      <CollapsibleContent
        className={cn(
          'text-popover-foreground outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'
        )}
      >
        <div ref={contentRef}>
          <div>
            <div className='mt-2 space-y-2'>{children}</div>
          </div>
        </div>
      </CollapsibleContent>
      <CollapsibleTrigger
        className='flex items-center justify-center w-full p-2 rounded-full transition-all duration-300 ease-in-out'
        style={{
          transform: isOpen ? 'translateY(0)' : 'translateY(-8px)',
          opacity: isOpen ? 1 : 0.7
        }}
      >
        <div className='absolute left-3 top-1/2 w-[calc(50%-2rem)] h-px bg-foreground/50'></div>
        <ChevronDown
          className={`h-4 w-4 transition-transform duration-300 ease-in-out ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
        <div className='absolute right-3 top-1/2 w-[calc(50%-2rem)] h-px bg-foreground/50'></div>
      </CollapsibleTrigger>
    </Collapsible>
  )
}
