import { X } from 'lucide-react'
import { Card, CardContent } from '../../ui/card'

export interface Item {
  id: string
  label: string
  labelTwo: string
  image: string
}

interface ItemTileProps {
  item: Item
  onRemove: (id: string) => void
}

export function ItemTile({ item, onRemove }: ItemTileProps) {
  return (
    <div className='flex flex-col items-center gap-2'>
      <div className='relative h-24 w-24 overflow-hidden rounded-full'>
        <img
          src={item.image}
          alt={item.label}
          className='h-full w-full object-cover transition-all duration-200 group-hover:brightness-50'
        />
        <button
          onClick={() => onRemove(item.id)}
          className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-200 group-hover:opacity-100'
        >
          <X className='h-6 w-6 text-white' />
        </button>
      </div>
      <p className='text-sm text-foreground font-semibold'>{item.label}</p>
      <p className='text-sm text-foreground font-semibold text-center'>
        {item.labelTwo}
      </p>
    </div>
  )
}
