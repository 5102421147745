import React from 'react'
import styles from '../TeamList.module.scss'
import { PlayerPosition } from '../../../metrics_server/sessions/hooks'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'

export interface TeamListItemProps {
  playerPosition: PlayerPosition
}

export const TeamListitem = ({ playerPosition }: TeamListItemProps) => {
  const { number, playerSession } = playerPosition

  const formattedHardware = useFormattedHardware()

  const device =
    playerPosition.playerSession &&
    formattedHardware.devices[playerPosition.playerSession.hardwareId]

  return (
    <div className={styles.teamListItem}>
      <div className={styles.nameAndTag}>
        <li
          key={`${number} + teamInViewNameList`}
          style={{ paddingBottom: '5px' }}
        >
          {playerSession && playerSession.player
            ? `${number}. ${playerSession.player.fullName}`
            : `${number}.`}
        </li>
        <li
          key={`${number} + teamInViewTagList`}
          style={{ paddingBottom: '5px', right: '5px', color: '#bbb' }}
        >
          {device && device.serial}
        </li>
      </div>
      <div className={styles.separator} />
    </div>
  )
}
