import { color } from 'html2canvas/dist/types/css/types/color'
import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../data_types'
import { EventTypeJson, ParentEventTypeProps } from '../data_types'
import { defaultEventTypeFeatures, EventFeatures } from '../features'
import { EventSubTypeGroup, EventSubTypeKeys } from '../subTypes/data_types'

export const defaultEventFeatures: EventFeatures = {
  ...defaultEventTypeFeatures,
  tagToEventXDistance: true,
  officiatingModeOnly: false,
  graph: true
}

export const eventTypesConfig = {
  // Flight Event Types //
  pass: {
    name: 'Pass',
    key: 'pass',
    value: 0,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, color: 'rgb(197, 34, 34)' }
    }
  },
  kick: {
    name: 'Kick',
    key: 'kick',
    value: 1,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, color: 'rgb(34, 197, 94)' }
    }
  },
  soccerPass: {
    name: {
      en: 'Pass',
      fr: 'Passe'
    },
    key: 'soccerPass',
    value: 6,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },
  soccerShotAtGoal: {
    name: {
      en: 'Shot At Goal',
      fr: 'Tir au but'
    },
    key: 'soccerShotAtGoal',
    value: 7,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(197, 34, 34)'
      }
    }
  },
  soccerKickOff: {
    name: {
      en: 'Kick Off',
      fr: "Coup d'envoi"
    },
    key: 'soccerKickOff',
    value: 8,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(64, 34, 197)'
      }
    }
  },
  soccerThrowIn: {
    name: {
      en: 'Throw In',
      fr: 'Rentrée de touche'
    },
    key: 'soccerThrowIn',
    value: 9,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(197, 34, 143)'
      }
    }
  },
  soccerCorner: {
    name: {
      en: 'Corner',
      fr: 'Corner'
    },
    key: 'soccerCorner',
    value: 10,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(228, 245, 0)'
      }
    }
  },
  soccerPenalty: {
    name: {
      en: 'Penalty',
      fr: 'Pénalité'
    },
    key: 'soccerPenalty',
    value: 11,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 192, 197)'
      }
    }
  },
  soccerDribble: {
    name: {
      en: 'Dribble',
      fr: 'Dribble'
    },
    key: 'soccerDribble',
    value: 12,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(197, 99, 34)'
      }
    }
  },
  soccerCross: {
    name: {
      en: 'Cross',
      fr: 'Centre'
    },
    key: 'soccerCross',
    value: 13,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 37, 197)'
      }
    }
  },
  soccerClearance: {
    name: {
      en: 'Clearance',
      fr: 'Dégagement'
    },
    key: 'soccerClearance',
    value: 14,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(72, 197, 34)'
      }
    }
  },
  soccerDeflection: {
    name: {
      en: 'Deflection',
      fr: 'Déviation'
    },
    key: 'soccerDeflection',
    value: 15,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 159, 197)'
      }
    }
  },
  lineout: {
    name: 'Lineout',
    key: 'lineout',
    value: 2,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },
  snapFlightEvent: {
    name: 'Snap',
    key: 'snapFlightEvent',
    value: 16,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },

  // Game Event Types //
  unknownGameEvent: {
    name: 'Unkonwn',
    key: 'unknownGameEvent',
    value: 'UNKNOWN',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },
  possession: {
    name: 'Possession',
    key: 'possession',
    value: 'POSSESSION',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },
  ballInPlay: {
    name: 'Ball In Play',
    key: 'ballInPlay',
    value: 'BALL_IN_PLAY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },
  penaltyGameEvent: {
    name: 'Penalty',
    key: 'penaltyGameEvent',
    value: 'PENALTY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        color: 'rgb(34, 197, 94)'
      }
    }
  },
  try: {
    name: 'Try',
    key: 'try',
    value: 'TRY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, shortcut: 't' }
    }
  },
  touchDown: {
    name: 'Touch Down',
    key: 'touchDown',
    value: 'TOUCH_DOWN',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  goalLineCrossed: {
    name: 'Goal Line Crossed',
    key: 'goalLineCrossed',
    value: 'GOAL_LINE_CROSSED',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  snapGameEvent: {
    name: 'Snap',
    key: 'snapGameEvent',
    value: 'SNAP',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  kickOffGameEvent: {
    name: 'Kick Off',
    key: 'kickOffGameEvent',
    value: 'KICKOFF',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  handOff: {
    name: 'Hand Off',
    key: 'handOff',
    value: 'HANDOFF',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  twoPointConversion: {
    name: '2-PT Conversion',
    key: 'twoPointConversion',
    value: 'CFL_2PT_CONVERSION',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  touch: {
    name: 'Touch',
    key: 'touch',
    value: 'TOUCH',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  soccerBallOverLine: {
    name: 'Ball Over Line',
    key: 'soccerBallOverLine',
    value: 'SOCCER_BALL_OVER_LINE',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  soccerGoal: {
    name: 'Goal',
    key: 'soccerGoal',
    value: 'SOCCER_GOAL',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  tackle: {
    name: 'Tackle',
    key: 'tackle',
    value: 'TACKLE',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  safety: {
    name: 'Safety',
    key: 'safety',
    value: 'SAFETY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  defensiveTwoPoint: {
    name: 'Defensive 2-PT',
    key: 'defensiveTwoPoint',
    value: 'DEFENSIVE_2_PT',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  huddleBreak: {
    name: 'Huddle Break',
    key: 'huddleBreak',
    value: 'HUDDLE_BREAK_OFFENSE',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  lineSet: {
    name: 'Line Set',
    key: 'lineSet',
    value: 'LINE_SET',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  qbRush: {
    name: 'QB Rush',
    key: 'qbRush',
    value: 'QB_RUSH',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  qbSack: {
    name: 'QB Sack',
    key: 'qbSack',
    value: 'QB_SACK',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  qbKneel: {
    name: 'QB Kneel',
    key: 'qbKneel',
    value: 'QB_KNEEL',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  timeOut: {
    name: 'Time Out',
    key: 'timeOut',
    value: 'TIMEOUT',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fumble: {
    name: 'Fumble',
    key: 'fumble',
    value: 'FUMBLE',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  manualPass: {
    name: 'Manual Pass',
    key: 'manualPass',
    value: 'MANUAL_PASS',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  impact: {
    name: 'Impact',
    key: 'impact',
    value: 'IMPACT',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },

  // Australian Football Event Types //
  unknownAustralianRulesEvent: {
    name: 'Unknown',
    key: 'unknownAustralianRulesEvent',
    value: 0,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  goal: {
    name: 'Goal',
    key: 'goal',
    value: 1,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  behind: {
    name: 'Behind',
    key: 'behind',
    value: 2,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  nearGoalLine: {
    name: 'Near Goal Line',
    key: 'nearGoalLine',
    value: 3,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  crossGoalLine: {
    name: 'Cross Goal Line',
    key: 'crossGoalLine',
    value: 4,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  outOfBounds: {
    name: 'Out of Bounds',
    key: 'outOfBounds',
    value: 5,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },

  // Time Event Types //
  firstPeriod: {
    key: 'firstPeriod',
    value: 0,
    name: {
      en: 'First',
      fr: 'Premier'
    },
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, period: true }
    }
  },
  secondPeriod: {
    key: 'secondPeriod',
    value: 1,
    name: {
      en: 'Second',
      fr: 'Deuxième'
    },
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, period: true }
    }
  },
  thirdPeriod: {
    key: 'thirdPeriod',
    value: 2,
    name: {
      en: 'Third',
      fr: 'Troisième'
    },
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, period: true }
    }
  },
  fourthPeriod: {
    key: 'fourthPeriod',
    value: 3,
    name: {
      en: 'Fourth',
      fr: 'Quatrième'
    },
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, period: true }
    }
  },
  stoppage: {
    key: 'stoppage',
    value: 4,
    name: 'Play',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultEventFeatures,
        startName: 'Stopped',
        endName: 'Resumed'
      }
    }
  },
  overTime: {
    key: 'overTime',
    value: 6,
    name: {
      en: 'Overtime',
      fr: 'Prolongation'
    },
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, period: true, hidePeriodName: true }
    }
  }
} as const

export type EventTypeProps = ParentEventTypeProps<EventSubTypeGroup>

export type EventTypes = DataTypes<typeof eventTypesConfig>

export type EventTypeKey = DataTypeKey<EventTypes>

export type EventTypeKeys = DataTypeKeys<EventTypeKey>

export type EventTypeValues = DataTypeValues<EventTypeKey>

export const getEventTypeGroup = (items: {
  [key in EventTypeKeys]?: EventTypes[key]
}) => {
  return getDataTypeGroup<
    EventTypeKeys,
    EventTypeValues,
    EventTypes,
    EventTypeProps
  >(items)
}

export type EventTypeGroup = ReturnType<typeof getEventTypeGroup>

export type EventTypeConfig = DataTypeConfig<
  EventTypeKeys,
  EventTypeValues,
  EventTypeProps
>

export const eventTypes = getEventTypeGroup(eventTypesConfig)

export type EventType = ReturnType<typeof eventTypes.getTypeByValue>

export type EventSubTypesJson = {
  [key in EventSubTypeKeys]?: EventTypeJson<EventFeatures>
}

export type EventTypesJson = {
  [key in EventTypeKeys]?: EventTypeJson<EventSubTypesJson>
}

export type EventJson = EventTypeJson<EventTypesJson>
