import { sportTypes } from '../metrics_server/sports/data_types'
import {
  DeviceState,
  HardwareDiagnosticsPacket
} from '../metrics_server/hardware/types'
import { environment } from '../metrics_server/env'
import { eventTypes as et } from '../metrics_server/events/types/data_types'

export const API_ROOT_URL = environment.API_ROOT_URL
export const BROADCAST_ROOT_URL = environment.BROADCAST_ROOT_URL
export const WS_ROOT_URL = environment.WS_ROOT_URL

export const tagTimeout = 10

export const loadingAnimationDelay = 300

export const permissionLevels = {
  admin: 0
}

export const hardwareTypes = {
  anchor: { name: 'Anchor', value: 1 },
  tag: { name: 'Tag', value: 2 },
  ball: { name: 'Ball', value: 3 }
}

export function getHardwareType(eNum) {
  let hardwareTypeObj
  for (const key in hardwareTypes) {
    if (hardwareTypes[key].value === eNum) hardwareTypeObj = hardwareTypes[key]
  }
  return hardwareTypeObj
}

export const eventTypes = {
  pass: { name: 'Pass', value: 'Pass', oldValue: 0 },
  kick: { name: 'Kick', value: 'Kick', oldValue: 1 },
  lineout: { name: 'Lineout', value: 'Lineout', oldValue: 2 }
}

export function getEventType(eNum) {
  let eventTypeObj
  for (const key in eventTypes) {
    if (eventTypes[key].value === eNum) eventTypeObj = eventTypes[key]
  }
  return eventTypeObj
}

export function getOldEventType(type) {
  let eventTypeObj
  for (var key in eventTypes) {
    if (eventTypes[key].oldValue === type) eventTypeObj = eventTypes[key]
  }
  return eventTypeObj
}

export const uiTypes = {
  skillTracker: { name: `Skill Tracker`, value: 0, key: 'skillTracker' },
  matchTracker: { name: `Match Tracker`, value: 1, key: 'matchTracker' },
  commentatorTool: {
    name: `Commentator Tool`,
    value: 2,
    key: 'commentatorTool'
  }
}

export const sportableColors = {
  colors: {
    sportableBlue: '#0099cc',
    sportableRed: '#f64645',

    colorSuccess: '#88c459',
    colorError: '#f5414f',
    colorWarning: '#ffd137',

    colorSuccess50: 'rgba(136, 196, 89, 0.5)',
    colorWarning50: 'rgba(255, 209, 55, 0.5)',
    colorError50: 'rgba(245, 65, 79, 0.5)',

    sportableGrey: '#c5c4c8',
    sportableGreyDark: '#97979a',
    sportableGreyLight: '#f2f2f2',

    tableBackground: '#f6f5fa',
    tableBackground2: '#eeedf2',

    success: '#00FF00',
    failure: '#f5414f',
    darkYellow: '#feb309',
    flightHighlight: '#ffff00',
    blue: '#245ccc'
  },

  playerColors: {
    // hex colours
    red: '#cc3333',
    blue: '#245ccc',

    darkteal: '#255463',
    lightred: '#e96260',
    lightblue: '#77a0ff',
    lightyellow: '#eee657',
    teal: '#0091a1',
    orange: '#f5a058',
    purple: '#c417c9',
    green: '#129f5a',
    lightgreen: '#99de73',
    brightgreen: '#00FF00',

    // rgba - 1 opacity
    red100: 'rgba(204, 51, 51, 1)',
    blue100: 'rgba(36, 92, 204, 1)',

    darkteal100: 'rgba(37, 84, 99, 1)',
    lightred100: 'rgba(233, 98, 96, 1)',
    lightblue100: 'rgba(119, 160, 255, 1)',
    // lightyellow100: 'rgba(238, 230, 87, 1)',
    teal100: 'rgba(0, 145, 161, 1)',
    orange100: 'rgba(245, 160, 88, 1)',
    purple100: 'rgba(196, 23, 201, 1)',
    green100: 'rgba(18, 159, 90, 1)',
    lightgreen100: 'rgba(153, 222, 115, 1)',
    brightgreen100: 'rgba(0, 255, 0, 1)',

    // rbga - opacity - 0.5
    red50: 'rgba(204, 51, 51, 0.5)',
    blue50: 'rgba(36, 92, 204, 0.5)',

    darkteal50: 'rgba(37, 84, 99, 0.5)',
    lightred50: 'rgba(233, 98, 96, 0.5)',
    lightblue50: 'rgba(119, 160, 255, 0.5)',
    // lightyellow50: 'rgba(238, 230, 87, 0.5)',
    teal50: 'rgba(0, 145, 161, 0.5)',
    orange50: 'rgba(245, 160, 88, 0.5)',
    purple50: 'rgba(196, 23, 201, 0.5)',
    green50: 'rgba(18, 159, 90, 0.5)',
    lightgreen50: 'rgba(153, 222, 115, 0.5)',
    brightgreen50: 'rgba(0, 255, 0, 0.5)'
  }
}

export const deviceTypes = {
  anchor: { name: 'Anchor', value: 'Anchor' },
  tag: { name: 'Tag', value: 'PlayerTag' },
  ball: { name: 'Ball', value: 'Ball' }
}

export function getDeviceType(eNum) {
  let deviceTypeObj
  for (var key in deviceTypes) {
    if (deviceTypes[key].value === eNum) deviceTypeObj = deviceTypes[key]
  }
  return deviceTypeObj
}

export const qaStateNames = {
  fieldQA: 'Field QA',
  hwQA: 'Hardware QA',
  investigate: 'Investigate',
  retired: 'Retired'
}

// forward pass
export const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value)
}

export const checkForForwardPass = (flight, sportType) => {
  if (sportType === sportTypes.items.americanFootball.value) return false
  return flight.forward && flight.type === et.items.pass.value
}

export const isForwardPassRelevant = (flight, sportType) => {
  if (sportType === sportTypes.items.americanFootball.value) return false
  // return (
  //   (!!flight.passXVelocity || typeof flight.passXVelocity === 'number') &&
  //   (!!flight.probabilityForward ||
  //     typeof flight.probabilityForward === 'number')
  // )
  const { passXVelocity, carryXVelocity, relativeVelX, probabilityForward } =
    flight
  return (
    isNumber(passXVelocity) &&
    isNumber(carryXVelocity) &&
    isNumber(relativeVelX) &&
    isNumber(probabilityForward)
  )
}

export const isForwardPassNotRelevant = (flight) => {
  return (
    flight.probabilityForward === 0 &&
    !flight.passXVelocity &&
    !flight.carryXVelocity &&
    !flight.relativeVelX
  )
}

export const checkForObject = (obj) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype

// Graphics
export const graphicsSelectionList = [
  {
    selectionName: 'Highest Hang-Time',
    id: 'HighestHangTimeKick',
    sportType: ['RugbyUnion']
  },
  {
    selectionName: 'Longest Kick',
    id: 'LongestKick',
    sportType: ['RugbyUnion', 'AmericanFootball']
  },
  {
    selectionName: 'Kicking Head-to-Head (union)',
    id: 'KickingHead2Head',
    sportType: ['RugbyUnion']
  },
  {
    selectionName: 'Box Kicking Head-to-Head (union)',
    id: 'BoxKickingHead2Head',
    sportType: ['RugbyUnion']
  },
  {
    selectionName: 'Kicking Head-to-Head (league)',
    id: 'KickingHead2HeadLeague',
    sportType: ['RugbyUnion', 'RugbyLeague']
  },
  {
    selectionName: 'Passing Head-to-Head',
    id: 'PassingHead2Head',
    sportType: ['RugbyUnion', 'AmericanFootball']
  },
  {
    selectionName: 'Passing Head-to-Head',
    id: 'PassingPlayersHead2Head',
    sportType: ['Soccer']
  },
  {
    selectionName: 'Longest Pass',
    id: 'LongestPass',
    sportType: ['RugbyUnion', 'AmericanFootball']
  },
  {
    selectionName: 'Fastest Pass',
    id: 'FastestPass',
    sportType: ['RugbyUnion', 'AmericanFootball']
  },
  {
    selectionName: 'Longest Kick Top 5',
    id: 'TopFiveLongestKicks',
    sportType: ['RugbyUnion', 'AmericanFootball']
  },
  {
    selectionName: 'Highest Hang-Time Top 5',
    id: 'TopFiveHighestHangTimeKicks',
    sportType: ['RugbyUnion']
  },
  {
    selectionName: 'Top 5 Fastest Shot Scored',
    id: 'TopFiveShotsAtGoal',
    sportType: ['Soccer']
  },
  {
    selectionName: 'Shots at Goal',
    id: 'ShotsAtGoal',
    sportType: ['Soccer']
  }
]

export const getGraphicsTypeBySportsType = (sportType, graphicsList) => {
  return graphicsList.filter((graphic) => {
    if (graphic.sportType.includes(sportType)) {
      return graphic
    }
  })
}

export const graphicTournamentOptions = {
  RugbyUnion: [
    { name: 'Super Rugby', value: 'SuperRugby' },
    { name: 'Prem Cup', value: 'PremCup' },
    { name: 'ENG v AUS', value: 'ENGvAUS' },
    { name: 'ENG v AUS - ENG', value: 'ENG' },
    { name: 'ENG v AUS - AUS', value: 'AUS' },
    { name: 'Genius', value: 'Genius' }
  ],
  AmericanFootball: [
    { name: 'Verizon', value: 'Verizon' },
    { name: 'Genius', value: 'Genius' },
    { name: 'N/A', value: 'n/a' }
  ],
  AustralianRules: [{ name: 'Australian Rules', value: 'AustralianRules' }],
  CanadianFootball: [{ name: 'Canadian Football', value: 'CanadianFootball' }],
  Boxing: [{ name: 'Boxing', value: 'Boxing' }],
  Demonstration: [{ name: 'Demonstration', value: 'Demonstration' }],
  RugbyLeague: [
    { name: 'NRL Pacific', value: 'NRLPacific' },
    { name: 'NRLW', value: 'NRLW' }
  ],
  Soccer: [{ name: 'Premier League', value: 'PremierLeague' }]
}

export const getGraphicTournamentOptionsBySportsType = (
  sportType,
  tournamentOptions
) => {
  return tournamentOptions.filter((tournament) => {
    if (tournament.sportType.includes(sportType)) {
      return tournament
    }
  })
}

export function getDeviceStateColour(
  device: DeviceState,
  hardwareStatus: HardwareDiagnosticsPacket['status']
) {
  if (!device) return null
  let status
  switch (device.type) {
    case 'Ball':
      status = 'balls'
      break
    case 'PlayerTag':
      status = 'tags'
      break
    case 'Anchor':
      status = 'anchors'
      break
    default:
      break
  }
  if (!status) return null
  if (hardwareStatus.sleep[status].includes(device.id))
    return sportableColors.colors.colorWarning50
  if (hardwareStatus.online[status].includes(device.id))
    return sportableColors.colors.colorSuccess50
  if (hardwareStatus.offline[status].includes(device.id))
    return sportableColors.colors.colorError50
  return null
}
