import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  CheckboxFilters,
  LabelledInput,
  Card
} from '@frontend/ui'
import {
  StrackCanvas,
  StrackContainer,
  StrackEvents,
  eventActions,
  getMockFlightEvent,
  sessionTypes,
  sportTypes,
  useAppDispatch,
  useEventsFilter,
  useFormattedSession,
  useFormattedTeam,
  useLanguage,
  useSessionFilter,
  useTeams
} from '@frontend/sportable'
import { PlayerPerformanceTable } from './player-performance-table'
import { useEffect, useMemo, useState } from 'react'

const textData = {
  showStatsByLabel: {
    en: 'Show stats by',
    fr: 'Afficher les statistiques par'
  },
  lastSessionOption: {
    en: 'Last session',
    fr: 'Dernière session'
  },
  trainingOption: {
    en: 'Training',
    fr: 'Entraînement'
  },
  matchOption: {
    en: 'Match',
    fr: 'Match'
  },
  sessionNameLabel: {
    en: 'Session name',
    fr: 'Nom de la session'
  },
  timeframeLabel: {
    en: 'Timeframe',
    fr: 'Plage horaire'
  },
  physicalMetricParameterLabel: {
    en: 'Physical Metric Parameter',
    fr: 'Paramètre de métrique physique'
  }
}

const options = {
  babylonActive: false,
  canvasId: 'performance-pitch',
  cover: 'rugby-cover',
  tracking: true,
  diags: false,
  anchorSetup: false,
  eventLabels: true,
  drillRegions: true
}

const canvasStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  padding: '0px',
  boxSizing: 'border-box',
  position: 'absolute',
  bottom: '0px',
  left: '0px'
}

// TODO: Fix typing issues //
const StrackPitch = ({
  strack,
  strackReady,
  canvasView,
  canvasStyle,
  setResetStrack,
  pitch,
  filteredEvents
}: {
  strack?
  strackReady?
  canvasView?
  canvasStyle?
  setResetStrack?
  pitch?
  filteredEvents?
}) => {
  // Redraw when pitch changes
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    if (isMounted) {
      setResetStrack(true)
    } else {
      setIsMounted(true)
    }
  }, [pitch])

  return (
    <StrackEvents
      events={filteredEvents?.map((event) => event.rawData) || []}
      active={true}
      canvasView={canvasView}
      strack={strack}
      canvasStyle={canvasStyle}
      strackReady={strackReady}
    />
  )
}

function getRandomObjects() {
  function getRandomValue(min, max) {
    return Math.random() * (max - min) + min
  }

  const obj1 = {
    pos: {
      x: getRandomValue(-50, 50),
      y: getRandomValue(0, 70),
      z: 2
    }
  }

  const obj2 = {
    pos: {
      x: getRandomValue(-50, 50),
      y: getRandomValue(0, 70),
      z: 2
    }
  }

  return [obj1, obj2]
}

export function PlayerPerformance({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const dispatch = useAppDispatch()

  const language = useLanguage()

  const [showBy, setShowBy] = useState('lastSession')
  const [selectedSessionId, setSelectedSessionId] = useState(null)

  const formattedSession = useFormattedSession(selectedSessionId)

  useEffect(() => {
    if (formattedSession.id) {
      // dispatch(eventActions.getSessionEvents(formattedSession.id))
      // dispatch(subSessionActions.getSubSessions(formattedSession.id))
    }
  }, [formattedSession])

  const teamsState = useTeams()
  const formattedTeam = useFormattedTeam(teamsState.selectedTeam)

  const sport = formattedTeam?.sport || sportTypes.items.soccer

  const {
    sortedSessions: allTeamSessions,
    updateFilterValue: updateAllTeamSessionsFilter
  } = useSessionFilter({
    team: {
      options: teamsState.options,
      value: teamsState.selectedTeam
    },
    sportType: {
      options: [],
      value: sport.value
    }
  })

  const allTeamSessionOptions = useMemo(() => {
    return allTeamSessions.map((session) => ({
      name: session.name,
      value: session.id
    }))
  }, [allTeamSessions])

  useEffect(() => {
    if (formattedTeam) {
      updateAllTeamSessionsFilter('team', teamsState.selectedTeam)
      updateAllTeamSessionsFilter('sportType', sport.value)
    }
  }, [formattedTeam])

  useEffect(() => {
    if (showBy === 'lastSession') {
      updateAllTeamSessionsFilter('type', null)
    } else if (showBy === 'training') {
      updateAllTeamSessionsFilter('type', sessionTypes.items.training.value)
    } else if (showBy === 'match') {
      updateAllTeamSessionsFilter('type', sessionTypes.items.match.value)
    }
  }, [showBy, updateAllTeamSessionsFilter])

  useEffect(() => {
    if (
      !allTeamSessionOptions.some(
        (sessionOption) => sessionOption.value === selectedSessionId
      )
    ) {
      setSelectedSessionId(allTeamSessionOptions[0]?.value)
    }
  }, [allTeamSessionOptions])

  useEffect(() => {
    if (!formattedSession?.sport) return

    const mockFlightEvents: ReturnType<typeof getMockFlightEvent>[] = []

    formattedSession.sport.props.parentEventTypes.items.flight.props.types.list.forEach(
      (flightEventType, index) => {
        // If the event type has no sub types, create a single mock event //
        if (flightEventType.props.types.isEmpty()) {
          const mockFlightData = getMockFlightEvent(
            'flightEvent-' + index,
            flightEventType.value,
            formattedSession.id,
            formattedSession.sessionData.teamsSessions[0].teamId,
            formattedSession.sessionData.playersSessions[0].playerId,
            null,
            null,
            0
          )
          mockFlightEvents.push(mockFlightData)
        }
      }
    )

    console.log(mockFlightEvents)
    mockFlightEvents.forEach((mockFlightData, index) => {
      mockFlightData.data = getRandomObjects()
      dispatch(eventActions.handleNewEventOnTopic(mockFlightData))
    })
  }, [formattedSession])

  const {
    filteredEvents: filteredPitchEvents,
    filters: pitchEventFilters,
    updateFilterValue: updatePitchEventFilterValue
  } = useEventsFilter(
    {
      team: {
        options: formattedSession.teams.options
      },
      type: {
        type: 'multipleWithAll'
      }
    },
    [
      'flight'
      // 'aussieRules',
      // 'snapGameEvent',
      // 'handOff',
      // 'kickOffGameEvent',
      // 'twoPointConversion',
      // 'touchDown',
      // 'penaltyGameEvent',
      // 'touch',
      // 'try',
      // 'tackle',
      // 'safety',
      // 'defensiveTwoPoint',
      // 'goalLineCrossed',
      // 'soccerGoal',
      // 'soccerBallOverLine',
      // 'manualPass',
      // 'fumble'
    ],
    formattedSession.id
  )

  console.log(filteredPitchEvents)

  const pitchFilterOptions = useMemo(() => {
    return pitchEventFilters.type.options.map((option, index) => {
      let name = option.name
      if (typeof option.name === 'object') {
        name = option.name[language]
      }
      return {
        label: name,
        value: option.value,
        color: option.color
      }
    })
  }, [pitchEventFilters.type.options, language])

  return (
    <>
      <div className='relative w-[calc(100%+4rem)] -left-8 px-8 py-7 bg-muted'>
        <div className='flex'>
          {/* Stats By Select */}
          <LabelledInput
            label={textData.showStatsByLabel[language]}
            className='mr-6'
          >
            <Select value={showBy} onValueChange={(value) => setShowBy(value)}>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='lastSession'>
                  {textData.lastSessionOption[language]}
                </SelectItem>
                <SelectItem value='training'>
                  {textData.trainingOption[language]}
                </SelectItem>
                <SelectItem value='match'>
                  {textData.matchOption[language]}
                </SelectItem>
              </SelectContent>
            </Select>
          </LabelledInput>
          {/* Session Select */}
          {(showBy === 'training' || showBy === 'match') && (
            <LabelledInput
              label={textData.sessionNameLabel[language]}
              className='mr-6'
            >
              <Select
                value={selectedSessionId || '0'}
                onValueChange={(value) => setSelectedSessionId(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder='' />
                </SelectTrigger>
                <SelectContent>
                  {allTeamSessionOptions.map((session) => (
                    <SelectItem key={session.value} value={session.value}>
                      {session.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </LabelledInput>
          )}
          {/* Timeframe Select */}
          {/* <LabelledInput
            label={textData.timeframeLabel[language]}
            className='mr-6'
          >
            <Select>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent></SelectContent>
            </Select>
          </LabelledInput> */}
          {/* Physical Select */}
          {/* <LabelledInput
            label={textData.physicalMetricParameterLabel[language]}
            className='mr-6'
          >
            <Select>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent></SelectContent>
            </Select>
          </LabelledInput> */}
        </div>
      </div>
      <div className='flex-1 pt-16'>
        <PlayerPerformanceTable sessionId={formattedSession.id} />
      </div>
      <div className='flex justify-center p-8 pt-16'>
        <CheckboxFilters
          selectedFilterValues={pitchEventFilters.type.value as string[]}
          filterOptions={pitchFilterOptions}
          setSelectedFilters={(filters) => {
            updatePitchEventFilterValue('type', filters as any)
          }}
        />
      </div>
      <div className='flex-1 h-screen'>
        <Card className='h-screen'>
          {formattedSession?.pitch?.type && (
            <StrackContainer padding={0} hiddenCanvases={[]}>
              <StrackCanvas
                active={true}
                selected={true}
                canvasId={'performance-pitch'}
                strackOptions={{
                  pitch: {
                    coordinates: formattedSession.pitch.coordinates,
                    arcs: formattedSession.pitch.arcs,
                    type: formattedSession.pitch.type
                  },
                  anchorConfig: formattedSession.anchorConfig,
                  sessionId: formattedSession?.id,
                  session: formattedSession,
                  ...options
                }}
                canvasStyle={canvasStyle}
              >
                <StrackPitch
                  pitch={formattedSession.pitch}
                  filteredEvents={filteredPitchEvents}
                />
              </StrackCanvas>
            </StrackContainer>
          )}
        </Card>
      </div>
    </>
  )
}
