// TODO: this component needs work - we need to add recent events detail card metrics to sport / session config //

import React from 'react'
import { CommentatorStatistic } from '../CommentatorStatistic/CommentatorStatistic'
import styles from './RecentEventsFlightDetailCard.module.scss'
import { useSelectedFormattedEvent } from '../../metrics_server/events/hooks'
import { parentEventTypes } from '../../metrics_server/events/data_types'

export interface RecentEventsFlightDetailCardProps {
  flightTime
}

export const RecentEventsFlightDetailCard = ({
  flightTime
}: RecentEventsFlightDetailCardProps) => {
  // Events //
  const formattedEvent = useSelectedFormattedEvent()
  // ====== //

  if (!formattedEvent)
    return (
      <div className={styles.noFlightContainer}>
        {/* <p>Select an event...</p> */}
      </div>
    )

  if (formattedEvent.eventType !== parentEventTypes.items.flight.value)
    return (
      <div className={styles.noFlightContainer}>
        {/* <p>Select an event...</p> */}
      </div>
    )

  return (
    <div className={styles.detailCardContainer}>
      <div className={styles.detailCardHeader}>
        <h5 className={styles.detailCardTime}>{flightTime}</h5>
        <h3 className={styles.detailCardAttribution}>
          {formattedEvent.player
            ? `${formattedEvent.player.selected?.fullName}`
            : ''}
        </h3>
      </div>

      {formattedEvent.type.selected.value ===
        parentEventTypes.items.flight.value && (
        <div className={styles.detailCardStatContainer}>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic
              title={formattedEvent.metrics.dist?.name}
              stat={formattedEvent.metrics.dist?.display}
            />
            <CommentatorStatistic
              title={formattedEvent.metrics.speed?.name}
              stat={formattedEvent.metrics.speed?.display}
            />
          </div>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic title={formattedEvent.type?.selected?.name} />
          </div>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic
              title={formattedEvent.metrics.territorialGain?.name}
              stat={formattedEvent.metrics.territorialGain?.display}
            />
            <CommentatorStatistic
              title={formattedEvent.metrics.hangTime?.name}
              stat={formattedEvent.metrics.hangTime?.display}
            />
          </div>
        </div>
      )}

      {/* 
      {isFlightEventType.pass(formattedEvent.type.selected) && (
        <div className={styles.detailCardStatContainer}>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic
              title={formattedFlightData.dist?.name}
              stat={formattedFlightData.dist?.value}
            />
            <CommentatorStatistic
              title={formattedFlightData.speed?.name}
              stat={formattedFlightData.speed?.value}
            />
          </div>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic title={flightType.name} />
          </div>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic
              title={formattedFlightData.efficiency?.name}
              stat={formattedFlightData.efficiency?.value}
            />
            <CommentatorStatistic
              title={formattedFlightData.hangTime?.name}
              stat={formattedFlightData.hangTime?.value}
            />
          </div>
        </div>
      )}
      {isFlightEventType.lineout(formattedEvent.type.selected) && (
        <div className={styles.detailCardStatContainer}>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic
              title={formattedFlightData.dist?.name}
              stat={formattedFlightData.dist?.value}
            />
            <CommentatorStatistic
              title={formattedFlightData.speed?.name}
              stat={formattedFlightData.speed?.value}
            />
          </div>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic title={flightType.name} />
          </div>
          <div className={styles.detailCardStat}>
            <CommentatorStatistic
              title={formattedFlightData.efficiency?.name}
              stat={formattedFlightData.efficiency?.value}
            />
            <CommentatorStatistic
              title={formattedFlightData.lineoutDeviation?.name}
              stat={formattedFlightData.lineoutDeviation?.value}
            />
          </div>
        </div>
      )}
      {!flightEventTypes.isType('pass', flightData.type) &&
        !flightEventTypes.isType('kick', flightData.type) &&
        !flightEventTypes.isType('lineout', flightData.type) && (
          <div className={styles.detailCardStatContainer}>
            <div className={styles.detailCardStat}>
              <CommentatorStatistic
                title={formattedFlightData.dist?.name}
                stat={formattedFlightData.dist?.value}
              />
              <CommentatorStatistic
                title={formattedFlightData.speed?.name}
                stat={formattedFlightData.speed?.value}
              />
            </div>
            <div className={styles.detailCardStat}>
              <CommentatorStatistic title={flightType.name} />
            </div>
            <div className={styles.detailCardStat}>
              <CommentatorStatistic
                title={formattedFlightData.hangTime?.name}
                stat={formattedFlightData.hangTime?.value}
              />
            </div>
          </div>
        )} */}
    </div>
  )
}
