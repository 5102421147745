import React, { useMemo, useState } from 'react'
import { EventTypeValues } from '../types/data_types'
import { EventSubTypeValues } from '../subTypes/data_types'
import { useEventsFilter } from '../filter'
import { AllEventTypeKeys, ProcessedRawEventData, RawEventData } from '../types'
import { TeamLogoCell } from '../../../components/Table/CustomCells/TeamLogoCell/TeamLogoCell'
import { useAppDispatch } from '../../../store/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { deleteTimeEvent, updateTimeEvent } from '../actions'
import { ConfirmationRequest } from '../../../components/ConfirmationRequest/ConfirmationRequest'
import { useSelectedFormattedSession } from '../../sessions/hooks'
import { useSportscaster } from '../../sportscaster/hooks'
import { getCurrentPeriod } from '../time/functions'
import { Table } from '../../../components/Table/Table'
import { ParentEventType } from '../data_types'
import { handleName } from '../../data_types'

type HiddenConfig = {
  deleteTimeEvent: boolean
}

export type CodingTableRow = {
  typeName: string
  type: EventTypeValues
  subType: EventSubTypeValues
  startTime: number
  id: string
  eventId: string
  __hidden?: HiddenConfig
  teamLogo: {
    logo: string
    color: string
  }
  rawData: RawEventData
}

export const generateCodingTableTimeEventRowData = (
  timeEvent: ProcessedRawEventData,
  timeEvents: ProcessedRawEventData[],
  periodName: string
) => {
  const rows = []

  const currentPeriod = getCurrentPeriod(timeEvents)

  const timeEventType = timeEvent.primaryType

  const timeEventName = {
    start: timeEventType.props.features.period
      ? `${timeEventType.name} ${periodName} ${timeEventType.props.features.startName}`
      : `${timeEventType.name} ${timeEventType.props.features.startName}`,
    end: timeEventType.props.features.period
      ? `${timeEventType.name} ${periodName} ${timeEventType.props.features.endName}`
      : `${timeEventType.name} ${timeEventType.props.features.endName}`
  }

  const startTime = {
    typeName: timeEventName.start,
    type: timeEventType.value,
    id: `${timeEvent.id}-start`,
    eventId: timeEvent.id,
    start: true,
    half: timeEventType.value,
    __hidden: {
      deleteTimeEvent: currentPeriod?.id !== timeEvent.id
    },
    deleteTimeEvent: 'bin',
    startTime: timeEvent.rawValues.startTime * 1000,
    rawData: timeEvent.rawData
  }
  rows.push(startTime)

  if (timeEvent.rawValues.endTime) {
    startTime.__hidden.deleteTimeEvent = true
    const timeEnd = {
      typeName: timeEventName.end,
      type: timeEventType.value,
      id: `${timeEvent.id}-end`,
      eventId: timeEvent.id,
      half: timeEventType.value,
      __hidden: {
        deleteTimeEvent: currentPeriod?.id !== timeEvent.id
      },
      deleteTimeEvent: 'bin',
      startTime: timeEvent.rawValues.endTime * 1000,
      rawData: timeEvent.rawData
    }
    rows.push(timeEnd)
  }

  return rows
}

export const generateCodingTableGameEventRowData = (
  gameEvent: ProcessedRawEventData
) => {
  const row: CodingTableRow = {
    typeName: handleName(gameEvent.primaryType.name),
    rawData: gameEvent.rawData,
    type: gameEvent.rawValues.type,
    subType: gameEvent.rawValues.subType,
    startTime: gameEvent.rawValues.startTime * 1000,
    id: `${gameEvent.id}-gameEvent`,
    eventId: gameEvent.id,
    __hidden: {
      deleteTimeEvent: true
    },
    teamLogo: gameEvent.processedValues.team || {
      logo: '',
      color: 'black'
    }
  }

  return row
}

export const generateCodingTableEventRowData = (
  data: ProcessedRawEventData[]
) => {
  let tableData = []
  data.forEach((event) => {
    switch (event.rawValues.parentType) {
      case 1:
        tableData = [
          ...generateCodingTableTimeEventRowData(
            event,
            data,
            handleName(
              event.processedValues.parentType.props?.features?.periodName
            ) || 'Period'
          ),
          ...tableData
        ]
        break
      case 6:
        tableData = [generateCodingTableGameEventRowData(event), ...tableData]
        break
      default:
        break
    }
  })
  return tableData
}

export const CodingTable = ({ active }: { active: boolean }) => {
  const dispatch = useAppDispatch()

  const sportscaster = useSportscaster()

  const formattedSession = useSelectedFormattedSession()
  const { live } = formattedSession

  // Manage visible coding table events
  const [visibleCodingTableEvents, setVisibleCodingTableEvents] = useState<
    AllEventTypeKeys[]
  >([
    'stoppage',
    'firstPeriod',
    'secondPeriod',
    'thirdPeriod',
    'fourthPeriod',
    'overTime',
    'possession',
    'snapGameEvent',
    'kickOffGameEvent',
    'handOff',
    'twoPointConversion',
    'penaltyGameEvent',
    'tackle',
    'safety',
    'defensiveTwoPoint',
    'qbKneel',
    'qbSack',
    'qbRush',
    'lineSet',
    'huddleBreak',
    'timeOut',
    'fumble',
    'manualPass'
  ])

  const togglePossessionEvents = () => {
    if (visibleCodingTableEvents.includes('possession')) {
      setVisibleCodingTableEvents(
        visibleCodingTableEvents.filter((event) => event !== 'possession')
      )
    } else {
      setVisibleCodingTableEvents([...visibleCodingTableEvents, 'possession'])
    }
  }

  const toggleStoppageEvents = () => {
    if (visibleCodingTableEvents.includes('stoppage')) {
      setVisibleCodingTableEvents(
        visibleCodingTableEvents.filter((event) => event !== 'stoppage')
      )
    } else {
      setVisibleCodingTableEvents([...visibleCodingTableEvents, 'stoppage'])
    }
  }

  const codingEventsFilters = useEventsFilter({}, visibleCodingTableEvents)

  const openDeleteEventConfirmationModal = (event) => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: () => {
          if (!event.start) {
            console.log(event)
            dispatch(
              updateTimeEvent({
                ...event.rawData,
                id: event.eventId,
                eventId: event.eventId,
                timeEnd: 0,
                sessionId: formattedSession.id
              })
            )
          } else {
            // if it is during 1st half or second half
            dispatch(deleteTimeEvent(event.eventId))
          }
          dispatch(toggleModal({}))
        },
        ChildComponent: ConfirmationRequest,
        message: 'Are you sure?',
        className: 'modalSmall',
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  const tableHeaders = useMemo(() => {
    const headers = [
      { name: 'Time', key: 'startTime', width: 30, type: 'date' },
      { name: 'Type', key: 'typeName', width: 50 },
      {
        name: 'Team',
        key: 'teamLogo',
        width: 9,
        type: 'component',
        CustomComponent: TeamLogoCell
      },
      {
        // Hide this on post sessions //
        name: '',
        key: 'deleteTimeEvent',
        type: 'icon',
        input: {
          type: 'icon',
          callback: (item) => {
            openDeleteEventConfirmationModal(item)
          }
        }
      }
    ]

    return headers
  }, [])

  const codingTableGeniusEventRowData = (geniusEvent: {
    eventType: string
    isConfirmed: boolean
    utcTimestamp: number
  }): CodingTableRow => {
    const { eventType, utcTimestamp } = geniusEvent
    const date = new Date(utcTimestamp)
    return {
      rawData: {} as any,
      typeName: eventType + ' - Genius Event',
      type: null,
      subType: null,
      startTime: date.getTime(),
      id: `${eventType}${utcTimestamp}-geniusEvent`,
      eventId: `${eventType}${utcTimestamp}-geniusEvent`,
      __hidden: {
        deleteTimeEvent: true
      },
      teamLogo: {
        logo: '',
        color: 'black'
      }
    }
  }

  // Convert event data into coding table data
  const codingTableData = useMemo(() => {
    const data = generateCodingTableEventRowData(
      codingEventsFilters.filteredEvents
    )

    // Hack - adding genius events to the coding table
    const geniusEventTableData = sportscaster.events
      .filter(
        (geniusEvent: any) => geniusEvent.eventType === 'AutoEventPassForward'
      )
      .map((geniusEvent: any) => codingTableGeniusEventRowData(geniusEvent))

    return [...data, ...geniusEventTableData]
  }, [codingEventsFilters, sportscaster.events])

  return (
    <Table
      // Row props
      id='eventTable'
      smallHead={true}
      // controls
      controls={[
        {
          name: visibleCodingTableEvents.includes('possession')
            ? 'Hide Possession'
            : 'Show Possession',
          callback: () => {
            togglePossessionEvents()
          }
        },
        {
          name: visibleCodingTableEvents.includes('stoppage')
            ? 'Hide Stoppages'
            : 'Show Stoppages',
          callback: () => toggleStoppageEvents()
        }
      ]}
      disableKeyboardShortcuts={{
        right: true,
        left: true,
        up: false,
        down: false
      }}
      // Table props
      options={{
        initialOrder: live ? 'dec' : 'asc',
        initialSortBy: 'startTime',
        sortActive: true
      }}
      headerFont={13}
      tableClass={'minimalistBlack'}
      className={'container'}
      data={codingTableData}
      headers={tableHeaders}
      pagination={true}
      scrollShortcutsEnabled={active}
    />
  )
}
