import React, { Component } from 'react'

import styles from './Loader.module.scss'
interface LoaderProps {
  color?: string
  message?: string
  small?: boolean
}

interface LoaderState {
  id: string
}

class Loader extends Component<LoaderProps, LoaderState> {
  constructor(props) {
    super(props)

    // Generate unique id for loader gradient
    var id = function () {
      return '_' + Math.random().toString(36).substr(2, 9)
    }

    this.state = { id: id() }
  }

  render() {
    let { color, small } = this.props
    let { id } = this.state
    if (!color) {
      color = '#0099cc'
    }

    return (
      <div className={styles.loadingContainer}>
        <svg
          className={small ? styles.spinnerSmall : styles.spinner}
          width={small ? '20px' : '30px'}
          height={small ? '20px' : '30px'}
          viewBox='0 0 66 66'
        >
          <circle
            className={styles.path}
            fill='transparent'
            strokeWidth='5'
            cx='33'
            cy='33'
            r='30'
            style={{ stroke: `url(#gradient${id}` }}
          />
          <linearGradient id={`gradient${id}`}>
            <stop
              offset='50%'
              style={{ stopColor: 'hsl(var(--foreground))' }}
              stopOpacity='1'
            />
            <stop
              offset='65%'
              style={{ stopColor: 'hsl(var(--foreground))' }}
              stopOpacity='.5'
            />
            <stop
              offset='100%'
              style={{ stopColor: 'hsl(var(--foreground))' }}
              stopOpacity='0'
            />
          </linearGradient>
          <svg
            className={`${styles.spinnerDot} ${styles.dot}`}
            width='6px'
            height='6px'
            viewBox='0 0 66 66'
            xmlns='http://www.w3.org/2000/svg'
            x='37'
            y='1'
          >
            <circle
              className={styles.path}
              fill={'hsl(var(--foreground))'}
              cx='33'
              cy='33'
              r='30'
            />
          </svg>
        </svg>
        {this.props.message && (
          <p
            id='loading-message'
            style={
              color === '#ffffff'
                ? { color: 'hsl(var(--foreground))' }
                : { color: 'hsl(var(--foreground))' }
            }
          >
            {this.props.message}
          </p>
        )}
      </div>
    )
  }
}

export default Loader
