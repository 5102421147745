import React, { useEffect, useState } from 'react'

import { MainView } from '../components/Views/Main/Main'
import { FormView } from '../components/Views/Form/Form'
import { DashboardView } from '../components/Views/Dashboard/Dashboard'

// Types
import { ViewType } from '../components/Views/Views.types'
import { Routes } from './App.types'

import requireAuth from '../ui/authentication/hoc'
import { SideBarView } from '../components/Views/SideBar/SideBar'
import { PageView } from '../components/Views/Page/Page'

// View Map
const viewMap = {
  [ViewType.MAIN]: MainView,
  [ViewType.FORM]: FormView,
  [ViewType.DASHBOARD]: DashboardView,
  [ViewType.SIDEBAR]: SideBarView,
  [ViewType.PAGE]: PageView
}

function connectRoute(route, landing) {
  let { type, Container } = route.view
  let { authenticate } = route
  let View = viewMap[type]
  let ConnectedView

  if (Container) {
    ConnectedView = authenticate
      ? requireAuth(Container(View), landing)
      : Container(View)
  } else {
    ConnectedView = requireAuth(View, landing)
  }
  return {
    ...route,
    ConnectedView
  }
}

export const CustomRoute = (props) => {
  const { route, landing, requireAuth } = props

  let { type, useProps } = route.view

  const [connectedRoute] = useState(connectRoute(route, landing))

  const viewProps = useProps ? useProps(props) : {}
  useEffect(() => {})

  // const viewProps = useProps ? useProps(route.view) : {}

  return (
    <connectedRoute.ConnectedView
      {...props}
      history={props.history}
      location={props.location}
      view={route.view}
      path={route.path}
      {...viewProps}
    />
  )
}
