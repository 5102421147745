import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from '@frontend/ui'

import avatar from './avatar.svg'

export function PlayerCarousel({
  selectedId,
  onChange,
  players
}: {
  selectedId: string
  onChange: (id: string) => void
  players: any[]
}) {
  return (
    <div className='w-full bg-muted'>
      <Carousel
        opts={{
          align: 'center',
          loop: true
        }}
        className=' h-52'
      >
        <CarouselContent>
          {players.map((player) => (
            <CarouselItem key={player.id} className='basis-40 pt-8 pb-4'>
              <div
                className='relative flex flex-col items-center cursor-pointer transition-all duration-300'
                onClick={() => onChange(player.id)}
              >
                <div className={`relative mb-4`}>
                  <div
                    className={`w-full rounded-full overflow-hidden border-2 ${
                      selectedId === player.id
                        ? 'border-blue-500 border-[3px]'
                        : 'border-foreground'
                    } transition-transform duration-300 ${
                      selectedId === player.id ? 'scale-125' : 'scale-100'
                    }`}
                  >
                    {player.img ? (
                      <img
                        src={player.img}
                        alt={player.id}
                        className='w-20 h-20 object-cover object-top top-0 relative'
                      />
                    ) : (
                      <img
                        src={avatar}
                        alt={player.id}
                        className='w-20 h-20 object-cover object-top'
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    selectedId === player.id ? 'text-xl mt-2' : 'text-sm'
                  } font-condensed text-center transition-all duration-300 h-14 leading-tight w-48`}
                >
                  <p>{player.number}</p>
                  <p>
                    {player.firstName.charAt(0)}. {player.lastName}
                  </p>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className='h-full left-0 rounded-none border-none bg-muted shadow-[6px_0_8px_0_rgba(0,0,0,0.25)]' />
        <CarouselNext className='h-full right-0 rounded-none border-none bg-muted shadow-[-6px_0_8px_0_rgba(0,0,0,0.25)]' />
      </Carousel>
    </div>
  )
}
