// Events Filter //

import { useEffect, useMemo, useRef } from 'react'
import { ParentEventTypeGroup, ParentEventTypeKeys } from './data_types'
import { useEvents } from './hooks'
import {
  useFormattedSession,
  useSelectedFormattedSession
} from '../sessions/hooks'
import {
  useFilterReducer,
  FilterTypes,
  getFilterState,
  runCheck,
  CustomFilterState
} from '../../hooks/filter'
import { AllEventTypeKeys, ProcessedRawEventData } from './types'
import { metricTypes, RawMetricTypeValues } from '../metrics/data_types'
import { EventTypeKeys, eventTypes } from './types/data_types'
import { EventSubTypeKeys, eventSubTypes } from './subTypes/data_types'
import { handleName } from '../data_types'

export type EventFilters = {
  [key in keyof ProcessedRawEventData['rawValues']]: FilterTypes<
    ProcessedRawEventData['rawValues'][key],
    key,
    keyof ProcessedRawEventData['rawValues']
  >
}

export const generateDefaultEventFilters = (): Omit<
  EventFilters,
  keyof RawMetricTypeValues
> => {
  return {
    ignore: {
      key: 'ignore',
      label: 'Ignore',
      type: 'singleWithAll' as const,
      options: [
        {
          name: 'Ignored',
          value: true
        },
        {
          name: 'Valid',
          value: false
        }
      ],
      value: 'All'
    },
    highlighted: {
      key: 'highlighted',
      label: 'Highlighted',
      type: 'singleWithAll' as const,
      options: [
        {
          name: 'True',
          value: true
        },
        {
          name: 'False',
          value: false
        }
      ],
      value: 'All'
    },
    sessionId: {
      key: 'sessionId',
      label: 'Session ID',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    },
    startTime: {
      key: 'startTime',
      label: 'Start Time',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    },
    endTime: {
      key: 'endTime',
      label: 'End Time',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    },
    sessionStartTime: {
      key: 'sessionStartTime',
      label: 'Session Start Time',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    },
    parentType: {
      key: 'parentType',
      label: 'Parent Type',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    },
    parentTypeName: {
      key: 'parentTypeName',
      label: 'Parent Type',
      type: 'searchStr' as const,
      options: [],
      value: null
    },

    type: {
      key: 'type',
      label: 'Type',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    },
    typeName: {
      key: 'typeName',
      label: 'Type Name',
      type: 'searchStr' as const,
      options: [],
      value: null
    },

    subType: {
      key: 'subType',
      label: 'Sub Type',
      type: 'multiple' as const,
      options: [],
      value: null
    },
    subTypeName: {
      key: 'subTypeName',
      label: 'Sub Type Name',
      type: 'searchStr' as const,
      options: [],
      value: null
    },

    ballSerial: {
      key: 'ballSerial',
      label: 'Ball Serial',
      type: 'singleWithAll' as const,
      options: [],
      value: 'All'
    }
  }
}

const generateEventMetricFilters = (
  customEventFilters: CustomFilterState<EventFilters>,
  parentEventTypes: ParentEventTypeGroup
) => {
  const eventType = parentEventTypes.getTypeByValue(
    customEventFilters.parentType?.value
  )
  const type = eventType?.props?.types?.getTypeByValue(
    customEventFilters.type?.value
  )
  const subType = type?.props?.types?.getTypeByValue(
    customEventFilters.subType?.value
  )

  let primaryType: typeof eventType | typeof type | typeof subType = eventType

  if (subType && !subType.isUnknown()) {
    primaryType = subType
  } else if (type && !type.isUnknown()) {
    primaryType = type
  }

  const eventMetricFilters = {} as EventFilters

  if (!primaryType.props.metricTypes) {
    for (const key in metricTypes.items) {
      const metricKey = key
      const metricType = metricTypes.items[metricKey]
      eventMetricFilters[metricKey] = metricType.props.defaultFilterType
    }
    return eventMetricFilters
  }

  for (const key in primaryType.props.metricTypes.items) {
    const metricKey = key
    const metricType = primaryType.props.metricTypes.items[metricKey]
    eventMetricFilters[metricKey] = {
      ...metricType.props.defaultFilterType,
      options: metricType.props.options
    }

    if (!eventMetricFilters[metricKey].value) {
      eventMetricFilters[metricKey].value = null
    }
  }

  return eventMetricFilters
}

export function useEventsFilter(
  customFilterState: CustomFilterState<EventFilters>,
  allIncludedEventTypeKeys: AllEventTypeKeys[],
  sessionId?: string
) {
  // Handle changes to visible event types to avoid unnecessary rerenders //
  const prevStoredVisibleEventTypeKeys = useRef<AllEventTypeKeys[]>([])

  const storedAllIncludedEventTypeKeys = useMemo(() => {
    if (
      !arraysContainSameElements(
        allIncludedEventTypeKeys,
        prevStoredVisibleEventTypeKeys.current
      )
    ) {
      prevStoredVisibleEventTypeKeys.current = allIncludedEventTypeKeys
      return allIncludedEventTypeKeys
    }

    return prevStoredVisibleEventTypeKeys.current
  }, [allIncludedEventTypeKeys])

  // Get all processed events and make an array //
  const events = useEvents()

  const { parentEventTypes, players } = useFormattedSession(sessionId)

  // Get all the parent event types (flight, game, time, aussie) from the visible event types //
  const {
    includedParentEventTypeKeys,
    includedEventTypeKeys,
    includedEventSubTypeKeys
  } = useMemo(
    () =>
      sortKeysIntoEventKeyListsIncludingParentTypes(
        parentEventTypes,
        storedAllIncludedEventTypeKeys
      ),
    [parentEventTypes, storedAllIncludedEventTypeKeys]
  )
  // ===== //

  const filterState = useMemo(() => {
    const fs = getFilterState(customFilterState, {
      ...generateDefaultEventFilters(),
      ...generateEventMetricFilters(customFilterState, parentEventTypes)
    })
    return fs
  }, [sessionId])

  // ===== //

  const { filters, updateFilterValue, updateFilterOptions } =
    useFilterReducer<EventFilters>(filterState)

  // Update Options on Team Change //
  useEffect(() => {
    if (typeof filters.team.value !== 'string') return
    const filteredPlayerOptions = players[filters.team.value].options
    if ('options' in filters.player) {
      updateFilterOptions('player', filteredPlayerOptions)
    }
  }, [filters.team, updateFilterOptions])
  // ============== //

  // Update Type Options on Event Type Change //
  useEffect(() => {
    const parentTypeOptions: typeof parentEventTypes.options = []

    if (parentEventTypes.items) {
      parentEventTypes.keys.forEach((typeKey) => {
        if (includedParentEventTypeKeys.includes(typeKey)) {
          parentTypeOptions.push({
            name: handleName(parentEventTypes.items[typeKey].name),
            value: parentEventTypes.items[typeKey].value
          })
        }
      })
    }

    updateFilterOptions('parentType', parentTypeOptions)
  }, [filterState])

  useEffect(() => {
    const parentEventType = parentEventTypes.getTypeByValue(
      filters.parentType.value
    )

    const typeOptions: typeof eventTypes.options = []

    if (parentEventType && parentEventType.props.types) {
      parentEventType.props.types.keys.forEach((typeKey) => {
        if (includedEventTypeKeys.includes(typeKey)) {
          const eventType = parentEventType.props.types.items[typeKey]
          typeOptions.push({
            name: handleName(eventType.name),
            value: eventType.value,
            color: eventType.props.features?.color
          })
        }
      })
    } else if (
      filters.parentType.value === 'All' ||
      !filters.parentType.value
    ) {
      // Loop through all parent event types and add all event types //
      for (const key in parentEventTypes.items) {
        const parentEventType =
          parentEventTypes.items[key as ParentEventTypeKeys]
        parentEventType.props.types.keys.forEach((typeKey) => {
          if (includedEventTypeKeys.includes(typeKey)) {
            const eventType = parentEventType.props.types.items[typeKey]
            console.log(eventType)
            typeOptions.push({
              name: handleName(eventType.name),
              value: eventType.value,
              color: eventType.props.features?.color
            })
          }
        })
      }
    }

    updateFilterOptions('type', typeOptions)
    updateFilterValue('type', 'All')

    updateFilterOptions('subType', [])
    updateFilterValue('subType', [])
  }, [filters.parentType.value, parentEventTypes, updateFilterOptions])

  useEffect(() => {
    const parentEventType = parentEventTypes.getTypeByValue(
      filters.parentType.value
    )
    const eventType = parentEventType?.props.types?.getTypeByValue(
      filters.type.value
    )

    const subTypeOptions: typeof eventSubTypes.options = []

    if (eventType && eventType.props.types) {
      eventType.props.types.keys.forEach((subTypeKey) => {
        if (includedEventSubTypeKeys.includes(subTypeKey)) {
          const eventSubType = eventType.props.types.items[subTypeKey]
          subTypeOptions.push({
            name: handleName(eventSubType.name),
            value: eventSubType.value
          })
        }
      })
    } else if (filters.type.value === 'All' || !filters.type.value) {
      // Loop through all parent event types and add all event types //
      for (const key in parentEventTypes.items) {
        const parentEventType =
          parentEventTypes.items[key as ParentEventTypeKeys]
        parentEventType.props.types.keys.forEach((typeKey) => {
          const eventType = parentEventType.props.types.items[typeKey]
          eventType.props.types.keys.forEach((subTypeKey) => {
            if (includedEventSubTypeKeys.includes(subTypeKey)) {
              const eventSubType = eventType.props.types.items[subTypeKey]
              subTypeOptions.push({
                name: handleName(eventSubType.name),
                value: eventSubType.value
              })
            }
          })
        })
      }
    }

    updateFilterOptions('subType', subTypeOptions)
    updateFilterValue('subType', null)
  }, [filters.type.value, parentEventTypes, updateFilterOptions])

  useEffect(() => {
    const eventType = parentEventTypes.getTypeByValue(filters.parentType.value)
    const type = eventType?.props.types?.getTypeByValue(filters.type.value)
    const subType = type?.props.types?.getTypeByValue(filters.subType.value)

    let primaryType: typeof eventType | typeof type | typeof subType = eventType

    if (subType && !subType.isUnknown()) {
      primaryType = subType
    } else if (type && !type.isUnknown()) {
      primaryType = type
    }

    // Outcome //
    const outcomeOptions = primaryType?.props.outcomeTypes?.options || []
    updateFilterOptions('outcome', outcomeOptions)

    // Metrics //
    if (primaryType?.props.metricTypes) {
      Object.values(primaryType?.props.metricTypes.items).forEach(
        (metricType) => {
          const primaryEventMetricType =
            primaryType.props.metricTypes.items[metricType.key]
          if ('options' in primaryEventMetricType.props) {
            updateFilterOptions(
              primaryEventMetricType.key,
              primaryEventMetricType.props.options
            )
            if (
              !primaryEventMetricType.props.options.some(
                (option) =>
                  option.value === filters[primaryEventMetricType.key].value
              )
            )
              updateFilterValue(
                primaryEventMetricType.key,
                primaryEventMetricType.props.defaultFilterType.value
              )
          }
        }
      )
    }
  }, [filters.type, parentEventTypes, updateFilterOptions])

  // ============== //

  const previousEventsData = useRef({})
  const previousFilterResults = useRef<{ [key: string]: boolean }>({})

  // Filter //
  const filteredEvents = useMemo(() => {
    console.log(events.rawData)
    const eventsArray = Object.values(events.rawData)

    let updatedEventsArray = eventsArray.filter((event) => {
      // console.log(filters, event)
      // Check if the event has already been filtered //
      // if (previousEventsData.current[event.id] === event) {
      //   return previousFilterResults.current[event.id]
      // }
      // Check if the event passes all filters //
      const filterCheck = Object.values(filters).every((filter) => {
        const itemValue = event.rawValues[filter.key]
        const check = runCheck(filter, itemValue)
        previousFilterResults.current[event.id] = check
        return check
      })
      return filterCheck
    })

    previousEventsData.current = events.rawData

    // Visible Event Filter //
    updatedEventsArray = updatedEventsArray
      // Filter by event type //
      .filter((event) => {
        // Remove all parent event types and event types that are not included in the visible event types list //
        if (
          storedAllIncludedEventTypeKeys.includes(
            event.processedValues.parentType.key
          )
        ) {
          return true
        }

        if (
          storedAllIncludedEventTypeKeys.includes(
            event.processedValues.type.key
          )
        ) {
          return true
        }

        if (
          storedAllIncludedEventTypeKeys.includes(
            event.processedValues.subType?.key
          )
        ) {
          return true
        }

        return false
      })

    return updatedEventsArray
  }, [
    events.rawData,
    filters,
    includedParentEventTypeKeys,
    includedEventTypeKeys,
    includedEventSubTypeKeys
  ])

  return useMemo(() => {
    return {
      filteredEvents,
      filters,
      updateFilterOptions,
      updateFilterValue
    }
  }, [filteredEvents, filters, updateFilterOptions, updateFilterValue])
}

export type EventsFilter = ReturnType<typeof useEventsFilter>

function sortKeysIntoEventKeyListsIncludingParentTypes(
  parentEventTypes: ParentEventTypeGroup,
  allIncludedEventTypeKeys: AllEventTypeKeys[]
) {
  const parentEventTypeKeys = new Set<ParentEventTypeKeys>()
  const eventTypeKeys = new Set<EventTypeKeys>()
  const eventSubTypeKeys = new Set<EventSubTypeKeys>()

  // Sort through all the visible event types and add the parent event types to the set //
  // Check if types and sub types exist without their parent - if so add the parents as well //
  allIncludedEventTypeKeys.forEach((typeKey) => {
    const parentEventTypeKey = typeKey as ParentEventTypeKeys
    const eventTypeKey = typeKey as EventTypeKeys
    const eventSubTypeKey = typeKey as EventSubTypeKeys

    const parentEventType = parentEventTypes.items[parentEventTypeKey]

    if (parentEventType) {
      parentEventTypeKeys.add(parentEventTypeKey)
      parentEventType.props.types.keys.forEach((eventTypeKey) => {
        eventTypeKeys.add(eventTypeKey)
        const eventType = parentEventType.props.types.items[eventTypeKey]
        if (eventType) {
          eventType.props.types.keys.forEach((eventSubTypeKey) => {
            eventSubTypeKeys.add(eventSubTypeKey)
          })
        }
      })
    } else {
      for (const key in parentEventTypes.items) {
        const parentEventType =
          parentEventTypes.items[key as ParentEventTypeKeys]
        const eventType = parentEventType?.props.types?.items[eventTypeKey]

        if (eventType) {
          parentEventTypeKeys.add(parentEventType.key)
          eventTypeKeys.add(eventType.key)
          eventType.props.types.keys.forEach((eventSubTypeKey) => {
            eventSubTypeKeys.add(eventSubTypeKey)
          })
        } else {
          for (const key in parentEventType?.props.types.items) {
            const eventType =
              parentEventType?.props.types?.items[key as EventTypeKeys]
            const eventSubType = eventType?.props.types?.items[eventSubTypeKey]

            if (eventSubType) {
              parentEventTypeKeys.add(parentEventType.key)
              eventTypeKeys.add(eventType.key)
              eventSubTypeKeys.add(eventSubTypeKey)
            }
          }
        }
      }
    }
  })

  return {
    includedParentEventTypeKeys: Array.from(parentEventTypeKeys),
    includedEventTypeKeys: Array.from(eventTypeKeys),
    includedEventSubTypeKeys: Array.from(eventSubTypeKeys)
  }
}

const arraysContainSameElements = (a, b) => {
  if (a.length !== b.length) return false
  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return sortedA.every((val, index) => val === sortedB[index])
}
