import { executeAppRequest } from '../../utils/request_handler'
import { API_ROOT_URL, BROADCAST_ROOT_URL } from '../../const'
import { UnitSystemTypeValues } from '../units/types'
import { RawFlightEventData } from './flight/types'
import { RawTimeEventData } from './time/types'
import { LineChartCoordinates, RawEventData } from './types'
import { RawGameEventData } from './game/types'
import { RawAussieRulesEventData } from './aussie_rules/types'
import { EventTypeValues } from './types/data_types'
import { RawSessionData } from '../sessions/types'

// Get Events //

export type GetSessionEventsData = {
  sessionId: string
  unitSystemValue: UnitSystemTypeValues
}

export type GetSessionEventsReponseBody = RawEventData[]

export const getSessionEvents = async ({
  sessionId,
  unitSystemValue
}: GetSessionEventsData) => {
  const options = {
    url: `sessions/${sessionId}/events?units=${unitSystemValue}`,
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<GetSessionEventsReponseBody>(options)
}

// ====== //

// Event //

export type GetEventResponse = RawEventData

export const getEvent = async (
  eventId: string,
  unitSystemValue: UnitSystemTypeValues
) => {
  const options = {
    url: `events/${eventId}?units=${unitSystemValue}`,
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<GetEventResponse>(options)
}

export function updateEvent(
  data: RawGameEventData | RawTimeEventData | RawAussieRulesEventData,
  sessionId: string,
  unitSystemValue: UnitSystemTypeValues
) {
  const options = {
    url: `sessions/${sessionId}/events/${data.id}?units=${unitSystemValue}`,
    data: data,
    method: 'put' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<RawEventData>(options)
}

// ====== //

// Game //

export type CreateGameEventRequestData = {
  sessionId: string
  type: EventTypeValues
}

export type CreateGameEventResponseBody = RawGameEventData

export function createGameEvent(data: CreateGameEventRequestData) {
  const options = {
    url: `/sessions/${data.sessionId}/gameEvents`,
    data,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<CreateGameEventResponseBody>(options)
}

// ====== //

// Time //

export type CreateTimeEventRequestBody = {
  sessionId: string
  type: EventTypeValues
}

export type TimeEventResponseBody = {
  timeEvents: {
    [id: string]: RawTimeEventData
  }
  session: RawSessionData
}

export function createTimeEvent(data: CreateTimeEventRequestBody) {
  const options = {
    url: `events/time`,
    data,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<TimeEventResponseBody>(options)
}

export type EndTimeEventRequestData = {
  data: RawTimeEventData
  gameEventQueryValue: 'SNAP' | 'KICKOFF'
}

export function endTimeEvent({
  data,
  gameEventQueryValue
}: EndTimeEventRequestData) {
  const options = {
    url: `events/time/${data.id}`,
    data,
    method: 'put' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  if (gameEventQueryValue) {
    options.url += `?gameEvent=${gameEventQueryValue}`
  }
  return executeAppRequest<TimeEventResponseBody>(options)
}

export type UpdateTimeEventRequestData = {
  data: Partial<RawTimeEventData>
}

export function updateTimeEvent(data: UpdateTimeEventRequestData) {
  const options = {
    url: `events/time/${data.data.id}`,
    data: data.data,
    method: 'patch' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<TimeEventResponseBody>(options)
}

export function deleteTimeEvent(eventId: string) {
  const options = {
    url: `events/time/${eventId}`,
    method: 'delete' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<TimeEventResponseBody>(options)
}

// ====== //

// Flight //

export type GetFlightResponse = RawFlightEventData

export const getFlight = async (
  flightId: string,
  unitSystemValue: UnitSystemTypeValues
) => {
  const options = {
    url: `flights/${flightId}?units=${unitSystemValue}`,
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<GetFlightResponse>(options)
}

export function updateFlight(
  data: RawFlightEventData,
  unitSystemValue: UnitSystemTypeValues
) {
  console.log(API_ROOT_URL)
  const options = {
    url: `flights/${data.id}?units=${unitSystemValue}`,
    data: data,
    method: 'put' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<RawFlightEventData>(options)
}

// ====== //

// Rugby Penalty Countdown //

export function getPenaltyCountdownState() {
  const options = {
    url: `penaltyCountdown`,
    method: 'get' as const,
    baseURL: BROADCAST_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<{ enabled: boolean }>(options)
}

export function startPenaltyCountdown() {
  const options = {
    url: `penaltyCountdown`,
    method: 'post' as const,
    baseURL: BROADCAST_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<null>(options)
}

export function clearPenaltyCountdown() {
  const options = {
    url: `penaltyCountdown`,
    method: 'delete' as const,
    baseURL: BROADCAST_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<null>(options)
}

// ====== //

// Ball in play //

export function setBallInPlay(ballId: number) {
  const options = {
    url: `events/ballInPlay/${ballId}`,
    data: {},
    method: 'post' as const,
    baseURL: BROADCAST_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<null>(options)
}

// ====== //

// // Get raw graph data for flight //

export function getFlightRawGraphData(flightId: string) {
  const options = {
    url: `/flights/${flightId}/rawData`,
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<{
    events: RawGameEventData[]
    imuData: LineChartCoordinates[]
  }>(options)
}
