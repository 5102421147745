import { Options } from '../data_types'
import { ParentEventTypeValues } from '../events/data_types'
import { EventSubTypeValues } from '../events/subTypes/data_types'
import { ProcessedRawEventData } from '../events/types'
import { EventTypeValues } from '../events/types/data_types'
import { getEmptyGroup } from '../functions'
import { isOutcomeType, outcomeTypes } from '../outcomes/data_types'
import { FormattedPlayer } from '../players/types'
import { FormattedSession, FormattedTeamSession } from '../sessions/types'
import { FormattedTeam } from '../teams/types'
import { Group } from '../types'
import { UnitSystem } from '../units/types'
import { aggregationOperatorTypes } from './aggregation_operators/data_types'
import {
  generateMetricTypeClass,
  MetricType,
  MetricTypeGroup,
  MetricTypeKeys,
  metricTypes,
  RawMetricTypeValues
} from './data_types'
import { getFormattedValue } from './metric'
import { FormattedMetric } from './types'

export function getMetricsOptionsWithUnits(
  metricTypes: MetricTypeGroup,
  unitSystem: UnitSystem
) {
  let options = metricTypes.options
  options = options.map(({ value }) => {
    const metricItem = generateMetricTypeClass(
      metricTypes.getTypeByValue(value)
    )
    return {
      name: metricItem.getMetricDisplayWithUnits(unitSystem, null),
      value
    }
  })
  return options
}

export function getMetricsKeyOptionsWithUnits(
  metricTypes: MetricTypeGroup,
  unitSystem: UnitSystem
) {
  let options = metricTypes.keyOptions
  options = options.map(({ value }) => {
    const metricItem = generateMetricTypeClass(metricTypes.items[value])
    return {
      name: metricItem.getMetricDisplayWithUnits(unitSystem, null),
      value
    }
  })
  return options
}

export function aggregateAndFormatMetricsForFlights(
  metricTypes: MetricTypeGroup,
  events: ProcessedRawEventData[],
  unitSystem: UnitSystem,
  formattedSession: FormattedSession
) {
  const data = {}
  for (const metricKey in metricTypes.items) {
    const flightMetric = generateMetricTypeClass(
      metricTypes.items[metricKey as MetricTypeKeys]
    )
    data[metricKey] = {
      [aggregationOperatorTypes.items.Mean.value]:
        flightMetric.getMetricFromFlights(
          events,
          unitSystem,
          aggregationOperatorTypes.items.Mean.value,
          formattedSession.teams,
          formattedSession.players.All
        ),
      [aggregationOperatorTypes.items.Maximum.value]:
        flightMetric.getMetricFromFlights(
          events,
          unitSystem,
          aggregationOperatorTypes.items.Maximum.value,
          formattedSession.teams,
          formattedSession.players.All
        ),
      [aggregationOperatorTypes.items.Minimum.value]:
        flightMetric.getMetricFromFlights(
          events,
          unitSystem,
          aggregationOperatorTypes.items.Minimum.value,
          formattedSession.teams,
          formattedSession.players.All
        ),
      [aggregationOperatorTypes.items.Sum.value]:
        flightMetric.getMetricFromFlights(
          events,
          unitSystem,
          aggregationOperatorTypes.items.Sum.value,
          formattedSession.teams,
          formattedSession.players.All
        )
    }
  }
  return data
}

export type FormattedMetricTableHeaders = {
  name: string
  key: MetricTypeKeys
  dec: number
  width: number
}

export function getMetricTableHeadersWithUnits(
  metricTypes: MetricTypeGroup,
  keys: Readonly<MetricTypeKeys[]>,
  unitSystem: UnitSystem,
  width: number,
  maxLength: number
): FormattedMetricTableHeaders[] {
  return keys.map((key) => {
    const metricInfo = generateMetricTypeClass(metricTypes.items[key])
    return {
      name: metricInfo.getMetricDisplayWithUnits(unitSystem, maxLength),
      key,
      dec: 'decimal' in metricInfo.props ? metricInfo.props.decimal : null,
      width
    }
  })
}

export const formatMetric = (
  metricType: MetricType,
  rawValues: Partial<RawMetricTypeValues>,
  teams: Group<FormattedTeam>,
  players: Group<FormattedPlayer>,
  unitSystem: UnitSystem,
  isOfficiatingMode: boolean,
  isTrainingMode: boolean,
  language = 'en',
  teamSessions: Group<FormattedTeamSession> = getEmptyGroup()
): FormattedMetric => {
  const metricInfo = generateMetricTypeClass(metricType)

  if (!metricInfo) {
    return
  }

  // If the metric is disabled, skip this metric //
  if (metricInfo.props.disable && metricInfo.props.disable(rawValues)) return

  // If the metric is only for officiating mode, and the session is not in officiating mode, skip this metric //
  if (isOfficiatingMode && metricInfo.props.officiatingModeOnly) return

  // If the metric is only for training mode, and the session is not in training mode, skip this metric //
  if (isTrainingMode && metricInfo.props.disableInTrainingMode) return

  const tag = metricInfo.props.getTag
    ? metricInfo.props.getTag(rawValues)
    : null

  const value = rawValues[metricType.key]

  let options:
    | Options<string>
    | Options<number>
    | Options<boolean>
    | Options<ParentEventTypeValues>
    | Options<EventTypeValues>
    | Options<EventSubTypeValues>

  if ('options' in metricInfo.props) {
    options = metricInfo.props.options
  }

  if (metricInfo.props.type === 'team') {
    options = teams.options
  }

  if (metricInfo.props.type === 'player') {
    options = getPlayerIdMetricOptions(
      teams,
      players,
      teamSessions,
      metricType.key as MetricTypeKeys,
      rawValues
    )
  }

  const processedMetricValue = metricInfo.processMetric(
    value,
    rawValues,
    teams,
    players
  )

  let name = metricType.name

  if (typeof name !== 'string') {
    name = name[language]
  }

  return {
    ...metricInfo.props,
    key: metricType.key,
    name: name,
    display: metricInfo.getMetricValueWithUnits(
      value,
      unitSystem,
      teams,
      players,
      rawValues
    ),
    units: metricInfo.getMetricUnits(unitSystem),
    value: value,
    processedValue: processedMetricValue,
    formattedValue: getFormattedValue(metricType, processedMetricValue),
    tag: tag,
    options: options
  }
}

export const formatMetrics = (
  metricTypes: MetricTypeGroup,
  rawValues: RawMetricTypeValues,
  teams: Group<FormattedTeam>,
  players: Group<FormattedPlayer>,
  unitSystem: UnitSystem,
  isOfficiatingMode: boolean,
  isTrainingMode: boolean,
  teamSessions: Group<FormattedTeamSession> = getEmptyGroup()
) => {
  const metrics: Partial<{ [key in MetricTypeKeys]: FormattedMetric }> = {}

  for (const key in metricTypes?.items) {
    const metricKey = key
    const metricType = metricTypes.items[metricKey]

    const formattedMetric = formatMetric(
      metricType,
      rawValues,
      teams,
      players,
      unitSystem,
      isOfficiatingMode,
      isTrainingMode,
      'en',
      teamSessions
    )

    if (!formattedMetric) continue

    metrics[metricKey] = formattedMetric
  }

  return metrics
}

// Session metric options //
export const getTeamIdMetricOptions = (formattedSession: FormattedSession) => {
  const { teams } = formattedSession
  return teams.optionsWithAll
}

export const getPlayerIdMetricOptions = (
  teams: Group<FormattedTeam>,
  players: Group<FormattedPlayer>,
  teamsSessions: Group<FormattedTeamSession>,
  metricKey: MetricTypeKeys,
  rawMetrics: Partial<RawMetricTypeValues>
) => {
  const teamSession = teamsSessions.map[rawMetrics.team]
  const team = teams.map[rawMetrics.team]

  if (!team) return []

  const oppositionTeam = teams.map[teamSession?.oppositionTeamId]

  const outcome = outcomeTypes.getTypeByValue(rawMetrics.outcome)

  // If the outcome is an interception and the metric key is 'toPlayerId', the opposition team is the target and set player options accordingly //
  let options = players[team.id].optionsWithNull
  if (
    metricKey === 'toPlayerId' &&
    isOutcomeType.interception(outcome) &&
    oppositionTeam
  ) {
    options = oppositionTeam.players.optionsWithNull
  }

  const playerId = rawMetrics[metricKey]

  // Player id must be a string //
  if (typeof playerId !== 'string') {
    console.log('Player id is not a string', playerId, metricKey)
    return options
  }

  const foundPlayer = options.find((player) => player.value === playerId)

  // If the player id exists but is not found, add an unknown player to the options //
  if (!foundPlayer && rawMetrics.toPlayerId) {
    const player = players.map[playerId]
    return [
      ...options,
      {
        value: player?.id || rawMetrics.toPlayerId,
        name:
          player?.nameAndNumber || 'Player not found: ' + rawMetrics.toPlayerId
      }
    ]
  } else {
    return options
  }
}
