export type EventFeatures = {
  graph: boolean

  tagToTouchXDistance: boolean
  tagToEventXDistance: boolean
  officiatingModeOnly: boolean

  // Time //
  periodName: {
    en: string
    fr: string
  }
  period: boolean
  hidePeriodName: boolean
  startName: string
  endName: string
  buttons: {
    start: {
      text: string
      colour: string
    }
    stop: {
      text: string
      colour: string
    }
  }

  shortcut: string

  color?: string
}

export const defaultEventTypeFeatures: EventFeatures = {
  graph: false,

  tagToTouchXDistance: false,
  tagToEventXDistance: false,
  officiatingModeOnly: false,

  // Time Features //
  periodName: {
    en: 'Period',
    fr: 'Période'
  },
  hidePeriodName: false,
  period: false,
  startName: 'Started',
  endName: 'Stopped',
  buttons: {
    start: {
      text: 'Start',
      colour: '#00ff00'
    },
    stop: {
      text: 'Stop',
      colour: '#ff0000'
    }
  },

  shortcut: null
}
