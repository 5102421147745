import { defaultPitch } from './pitch'
import { soccerEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
import { sportableColors } from '../../../constants/sportableColors'
import { parentEventTypes } from '../../events/data_types'
import { eventTypes } from '../../events/types/data_types'

const sportMetrics = getMetricTypeGroup(
  getMetricTypesConfigFromJson(dataTypes.metrics, metricTypesConfig)
)

console.log(sportMetrics.items.dist)

export const soccerProps = {
  parentEventTypes: soccerEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  metrics: sportMetrics,
  reportMetricConfig: {
    playerPerformanceTable: {
      all: {
        metrics: {
          keys: ['involvements', 'involvementsPerMinute']
        },
        eventMetricGroup: {
          eventTypes: [
            'soccerPass',
            'soccerDribble',
            'soccerCross',
            'soccerClearance',
            'soccerPenalty',
            'soccerCorner',
            'soccerShotAtGoal'
          ],
          groupName: {
            en: 'Success %',
            fr: 'Succès %'
          }
        }
      }
    },
    playerComparisonTable: {
      pass: {
        metrics: [
          ['totalForwardPasses', 'forwardPassesCompletionRate'],
          ['totalBackwardPasses', 'backwardPassesCompletionRate'],
          ['totalSidewaysPasses', 'sidewaysPassesCompletionRate']
        ]
      }
    },
    monitoringCards: {
      technicalMetrics: [
        'involvements',
        'involvementsPerMinute',
        'totalForwardPasses',
        'forwardPassesComplete',
        'shots',
        'shotsConversion',
        'duelsWon'
      ],
      physicalMetrics: [
        'distance',
        'distancePerMin',
        'highSpeedRunningDistance',
        'highSpeedRunningDistancePerMin',
        'accelCount',
        'decelCount',
        'totalWork'
      ]
    },
    dashboardMetrics: {
      performanceAgainstAverage: [
        'totalRuns',
        'totalRunDistance',
        'runIntensity',
        'totalPasses',
        'totalForwardPasses',
        'totalBackwardPasses',
        'totalSidewaysPasses',
        'averageTimeOnBall',
        'totalShots',
        'shotConversion',
        'shotsOnTargetRatio',
        'totalShotsAtGoal',
        'averageRecycleTime',
        'totalGoalsScored',
        'totalPassCompletion'
      ],
      cardGrid: [
        ['totalDistance', 'averageMaxSpeed', 'xGImpacts'],
        ['avgInvolvements', 'totalCarries', 'completedPasses', 'totalShots']
      ]
    }
  },
  pitch: {
    default: defaultPitch,
    pitchPointCount: 34,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    setupTypes: pitchSetupTypes,
    enableRotate: true,
    enableBench: true,
    defaultDrillRegion: {
      P1: 'P10',
      P2: 'P8',
      P3: 'P3',
      P4: 'P1',
      P5: 'P16',
      P6: 'P33',
      P7: 'P15',
      P8: 'P34'
    } as const,
    drillsGoalsOrPosts: {
      enabled: true,
      dimensions: {
        large: {
          width: 7.32,
          height: 2.44,
          depth: 2.44
        },
        small: {
          width: 1.914,
          height: 1.914,
          depth: 1.61
        }
      }
    }
  },
  features: {
    drillsEnabled: true,
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 18,
    defaultUnitSystem: 'British',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        a: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
              formattedEvent.type.selected?.value ===
                eventTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.complete?.value
        },
        x: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
              formattedEvent.type.selected?.value ===
                eventTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        i: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
              formattedEvent.type.selected?.value ===
                eventTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        }
      }
    },
    recentEventTableMetrics: ['dist', 'hangTime', 'speed'],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {
      '1': {
        name: 'No Bib',
        colour: null
      },
      '2': {
        name: 'Red',
        colour: sportableColors.colors.sportableRed
      },
      '3': {
        name: 'Blue',
        colour: sportableColors.colors.sportableBlue
      },
      '4': {
        name: 'Green',
        colour: sportableColors.colors.sportableGreen
      },
      '5': {
        name: 'Orange',
        colour: sportableColors.colors.sportableOrange
      }
    },
    targetMetrics: ['dist', 'hangTime']
  } as const
}

export const soccerConfig = {
  key: 'soccer' as const,
  value: 'Soccer',
  name: 'Soccer',
  props: soccerProps
}
