import React, { useState } from 'react'
import { AuthFormWithPasswordValidation } from '../../../metrics_server/user/components/AuthFormWithPasswordValidation'
import { validatePassword } from '../../../metrics_server/user/functions'
import { useAppDispatch } from '../../../store/hooks'
import {
  confirmNewPassword,
  NewPasswordRequestBody
} from '../../../metrics_server/user/actions'
import { AuthFormProps } from '../config'

export const ConfirmPasswordForm = (props: AuthFormProps) => {
  const dispatch = useAppDispatch()
  const { switchForm } = props

  const [fields] = useState([
    {
      name: 'password',
      type: 'text',
      textType: 'password',
      label: 'Password'
    },
    {
      name: 'password_confirmation',
      type: 'text',
      textType: 'password',
      label: 'Confirm password'
    }
  ])

  const handleSubmit = (values: NewPasswordRequestBody) => {
    dispatch(confirmNewPassword(values, () => switchForm('signin')))
  }

  return (
    <AuthFormWithPasswordValidation
      fields={fields}
      handleSubmit={handleSubmit}
      validate={(values) => validatePassword(values)}
    />
  )
}
