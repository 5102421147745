/* 
Establishes connections with sportable MQTT brokers and sets up a context to provide these connections to child components. 
Returns a MqttContext.Provider with the clients object as the context value and all child components as children. 
The clients object contains a key-value pair for each broker that has been connected to.
*/

import React, { createContext, useEffect, useState, useRef } from 'react'
import { MqttController } from '../../utils/mqtt'
import * as sportscasterActions from '../sportscaster/actions'
import * as insightsActions from '../insights/actions'
import { useMqtt } from './hooks'
import { useUser } from '../user/hooks'
import { useAppDispatch } from '../../store/hooks'

export const MqttContext = createContext(null)

export default ({ children }) => {
  const [clients, setClients] = useState({})
  const mqtt = useMqtt()
  const user = useUser()
  const timeouts = useRef({})
  const dispatch = useAppDispatch()

  const onHealthMessage = (host, message) => {
    // if (host === 'ws://localhost:15675')
    //   console.log('health message', message, host)
    if (timeouts.current[host]) {
      clearTimeout(timeouts.current[host])
    }
    const timeout = setTimeout(() => {
      dispatch(
        sportscasterActions.setSportscasterHealth({ host, healthy: false })
      )
    }, 5000)
    timeouts.current = {
      ...timeouts.current,
      [host]: timeout
    }
    // TODO - getting sent multiple version numbers
    dispatch(sportscasterActions.setSportscasterHealth({ host, ...message }))
  }

  const onSuccess = (host, client) => {
    dispatch(sportscasterActions.setSportscasterHealth({ host, mqtt: true }))
    dispatch(sportscasterActions.setConnectionEstablished(host))

    // Connect to initial topics if selected broker host
    if (host === mqtt.selectedBrokerHost) {
      client.subscribe('commentator/updates', (data) => {
        dispatch(insightsActions.updateInsightsDataFetchQueue(data))
      })
    }
  }

  const onError = (host) => {
    dispatch(sportscasterActions.setSportscasterHealth({ host, mqtt: false }))
  }

  useEffect(() => {
    if (mqtt.brokers.length > 0 && user.data.id) {
      console.log('Connecting to MQTT brokers...')
      const newClients = {}
      mqtt.brokers.forEach((broker) => {
        console.log(broker)
        if (broker.Host === '127.0.0.1') {
          newClients[broker.WSSHost] = connectSportableBroker(
            broker.WSSHost,
            broker,
            onSuccess,
            onError,
            onHealthMessage
          )
        }
      })

      setClients(newClients)
    } else if (mqtt.brokers.length > 0 && !user.data.id) {
      Object.values<any>(clients).forEach((client) => {
        client.endClient()
      })

      // Unsubcribe to main broker topics
      const selectedBrokerClient = clients[mqtt.selectedBrokerHost]
      if (selectedBrokerClient) {
        selectedBrokerClient.unsubscribe('commentator/updates')
      }
    }
  }, [mqtt.brokers, user.data])

  return (
    <MqttContext.Provider value={clients}> {children} </MqttContext.Provider>
  )
}

//Connect to MQTT Broker
function connectSportableBroker(
  name,
  broker,
  onSuccess,
  onError,
  onHealthMessage
) {
  const mqttClient = new MqttController.Client(broker.name, broker.location)

  const uniqueSuffix = Math.floor((1 + Math.random()) * 0x100000000)
    .toString(16)
    .substring(1)

  // Use regex to determine protocol and path of broker connection
  const regex = new RegExp('^wss')
  const isWSS = regex.test(broker.WSSHost)
  const protocol = isWSS ? 'wss' : 'ws'
  const path = isWSS ? '' : '/ws'

  const options = {
    clientId: `match_tracker_ui_${uniqueSuffix}`,
    protocol,
    username: broker.Username,
    password: broker.Password,
    keepalive: 30,
    path: null
    // log: (log, test) => {
    //   console.log(log, test)
    // }
  }

  if (path) {
    options.path = path
  }

  mqttClient.connect(
    broker.WSSHost,
    options,
    () => {
      // Success
      console.log('connected to mqtt broker: ', broker.WSSHost)
      onSuccess(broker.WSSHost, mqttClient)
    },
    (error, type, e) => {
      // Error
      console.log(type, e)
      console.log(broker)
      onError(broker.WSSHost)
    },
    'sportscaster/version',
    (message, topic) => {
      onHealthMessage(broker.WSSHost, message)
    }
  )

  return mqttClient
}
