import React, { useState } from 'react'
import { containsNumber } from '../../../utils/helpers'
import { validatePassword } from '../../../metrics_server/user/functions'
import { AuthFormWithPasswordValidation } from '../../../metrics_server/user/components/AuthFormWithPasswordValidation'
import { FormValidationErrors } from '../../../metrics_server/user/types'
import { useAppDispatch } from '../../../store/hooks'
import {
  SignupRequestBody,
  signupUser
} from '../../../metrics_server/user/actions'
import { AuthFormProps } from '../config'

const validate = (values) => {
  const errors: FormValidationErrors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (containsNumber(values.firstName)) {
    errors.firstName = 'Contains a number'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (containsNumber(values.lastName)) {
    errors.lastName = 'Contains a number'
  }

  return { ...errors, ...validatePassword(values) }
}

export const CreatePasswordForm = (props: AuthFormProps) => {
  const dispatch = useAppDispatch()
  const { switchForm } = props

  const [fields] = useState([
    {
      name: 'firstName',
      type: 'text',
      label: 'First name'
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last name'
    },
    {
      name: 'password',
      type: 'text',
      textType: 'password',
      label: 'Password'
    },
    {
      name: 'password_confirmation',
      type: 'text',
      textType: 'password',
      label: 'Confirm password'
    }
  ])

  const handleSubmit = (values: SignupRequestBody) => {
    dispatch(signupUser(values, () => switchForm('signin')))
  }

  return (
    <AuthFormWithPasswordValidation
      fields={fields}
      validate={validate}
      handleSubmit={handleSubmit}
    />
  )
}
