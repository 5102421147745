'use client'

import * as React from 'react'
import { Checkbox } from '../ui/checkbox'

interface FilterOption {
  value: string
  label: string
  color: string
}

export function CheckboxFilters({
  selectedFilterValues,
  setSelectedFilters,
  filterOptions
}: {
  selectedFilterValues: string[]
  setSelectedFilters: (filters: string[]) => void
  filterOptions: FilterOption[]
}) {
  const getNewFilterValues = (filterValue: string) => {
    const currentFilterValues = [...selectedFilterValues]
    if (filterValue === 'All') {
      // If "All" is being toggled
      if (currentFilterValues.includes('All')) {
        // If "All" is being unchecked, clear All
        return []
      } else {
        // If "All" is being checked, select All
        return filterOptions.map((option) => option.value)
      }
    } else {
      if (currentFilterValues.includes(filterValue)) {
        // Remove the filter and "All" if it was selected
        return currentFilterValues.filter(
          (value: string) => value !== filterValue && value !== 'All'
        )
      } else {
        // Add the filter and "All" if All others are now selected
        const newFilters = [...currentFilterValues, filterValue]
        if (
          newFilters.length === filterOptions.length - 1 &&
          !newFilters.includes('All')
        ) {
          return [...newFilters, 'All']
        }
        return newFilters
      }
    }
  }

  const handleFilterChange = (filterValue: string) => {
    setSelectedFilters(getNewFilterValues(filterValue))
  }

  return (
    <div className='flex flex-wrap items-center justify-center gap-8 bg-background p-4'>
      {filterOptions.map((option) => (
        <label
          key={option.value}
          className='flex items-center space-x-2 text-foreground'
        >
          <Checkbox
            id={option.value}
            checked={selectedFilterValues.includes(option.value)}
            onCheckedChange={() => handleFilterChange(option.value)}
            className='border border-foreground data-[state=checked]:bg-foreground data-[state=checked]:text-background'
          />
          <div className='flex items-center gap-2'>
            <div className={`h-4 w-0.5`} style={{ background: option.color }} />
            <span>{option.label}</span>
          </div>
        </label>
      ))}
    </div>
  )
}
