import React from 'react'
import styles from './Notification.module.scss'
import { DiagnosticNotification } from './DiagnosticNotification/DiagnosticNotification'
import { DownloadNotification } from './DownloadNotification/DownloadNotification'
import { NotificationType } from '../../types'
import { useNotifications } from '../../hooks'

export const Notification = () => {
  const notifications = useNotifications()

  const renderNotifications = (notification: NotificationType, index) => {
    const { timestamp, arrivalTime, type, text } = notification
    const timeDifference = arrivalTime - timestamp
    const isRelevantNotification = timeDifference <= 20

    if (type && isRelevantNotification && notifications.on) {
      return <DiagnosticNotification notification={notification} />
    }

    if (text?.includes('Downloading')) {
      return (
        <div
          className={styles.downloadNotificationContainer}
          key={`downloadNotification-${index}`}
        >
          <DownloadNotification notification={notification} />
        </div>
      )
    }
    return null
  }

  return (
    <div className={styles.notificationContainer}>
      {notifications.popUpNotifications.map(renderNotifications)}
    </div>
  )
}
