import { useCallback, useMemo, useReducer } from 'react'
import { Options } from '../metrics_server/data_types'

export interface Filter<v, k, d> {
  // If v is an array, then the type of the value is the element //
  options: Options<v extends Array<any> ? v[number] : v | 'All'>
  value: v extends Array<any> ? v[number] : v | 'All'
  dependencies?: d[]
  key: k
  label: string
}

export type Filters<T> = {
  [K in keyof T]: Filter<T[K], K, keyof T>
}

export type FilterValues<T extends { [key in keyof T]: { value } }> = {
  [key in keyof T]: T[key]['value']
}

export interface TimeFilter {
  value: number
}

export type FilterT<k, d> = {
  label?: string
  key: k
  dependencies?: d[]
}

export type FilterSingle<v, k, d> = {
  type: 'single'
  value: v
  options: Options<v>
} & FilterT<k, d>

export type FilterSingleWithAll<v, k, d> = {
  type: 'singleWithAll'
  options: Options<(v | 'All') | v>
  value: (v | 'All') | v
} & FilterT<k, d>

export type SearchStr<v, k, d> = {
  type: 'searchStr'
  options: Options<v>
  value: v
} & FilterT<k, d>

export type FilterMultipleNew<v, k, d> = {
  type: 'multiple'
  options: Options<v>
  value: v[]
} & FilterT<k, d>

export type FilterMultipleWithAll<v, k, d> = {
  type: 'multipleWithAll'
  options: Options<v | 'All'>
  value: (v[] | 'All') | v[]
} & FilterT<k, d>

export type FilterRange<k, d> = {
  type: 'range'
  options: Options<number>
  value: [number, number]
} & FilterT<k, d>

export interface FilterMultiple<v, k = string> {
  options: Options<v>
  value: v[]
  dependencies?: k[]
}

export type SearchStrFilter = {
  options: Options<string>
  value: string
}

export type FilterTypes<values, k, d = string> =
  | FilterMultipleNew<values, k, d>
  | FilterMultipleWithAll<values, k, d>
  | FilterSingle<values, k, d>
  | FilterSingleWithAll<values, k, d>
  | SearchStr<values, k, d>
  | FilterRange<k, d>

export function useFilterReducer<
  Filters extends {
    [key in keyof Filters]: FilterTypes<
      Filters[key]['value'],
      Filters[key]['key'],
      keyof Filters
    >
  }
>(initialFilterState: Filters) {
  const UPDATE_FILTER = 'UPDATE_FILTER'
  const UPDATE_OPTIONS = 'UPDATE_OPTIONS'

  const filterReducer = (state: Filters, action) => {
    switch (action.type) {
      case UPDATE_FILTER:
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value
          }
        }

      case UPDATE_OPTIONS:
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            options: action.payload.options
          }
        }

      default:
        return state
    }
  }

  const [filters, dispatch] = useReducer<typeof filterReducer>(
    filterReducer,
    initialFilterState
  )

  const updateFilterValue = useCallback(
    <k extends keyof Filters>(name: k, value: Filters[k]['value']) => {
      dispatch({
        type: UPDATE_FILTER,
        payload: { name, value }
      })
    },
    []
  )

  const updateFilterOptions = useCallback(
    <k extends keyof Filters>(name: k, options: Filters[k]['options']) => {
      let newOptions = [...options]
      if (
        filters[name].type === 'singleWithAll' ||
        filters[name].type === 'multipleWithAll'
      ) {
        newOptions = [{ name: 'All', value: 'All' }, ...options]
      }
      dispatch({
        type: UPDATE_OPTIONS,
        payload: { name, options: newOptions }
      })
    },
    []
  )

  return useMemo(() => {
    return {
      filters,
      updateFilterValue,
      updateFilterOptions
    }
  }, [filters, updateFilterValue, updateFilterOptions])
}

export type CustomFilterState<F extends { [key: string]: { value } }> =
  Partial<{
    [k in keyof F]: Partial<FilterTypes<F[k]['value'], k, keyof F>>
  }>

export function getFilterState<
  DefaultFilters extends {
    [key in keyof DefaultFilters]: FilterTypes<
      DefaultFilters[key]['value'],
      DefaultFilters[key]['key'],
      keyof DefaultFilters
    >
  },
  CustomFilters extends CustomFilterState<DefaultFilters>
>(customFilterState: CustomFilters, defaultFilterState: DefaultFilters) {
  // Add custom filters to default filters //

  const combinedState = {} as any
  for (const key in defaultFilterState) {
    const defaultFilter = defaultFilterState[key]
    const customFilter = customFilterState[key]

    const combinedFilter = {
      key: defaultFilter.key,
      type: customFilter?.type || defaultFilter.type,
      value: customFilter?.value || defaultFilter.value,
      options: customFilter?.options
        ? [...customFilter.options]
        : [...defaultFilter.options],
      label: customFilter?.label || defaultFilter.label,
      dependencies: customFilter?.dependencies || defaultFilter.dependencies
    } as FilterTypes<any, any, any>

    if (combinedFilter.type === 'singleWithAll') {
      combinedFilter.options.unshift({ name: 'All', value: 'All' })
    }

    if (combinedFilter.type === 'multipleWithAll') {
      combinedFilter.options.unshift({ name: 'All', value: 'All' })
    }

    combinedState[key] = combinedFilter
  }

  return combinedState as DefaultFilters
}

export function runCheck<F extends FilterTypes<any, any, any>>(
  filter: F,
  itemValue
) {
  if (
    filter.type === 'searchStr' &&
    typeof itemValue === 'string' &&
    typeof filter.value === 'string'
  ) {
    console.log('searchStr', itemValue, filter.value)
    if (filter.value === null) return true
    if (filter.value === '') return true
    const lowerCase = itemValue.toLowerCase()
    return lowerCase.includes(filter.value.toLowerCase())
  }

  // TODO: ADD EXACT Number for player

  if (filter.type === 'single' || filter.type === 'singleWithAll') {
    if (filter.value === null) return true
    if (filter.value === 'All') return true
    if (filter.value === itemValue) return true
    return false
  }

  if (filter.type === 'multiple' || filter.type === 'multipleWithAll') {
    if (filter.value === null) return true
    if (filter.value.includes('All')) return true
    if (filter.value.includes(itemValue)) return true
    console.log(
      filter.label,
      itemValue,
      filter.value,
      'false----------------------------------------------'
    )
    return false
  }

  return true
}

export const isFilterOptionsEmpty = (filter: FilterTypes<any, any, any>) => {
  if (filter.options && filter.options.length === 0) {
    return true
  }
  if (
    filter.options &&
    filter.options.length === 1 &&
    filter.options[0].value === 'All'
  ) {
    return true
  }
  return false
}
