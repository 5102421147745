import * as React from 'react'

import { cn } from '../../lib/utils'

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className='relative w-full overflow-auto'>
    <table
      ref={ref}
      className={cn('w-full caption-bottom text-sm', className)}
      {...props}
    />
  </div>
))
Table.displayName = 'Table'

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('[&_tr:last-child]:border-0', className)}
    {...props}
  />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      'border-t bg-muted/50 font-medium [&>tr]:last:border-b-0',
      className
    )}
    {...props}
  />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    rowContrasting?: boolean
    isTotalRow?: boolean
  }
>(({ className, rowContrasting, isTotalRow, ...props }, ref) => {
  return (
    <tr
      ref={ref}
      className={cn(
        `border-b-4 border-background transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted ${
          isTotalRow
            ? 'bg-background'
            : rowContrasting
            ? 'even:bg-muted odd:bg-muted-alt'
            : ''
        }`,
        className
      )}
      {...props}
    />
  )
})
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    arms?: boolean
  }
>(({ arms, className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'relative h-14 px-2 text-left align-middle font-medium text-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      className
    )}
    {...props}
  >
    {props.children}
    {arms && (
      <>
        <div className='absolute left-0 top-1/2 w-2/5 h-px bg-foreground'>
          <div className='absolute left-0 top-0 w-px h-2 bg-foreground'></div>
        </div>
        <div className='absolute right-0 top-1/2 w-2/5 h-px bg-foreground'>
          <div className='absolute right-0 top-0 w-px h-2 bg-foreground'></div>
        </div>
      </>
    )}
  </th>
))
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'p-3 border-r-4 border-background align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      className
    )}
    {...props}
  />
))
TableCell.displayName = 'TableCell'

const TableMetricCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    value: number
    maxValue: number
    markerValue: number
  }
>(({ className, value, maxValue, markerValue, ...props }, ref) => {
  const barProps = React.useMemo(() => {
    let markerPercent = null,
      barColor = null,
      valuePercent = null

    if (!value || !maxValue)
      return {
        valuePercent,
        markerPercent,
        barColor
      }

    valuePercent = (value / maxValue) * 100

    if (markerValue) {
      markerPercent = (markerValue / maxValue) * 100

      // Determine color based on value compared to average
      barColor = 'bg-yellow-500'

      if (value >= markerValue) {
        barColor = 'bg-green-500'
      }
      const percentBelowAverage = ((markerValue - value) / markerValue) * 100
      if (percentBelowAverage > 50) {
        barColor = 'bg-red-500'
      }
    }

    return {
      valuePercent,
      markerPercent,
      barColor
    }
  }, [value, maxValue, markerValue])

  return (
    <td
      ref={ref}
      className={cn(
        'border-r-4 border-background relative p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
        className
      )}
    >
      {props.children}
      {barProps.valuePercent && (
        <div className='absolute left-0 bottom-0 w-full h-1 bg-muted bg-background'>
          <div
            className={`absolute left-0 top-0 h-full ${barProps.barColor}`}
            style={{ width: `${barProps.valuePercent}%` }}
          />
          {barProps.markerPercent && (
            <div
              className='absolute top-0 w-2 h-full bg-foreground/80'
              style={{ left: `${barProps.markerPercent}%` }}
            />
          )}
        </div>
      )}
    </td>
  )
})
TableCell.displayName = 'TableMetricCell'

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn('mt-4 text-sm text-muted-foreground', className)}
    {...props}
  />
))
TableCaption.displayName = 'TableCaption'

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  TableMetricCell
}
