import React, { useEffect } from 'react'
import BasicTextField from '../../../Material/Text'
import BasicSelect from '../../../Material/Select'
import MultipleSelectChip from '../../../Material/MultipleSelect'

import { aggregationOperatorTypes } from '../../../../metrics_server/metrics/aggregation_operators/data_types'
import { Box } from '@mui/material'
import { useColumnQueryForm } from './ColumnForm.logic'
import { DraggableList } from '../../../DraggableList/DraggableList'
import { ColumnQuery } from '../../../../metrics_server/insights/types'
import { Sport } from '../../../../metrics_server/sports/data_types'

export const InsightsTableColumnForm = ({
  sport,
  initialColumns,
  onChange
}: {
  sport: Sport
  initialColumns: ColumnQuery[]
  onChange: (columns: ColumnQuery[]) => void
}) => {
  // Column form for custom queries
  const { columns, setColumns, addColumn, removeColumn, updateValue } =
    useColumnQueryForm(initialColumns)

  useEffect(() => {
    onChange(columns)
  }, [columns])

  return (
    <DraggableList
      values={columns}
      onChange={setColumns}
      onAddRow={addColumn}
      onRemoveRow={removeColumn}
      renderComponent={(item) => (
        <InsightsTableColumnFormItem
          sport={sport}
          item={item}
          onChange={updateValue}
        />
      )}
    />
  )
}

export const InsightsTableColumnFormItem = ({
  sport,
  item,
  onChange
}: {
  sport: Sport
  item: ColumnQuery
  onChange
}) => {
  const flightTypes = sport.props.parentEventTypes.items.flight.props.types
  const flightTypeSelectOptions = flightTypes.options
  const selectedFlightType = flightTypes.getTypeByValue(item.flightType)
  const flightSubTypeOptions = selectedFlightType?.props.types?.options
  const metricOptions = selectedFlightType?.props.metricTypes?.options

  return (
    <Box
      sx={{
        gap: 2,
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)'
      }}
    >
      <BasicTextField
        label={'Name'}
        value={item.name}
        onChange={(value) => onChange(item.id, 'name', value)}
        size='small'
      />
      <BasicSelect
        label={'Flight Type'}
        options={flightTypeSelectOptions}
        selected={item.flightType}
        onChange={(value) => {
          onChange(item.id, 'flightType', value)
        }}
        size='small'
      />
      {flightSubTypeOptions && (
        <MultipleSelectChip
          label={'Flight Sub Type'}
          options={flightSubTypeOptions}
          selected={item.flightSubTypes}
          onChange={(value) => {
            onChange(item.id, 'flightSubTypes', value)
          }}
          size='small'
        />
      )}
      {selectedFlightType && (
        <BasicSelect
          label={'Stat'}
          options={metricOptions}
          selected={item.stat}
          onChange={(value) => {
            onChange(item.id, 'stat', value)
          }}
          size='small'
        />
      )}
      {selectedFlightType && (
        <BasicSelect
          label={'Aggregation'}
          options={aggregationOperatorTypes.options.filter(
            (option) => option.value !== 'NoOp'
          )}
          selected={item.aggregation}
          onChange={(value) => {
            onChange(item.id, 'aggregation', value)
          }}
          size='small'
        />
      )}
    </Box>
  )
}
