import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import viewAnimations from '../animations'
import { useRouter } from '../../../ui/router/hooks'
import { useAppDispatch } from '../../../store/hooks'
import { setRedirectPop } from '../../../ui/router/actions'
import styles from '../Views.module.scss'
import { PageProps } from './Page.types'

export interface PageViewProps {
  path: string
  view: PageProps
  location: Location
  history: string[]
  switchToTab?: string
}

export function PageView(props: PageViewProps) {
  const dispatch = useAppDispatch()
  const { view, location, history, switchToTab, path } = props

  const router = useRouter()

  // ============ Router ================== //
  // Listen for back button and change route
  useEffect(() => {
    if (location.pathname !== router.redirect && location.pathname !== path) {
      dispatch(setRedirectPop(location.pathname))
    }
  }, [location.pathname, router.redirect])

  // Listen for redirect and animate out
  useEffect(() => {
    const { history } = props
    if (router.action === 'PUSH' && router.redirect !== path) {
      history.push(router.redirect)
      animateComponentOut(router.redirect)
    }
  }, [router.redirect])
  // ====================================== //

  // ============ Animation ================== //
  const [animationComplete, setAnimationComplete] = useState<boolean>(false)
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const footerRef = useRef(null)

  useEffect(() => {
    animateComponentIn()
  }, [])

  const animateComponentIn = useCallback(() => {
    if (!router.prevRoute) {
      viewAnimations.slideInHeader(headerRef.current, 0.5, () => {
        viewAnimations.showContent(contentRef.current, 0.5, () => {
          setAnimationComplete(true)
        })
      })
    } else {
      viewAnimations.showContent(contentRef.current, 0.5, () => {
        setAnimationComplete(true)
      })
    }
  }, [location, router])

  const animateComponentOut = useCallback(
    (nextRoute) => {
      viewAnimations.hideContent(contentRef.current, 0.5, () => {
        // Slide out footer
        const isNextFooter = router.footerRoutes.indexOf(nextRoute) >= 0
        const isFooter = router.footerRoutes.indexOf(location.pathname) >= 0
        if (!isNextFooter && isFooter) {
          viewAnimations.slideOutFooter(footerRef.current, 0.5)
        }

        // Slide out header
        viewAnimations.slideOutHeader(headerRef.current, 0.5, () => {
          history.push(nextRoute)
        })
      })
    },
    [history, router, location]
  )
  // =================================== //

  return (
    <div>
      {view.Header && (
        <div ref={headerRef}>
          <view.Header router={router} animationComplete={animationComplete} />
        </div>
      )}
      <div ref={contentRef} className={styles.content}>
        <view.Content animationComplete={animationComplete} />
      </div>
    </div>
  )
}
