import { Options } from '../data_types'
import { getEmptyGroup } from '../functions'
import { AppPlayer, FormattedPlayer, PlayerData } from '../players/types'
import { Sport, SportTypeValues } from '../sports/data_types'
import { Group, IDMap } from '../types'

export interface Option {
  name: string
  image: {
    logo: string
    color: string
  }
  value: string
  id: string
}

export interface TeamsState {
  hasValidData: boolean
  items: {
    [id: string]: Team | RawTeamData
  }
  rawData: IDMap<RawTeamData>
  options: Option[]
  fetched: boolean
  selectedTeam: string
  selectedBTeam: string
}

export interface Team {
  id: string
  name: string
  type: string
  color: string
  logo: string
  organisationId: string
  Players?: string[]
  Sensors?: any[]
  Tags?: any[]
  sessionPlayerCount?: number
  gender?: string
  sportType?: SportTypeValues
}

export type AppTeam = {
  players: {
    map: IDMap<AppPlayer>
    options: Options<string>
  }
  data: Team
  oppositionTeamId: string
}

export type RawTeamData = {
  id: string
  name: string
  type: string
  color: string
  logo: string
  organisationId: string
  sportType: SportTypeValues
  gender: 'male' | 'female'
}

export const EMPTY_RAW_TEAM_DATA: RawTeamData = {
  id: '',
  name: '',
  type: '',
  color: '',
  logo: '',
  organisationId: '',
  sportType: null,
  gender: 'male'
}

export interface FormattedTeam {
  id: string
  rawData: RawTeamData
  name: string
  type: string
  color: string
  logo: string
  organisationId: string
  players: Group<FormattedPlayer>
  gender: string
  sportType: SportTypeValues
  sport: Sport
}

export const EMPTY_FORMATTED_TEAM: FormattedTeam = {
  id: '',
  rawData: EMPTY_RAW_TEAM_DATA,
  name: '',
  type: '',
  color: '',
  logo: '',
  organisationId: '',
  players: getEmptyGroup(),
  gender: 'male',
  sport: null,
  sportType: null
}

export interface TeamListState {
  id?: number | string
  playerId?: string
  player?: PlayerData
  teamId?: string
  number?: number
  shirtNumber?: number
  linked: boolean
}

export const GET_TEAMS = 'GET_TEAMS'
export const CREATE_TEAM = 'CREATE_TEAM'
export const JOIN_TEAM = 'JOIN_TEAM'
export const GET_TEAM_PLAYERS = 'GET_TEAM_PLAYERS'
export const EDIT_TEAM = 'EDIT_TEAM'
export const DELETE_TEAM = 'DELETE_TEAM'
export const SET_TEAM = 'SET_TEAM'
export const FILTER_TEAM = 'FILTER_TEAM'
