import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { InsightsCardForm } from '../../../components/Forms/InsightsCardForm/InsightsCardForm'
import { InsightsTableForm } from '../../../components/Forms/InsightsTableForm/InsightsTableForm'
import { updateInsightsConfig } from '../../../metrics_server/insights/actions'
import { useInsights } from '../../../metrics_server/insights/hooks'
import {
  InsightsCardConfig,
  InsightsTableConfig
} from '../../../metrics_server/insights/types'
import { toggleModal } from '../../../ui/modal/actions'

export const useInsightsTableFormModal = () => {
  // Redux //
  const insights = useInsights()
  const dispatch = useAppDispatch()
  // ====== //

  const openInsightsTableFormModal = useCallback(
    (configId: string) => {
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          wrapper: true,
          width: '50%',
          ChildComponent: () => (
            <InsightsTableForm
              configId={configId}
              handleProceed={(values: InsightsTableConfig) => {
                // Save / Update Table
                values.config.type = 'tables'
                dispatch(
                  updateInsightsConfig(
                    values,
                    insights.layouts.selectedId.sessionLayout
                  )
                )
                dispatch(toggleModal({}))
              }}
              handleSecondaryButton={() => {
                dispatch(toggleModal({}))
              }}
            />
          ),
          className: 'modalLarge'
        })
      )
    },
    [insights.layouts.selectedId.sessionLayout]
  )

  return {
    openInsightsTableFormModal
  }
}

export const useInsightsCardFormModal = () => {
  // Redux //
  const insights = useInsights()
  const dispatch = useAppDispatch()
  // ====== //

  const openInsightsCardFormModal = useCallback(
    (configId: string) => {
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          wrapper: true,
          width: '50%',
          ChildComponent: () => (
            <InsightsCardForm
              configId={configId}
              handleProceed={(values: InsightsCardConfig) => {
                // Save / Update Table
                values.config.type = 'cards'
                dispatch(
                  updateInsightsConfig(
                    values,
                    insights.layouts.selectedId.sessionLayout
                  )
                )
                dispatch(toggleModal({}))
              }}
              handleSecondaryButton={() => {
                dispatch(toggleModal({}))
              }}
            />
          ),
          className: 'modalLarge'
        })
      )
    },
    [insights.layouts.selectedId.sessionLayout]
  )

  return {
    openInsightsCardFormModal
  }
}
