import React, { useEffect, useRef, useState } from 'react'
import {
  Content,
  ContentErrors,
  createAjvValidator,
  JSONEditor,
  JSONPatchResult,
  JSONValue,
  Validator
} from 'vanilla-jsoneditor'

import FileInput from '../../FileInput/FileInput'
import { OnChange, OnChangeTyped } from '../Inputs/TextInput/TextInput'

import styles from './JsonForm.module.scss'
import formStyles from '../Form/Form.module.scss'

export interface JsonFormProps {
  title?: string
  label?: string

  schema?: JSONValue
  readOnly?: boolean
  defaultValue?: JSONValue | NonNullable<unknown>
  onChange?: OnChange
  onValidationChange?: OnChangeTyped<ContentErrors>
  disableFileInput?: boolean
  stylingEnabled?: boolean

  name?: string
}

export const JsonForm = ({
  defaultValue = {},
  title,
  schema,
  onChange,
  onValidationChange,
  label,
  readOnly = false,
  disableFileInput = false,
  stylingEnabled = true,
  name = 'file'
}: JsonFormProps) => {
  const [json, setJson] = useState<JSONValue>(defaultValue)
  const [fileName, setFileName] = useState<Blob>(undefined)

  const refContainer = useRef(null)
  const refEditor = useRef(null)

  let validator: Validator = () => {
    return []
  }
  if (schema) validator = createAjvValidator({ schema })

  useEffect(() => {
    if (fileName) {
      fileName
        .text()
        .then((body) => {
          if (typeof body === 'string') {
            const result = JSON.parse(body)
            setJson(result)

            refEditor.current?.update({
              json: result
            })
            onChange && onChange(result)
          }
        })
        .catch((e) => {
          console.error(e)
          window.alert('Invalid JSON')
        })
    }
  }, [fileName])

  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer?.current,
      props: {
        content: {
          json: json
        },
        readOnly: readOnly,
        validator: validator,
        onChange: (
          content: Content,
          previousContent: Content,
          changeStatus: {
            contentErrors: ContentErrors
            patchResult: JSONPatchResult | null
          }
        ) => {
          setJson(content)
          onValidationChange && onValidationChange(changeStatus.contentErrors)
          onChange && onChange(content)
        }
      }
    })

    refEditor.current?.validate()

    return () => {
      if (refEditor.current) {
        refEditor.current?.destroy()
        refEditor.current = null
      }
    }
  }, [])

  return (
    <div className={stylingEnabled && formStyles.labelFieldContainer}>
      {title && title !== '' && <h2>{title}</h2>}
      {!disableFileInput && (
        <FileInput
          name={name}
          label={label}
          onChange={(e, value) => {
            if (value.length === 1) {
              setFileName(value[0])
            } else {
              window.alert('Please select only one JSON file')
            }
          }}
          accept='application/JSON'
        />
      )}
      <div>
        <div
          className={stylingEnabled && styles.editorContainer}
          ref={refContainer}
        ></div>
      </div>
    </div>
  )
}
