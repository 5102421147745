import { Buffer } from 'buffer'
import { useCallback, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { CSVFile, JSONUploadState, PitchFile, Base64Result } from './types'
import { useOrganisations } from '../organisations/hooks'
import { useFormattedSession } from '../sessions/hooks'
import { autoLocate, postAnchorPitchSetup, setPitchConfig } from './actions'
import { TotalStationRequestBody } from '../../model/external/total_station'
import {
  extractBase64,
  isCSVFile,
  isJSONUploadState,
  isPitchFile,
  toBase64
} from './functions'
import { setError } from '../../ui/error/actions'
import {
  PitchSetupTypeKeys,
  PitchSetupTypeValues,
  pitchSetupTypes
} from './data_types'
import { setInfo } from '../../ui/info/actions'
import { useHardware } from '../hardware/hooks'

export const usePitches = () => {
  const pitches = useAppSelector((state) => state.pitches)
  return pitches
}

export const usePitchSelector = () => {
  const pitches = usePitches()
  const pitchOptions = useMemo(() => {
    return Object.values(pitches.items).map((pitch) => {
      return { name: pitch.name, value: pitch.id }
    })
  }, [pitches])

  const [selectedPitchId, setSelectedPitchId] = useState(null)

  return {
    pitchOptions,
    selectedPitchId,
    setSelectedPitchId
  }
}

export const useAnchorPitchSetup = () => {
  const dispatch = useAppDispatch()
  const pitches = usePitches()
  const organisations = useOrganisations()
  const formattedSession = useFormattedSession('setup')
  const { formattedHardware } = useHardware()

  const handleAnchorPitchSetup = useCallback(
    (
      type: PitchSetupTypeValues,
      formData?: CSVFile | PitchFile | JSONUploadState
    ): void => {
      const { side, setupTagList, inUse, pitchJson, anchorJson } = pitches
      const { selected } = organisations

      switch (type) {
        case pitchSetupTypes.items.pitch.value:
          if (isPitchFile(formData))
            dispatch(setPitchConfig(formData.selectedPitchId))
          break
        case pitchSetupTypes.items.auto.value:
          {
            const autoData = {
              direction: side,
              pitchId: inUse.id,
              tagList: null,
              type: formattedSession.sport.value
            }

            if (
              side === 0 &&
              (!setupTagList[0] || !setupTagList[1] || !setupTagList[2])
            ) {
              dispatch(
                setError({
                  message: 'Tags not set for full pitch setup'
                })
              )
              return
            }

            if (side === 0) autoData.tagList = setupTagList

            // Check if anchors are fluctuating //
            const fluctuatingAnchors = {}
            formattedHardware.types.anchor.devices.list.forEach((anchor) => {
              if (anchor.hasFluctuated) {
                fluctuatingAnchors[anchor.id] = anchor.serial
              }
            })

            if (Object.keys(fluctuatingAnchors).length > 0) {
              dispatch(
                setInfo({
                  message: `Anchors ${Object.values(fluctuatingAnchors).join(
                    ', '
                  )} distances to master are fluctuating. Are you sure you want to run autosetup?`,
                  header: 'Anchors Unstable',
                  proceed: () => dispatch(autoLocate(autoData))
                })
              )
            } else {
              dispatch(autoLocate(autoData))
            }
          }
          break
        case pitchSetupTypes.items.totalStation.value:
          if (isCSVFile(formData)) {
            const anchorCsvToBase64 = toBase64(
              formData.anchorFileWithoutIgnored,
              'anchor'
            )

            const pitchCsvToBase64 = toBase64(
              formData.pitchFileWithoutIgnored,
              'pitch'
            )

            Promise.all([anchorCsvToBase64, pitchCsvToBase64]).then(
              (results: Base64Result[]) => {
                const anchorBase64 = extractBase64(results, 'anchor')
                const pitchBase64 = extractBase64(results, 'pitch')

                const data: TotalStationRequestBody = {
                  anchorBase64: anchorBase64,
                  anchorExtension: formData.anchorExtension,
                  pitchBase64: pitchBase64,
                  pitchExtension: formData.pitchExtension,
                  type: formattedSession.sport.value,
                  save: formData.save,
                  organisationID: parseInt(selected),
                  name: null
                }

                if (formData.save) data.name = formData.name

                dispatch(postAnchorPitchSetup(data))
              }
            )
          }
          break
        case pitchSetupTypes.items.uploadJSON.value:
          if (isJSONUploadState(formData)) {
            const anchorBase64 = Buffer.from(
              JSON.stringify(formData.anchorJSON['json'])
            ).toString('base64')

            let pitchBase64: string | undefined = undefined

            if (formData.coordinateJSON) {
              pitchBase64 = Buffer.from(
                JSON.stringify(formData.coordinateJSON['json'])
              ).toString('base64')
            }

            const data: TotalStationRequestBody = {
              anchorBase64: anchorBase64,
              anchorExtension: 'application/json',
              pitchBase64: pitchBase64,
              pitchExtension: 'application/json',
              type: formattedSession.sport.value,
              save: false,
              organisationID: parseInt(selected),
              name: null
            }
            dispatch(postAnchorPitchSetup(data))
          }
          break
      }
    },
    [pitches, organisations, formattedSession.sport, dispatch]
  )

  return { handleAnchorPitchSetup }
}
