import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { getSessionChallenges } from '../../metrics_server/targets/actions'
import { getSubSessions } from '../../metrics_server/sub_sessions/actions'
import { getSessionEvents } from '../../metrics_server/events/actions'
import { useSelectedFormattedSession } from '../../metrics_server/sessions/hooks'
import { useUnits } from '../../metrics_server/units/hooks'
import { useTargetSetupHeaderProps } from './header'
import { useEventsFilter } from '../../metrics_server/events/filter'

export interface TargetsSetupProps {
  view: any
}

export const TargetsSetup: any = (ChildComponent) => {
  return (props: TargetsSetupProps) => {
    // Redux //
    const units = useUnits()
    const dispatch = useAppDispatch()
    // ====== //

    // Session //
    const { sessionData, live, sport, playersSessions, pitch } =
      useSelectedFormattedSession()
    // ===== //

    // Header //
    const headerProps = useTargetSetupHeaderProps(props)
    // ====== //

    const [options, setOptions] = useState([])

    // Fetch Data //
    useEffect(() => {
      dispatch(getSessionEvents(sessionData.id))
      dispatch(getSessionChallenges(sessionData.id))
      dispatch(getSubSessions(sessionData.id))
    }, [units.selected])
    // ======== //

    const flightEventsFilters = useEventsFilter({}, [
      ...sport.props.features.targetEvents
    ])

    return (
      <ChildComponent
        {...props}
        live={live}
        options={options}
        setOptions={setOptions}
        {...headerProps}
        // Pitch
        pitch={pitch}
        playersSessions={playersSessions}
        // State
        flightEventsFilters={flightEventsFilters}
      />
    )
  }
}
