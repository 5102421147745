'use client'

import * as React from 'react'
import { addDays, format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'

import { cn } from '../../lib/utils'
import { Button } from './button'
import { Calendar } from './calendar'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { DateRange } from 'react-day-picker'

export type DatePickerWithRangeProps = React.HTMLAttributes<HTMLDivElement> & {
  dateRange: DateRange | undefined
  onChange: (dateRange: DateRange | undefined) => void
}

export function DatePickerWithRange({
  className,
  dateRange,
  onChange
}: DatePickerWithRangeProps) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !dateRange && 'text-muted-foreground'
            )}
          >
            <CalendarIcon />
            {dateRange?.from ? (
              dateRange?.to ? (
                <>
                  {format(dateRange?.from, 'LLL dd, y')} -{' '}
                  {format(dateRange?.to, 'LLL dd, y')}
                </>
              ) : (
                format(dateRange?.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={(date) => onChange(date)}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
