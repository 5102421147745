// Battery Images
import anchorEmpty from '../../assets/img/anchor-empty.png'
import anchorLow from '../../assets/img/anchor-low.png'
import anchorMid from '../../assets/img/anchor-mid.png'
import anchorHigh from '../../assets/img/anchor-high.png'
import anchorFull from '../../assets/img/anchor-full.png'
import anchorCharging from '../../assets/img/anchor-charging.png'
import anchorImg from '../../assets/img/anchor.png'

import batteryEmpty from '../../assets/img/tag_empty_batt.svg'
import batteryLow from '../../assets/img/tag_low_batt.svg'
import batteryMid from '../../assets/img/tag_mid_batt.svg'
import batteryHigh from '../../assets/img/tag_high_batt.svg'
import batteryFull from '../../assets/img/tag_full_batt.svg'
import batteryCharging from '../../assets/img/tag_in_charge.svg'

import { sportableColors } from '../../const'
import { encodeHardwareId } from '../../utils/encoding'
import { capitalizeFirstLetter, distance3D } from '../../utils/helpers'
import { Anchors } from '../pitches/types'
import { DeviceTypeKeys, deviceTypes } from './data_types'
import {
  ChargingStates,
  DeviceState,
  FormattedDevice,
  FormattedHardware,
  FormattedHardwareStatus,
  HardwareDiagnosticsPacket,
  HardwareState,
  HardwareStates,
  HardwareStatusTypeKeys
} from './types'
import { addItemToGroup, getEmptyGroup } from '../functions'

export function getDeviceTypeKeyFromHardwareStatusKey(
  type: HardwareStatusTypeKeys
): DeviceTypeKeys {
  if (type === 'anchors') return 'anchor'
  if (type === 'tags') return 'playerTag'
  if (type === 'balls') return 'ball'
  return null
}

export function getDeviceBySerial(
  formattedDevices: FormattedDevice[],
  serial: string
): FormattedDevice {
  return formattedDevices.find((device) => {
    return device.serial === serial
  })
}

const previousDistancesToMaster: { [id: number]: number } = {}
const lastFluctuations: { [id: number]: number } = {}

export function formatDevice(
  deviceId: number,
  diagnostics: HardwareDiagnosticsPacket,
  anchorConfig: Anchors,
  masterId: number,
  statusType: HardwareStates
): FormattedDevice {
  const deviceState = diagnostics.hardwareState[deviceId]
  const index = diagnostics.hardwareIndex.indexOf(deviceId)

  // Generate device maps from hardware data matrices //
  const masterSignalStrength = diagnostics.masterSignalStrength[index]
  const { rssiMatrix, imuLenMatrix, countMatrix } = diagnostics
  const rssi = {},
    anchorMeasurements = {}

  const generateMatrixMetrics = (matrix, map, thresholds, unit) => {
    matrix[index].forEach((value, j) => {
      const otherDeviceId = diagnostics.hardwareIndex[j]
      let colour
      if (value < thresholds.lower) colour = 'red'
      else if (value > thresholds.upper) colour = 'green'
      else colour = 'yellow'
      map[otherDeviceId] = {
        value: value,
        colour,
        display: `${value}${unit}`
      }
    })
  }

  generateMatrixMetrics(rssiMatrix, rssi, { lower: -80, upper: -40 }, 'dB')
  generateMatrixMetrics(
    countMatrix,
    anchorMeasurements,
    {
      lower: -80,
      upper: -40
    },
    ''
  )
  // ======== //

  // Get Anchor Position Health and Master info //
  const masterIndex = diagnostics.hardwareIndex.indexOf(masterId)
  const masterInPitchSetup = anchorConfig[masterId]

  const anchorInPitchSetup = anchorConfig[deviceId]
  const uwbDistance = diagnostics.distanceMatrix[index]?.[masterIndex]

  let positionHealth, positionHealthColor

  if (anchorInPitchSetup && masterInPitchSetup) {
    const distanceToMaster = distance3D(
      anchorInPitchSetup.pos,
      masterInPitchSetup.pos
    )
    positionHealth = Math.abs(distanceToMaster - uwbDistance).toFixed(2)

    if (positionHealth <= 0.3) {
      positionHealthColor = 'rgba(71, 206, 71, 1)'
    } else if (positionHealth <= 1) {
      positionHealthColor = 'rgba(254, 126, 3, 1)'
    } else if (positionHealth > 1) {
      positionHealthColor = 'rgba(255, 0, 0, 1)'
    }
  }
  // ======== //

  let statusColour = 'transparent'
  if (statusType === 'online')
    statusColour = sportableColors.colors.colorSuccess50
  else if (statusType === 'offline')
    statusColour = sportableColors.colors.colorError50
  else if (statusType === 'sleep')
    statusColour = sportableColors.colors.colorWarning50

  // Check for anchor fluctuations //
  let hasFluctuated = false

  if (deviceState.type === deviceTypes.items.anchor.value) {
    const previousDistanceToMaster = previousDistancesToMaster[deviceId]
    const lastFluctuation = lastFluctuations[deviceId]
    const threshold = 1

    if (
      previousDistanceToMaster &&
      uwbDistance &&
      (previousDistanceToMaster - uwbDistance > threshold ||
        previousDistanceToMaster - uwbDistance < -threshold)
    ) {
      hasFluctuated = true
      lastFluctuations[deviceId] = new Date().getTime()
    }

    if (lastFluctuation && new Date().getTime() - lastFluctuation < 10000) {
      hasFluctuated = true
    }

    previousDistancesToMaster[deviceId] = uwbDistance
  }

  return {
    id: deviceId,
    serial: deviceState.serial,
    productName: deviceState.productName,
    battery: {
      value: deviceState.slowSensors.battery,
      colour: 'green',
      display: `${deviceState.slowSensors.battery || '-'}%`
    },
    chargingState: isDeviceCharging(deviceState),
    isInPlay: deviceState.dataMetrics.location?.ballInPlay,
    inPitch: deviceState.dataMetrics.location?.inPitch,
    type: deviceTypes.getTypeByValue(deviceState.type),
    status: {
      value: statusType,
      colour: statusColour,
      display: capitalizeFirstLetter(statusType)
    },
    positionDataRate: {
      value: deviceState.positionDataRate,
      colour: 'green',
      display: `${deviceState.positionDataRate} Hz`
    },
    accelerationStdDev: {
      value: deviceState.accStdDeviation,
      colour: 'green',
      display: `${deviceState.accStdDeviation}`
    },
    positionHealth: {
      value: positionHealth,
      colour: positionHealthColor,
      display: `${positionHealth} m`
    },
    rssi,
    imuLength: {
      value: imuLenMatrix[index][index],
      colour: 'green',
      display: `${imuLenMatrix[index][index]} Hz`
    },
    anchorMeasurements,
    slowSensor: deviceState.slowSensors,
    master: deviceId === masterId,
    greyListed: deviceState.greyListed,
    position: anchorInPitchSetup?.pos,
    reports: deviceState.reports,
    masterSignalStrength,
    state: deviceState.state,
    seatState: deviceState.seatState,
    lastOnline: deviceState.lastOnline,
    inSession: deviceState.inSession,
    distanceToMaster: uwbDistance,
    hasFluctuated
  }
}

export function createEmptyFormattedHardwareStatus(): FormattedHardwareStatus {
  return {
    online: {
      name: 'Online',
      devices: getEmptyGroup<FormattedDevice, number>()
    },
    offline: {
      name: 'Offline',
      devices: getEmptyGroup<FormattedDevice, number>()
    },
    sleep: {
      name: 'Sleep',
      devices: getEmptyGroup<FormattedDevice, number>()
    }
  }
}

export function createEmptyFormattedHardware(): FormattedHardware {
  return {
    types: {
      anchor: {
        status: createEmptyFormattedHardwareStatus(),
        devices: getEmptyGroup<FormattedDevice, number>(),
        type: deviceTypes.items.anchor
      },
      playerTag: {
        status: createEmptyFormattedHardwareStatus(),
        devices: getEmptyGroup<FormattedDevice, number>(),
        type: deviceTypes.items.playerTag
      },
      ball: {
        status: createEmptyFormattedHardwareStatus(),
        devices: getEmptyGroup<FormattedDevice, number>(),
        type: deviceTypes.items.ball
      }
    },
    status: createEmptyFormattedHardwareStatus(),
    devices: getEmptyGroup<FormattedDevice, number>()
  }
}

export function formatHardwareData(
  diagnostics: HardwareDiagnosticsPacket,
  anchorConfig: Anchors
) {
  const formattedHardwareState = createEmptyFormattedHardware()

  let masterId: number
  // find master and set id, index and inPitchSetup
  for (const hardwareId in diagnostics.hardwareState) {
    const dev = diagnostics.hardwareState[hardwareId]
    if (dev && dev.slowSensors.uwbRole === 'uwbMain') {
      masterId = dev.id
    }
  }

  const hardwareStatusKeys = Object.keys(diagnostics.status)

  // Generate formatted hardware state //
  hardwareStatusKeys.forEach((hardwareStatusKey: HardwareStates) => {
    if (diagnostics.status[hardwareStatusKey])
      for (const type in diagnostics.status[hardwareStatusKey]) {
        const deviceTypeKey = getDeviceTypeKeyFromHardwareStatusKey(
          type as HardwareStatusTypeKeys
        )
        const deviceTypeList =
          diagnostics.status[hardwareStatusKey][type as HardwareStatusTypeKeys]
        deviceTypeList.forEach((deviceId) => {
          const formattedDevice = formatDevice(
            deviceId,
            diagnostics,
            anchorConfig,
            masterId,
            hardwareStatusKey
          )
          if (formattedDevice.type.key === deviceTypeKey) {
            addItemToGroup(
              formattedHardwareState.types[deviceTypeKey].status[
                hardwareStatusKey
              ].devices,
              formattedDevice,
              deviceId.toString(),
              'id',
              encodeHardwareId(deviceId)
            )
          }
          addItemToGroup(
            formattedHardwareState.types[deviceTypeKey].devices,
            formattedDevice,
            deviceId.toString(),
            'id',
            encodeHardwareId(deviceId)
          )
          addItemToGroup(
            formattedHardwareState.status[hardwareStatusKey].devices,
            formattedDevice,
            deviceId.toString(),
            'id',
            encodeHardwareId(deviceId)
          )
          addItemToGroup(
            formattedHardwareState.devices,
            formattedDevice,
            deviceId.toString(),
            'id',
            encodeHardwareId(deviceId)
          )
        })
      }
  })

  return formattedHardwareState
}

export const batteryLevels = {
  empty: {
    min: 5,
    batteryImg: batteryEmpty,
    anchorImg: anchorEmpty
  },
  low: {
    min: 25,
    batteryImg: batteryLow,
    anchorImg: anchorLow
  },
  mid: {
    min: 50,
    batteryImg: batteryMid,
    anchorImg: anchorMid
  },
  high: {
    min: 75,
    batteryImg: batteryHigh,
    anchorImg: anchorHigh
  },
  full: {
    min: 100,
    batteryImg: batteryFull,
    anchorImg: anchorFull
  },
  charging: {
    batteryImg: batteryCharging,
    anchorImg: anchorCharging
  },
  noBattery: {
    batteryImg: null,
    anchorImg: anchorImg
  }
}

export function getBatteryLevel(percent: number) {
  if (percent <= batteryLevels.empty.min) {
    return batteryLevels.empty
  } else if (percent <= batteryLevels.low.min) {
    return batteryLevels.low
  } else if (percent <= batteryLevels.mid.min) {
    return batteryLevels.mid
  } else if (percent <= batteryLevels.high.min) {
    return batteryLevels.high
  } else if (percent <= batteryLevels.full.min) {
    return batteryLevels.full
  } else if (percent > batteryLevels.full.min) {
    return batteryLevels.charging
  } else {
    return batteryLevels.noBattery
  }
}

export function isDeviceCharging(device: DeviceState): ChargingStates {
  if (!device) return 'notCharging'
  switch (device.productName) {
    case 'Junco':
      if (device.slowSensors.avgCurrent >= 50) return 'charging'
      if (
        device.slowSensors.avgCurrent < 50 &&
        device.slowSensors.avgCurrent > 0
      )
        return 'trickleCharging'
      return 'notCharging'
    case 'Swift':
      if (device.slowSensors.avgCurrent > 0) return 'charging'
      return 'notCharging'
    default:
      if (device.slowSensors.avgCurrent > 0) return 'charging'
      return 'notCharging'
  }
}
