import _ from 'lodash'
import {
  SET_SESSION_EVENTS,
  SELECT_EVENT,
  DELETE_EVENT,
  UPDATE_SELECTED_COLUMNS,
  SET_PENALTY_COUNTDOWN_ACTIVE,
  ADD_EVENT,
  SET_EVENT_UPDATE_REQUEST_PENDING,
  SET_FLIGHT_RAW_GRAPH_DATA,
  ProcessedRawEventData,
  UPDATE_SESSION_EVENTS
} from './types'
import { EventsState } from './types'
import { REMOVE_USER_DATA } from '../types'
import {
  AddEventAction,
  DeleteEventAction,
  SetEventUpdateRequestPendingAction,
  SetFlightRawGraphDataAction,
  SetPenaltyCountdownActiveAction,
  SetSelectedEventAction,
  SetSessionEventsAction,
  UpdateSelectedColumnsAction,
  UpdateSessionEventsAction
} from './actions'
import { RemoveUserDataAction } from '../user/actions'
import { processEvent, processEvents } from './functions'

const initialState: EventsState = {
  rawData: {},
  items: {},
  selectedEventId: null,
  flights: {},
  columns: ['dist', 'hangTime'],
  flightGraphs: {},
  sessionId: null,
  penaltyCountdownActive: false,
  eventUpdateRequestPending: false
}

export function eventsReducer(
  state = initialState,
  action:
    | SetSessionEventsAction
    | UpdateSessionEventsAction
    | DeleteEventAction
    | SetSelectedEventAction
    | AddEventAction
    | UpdateSelectedColumnsAction
    | SetPenaltyCountdownActiveAction
    | SetEventUpdateRequestPendingAction
    | SetFlightRawGraphDataAction
    | RemoveUserDataAction
): EventsState {
  let processedEvent: ProcessedRawEventData
  let processedEvents: {
    [key: string]: ProcessedRawEventData
  } = {}
  switch (action.type) {
    case SET_SESSION_EVENTS: {
      processedEvents = processEvents(
        action.payload.data,
        action.payload.formattedSession,
        action.payload.unitSystemValue
      )

      return {
        ...state,
        rawData: processedEvents,
        sessionId: action.payload.formattedSession.id
      }
    }

    case UPDATE_SESSION_EVENTS: {
      processedEvents = processEvents(
        action.payload.data,
        action.payload.formattedSession,
        action.payload.unitSystemValue
      )

      return {
        ...state,
        rawData: {
          ...state.rawData,
          ...processedEvents
        }
      }
    }

    case ADD_EVENT:
      processedEvent = processEvent(
        action.payload.event,
        action.payload.formattedSession,
        action.payload.unitSystemValue
      )

      return {
        ...state,
        rawData: {
          ...state.rawData,
          [processedEvent.id]: processedEvent
        }
      }

    case SELECT_EVENT:
      return {
        ...state,
        selectedEventId: action.payload
      }

    case DELETE_EVENT:
      processedEvents = { ...state.rawData }
      delete processedEvents[action.payload]
      return {
        ...state,
        rawData: processedEvents
      }

    case UPDATE_SELECTED_COLUMNS:
      return {
        ...state,
        columns: action.payload
      }

    case SET_PENALTY_COUNTDOWN_ACTIVE:
      return {
        ...state,
        penaltyCountdownActive: action.payload
      }

    case SET_EVENT_UPDATE_REQUEST_PENDING:
      return {
        ...state,
        eventUpdateRequestPending: action.payload
      }

    case SET_FLIGHT_RAW_GRAPH_DATA:
      return {
        ...state,
        flightGraphs: {
          [action.payload.flightId]: {
            data: action.payload.data
          }
        }
      }

    // Remove user data
    case REMOVE_USER_DATA:
      // TODO: Removed for rep tool dummy data purposes. Uncomment when ready to
      return state
      return initialState

    default:
      return state
  }
}
