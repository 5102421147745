import React from 'react'
import {
  createSportableApp,
  mockDrillsStateReportingTool,
  reportingToolDummyPlayerState,
  reportingToolDummyTeamState
} from '@frontend/sportable'
import { env } from '@frontend/sportable'
import { mockSessionsState } from '@frontend/sportable'
import { createRoot } from 'react-dom/client'
import { appConfig } from './app/config'
import './ui.css'

// initSentry(
//   env.environment,
//   version,
//   'https://0ddfb6845d1c46efa3d7e0f965577158@o241876.ingest.sentry.io/5589770'
// )

const initialState = {
  version: {
    ui: `${0}`,
    lib: `${0}`,
    uiType: {
      name: 'Reporting Tool',
      value: 'reportingTool'
    },
    app: {
      Env: env.environment.ENV,
      Deployment: env.environment.deployment
    }
  },
  sessions: {
    ...mockSessionsState
  },
  teams: {
    ...reportingToolDummyTeamState
  },
  players: {
    ...reportingToolDummyPlayerState
  },
  drills: {
    ...mockDrillsStateReportingTool
  }
}

const ConnectedApp = () => {
  console.log(initialState)
  return createSportableApp(initialState, appConfig as any)
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<ConnectedApp />)
