import { defaultPitch } from './pitch'
import { americanFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
import { sportableColors } from '../../../constants/sportableColors'
import { eventTypes } from '../../events/types/data_types'
import { parentEventTypes } from '../../events/data_types'

const sportMetrics = getMetricTypeGroup(
  getMetricTypesConfigFromJson(dataTypes.metrics, metricTypesConfig)
)

export const americanFootballProps = {
  parentEventTypes: americanFootballEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  metrics: sportMetrics,
  reportMetricConfig: {
    playerPerformanceTable: {
      all: {
        metrics: {
          keys: ['involvements', 'involvementsPerMinute']
        },
        eventMetricGroup: {
          eventTypes: ['pass', 'kick', 'snapFlightEvent'],
          groupName: {
            en: 'Success %',
            fr: 'Succès %'
          }
        }
      }
    },
    playerComparisonTable: {
      pass: {
        metrics: [
          ['averageThrowDistance', 'averageSpeed'],
          ['averageSpin', 'averageEfficiency'],
          ['totalPasses', 'totalPassCompletion']
        ]
      }
    },
    monitoringCards: {
      technicalMetrics: [
        'involvements',
        'involvementsPerMinute',
        'totalPassCompletion',
        'averageThrowDistance',
        'averageSpeed',
        'averageSpin',
        'averageEfficiency'
      ],
      physicalMetrics: [
        'distance',
        'distancePerMin',
        'highSpeedRunningDistance',
        'highSpeedRunningDistancePerMin',
        'accelCount',
        'decelCount',
        'totalWork'
      ]
    },
    dashboardMetrics: {
      performanceAgainstAverage: [
        'involvements',
        'involvementsPerMinute',

        'distance',
        'distancePerMin',
        'highSpeedRunningDistance',
        'highSpeedRunningDistancePerMin',
        'accelCount',
        'decelCount',
        'totalWork',

        'totalPassCompletion',
        'averageThrowDistance',
        'averageSpeed',
        'averageSpin',
        'averageEfficiency'
      ],
      cardGrid: [
        ['totalPassCompletion', 'averageThrowDistance', 'averageSpeed'],
        [
          'averageSpin',
          'averageEfficiency',
          'totalPassCompletion',
          'totalPassCompletion'
        ]
      ]
    }
  },
  pitch: {
    default: defaultPitch,
    pitchPointCount: 30,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    tenYardMarkings: 10,
    enableBench: true,
    enableRotate: true,
    defaultDrillRegion: {
      P1: 'P26',
      P2: 'P14',
      P3: 'P13',
      P4: 'P1',
      P5: null,
      P6: null,
      P7: null,
      P8: null
    } as const,
    drillsGoalsOrPosts: {
      enabled: false
    }
  },
  features: {
    drillsEnabled: true,
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'American',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        x: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
              formattedEvent.type.selected?.value ===
                eventTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        i: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
              formattedEvent.type.selected?.value ===
                eventTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {
      '1': {
        name: 'No Bib',
        colour: null
      },
      '2': {
        name: 'Offense',
        colour: sportableColors.colors.sportableRed
      },
      '3': {
        name: 'Defense',
        colour: sportableColors.colors.sportableBlue
      }
    }
  } as const
}

export const americanFootballConfig = {
  key: 'americanFootball' as const,
  value: 'AmericanFootball',
  name: 'American Football',
  props: americanFootballProps
}
