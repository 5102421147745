import { ViewType } from '../../components/Views/Views.types'
import { CreateSessionFormConfig, formMatrix, formConfig } from './form_config'
import { FormViewProps } from '../../components/Views/Form/Form.types'
import { Route } from '../../App/App.types'

export const path = '/createsession'

export type CreateSessionFormProps = FormViewProps<CreateSessionFormConfig>

export const createSessionRoute: Route<CreateSessionFormProps> = {
  path: path,
  view: {
    type: ViewType.FORM,
    name: 'Create Session Form',
    side: 0,
    formMatrix,
    formConfig
  }
}
