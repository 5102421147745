import { useSelectedFormattedEvent } from '../../../../metrics_server/events/hooks'
import {
  useFormattedSession,
  useSessions
} from '../../../../metrics_server/sessions/hooks'
import { useEffect, useRef, useState } from 'react'
import { Strack } from '../../Strack.types'

export interface SessionTagInfoProps {
  strack: Strack
  tagId: number
}

export const SessionTagInfo: React.FC<SessionTagInfoProps> = ({
  strack,
  tagId
}) => {
  const sessions = useSessions()
  const { playersSessions, isOfficiatingMode } = useFormattedSession(
    sessions.selectedId
  )
  const playerSession = playersSessions.byHardwareId.map[tagId]
  const formattedEvent = useSelectedFormattedEvent()

  const [distanceFromTagToEvent, setDistanceFromTagToEvent] = useState(null)
  const [distanceLabel, setDistanceLabel] = useState(null)
  const distanceFromTagToEventInterval = useRef(null)

  const getColorBasedOnDistance = (distance) => {
    if (distance < 2) {
      return 'green'
    } else if (distance >= 2 && distance < 5) {
      return 'orange'
    } else {
      return 'red'
    }
  }

  // Set X distance of tag to event if applicable //
  // useEffect(() => {
  //   if (!formattedEvent?.endTime || !tagId) return
  //   const tag = strack.mapObjects[tagId]

  //   if (formattedEvent.compareTagPosition) {
  //     setDistanceLabel(formattedEvent.compareTagPosition.label)
  //   } else {
  //     setDistanceLabel(null)
  //   }

  //   clearInterval(distanceFromTagToEventInterval.current)
  //   distanceFromTagToEventInterval.current = setInterval(() => {
  //     if (formattedEvent && formattedEvent.compareTagPosition && tag) {
  //       if (
  //         formattedEvent.compareTagPosition.x === 0 &&
  //         formattedEvent.compareTagPosition.y === 0
  //       ) {
  //         setDistanceFromTagToEvent('N/A')
  //       } else {
  //         const xDistance = Math.abs(
  //           tag.x - formattedEvent.compareTagPosition.x
  //         )
  //         const distance = Math.sqrt(Math.pow(xDistance, 2))
  //         setDistanceFromTagToEvent(distance.toFixed(2))
  //       }
  //     } else {
  //       setDistanceFromTagToEvent(null)
  //     }
  //   }, 500)
  // }, [tagId, formattedEvent])

  return (
    <>
      {playerSession?.player && <h5>{playerSession.player.fullName}</h5>}
      {tagId &&
        formattedEvent &&
        distanceFromTagToEvent &&
        isOfficiatingMode && (
          <div>
            <h4>
              {distanceLabel}:{' '}
              <span
                style={{
                  color: getColorBasedOnDistance(distanceFromTagToEvent)
                }}
              >
                {distanceFromTagToEvent}
              </span>
            </h4>
          </div>
        )}
    </>
  )
}
