import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProcessedDrill, RawDrill, RawPlayerBreakdowns } from './types'
import { getColor } from '../../utils/helpers'
import _ from 'lodash'
import { processDrill } from './functions'
import { FormattedSession } from '../sessions/types'

export interface DrillsState {
  processedData: {
    [drillId: string]: ProcessedDrill
  }
  drillCount: number
  playerBreakdowns: {
    [drillId: string]: RawPlayerBreakdowns
  }
  selectedId: string | null
  loading: boolean
  error: string | null
  goals: boolean
}

const initialState: DrillsState = {
  processedData: {},
  drillCount: 0,
  playerBreakdowns: {},
  loading: false,
  error: null,
  selectedId: null,
  goals: true
}

const drillsSlice = createSlice({
  name: 'drills',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true
      state.error = null
    },
    stopLoading: (state) => {
      state.loading = false
    },
    toggleGoals: (state) => {
      state.goals = !state.goals
    },
    selectDrill: (state, action: PayloadAction<RawDrill['id']>) => {
      state.selectedId = action.payload
    },
    updateDrillRegion: (state, action) => {
      const { drillId, region } = action.payload
      state.processedData[drillId].extraInfo.region = region
    },
    setDrills: (
      state,
      action: PayloadAction<{
        drills: RawDrill[]
        formattedSession: FormattedSession
      }>
    ) => {
      // Apply color to the drills
      const drills = action.payload.drills
        .sort((a, b) => a.startTime - b.startTime)
        .map((drill, index) => {
          const color = getColor(index)
          const processedDrill = processDrill(
            drill,
            action.payload.formattedSession,
            color
          )
          return processedDrill
        })
      state.processedData = _.keyBy(drills, 'id')
      state.drillCount = drills.length
      state.loading = false
      state.error = null
    },
    addDrill: (
      state,
      action: PayloadAction<{
        drill: RawDrill
        formattedSession: FormattedSession
      }>
    ) => {
      const color = getColor(state.drillCount - 1)
      const processedDrill = processDrill(
        action.payload.drill,
        action.payload.formattedSession,
        color
      )
      state.processedData[action.payload.drill.id] = processedDrill
      state.drillCount = Object.values(state.processedData).length
      state.loading = false
      state.error = null
    },
    updateDrill: (
      state,
      action: PayloadAction<{
        drill: RawDrill
        formattedSession: FormattedSession
      }>
    ) => {
      const existingDrill = state.processedData[action.payload.drill.id]
      const processedDrill = processDrill(
        action.payload.drill,
        action.payload.formattedSession,
        existingDrill.color
      )
      state.processedData[existingDrill.id] = {
        ...existingDrill,
        ...processedDrill
      }
      state.loading = false
      state.error = null
    },
    updateDrillPlayerBreakdowns: (
      state,
      action: PayloadAction<{
        drillId: string
        rawPlayerBreakdowns: RawPlayerBreakdowns
      }>
    ) => {
      state.playerBreakdowns[action.payload.drillId] =
        action.payload.rawPlayerBreakdowns
    }
  }
})

export const {
  startLoading,
  stopLoading,
  setDrills,
  addDrill,
  updateDrill,
  updateDrillRegion,
  selectDrill,
  updateDrillPlayerBreakdowns,
  toggleGoals
} = drillsSlice.actions
export const drillsReducer = drillsSlice.reducer
