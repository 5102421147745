import React from 'react'

import Loader from '../Loader/Loader'
import CheckIcon from '../../assets/img/checkmark.svg'

import styles from './Navbar.module.scss'
import { useTheme } from '../../ui/config/hook'

type CenterButtonItem = {
  type: string
  text: string
  hidden: boolean
}

interface CenterButtonProps {
  item: any
  callback: () => void
  text: string
  icon: string
  disabled: boolean
  loading: boolean
  visible: boolean
}

export const CenterButton = ({
  item,
  callback,
  text,
  icon,
  disabled,
  loading,
  visible
}: CenterButtonProps) => {
  const getStyles = (icon) => {
    if (icon === 'recording') {
      return `${styles.circle} ${styles.black}`
    } else if (disabled) {
      return `${styles.circle} ${styles.disabled}`
    } else {
      return `${styles.circle}`
    }
  }

  const handleClick = () => {
    if (!disabled && !loading) {
      callback()
    }
  }

  const theme = useTheme()

  return (
    <div key={item.type} className={styles.navItem}>
      {visible && (
        <div
          style={{ display: item.hidden ? 'none' : 'flex' }}
          className={styles.circleContainer}
        >
          <div
            onClick={handleClick}
            className={getStyles(icon)}
            style={{
              backgroundColor: icon === 'recording' ? '#323233' : theme.appColor
            }}
            id='centerButton'
            data-cy='centerButton'
            data-disabled={disabled}
          >
            {loading ? (
              <Loader color='white' />
            ) : (
              <React.Fragment>
                {icon === 'tick' && <img src={CheckIcon} alt='' />}
                {icon === 'plus' && (
                  <React.Fragment>
                    <div className={styles.lineOne} />
                    <div className={styles.lineTwo} />
                  </React.Fragment>
                )}
                {icon === 'recording' && (
                  <React.Fragment>
                    <div className={styles.recordCircleMT} />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <h4 className={styles.startSession}>{text}</h4>
        </div>
      )}
    </div>
  )
}
