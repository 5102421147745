import { Route } from '../../App/App.types'
import { useMatchTrackerNavbarProps } from '../../apps/MatchTracker/hooks'
import { MainProps } from '../../components/Views/Main/Main.types'
import { ViewType } from '../../components/Views/Views.types'
import { TeamsContent } from './Content'
import { useTeamsOptions } from './options'

export const path = '/teams'

export const teamsListRoute: Route<MainProps> = {
  path,
  view: {
    type: ViewType.MAIN,
    name: 'Teams',
    Content: TeamsContent,
    useNavbarProps: useMatchTrackerNavbarProps,
    useOptions: useTeamsOptions
  }
}
