// Used in validation and to change the 'from' team of a flight

import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { SelectForm } from '../../../components/Forms/SelectForm/SelectForm'
import { updateEvent } from '../../../metrics_server/events/actions'
import {
  useSelectedEvent,
  useSelectedFormattedEvent
} from '../../../metrics_server/events/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { parentEventTypes } from '../../../metrics_server/events/data_types'
import { eventTypes } from '../../../metrics_server/events/types/data_types'

export const useChangeFromTeamModal = () => {
  const dispatch = useAppDispatch()
  const event = useSelectedEvent()
  const formattedEvent = useSelectedFormattedEvent()

  const openChangeFromTeamModal = useCallback(() => {
    if (!event) return
    const { id, eventTypeValue } = event
    const { team } = formattedEvent

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        wrapper: true,
        title: 'Select Team',
        className: 'modalLarge',

        ChildComponent: () => (
          <SelectForm
            handleSubmit={(values) => {
              dispatch(
                updateEvent({
                  id,
                  teamId: values.selected === 'none' ? null : values.selected
                })
              )
              dispatch(toggleModal({}))
            }}
            options={team.options}
            initialValue={team?.selected ? team.selected.id : 'none'}
            // title={'Select Team'}
          />
        ),
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [formattedEvent])

  return {
    openChangeFromTeamModal
  }
}
