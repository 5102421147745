import React, { useState } from 'react'
import TeamFormGeneric from '../../../components/Forms/TeamForm/TeamForm'
import { isLocal } from '../../../metrics_server/env'
import { JoinOrganisationAndTeamFormProps } from '../config'
import { createTeam } from '../../../metrics_server/teams/actions'
import { useAppDispatch } from '../../../store/hooks'
import { createOrganisation } from '../../../metrics_server/organisations/actions'
import { setRedirect, goBack } from '../../../ui/router/actions'
import { useNewOrganisationCheck } from '../hooks'

export const CreateTeamForm = ({
  switchForm
}: JoinOrganisationAndTeamFormProps) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const isNewOrganisation = useNewOrganisationCheck()

  const handleSubmit = (values) => {
    setLoading(true)
    // If no organisation is setup, create one with the new team //
    if (isNewOrganisation) {
      dispatch(
        createOrganisation(values, () => {
          switchForm(null)
          dispatch(setRedirect('/teams'))
        })
      )
    } else {
      dispatch(
        createTeam(values, () => {
          switchForm(null)
          dispatch(setRedirect('/teams'))
        })
      )
    }
  }

  return (
    <TeamFormGeneric
      newTeam={true}
      handleProceed={handleSubmit}
      handleSecondaryBtn={() => switchForm('joinTeam')}
      loading={loading}
    />
  )
}
