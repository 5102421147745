import { useAppDispatch } from '../../../store/hooks'
import { signinUser } from '../../../metrics_server/user/actions'
import { Button } from '../../../components/Button/Button'
import { useCallback, useState } from 'react'

export const SigninFormNew = (props) => {
  const { switchForm } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useAppDispatch()
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      console.log(e)

      const values = {
        email,
        password
      }

      dispatch(
        signinUser(values, () => {
          switchForm(null)
        })
      )
    },
    [email, password]
  )

  return (
    <div className='flex min-h-screen'>
      {/* Left side with background image */}
      <div className='hidden md:block w-1/4 bg-[#2196f3] relative'>
        <div
          className='absolute inset-0 bg-cover bg-center'
          style={{
            backgroundImage: "url('/placeholder.svg?height=1080&width=1920')",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
      </div>

      {/* Right side with login form */}
      <div className='flex-1 flex justify-center px-16 py-32 bg-background min-h-screen'>
        <div className='w-full space-y-8'>
          <div className='space-y-6'>
            <h1 className='text-4xl'>Welcome to Sportable Reporting</h1>
            <p className='text-xl con'>
              Please login to access your latest reports
            </p>
          </div>

          <form onSubmit={(e) => handleSubmit(e)} className='space-y-6 w-1/2'>
            <input
              className='bg-foreground border text-background p-2'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='text'
            />
            <input
              className='bg-foreground border text-background p-2'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
            />

            <Button
              className='bg-foreground border text-background p-2'
              type='submit'
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
