import { drawCircle, drawLine } from '../2D/drawing'
import { Dimensions, Field } from '../types'

export function applySoccerSettings(field: Field, dimensions: Dimensions) {
  // Check if multi pitch - (dimensions.P43 exists) //
  const topY = dimensions.P43 ? dimensions.P43.y : dimensions.P9.y

  field.height = topY
  field.width = dimensions.P3.x - dimensions.P1.x
  field.tryLineDistance = dimensions.P3.x - dimensions.P1.x

  field.planes = {
    left: dimensions.P1.x,
    right: dimensions.P3.x,
    top: topY,
    bottom: dimensions.P2.y
  }

  field.edges = 3

  // Set origin to base
  field.originOffsetX = 0
  field.originOffsetY = field.height / 2

  field.color = '#089b64'
}

// Draw soccer pitch background on 2D canvas
export const drawSoccerLines = (
  props,
  dimensions,
  color,
  ctx,
  pixelScale,
  view,
  pitchFlipped?,
  pitchRotated?,
  lineWidth?,
  postWidth?
) => {
  const { field } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  const pitchHeight = dimensions.P9.y - dimensions.P1.y

  const pitchCanvasRectStyles = props.getCanvasRectStylesFromPitchCoordinates(
    dimensions.P10,
    dimensions.P8,
    dimensions.P3,
    dimensions.P1
  )

  // Draw horizantal mown  patches

  let numberOfVerticalPatches = 20
  let numberOfHorizontalPatches = numberOfVerticalPatches * 0.7

  if (pitchRotated) {
    numberOfVerticalPatches = numberOfVerticalPatches * 0.7
    numberOfHorizontalPatches = 20
  }

  const verticalPatchWidth =
    pitchCanvasRectStyles.width / numberOfVerticalPatches
  const horizontalPatchWidth =
    pitchCanvasRectStyles.height / numberOfHorizontalPatches

  for (let i = 0; i < numberOfVerticalPatches; i++) {
    if (i % 2 === 0) {
      const scaledOriginX = pitchCanvasRectStyles.x + i * verticalPatchWidth
      const scaledOriginY = pitchCanvasRectStyles.y
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOriginX,
        scaledOriginY,
        verticalPatchWidth,
        pitchCanvasRectStyles.height
      )
    }
  }

  for (let i = 0; i < numberOfHorizontalPatches; i++) {
    if (i % 2 === 0) {
      const scaledOriginX = pitchCanvasRectStyles.x
      const scaledOriginY = pitchCanvasRectStyles.y + i * horizontalPatchWidth
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOriginX,
        scaledOriginY,
        pitchCanvasRectStyles.width,
        horizontalPatchWidth
      )
    }
  }

  // Border

  ctx.beginPath()
  ctx.setLineDash([0])

  const p1Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P1.x,
    dimensions.P1.y
  )
  const p3Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P3.x,
    dimensions.P3.y
  )
  const p8Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P8.x,
    dimensions.P8.y
  )
  const p10Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P10.x,
    dimensions.P10.y
  )

  ctx.moveTo(p1Coord.scaleX, p1Coord.scaleY)
  ctx.lineTo(p3Coord.scaleX, p3Coord.scaleY)
  ctx.lineTo(p8Coord.scaleX, p8Coord.scaleY)
  ctx.lineTo(p10Coord.scaleX, p10Coord.scaleY)
  ctx.lineTo(p1Coord.scaleX, p1Coord.scaleY)
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  ctx.stroke()

  // Halfway line
  drawLine(
    dimensions.P2.x,
    dimensions.P2.y,
    dimensions.P9.x,
    dimensions.P9.y,
    ctx,
    null,
    lineWidth,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  // Draw Center Circle
  drawCircle(
    dimensions.P25.x,
    dimensions.P25.y,
    ctx,
    (dimensions.P25.x - dimensions.P24.x) * pixelScale,
    'rgba(255,255,255,0.8)',
    null,
    lineWidth,
    getCanvasCoordinate,
    pixelScale,
    null
  )

  // Draw Center Spot

  const dotSize = postWidth

  drawCircle(
    dimensions.P25.x,
    dimensions.P25.y,
    ctx,
    dotSize,
    'rgba(255,255,255,0.8)',
    null,
    dotSize,
    getCanvasCoordinate,
    pixelScale,
    null
  )

  // Draw Left Penalty Area
  ctx.beginPath()
  ctx.setLineDash([0])
  const p11Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P11.x,
    dimensions.P11.y
  )
  const p23Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P23.x,
    dimensions.P23.y
  )
  const p20Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P20.x,
    dimensions.P20.y
  )
  const p14Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P14.x,
    dimensions.P14.y
  )
  const p7Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P7.x,
    dimensions.P7.y
  )
  const p26Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P26.x,
    dimensions.P26.y
  )
  const p29Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P29.x,
    dimensions.P29.y
  )
  const p4Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P4.x,
    dimensions.P4.y
  )
  const p12Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P12.x,
    dimensions.P12.y
  )
  const p18Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P18.x,
    dimensions.P18.y
  )
  const p17Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P17.x,
    dimensions.P17.y
  )
  const p13Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P13.x,
    dimensions.P13.y
  )
  const p6Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P6.x,
    dimensions.P6.y
  )
  const p31Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P31.x,
    dimensions.P31.y
  )
  const p32Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P32.x,
    dimensions.P32.y
  )
  const p5Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P5.x,
    dimensions.P5.y
  )

  ctx.moveTo(p11Coord.scaleX, p11Coord.scaleY)
  ctx.lineTo(p23Coord.scaleX, p23Coord.scaleY)
  ctx.lineTo(p20Coord.scaleX, p20Coord.scaleY)
  ctx.lineTo(p14Coord.scaleX, p14Coord.scaleY)

  // Draw Right Penalty Area
  ctx.moveTo(p7Coord.scaleX, p7Coord.scaleY)
  ctx.lineTo(p26Coord.scaleX, p26Coord.scaleY)
  ctx.lineTo(p29Coord.scaleX, p29Coord.scaleY)
  ctx.lineTo(p4Coord.scaleX, p4Coord.scaleY)

  // Draw Left Goal Area
  ctx.moveTo(p12Coord.scaleX, p12Coord.scaleY)
  ctx.lineTo(p18Coord.scaleX, p18Coord.scaleY)
  ctx.lineTo(p17Coord.scaleX, p17Coord.scaleY)
  ctx.lineTo(p13Coord.scaleX, p13Coord.scaleY)

  // Draw Right Goal Area
  ctx.moveTo(p6Coord.scaleX, p6Coord.scaleY)
  ctx.lineTo(p31Coord.scaleX, p31Coord.scaleY)
  ctx.lineTo(p32Coord.scaleX, p32Coord.scaleY)
  ctx.lineTo(p5Coord.scaleX, p5Coord.scaleY)

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  ctx.stroke()

  // Draw Left Penalty Spot
  drawCircle(
    dimensions.P19.x,
    dimensions.P19.y,
    ctx,
    postWidth,
    'rgba(255,255,255,0.8)',
    null,
    postWidth,
    getCanvasCoordinate,
    pixelScale,
    null
  )

  // Draw Right Penalty Spot
  drawCircle(
    dimensions.P30.x,
    dimensions.P30.y,
    ctx,
    postWidth,
    'rgba(255,255,255,0.8)',
    null,
    postWidth,
    getCanvasCoordinate,
    pixelScale,
    null
  )

  function drawArc(
    pitchFlipped,
    defaultPenaltyPoint,
    rotatedPenaltyPoint,
    arcStartPoint,
    arcEndPoint
  ) {
    ctx.beginPath()
    const penaltyArcRadius = 9.15 * pixelScale // Adjust this value based on scale
    const startAngle = Math.atan2(
      arcStartPoint.y - defaultPenaltyPoint.y,
      arcStartPoint.x - defaultPenaltyPoint.x
    )
    let endAngle = Math.atan2(
      arcEndPoint.y - defaultPenaltyPoint.y,
      arcEndPoint.x - defaultPenaltyPoint.x
    )

    if (endAngle < startAngle) {
      endAngle += 2 * Math.PI
    }

    ctx.arc(
      getCanvasCoordinate(pixelScale, rotatedPenaltyPoint.x).scaleX,
      getCanvasCoordinate(pixelScale, null, rotatedPenaltyPoint.y).scaleY,
      penaltyArcRadius, // radius
      startAngle, // startAngle
      endAngle, // endAngle
      pitchFlipped // use pitchFlipped as the anticlockwise argument
    )
    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  if (field.width >= 80) {
    // Draw Left Penalty Arc
    if (!pitchFlipped) {
      drawArc(
        pitchFlipped,
        dimensions.P19,
        dimensions.P19,
        dimensions.P21,
        dimensions.P22
      )
    } else {
      drawArc(
        !pitchFlipped,
        dimensions.P30,
        dimensions.P19,
        dimensions.P27,
        dimensions.P28
      )
    }

    // Draw Right Penalty Arc
    if (!pitchFlipped) {
      drawArc(
        !pitchFlipped,
        dimensions.P30,
        dimensions.P30,
        dimensions.P28,
        dimensions.P27
      )
    } else {
      drawArc(
        pitchFlipped,
        dimensions.P19,
        dimensions.P30,
        dimensions.P22,
        dimensions.P21
      )
    }
  }

  // Goal dots

  const goalDotSize = postWidth

  drawCircle(
    dimensions.P16.x,
    dimensions.P16.y,
    ctx,
    goalDotSize,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P15.x,
    dimensions.P15.y,
    ctx,
    goalDotSize,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P33.x,
    dimensions.P33.y,
    ctx,
    goalDotSize,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P34.x,
    dimensions.P34.y,
    ctx,
    goalDotSize,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
}
