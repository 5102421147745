import { AxiosResponse } from 'axios'
import { setError } from '../../ui/error/actions'
import {
  AppAPIError,
  generateAPIErrorMessage
} from '../../utils/request_handler'
import { signoutUser } from '../user/actions'

export function requestAction<RequestDataType, ResponseDataType>(
  request: (data: RequestDataType) => Promise<AxiosResponse<ResponseDataType>>,
  callback: (response: ResponseDataType) => void,
  data: RequestDataType | null
) {
  return async (dispatch) => {
    try {
      const response = await request(data)
      callback(response.data)
      return response
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function handleAPIError(
  error: AppAPIError,
  errorType: 'error' | 'errorDetail',
  customMessage?: string
) {
  return (dispatch) => {
    // If the error is a 401 (Unauthorised), signout the user //
    if (error.response?.status === 401) {
      setTimeout(() => {
        dispatch(
          setError({
            message: 'User no longer authenticated please login again...',
            proceed: () => dispatch(signoutUser())
          })
        )
      }, 1000)
    } else {
      console.error('error', error)
      dispatch(
        setError({
          message: generateAPIErrorMessage(error, errorType, customMessage)
        })
      )
    }
  }
}
