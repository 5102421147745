import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './Table.module.scss'

//3 TanStack Libraries!!!
import { flexRender } from '@tanstack/react-table'
import { useVirtual } from 'react-virtual'
import { setSelectedEvent } from '../../metrics_server/events/actions'
import { useAppDispatch } from '../../store/hooks'
import { TableControl } from './Table.types'
import { useEvents } from '../../metrics_server/events/hooks'
import { sportableColors } from '../../constants/sportableColors'

const ROW_HEIGHT = 35 // seems to be the right height for maintaining selected event in middle of table

// Benchmarking the virtual lib //
const useVirtualBenchmark = ({ parentRef, size, overscan }) => {
  const rowVirtualizer = useVirtual({
    size,
    parentRef,
    estimateSize: React.useCallback(() => ROW_HEIGHT, []),
    overscan
  })
  return rowVirtualizer
}

export const NewTable = ({
  table,
  controls,
  title,
  handleShiftUpShortcut,
  active,
  id
}) => {
  const dispatch = useAppDispatch()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const events = useEvents()
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1)
  const [isScrolling, setIsScrolling] = useState(false)
  const scrollIntervalRef = useRef(null)
  const resizeObserverRef = useRef(null)

  const handleRowClick = (row) => {
    const event = row?.original
    dispatch(setSelectedEvent(event?.id))
  }

  const renderControl = (option: TableControl, index: number) => {
    if (option.hidden) return <noscript />
    return (
      <button
        className={`${styles.option} link button`}
        key={index}
        onClick={() => {
          const item = flatRows[selectedRowIndex]?.original
          option.callback(item)
        }}
      >
        {option.name}
      </button>
    )
  }

  const { flatRows, rows, rowsById } = table.getRowModel()

  const rowIndexMap = useMemo(() => {
    const map = new Map()
    flatRows.forEach((row, index) => {
      map.set(row.id, index)
    })
    return map
  }, [flatRows])

  const scrollToSelectedRow = useCallback(() => {
    if (selectedRowIndex !== -1 && tableContainerRef.current) {
      const scrollAndObserve = () => {
        const containerHeight = tableContainerRef.current.clientHeight
        if (containerHeight > 0) {
          const currentIndex = rowIndexMap.get(flatRows[selectedRowIndex].id)
          const scrollTop =
            currentIndex * ROW_HEIGHT - containerHeight / 2 + ROW_HEIGHT / 2
          tableContainerRef.current.scrollTo({
            top: Math.max(0, scrollTop),
            behavior: 'smooth'
          })
          if (resizeObserverRef.current) {
            resizeObserverRef.current.disconnect()
          }
        } else {
          if (!resizeObserverRef.current) {
            resizeObserverRef.current = new ResizeObserver((entries) => {
              for (const entry of entries) {
                if (entry.contentRect.height > 0) {
                  scrollAndObserve()
                  break
                }
              }
            })
          }
          resizeObserverRef.current.observe(tableContainerRef.current)
        }
      }

      setTimeout(scrollAndObserve, 0)
    }
  }, [selectedRowIndex, flatRows, rowIndexMap])

  const handleScroll = useCallback(
    (direction) => {
      const currentIndex = rowIndexMap.get(flatRows[selectedRowIndex].id)
      const newIndex =
        direction === 'up'
          ? Math.max(currentIndex - 1, 0)
          : Math.min(currentIndex + 1, flatRows.length - 1)

      const newSelectedRow = flatRows.find(
        (row) => rowIndexMap.get(row.id) === newIndex
      )
      setSelectedRowIndex(flatRows.indexOf(newSelectedRow))
      dispatch(setSelectedEvent(newSelectedRow?.original?.id))

      scrollToSelectedRow()
    },
    [selectedRowIndex, flatRows, dispatch, rowIndexMap, scrollToSelectedRow]
  )

  const startScrolling = useCallback(
    (direction) => {
      if (scrollIntervalRef.current) return
      setIsScrolling(true)
      scrollIntervalRef.current = setInterval(
        () => handleScroll(direction),
        100
      )
    },
    [handleScroll]
  )

  const stopScrolling = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current)
      scrollIntervalRef.current = null
      setIsScrolling(false)
    }
  }, [])

  const handleKeyDown = useCallback(
    (event) => {
      if (flatRows.length === 0) return

      if (event.shiftKey) {
        if (event.key === 'ArrowUp' && handleShiftUpShortcut) {
          event.preventDefault()
          if (event.repeat) return
          const mostRecentKick = handleShiftUpShortcut()
          if (mostRecentKick) {
            const mostRecentKickRow = flatRows.find(
              (row) => row?.original.id === mostRecentKick.id
            )
            if (mostRecentKickRow) {
              setSelectedRowIndex(flatRows.indexOf(mostRecentKickRow))
            }
          }
        } else if (event.key === 'ArrowDown') {
          event.preventDefault()
          if (event.repeat) return
          //   handleShiftDownShortcut()
        }
      } else {
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault()
            if (!isScrolling) handleScroll('up')
            startScrolling('up')
            break
          case 'ArrowDown':
            event.preventDefault()
            if (!isScrolling) handleScroll('down')
            startScrolling('down')
            break
          default:
            return
        }
      }
    },
    [flatRows, handleScroll, startScrolling, isScrolling, handleShiftUpShortcut]
  )

  const handleKeyUp = useCallback(
    (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        stopScrolling()
      }
    },
    [stopScrolling]
  )

  useEffect(() => {
    if (active) {
      scrollToSelectedRow()
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('keyup', handleKeyUp)
    } else {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      stopScrolling()
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      stopScrolling()
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect()
      }
    }
  }, [active, handleKeyDown, handleKeyUp, stopScrolling, scrollToSelectedRow])

  useEffect(() => {
    let selectedRow
    const highlightedEventId = events.selectedEventId
    if (highlightedEventId) {
      selectedRow = flatRows.find(
        (row) => row.original?.id === highlightedEventId
      )
    }
    if (selectedRow) {
      setSelectedRowIndex(flatRows.indexOf(selectedRow))

      if (active) {
        scrollToSelectedRow()
      }
    }
  }, [events.selectedEventId, flatRows, active, scrollToSelectedRow])

  const rowVirtualizer = useVirtualBenchmark({
    parentRef: tableContainerRef,
    size: flatRows.length,
    overscan: 10
  })

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  return (
    <>
      {(controls || title) && (
        <div className={styles.headerContainer}>
          <div className={styles.titleContainer}>
            <h5>{title}</h5>
          </div>
          <div className={styles.optionsContainer}>
            {controls.map(renderControl)}
          </div>
        </div>
      )}
      <div
        ref={tableContainerRef}
        style={{
          height: controls || title ? 'calc(100% - 25px)' : '100%',
          overflowY: 'auto',
          borderBottom: '1px solid #e3e3e3'
        }}
        data-cy={`table-${id}`}
      >
        <table className={styles['minimalistBlack']}>
          <thead style={{ position: 'sticky', top: 0 }}>
            {table.getHeaderGroups() &&
              table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          fontSize: '13px',
                          width: header.getSize() + '%',
                          writingMode:
                            header.id === 'ignore' ? 'vertical-rl' : undefined
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {/* {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )} */}
                            {header.column.columnDef.header}
                            {header.column.getIsSorted() === 'asc' && (
                              <span>&#9652;</span>
                            )}
                            {header.column.getIsSorted() === 'desc' && (
                              <span>&#9662;</span>
                            )}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = flatRows[virtualRow.index]
              const rowData = row.original

              const color =
                (rowData && rowData.id === events.selectedEventId) ||
                (rowData && rowData.hasFailedToRender)
                  ? 'white'
                  : rowData?.__color
                  ? rowData?.__color
                  : null
              return (
                <tr key={row.id} data-cy={`table-row-${rowData.id}`}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        onClick={() => handleRowClick(row)}
                        style={{
                          backgroundColor: rowData.hasFailedToRender
                            ? 'red'
                            : rowData &&
                              rowData.id === events.selectedEventId &&
                              sportableColors.colors.tableHighlightColor,
                          fontSize: '13px',
                          color: color,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
