import React, { useEffect, useMemo, useState } from 'react'
import styles from './Graphics.module.scss'
import { Toggle } from '../../../components/Toggle/Toggle'
import { Button } from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import { SelectionSideBar } from '../../../components/SelectionSidebar/SelectionSidebar'
import {
  graphicsSelectionList,
  getGraphicsTypeBySportsType
} from '../../../const/index'
import { useAppDispatch } from '../../../store/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useUnits } from '../../../metrics_server/units/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { setInfo } from '../../../ui/info/actions'
import {
  getAspectRatios,
  getGraphicsTypes,
  getSessionGraphic,
  getTournaments
} from '../../../metrics_server/graphics/actions'
import {
  useAspectRatioOptions,
  useGraphics
} from '../../../metrics_server/graphics/hooks'
import BasicSelect from '../../../components/Material/Select'
import { isSoccer } from '../../../metrics_server/sports/functions'

export const Graphics = () => {
  const unitSystemMap = {
    SI: 0,
    Metric: 1,
    British: 2,
    American: 3,
    AmericanFeet: 4
  }

  const dispatch = useAppDispatch()
  const { sessionData, sport } = useSelectedFormattedSession()
  const graphics = useGraphics()
  const aspectRatioOptions = useAspectRatioOptions()

  const units = useUnits()

  // On mount fetch graphics types, tournaments and aspect ratios available from the backend
  useEffect(() => {
    dispatch(getGraphicsTypes())
    dispatch(getTournaments())
    dispatch(getAspectRatios())
  }, [])

  // useEffect(() => {
  //   if (graphics.tournaments.length > 0) {
  //     // setTournament(graphics.tournaments[0])
  //     setTournament('superrugby')
  //   }
  // }, [graphics.tournaments])

  // // do the same for aspect ratios
  // useEffect(() => {
  //   if (graphics.aspectRatios.length > 0) {
  //     setAspectRatio(graphics.aspectRatios[0]?.value)
  //   }
  // }, [graphics.aspectRatios])

  const graphicsTypeBySportsType = getGraphicsTypeBySportsType(
    sessionData.sportType,
    graphicsSelectionList
  )

  // STATE
  const [graphicType, setGraphicType] = useState(
    graphicsTypeBySportsType[0] || []
  )
  const [stadium, setStadium] = useState('')
  const [tournament, setTournament] = useState('adidas')
  const [aspectRatio, setAspectRatio] = useState(
    aspectRatioOptions[0]?.value || '1x1'
  )

  const [graphicsImageURL, setGraphicsImageURL] = useState(
    graphics?.[graphicType.id]?.[aspectRatio]?.image
  )

  const tournamentOptions = useMemo(() => {
    return graphics.tournaments.map((tournament) => {
      return {
        value: tournament,
        name: tournament
      }
    })
  }, [graphics.tournaments])

  // const initialSoccerPlayerState = {
  //   playerOne: playerOptions[0]?.value,
  //   playerTwo: playerOptions[1]?.value
  // }

  // const soccerPlayerReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'setPlayer':
  //       return { ...state, playerOne: action.value }
  //     case 'setPlayerTwo':
  //       return { ...state, playerTwo: action.value }
  //     default:
  //       throw new Error()
  //   }
  // }

  // const [soccerPlayerState, playerDispatch] = useReducer(
  //   soccerPlayerReducer,
  //   initialSoccerPlayerState
  // )

  // function getGraphicImageURL(graphicsImage) {
  //   if (graphicsImage) {
  //     return URL.createObjectURL(graphicsImage)
  //   } else {
  //     return ''
  //   }
  // }

  useEffect(() => {
    if (!graphics[graphicType?.id] || !aspectRatio) {
      return
    } else {
      setGraphicsImageURL(graphics[graphicType?.id][aspectRatio].image)
    }
  }, [graphics?.[graphicType.id]?.[aspectRatio]?.image])

  const graphicsIsGenerating =
    graphics?.[graphicType.id]?.[aspectRatio]?.generating

  const updateTournament = (name, value) => {
    setTournament(value)
    fetchGraphics(graphicType, stadium, value)
  }

  const handleSelectGraphic = (graphicType, stadium, tournament) => {
    const type = graphicsTypeBySportsType.find((type) => {
      return type.id === graphicType
    })

    setGraphicType(type)
    // fetchGraphics(graphicType, speedUnit, stadium, tournament)
  }

  const handleSetAspectRatio = (aspectRatio) => {
    setAspectRatio(aspectRatio)
  }

  const fetchGraphics = (graphicType, stadium, tournament) => {
    aspectRatioOptions.forEach((aspectRatio) => {
      const data = {
        Graphic: graphicType.id,
        Stadium: stadium,
        Tournament: tournament,
        Units: unitSystemMap[units.selected],
        AspectRatio: aspectRatio.value
      }
      dispatch(getSessionGraphic(sessionData.id, data))
    })
  }

  const downloadGraphic = (blobURL, filename) => {
    const link = document.createElement('a')
    link.href = blobURL
    link.download = filename
    link.click()
  }

  const handleDownloadGraphic = (image, name) => {
    if (!image) {
      return dispatch(
        setInfo({
          message: 'API error, unable to download template. Please try again',
          header: 'Download Error',
          proceed: () => dispatch(toggleModal({}))
        })
      )
    }
    downloadGraphic(image, 'graphic')
  }

  const handleUpdateGraphic = (graphicType, stadium, tournament) => {
    fetchGraphics(graphicType, stadium, tournament)
  }

  const handleUpdateSoccerGraphic = (graphicType, stadium, tournament) => {
    aspectRatioOptions.forEach((aspectRatio) => {
      const data = {
        Graphic: graphicType.id,
        Stadium: stadium,
        Tournament: tournament,
        Units: unitSystemMap[units.selected],
        AspectRatio: aspectRatio.value
        // PlayerIDs: [soccerPlayerState.playerOne, soccerPlayerState.playerTwo]
      }
      dispatch(getSessionGraphic(sessionData.id, data))
    })
  }

  useEffect(() => {
    if (
      graphics.tournaments.length > 0 &&
      aspectRatioOptions.length > 0 &&
      graphics.graphicsTypes.length > 0
    ) {
      // Check if the graphics state already contains the data you're about to fetch
      const isDataAlreadyFetched = aspectRatioOptions.some((aspectRatio) => {
        return graphics[graphicType.id]?.[aspectRatio.value]
      })

      if (!isDataAlreadyFetched) {
        handleUpdateSoccerGraphic(graphicType, stadium, tournament)
      }
    }
  }, [graphics.tournaments, aspectRatioOptions, graphics.graphicsTypes])

  // TODO: unable to fetch graphics. Please select the correct criteria

  return (
    <div className={styles.graphicsContainer}>
      <div className={styles.graphicsAndPlayerComparisonContainer}>
        {isSoccer(sport) && <></>}
        {graphics.tournaments.length > 0 && (
          <div className={styles.graphicsSettings}>
            <BasicSelect
              label={'Select Tournament'}
              options={tournamentOptions}
              selected={tournament}
              onChange={(value) => updateTournament('tournament', value)}
              variant={'standard'}
            />
            <BasicSelect
              selected={stadium}
              onChange={(value) => setStadium(value)}
              label={'Stadium'}
              variant='standard'
            />
          </div>
        )}
        <div className={styles.graphicsHTMLTemplate}>
          {!graphicsIsGenerating ? (
            <React.Fragment>
              {graphicsImageURL ? (
                <img
                  alt={'graphic'}
                  src={graphicsImageURL}
                  className={styles.graphicsImage}
                />
              ) : (
                <div className={styles.noImageFound}>No image found</div>
              )}
            </React.Fragment>
          ) : (
            <div className={styles.loaderContainer}>
              <Loader message='Loading in graphics...' />
            </div>
          )}
        </div>
        <div className={styles.graphicsVisualSettingsAndDownload}>
          <div className={styles.toggleStyles}>
            <h6 style={{ textAlign: 'center', paddingBottom: '4px' }}>
              Aspect Ratio
            </h6>
            <Toggle
              name={'aspectRatio'}
              input={{
                value: aspectRatio,
                onChange: (aspectRatio) => handleSetAspectRatio(aspectRatio)
              }}
              options={aspectRatioOptions}
              size={'s'}
            />
          </div>
        </div>
      </div>
      <div className={styles.graphicsSelectionBarContainer}>
        <div>
          <SelectionSideBar
            graphicsSelectionList={graphicsTypeBySportsType}
            input={{
              id: graphicType.id,
              onSelect: (graphicType) =>
                handleSelectGraphic(graphicType, stadium, tournament)
            }}
          />
        </div>
        <div className={styles.fetchGraphicsButton}>
          <Button
            disabled={false}
            className={'btn--primary'}
            handleClick={() =>
              isSoccer(sport)
                ? handleUpdateSoccerGraphic(graphicType, stadium, tournament)
                : handleUpdateGraphic(graphicType, stadium, tournament)
            }
          >
            Get Graphic
          </Button>

          <Button
            disabled={false}
            className={'btn--primary'}
            handleClick={() =>
              handleDownloadGraphic(graphicsImageURL, 'graphic')
            }
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  )
}

/* {isSoccer(sport) && (
          <div className={styles.graphicsSettings}>
            <div style={{ width: '20%' }}>
              <SearchableDropdown
                items={tournaments}
                onSelect={(value) => setTournament(value)}
                value={tournament}
                label={'Select Tournament'}
                isMultiple={false}
                minWidth={300}
              />
            </div>
            <div style={{ width: '20%' }}>
              <SearchableDropdown
                items={playerOptions}
                onSelect={(value) =>
                  playerDispatch({ type: 'setPlayer', value })
                }
                value={soccerPlayerState.playerOne}
                label={'Select Player One'}
                isMultiple={false}
                minWidth={250}
              />
            </div>
            <div style={{ width: '20%' }}>
              <SearchableDropdown
                items={playerOptions}
                onSelect={(value) =>
                  playerDispatch({ type: 'setPlayerTwo', value })
                }
                value={soccerPlayerState.playerTwo}
                label={'Select Player Two'}
                isMultiple={false}
                minWidth={250}
              />
            </div>
          </div>
        )} */
//
