import React from 'react'
import { Grid } from '@mui/material'
import BasicSelect from '../Material/Select'
import styles from './EventFilters.module.scss'
import { EventsFilter } from '../../metrics_server/events/filter'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import { ParentEventTypeGroup } from '../../metrics_server/events/data_types'
import { MetricTypeKeys } from '../../metrics_server/metrics/data_types'
import { isFilterOptionsEmpty } from '../../hooks/filter'

export interface EventFiltersProps {
  eventsFilter: EventsFilter
  sessionId: string
}

const eventTypeHasMetric = (
  parentEventTypes: ParentEventTypeGroup,
  filters: EventsFilter['filters'],
  metricKey: MetricTypeKeys
) => {
  const eventType = parentEventTypes.getTypeByValue(filters.parentType.value)
  const type = eventType?.props?.types?.getTypeByValue(filters.type.value)
  const subType = type?.props?.types?.getTypeByValue(filters.subType.value)

  let primaryType: typeof eventType | typeof type | typeof subType = eventType

  if (subType && !subType.isUnknown()) {
    primaryType = subType
  } else if (type && !type.isUnknown()) {
    primaryType = type
  }

  return primaryType?.props.metricTypes?.items[metricKey]
}

export function EventFiltersForm({
  sessionId,
  eventsFilter
}: EventFiltersProps) {
  const { filters, updateFilterValue } = eventsFilter

  const formattedSession = useFormattedSession(sessionId)

  return (
    <div className={styles.controls}>
      <Grid container rowSpacing={0} columnSpacing={1}>
        {/* <h5>Filter</h5> */}
        {/* <div style={{fontSize: '13px'}}>Team:</div>
            <div className="smallSelectContainer">
              <Select className="small-select" options={teamOptions} input={{name: 'tea0mFilter', value: teamFilter}} updateValue={this.updateFilterValue}/>
            </div> */}
        <Grid item xs={6}>
          <BasicSelect
            variant='standard'
            selected={filters.parentType.value}
            onChange={(value) => updateFilterValue('parentType', value)}
            label='Select Event Type'
            options={filters.parentType.options}
            size={'small'}
            fontSize={12}
            padding={0}
            labelMarginTop={8}
            labelShrink={true}
          />
        </Grid>
        {!isFilterOptionsEmpty(filters.type) && (
          <Grid item xs={6}>
            <BasicSelect
              variant='standard'
              selected={filters.type.value}
              onChange={(value) => updateFilterValue('type', value)}
              label='Select Type'
              options={filters.type.options}
              size={'small'}
              fontSize={12}
              padding={0}
              labelMarginTop={8}
              labelShrink={true}
            />
          </Grid>
        )}

        {!isFilterOptionsEmpty(filters.team) && (
          <Grid item xs={6}>
            <BasicSelect
              variant='standard'
              selected={filters.team.value}
              onChange={(value) => updateFilterValue('team', value)}
              label={'Team'}
              options={filters.team.options}
              size={'small'}
              fontSize={12}
              padding={0}
              labelMarginTop={8}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          {/* <SearchableDropdown
            fontSize={12}
            items={filters.playerId.options.sort((a, b) => a.number - b.number)}
            onSelect={(value) => updateFilterValue('playerId', value)}
            filterName={'player'}
            value={filters.playerId.value}
            label={'Player'}
            isMultiple={true}
            disableCloseOnSelect={true}
            inputVariant='standard'
            labelShrink={true}
          /> */}
        </Grid>
        {eventTypeHasMetric(
          formattedSession.parentEventTypes,
          filters,
          'madeTouch'
        ) && (
          <Grid item xs={6}>
            <BasicSelect
              variant='standard'
              options={filters.madeTouch.options}
              onChange={(value) => updateFilterValue('madeTouch', value)}
              label={'Made Touch'}
              selected={filters.madeTouch.value}
              size={'small'}
              fontSize={12}
              padding={0}
              labelMarginTop={8}
            />
          </Grid>
        )}

        {!isFilterOptionsEmpty(filters.subType) && (
          <Grid item xs={6}>
            {/* <SearchableDropdown
              fontSize={12}
              items={filters.subType.options}
              onSelect={(value) => updateFilterValue('subType', value)}
              filterName={'kickTypes'}
              value={filters.subType.value}
              label={'Sub Type'}
              isMultiple={true}
              inputVariant='standard'
              labelShrink={true}
            /> */}
          </Grid>
        )}

        {eventTypeHasMetric(
          formattedSession.parentEventTypes,
          filters,
          'forward'
        ) && (
          <Grid item xs={6}>
            <BasicSelect
              variant='standard'
              selected={filters.forward.value}
              onChange={(value) => updateFilterValue('forward', value)}
              label={'Forward'}
              options={filters.forward.options}
              size={'small'}
              fontSize={12}
              padding={0}
              labelMarginTop={8}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
