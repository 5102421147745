import React from 'react'
import { CreateOrganisationForm } from './CreateOrganisationForm/CreateOrganisationForm'
import { JoinOrganisationForm } from './JoinOrganisationForm/JoinOrganisationForm'
import { JoinTeamForm } from './JoinTeamForm/JoinTeamForm'
import { CreateTeamForm } from './CreateTeamForm/CreateTeamForm'
import { FormConfig } from '../../components/Views/Form/Form.types'

export type JoinOrganisationAndTeamFormKeys =
  | 'createOrganisation'
  | 'joinOrganisation'
  | 'createTeam'
  | 'joinTeam'

export type JoinOrganisationAndTeamFormConfig =
  FormConfig<JoinOrganisationAndTeamFormKeys>

export const formConfig: JoinOrganisationAndTeamFormConfig = {
  createOrganisation: {
    key: 'createOrganisation',
    name: 'Create Organisation',
    Component: CreateOrganisationForm,
    title: () => {
      return <h1>Create Organisation</h1>
    }
  },
  joinOrganisation: {
    key: 'joinOrganisation',
    name: 'Join Organisation',
    Component: JoinOrganisationForm,
    title: () => {
      return (
        <h1>
          Join An <span>Organisation</span>
        </h1>
      )
    }
  },
  createTeam: {
    key: 'createTeam',
    name: 'Create Team',
    Component: CreateTeamForm,
    title: () => {
      return <h1>Create New Team</h1>
    }
  },
  joinTeam: {
    key: 'joinTeam',
    name: 'Join Team',
    Component: JoinTeamForm,
    title: () => {
      return <h1>Join Team</h1>
    }
  }
}

export const formMatrix = [
  [formConfig.joinOrganisation, formConfig.joinTeam],
  [formConfig.createOrganisation, formConfig.createTeam]
]
