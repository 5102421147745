import React from 'react'
import styles from './NotificationsListItem.module.scss'
import downloadIcon from '../../../../../assets/img/download-inverse.svg'
import batteryLow from '../../../../../assets/img/tag_low_batt.svg'
import {
  checkLowBattLevelAndReturnCorrectText,
  renderDeviceIcon
} from '../../../functions'
import { NotificationType } from '../../../types'
import { sportableColors } from '../../../../../const'
import { useSessions } from '../../../../sessions/hooks'
import moment from 'moment'
import { clearNotificationInHistory } from '../../../actions'
import { useAppDispatch } from '../../../../../store/hooks'

export interface NotificationsListItemProps {
  notification: NotificationType
}

export const NotificationsListItem = ({
  notification
}: NotificationsListItemProps) => {
  const dispatch = useAppDispatch()

  const { deviceType, timestamp, title, description, text, arrivalTime } =
    notification

  let isDownloadNotification: boolean
  let downloadNotificationTimestamp: string

  if (!!text && !!arrivalTime) {
    isDownloadNotification = true
    downloadNotificationTimestamp = moment.unix(arrivalTime).format('h:mm:ss')
  }

  const convertedTime = moment.unix(timestamp).format('h:mm:ss')

  const isLowBattery = description
    ? description.includes('Low Battery')
    : undefined

  const icon = downloadIcon

  const sessions = useSessions()

  const CloseNotificationButton = ({ notification }) => {
    return (
      <p
        id='notificationClose'
        style={{
          cursor: 'pointer',
          color: 'gray'
        }}
        onClick={(e) => {
          e.stopPropagation() // Prevent event bubbling
          dispatch(clearNotificationInHistory(notification))
        }}
      >
        x
      </p>
    )
  }

  // download notification
  if (isDownloadNotification) {
    return (
      <div className={styles.outerContainer}>
        <div className={styles.diagsNotificationContainer}>
          <div
            style={{
              backgroundImage: `url(${icon})`
            }}
            className={styles.icon}
          />

          <div className={styles.infoContainer}>
            <div className={styles.timestampAndTitle}>
              <h5
                style={{
                  color: '#323233',
                  paddingLeft: '10px'
                }}
              >
                {text}{' '}
              </h5>
              <p style={{ fontStyle: 'italic' }}>
                {downloadNotificationTimestamp}
              </p>
            </div>
          </div>
        </div>
        <CloseNotificationButton notification={notification} />
      </div>
    )
  }

  if (!deviceType || !timestamp) {
    return null
  }

  return (
    <div className={styles.outerContainer}>
      <div className={styles.diagsNotificationContainer}>
        <div className={styles.deviceIcon}>
          {renderDeviceIcon(deviceType, sessions.activeSession?.sportType)}
        </div>
        <div className={styles.separator} />
        <div className={styles.infoContainer}>
          <div className={styles.timestampAndTitle}>
            <h5 style={{ color: 'gray' }}>{convertedTime}</h5>
            <h5
              style={{
                color: '#323233',
                paddingLeft: '10px'
              }}
            >
              {title}
            </h5>
          </div>
          {description && (
            <div className={styles.description}>
              {isLowBattery && (
                <img
                  src={batteryLow}
                  alt='batt'
                  className={styles.batteryImage}
                  style={{ paddingRight: '10px' }}
                />
              )}
              <h5 style={{ color: sportableColors.colors.colorError }}>
                {isLowBattery
                  ? checkLowBattLevelAndReturnCorrectText(description)
                  : description}
              </h5>
            </div>
          )}
        </div>
      </div>
      <CloseNotificationButton notification={notification} />
    </div>
  )
}
