import React from 'react'
import { useToggleLogic } from './Toggle.logic'
import styles from './Toggle.module.scss'
export interface ToggleProps {
  input: Input
  options: Array<Option>
  size?: 'xs' | 's' | 'm' | 'l'
  name: string
}

type Option = {
  name: string
  value: string | number
}

type Input = {
  value: string | number
  onChange: (value: string | number) => void
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const { toggleStyle, sliderStyle, toggleItemStyle, size } =
    useToggleLogic(props)

  const renderOption = (option: Option, index: number) => {
    let isSelected = props.input.value === option.value
    if (!props.input.value && props.input.value !== 0) {
      isSelected = index === 0
    }
    return (
      <div
        key={option.value}
        onClick={() => {
          props.input.onChange(option.value)
        }}
        style={toggleItemStyle}
        className={`${styles.toggle} ${
          isSelected && styles.toggleActive
        } ${size}`}
        data-cy={`${props.name}-${option.value.toString()}`}
      >
        {option.name}
      </div>
    )
  }

  return (
    <div style={toggleStyle} className={styles.toggleContainer}>
      {props.options.map(renderOption)}
      <div style={sliderStyle} className={`${styles.toggleSlider}`}></div>
      <input
        name={props.name}
        value={props.input.value}
        style={{ display: 'none' }}
      />
    </div>
  )
}
