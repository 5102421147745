import { Player } from '.'
import { ReportingHeader } from '../../app/header'

export const path = '/player'

export const playerRoute = {
  path,
  view: {
    type: 'PAGE',
    name: 'Player',
    Content: Player,
    Header: ReportingHeader
  }
}
