import React, { useCallback } from 'react'
import { FormContainer } from '../Form/Form'
import {
  FormattedSession,
  RawSessionData,
  SessionData
} from '../../../metrics_server/sessions/types'
import { sessionModeTypes } from '../../../metrics_server/sessions/modes/data_types'
import { sessionTypes } from '../../../metrics_server/sessions/data_types'
import { useAppDispatch } from '../../../store/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { patchSession } from '../../../metrics_server/sessions/actions'

export interface EditSessionFormProps {
  handleProceed: (values) => void
  initialValues: SessionData | RawSessionData
  handleCancel?: () => void
}

const validate = (values) => {
  const errors = {} as any

  // Validate name field
  if (!values.name) {
    errors.name = 'Name is required'
  }

  return errors
}

export const EditSessionForm = (props: EditSessionFormProps) => {
  const sessionType = sessionTypes.getTypeByValue(props.initialValues.type)

  const fields = [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      initialValue: props.initialValues.name
    },
    {
      name: 'mode',
      type: 'toggle',
      size: 'm',
      width: '50%',
      initialValue: props.initialValues.mode,
      options: sessionModeTypes.options
    },
    {
      name: 'subType',
      type: 'toggle',
      size: 'm',
      width: '125%',
      initialValue: props.initialValues.subType,
      options: sessionType.props.subTypes?.options
    }
  ]

  const handleSubmit = (values, initialValues) => {
    const data = {
      ...initialValues,
      name: values.name,
      mode: values.mode,
      subType: values.subType
    }
    props.handleProceed(data)
  }

  return (
    <FormContainer
      name={'editSessionForm'}
      fields={fields}
      validate={validate}
      validateOnBlur={true}
      onSubmit={(values) => handleSubmit(values, props.initialValues)}
      title={'Edit Session'}
      submitText={'Update'}
      secondaryButtonText={'Cancel'}
      secondaryButtonHandleClick={props.handleCancel}
    />
  )
}

export default EditSessionForm

export const useEditSessionFormModal = (session: FormattedSession) => {
  const dispatch = useAppDispatch()
  const openEditSessionModal = useCallback(() => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        ChildComponent: () => {
          return (
            <EditSessionForm
              handleProceed={(values) => {
                dispatch(patchSession(values))
                dispatch(toggleModal({}))
              }}
              initialValues={session.sessionData}
              handleCancel={() => dispatch(toggleModal({}))}
            />
          )
        },
        message: 'Would you like to download the session data in .csv format?',
        className: 'modalSmall',
        wrapper: true,
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [session, dispatch])

  return {
    openEditSessionModal
  }
}
