import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../data_types'

import { australianRulesConfig } from './australian_rules'
import { rugbyUnionConfig } from './rugby_union'
import { rugbyLeagueConfig } from './rugby_league'
import { americanFootballConfig } from './american_football'
import { demonstrationConfig } from './demonstration'
import { canadianFootballConfig } from './canadian_football'

import { ParentEventTypeGroup } from '../events/data_types'
import { Pitch } from '../pitches/types'

import { UnitSystemNames } from '../units/types'
import { soccerConfig } from './soccer'
import { MetricTypeGroup, MetricTypeKeys } from '../metrics/data_types'
import { PitchSetupTypeGroup } from '../pitches/data_types'
import { Bibs } from '../drills/types'
import { DefaultSoccerDrillRegion } from './soccer/types'
import { DefaultAmericanFootballDrillRegion } from './american_football/types'
import { AllEventTypeKeys } from '../events/types'
import { EventTypeKeys } from '../events/types/data_types'

export type SportTypeFeatures = {
  positionNumbers: boolean
  noOfPlayersInStartingLineUp: number
  maxPlayers: number
  defaultUnitSystem: UnitSystemNames
  keyboardShortcuts: any
  targetEvents: Readonly<AllEventTypeKeys[]>
  recentEventTableMetrics: Readonly<MetricTypeKeys[]>
  broadcastIntegrationAvailable: boolean
  scoreboard: boolean
  homeSideCheck: boolean
  bibs: Bibs
  drillsEnabled?: boolean
  targetMetrics?: Readonly<MetricTypeKeys[]>
}

export const defaultSportTypeFeatures: SportTypeFeatures = {
  positionNumbers: false,
  noOfPlayersInStartingLineUp: 0,
  maxPlayers: 0,
  defaultUnitSystem: 'SI',
  keyboardShortcuts: {},
  targetEvents: [],
  recentEventTableMetrics: [],
  broadcastIntegrationAvailable: false,
  scoreboard: false,
  homeSideCheck: false,
  bibs: {}
}

export const sportTypesConfig: {
  australianRules: typeof australianRulesConfig
  rugbyUnion: typeof rugbyUnionConfig
  rugbyLeague: typeof rugbyLeagueConfig
  americanFootball: typeof americanFootballConfig
  demonstration: typeof demonstrationConfig
  canadianFootball: typeof canadianFootballConfig
  soccer: typeof soccerConfig
} = {
  australianRules: australianRulesConfig,
  rugbyUnion: rugbyUnionConfig,
  rugbyLeague: rugbyLeagueConfig,
  americanFootball: americanFootballConfig,
  demonstration: demonstrationConfig,
  canadianFootball: canadianFootballConfig,
  soccer: soccerConfig
}

console.log('SPORTS CONFIG: ', sportTypesConfig)

export type SportTypeProps = {
  parentEventTypes: ParentEventTypeGroup
  playerSummaryMetricTypes: MetricTypeGroup
  metrics: MetricTypeGroup
  reportMetricConfig: {
    playerPerformanceTable: {
      all: {
        metrics: {
          keys: string[]
        }
        eventMetricGroup: {
          eventTypes: string[]
          groupName: string | { en: string; fr: string }
        }
      }
    }
    playerComparisonTable: {
      pass: {
        metrics: string[][]
      }
    }
    dashboardMetrics: {
      performanceAgainstAverage: string[]
      cardGrid: string[][]
    }
    monitoringCards: {
      technicalMetrics: string[]
      physicalMetrics: string[]
    }
  }
  pitch: {
    default: Pitch
    pitchPointCount: number
    defaultDrillRegion?:
      | DefaultSoccerDrillRegion
      | DefaultAmericanFootballDrillRegion
    drillsGoalsOrPosts?: {
      enabled: boolean
      dimensions?: {
        [key: string]: {
          width: number
          height: number
          depth?: number
        }
      }
    }
    setupTypes: PitchSetupTypeGroup
    minPadding2D: number
    minPadding3D: number
    cameraYScale: number
    textureCanvasScale: number
    tenYardMarkings?: number
    crossfieldLines?: Array<{
      name: string
      lower: string
      upper: string
    }>
    enableBench: boolean
    enableRotate?: boolean
  }
  features: SportTypeFeatures
}

export class SportType {
  props: SportTypeProps
}

export type SportTypes = DataTypes<typeof sportTypesConfig>

export type SportTypeKey = DataTypeKey<SportTypes>

export type SportTypeKeys = DataTypeKeys<SportTypeKey>

export type SportTypeValues = DataTypeValues<SportTypeKey>

export const getSportTypeGroup: any = (items: {
  [key in SportTypeKeys]: SportTypes[key]
}) => {
  return getDataTypeGroup<
    SportTypeKeys,
    SportTypeValues,
    SportTypes,
    SportTypeProps
  >(items)
}

export type SportTypeGroup = ReturnType<typeof getSportTypeGroup>

export type SportTypeConfig = DataTypeConfig<
  SportTypeKeys,
  SportTypeValues,
  SportTypeProps
>

export type Sport = SportTypeGroup['items'][SportTypeKeys]

export const sportTypes = getSportTypeGroup(sportTypesConfig)

export const isSportType = generateTypeChecks<SportTypeKeys, SportTypeGroup>(
  sportTypes
)
