import { defaultPitch } from './pitch'
import { demonstrationEventTypes } from './events'
import { pitchSetupTypes } from '../../pitches/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

const sportMetrics = getMetricTypeGroup(
  getMetricTypesConfigFromJson([], metricTypesConfig)
)

export const demonstrationProps = {
  parentEventTypes: demonstrationEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  metrics: sportMetrics,
  reportMetricConfig: {
    playerPerformanceTable: {
      all: {
        metrics: {
          keys: []
        },
        eventMetricGroup: {
          eventTypes: [],
          groupName: {
            en: 'Success %',
            fr: 'Succès %'
          }
        }
      }
    },
    playerComparisonTable: {
      pass: {
        metrics: [[], [], []]
      }
    },
    monitoringCards: {
      technicalMetrics: [],
      physicalMetrics: []
    },
    dashboardMetrics: {
      performanceAgainstAverage: [],
      cardGrid: [[], []]
    }
  },
  pitch: {
    default: defaultPitch,
    pitchPointCount: 4,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    enableBench: true
  },
  features: {
    targetEvents: [],
    positionNumbers: false,
    maxPlayers: 50,
    defaultUnitSystem: 'SI',
    keyboardShortcuts: {},
    recentEventTableMetrics: [],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {}
  } as const
}

export const demonstrationConfig = {
  key: 'demonstration' as const,
  value: 'Demonstration',
  name: 'Demonstration',
  props: demonstrationProps
}
