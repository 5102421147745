import { useMemo } from 'react'
import { JoinOrganisationAndTeamFormConfig } from './form_config'
import { UseForm } from '../../components/Views/Form/Form.types'
import { useNewOrganisationCheck } from './hooks'

export const useJoinOrganisationAndTeamForm: UseForm<
  JoinOrganisationAndTeamFormConfig
> = (viewProps) => {
  const isNewOrganisation = useNewOrganisationCheck()

  const initialFormKey = useMemo(() => {
    return isNewOrganisation ? 'joinOrganisation' : 'joinTeam'
  }, [isNewOrganisation])

  return { initialFormKey, onSwitchForm: null }
}
