import React, { useState } from 'react'
import { ContentErrors } from 'vanilla-jsoneditor'
import { JsonForm } from '../../JsonForm/JsonForm'
import anchorsSchema from '../../../../model/external/anchors.schema.json'
import pitchSchema from '../../../../model/external/pitch_coordinates.schema.json'
import { Button } from '../../../Button/Button'
import { useAppDispatch } from '../../../../store/hooks'
import { JSONUploadState } from '../../../../metrics_server/pitches/types'
import { setError } from '../../../../ui/error/actions'
import { toggleModal } from '../../../../ui/modal/actions'
import { useAnchorPitchSetup } from '../../../../metrics_server/pitches/hooks'
import { pitchSetupTypes } from '../../../../metrics_server/pitches/data_types'

export const JSONUpload = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { handleAnchorPitchSetup } = useAnchorPitchSetup()

  const openAnchorPitchJSONUploadModal = () => {
    dispatch(
      toggleModal({
        active: true,
        handleProceed: () => {
          dispatch(toggleModal({}))
        },
        wrapper: true,
        title: 'Update Total Station JSON',
        width: '60vw',
        handleClose: () => {
          dispatch(toggleModal({}))
        },
        ChildComponent: () => (
          <JSONUploadModal
            handleSubmit={(state: JSONUploadState) => {
              if (!state) {
                alert(`you haven't added any JSON yet`)
              } else if (!state.anchorJSON) {
                alert(`you haven't added any anchors`)
              } else if (!state.coordinateJSON) {
                alert(`you haven't added any coordinates pitch/points`)
              } else {
                handleAnchorPitchSetup(
                  pitchSetupTypes.items.uploadJSON.value,
                  state
                )
              }
            }}
          />
        ),
        className: 'modalSmall'
      })
    )
  }

  return (
    <div style={{ marginTop: '2vh' }}>
      <Button
        id={'openAnchorPitchJSONUploadModal'}
        className={'btn--primary'}
        handleClick={() => {
          openAnchorPitchJSONUploadModal()
        }}
      >
        Upload
      </Button>
    </div>
  )
}

export interface JSONUploadModalProps {
  handleSubmit: (state: JSONUploadState) => void
}

const JSONUploadModal = ({
  handleSubmit
}: JSONUploadModalProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [json, setJson] = useState<JSONUploadState>({})
  const [hasErrors, setHasError] = useState<ContentErrors>(undefined)

  const validation = (err: ContentErrors) => {
    if (err && 'validationErrors' in err && err.validationErrors.length > 0) {
      setHasError(err)
    } else if (err && 'parseError' in err && err.parseError) {
      setHasError(err)
    } else {
      setHasError(undefined)
    }
  }
  return (
    <div
      style={{
        overflowY: 'scroll'
      }}
    >
      <div
        style={{
          display: 'flex'
        }}
      >
        <JsonForm
          name={'anchorJSONUpload'}
          title={'Anchors'}
          label={'Import separate anchor JSON:'}
          schema={anchorsSchema}
          onChange={(obj) => {
            setJson((prev) => {
              return {
                ...prev,
                anchorJSON: obj
              }
            })
          }}
          onValidationChange={validation}
          defaultValue={json.anchorJSON ? json.anchorJSON : {}}
          readOnly={true}
        />
        <JsonForm
          name={'pitchJSONUpload'}
          title={'Pitch points'}
          label={'Import separate pitch points JSON:'}
          schema={pitchSchema}
          onChange={(obj) => {
            setJson((prev) => {
              return {
                ...prev,
                coordinateJSON: obj
              }
            })
          }}
          onValidationChange={validation}
          defaultValue={json.coordinateJSON}
          readOnly={true}
        />
      </div>
      <Button
        id={'confirmAnchorPitchJSONUpload'}
        className={'btn--primary'}
        handleClick={() => {
          if (hasErrors) {
            let err = ''

            if ('validationErrors' in hasErrors) {
              for (const e in hasErrors.validationErrors) {
                const er = hasErrors.validationErrors[e]
                err += `${e}:
                
                \tMessage:  ${er.message}
                \tPath:     ${er.path}
                \tSeverity: ${er.severity}\n`
              }
            } else {
              err = `Message: ${hasErrors.parseError.message}`
            }
            dispatch(setError({ message: err }))
          } else {
            handleSubmit(json)
          }
        }}
        disabled={
          hasErrors !== undefined ||
          json.coordinateJSON === undefined ||
          json.anchorJSON === undefined
        }
      >
        Upload
      </Button>
    </div>
  )
}
export default JSONUpload
