import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../Button/Button'

import { InfoState } from '../../ui/info/types'
import { HandleClose, HandleProceed } from '../../ui/modal/types'

import styles from './Info.module.scss'

interface InfoMessageProps {
  info: InfoState
  handleProceed: HandleProceed
  handleClose: HandleClose
}

export class InfoMessage extends Component<InfoMessageProps> {
  render() {
    const { info, handleProceed, handleClose } = this.props
    return (
      <div className={styles.successContainer}>
        <h3 className={styles.successTitle}>
          {info.header ? info.header : 'Info'}
        </h3>
        <div></div>
        <h5 className={styles.successMessage}>{info.message}</h5>
        <div className={styles.buttonContainer}>
          <Button
            id='infoOkButton'
            handleClick={handleProceed}
            className='btn--primary'
            type='button'
          >
            Ok
          </Button>
          {handleClose && (
            <Button
              handleClick={handleClose}
              className='btn--plain'
              type='button'
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.info
  }
}

export default connect(mapStateToProps)(InfoMessage)
