import {
  AUTH_ERROR,
  AUTH_LOADING,
  SIGNED_IN,
  SET_FORGOT_PASSWORD
} from './action_types'

import { AuthError, SetIsSigningIn } from './types'

export const authenticationError: AuthError = (error) => {
  return {
    type: AUTH_ERROR,
    payload: error
  }
}

export const setIsLoading: SetIsSigningIn = (isLoading: boolean) => {
  return {
    type: AUTH_LOADING,
    payload: isLoading
  }
}

export const setIsSignedIn = (bool) => {
  return {
    type: SIGNED_IN,
    payload: bool
  }
}

export const setForgotPassword = (bool: boolean) => {
  return {
    type: SET_FORGOT_PASSWORD,
    payload: bool
  }
}
