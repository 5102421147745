import {
  createGroupFromList,
  formatMetric,
  generateMetricTypeClass,
  sessionTypes,
  sportTypes,
  useAppDispatch,
  useEventsFilter,
  useFormattedSession,
  useFormattedTeam,
  useLanguage,
  useSessionFilter,
  useTeams,
  useUnitsSystem
} from '@frontend/sportable'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  MetricCard,
  LabelledInput
} from '@frontend/ui'
import { MetricChart } from '../../../components/metric-chart'
import { useEffect, useMemo, useState } from 'react'

const textData = {
  showStatsBy: {
    en: 'Show stats by',
    fr: 'Afficher les statistiques par'
  },
  lastSession: {
    en: 'Last session',
    fr: 'Dernière session'
  },
  training: {
    en: 'Training',
    fr: 'Entraînement'
  },
  match: {
    en: 'Match',
    fr: 'Match'
  },
  sessionName: {
    en: 'Session name',
    fr: 'Nom de la session'
  },
  timeframe: {
    en: 'Timeframe',
    fr: 'Plage horaire'
  },
  physicalMetricParameter: {
    en: 'Physical Metric Parameter',
    fr: 'Paramètre de métrique physique'
  },
  physicalMetrics: {
    en: 'Physical Metrics',
    fr: 'Métriques physiques'
  },
  technicalMetrics: {
    en: 'Technical Metrics',
    fr: 'Métriques techniques'
  }
}

const dummyPhysicalMetrics = {
  soccer: {
    distance: {
      session: 16.2,
      average: 14,
      max: 20
    },
    distancePerMin: {
      session: 3.7,
      average: 14,
      max: 20
    },
    highSpeedRunningDistance: {
      session: 12,
      average: 14,
      max: 20
    },
    highSpeedRunningDistancePerMin: {
      session: 16.2,
      average: 14,
      max: 20
    },
    accelCount: {
      session: 16.2,
      average: 14,
      max: 20
    },
    decelCount: {
      session: 16.2,
      average: 14,
      max: 20
    },
    totalWork: {
      session: 16.2,
      average: 14,
      max: 20
    }
  },
  americanFootball: {
    distance: {
      session: 16.2,
      average: 14,
      max: 20
    },
    distancePerMin: {
      session: 3.7,
      average: 14,
      max: 20
    },
    highSpeedRunningDistance: {
      session: 12,
      average: 14,
      max: 20
    },
    highSpeedRunningDistancePerMin: {
      session: 16.2,
      average: 14,
      max: 20
    },
    accelCount: {
      session: 16.2,
      average: 14,
      max: 20
    },
    decelCount: {
      session: 16.2,
      average: 14,
      max: 20
    },
    totalWork: {
      session: 16.2,
      average: 14,
      max: 20
    }
  }
}

const dummyTechnicalMetrics = {
  soccer: {
    involvements: {
      session: 10.2,
      average: 14.8,
      max: 21.3
    },
    involvementsPerMinute: {
      session: 8.4,
      average: 12.1,
      max: 17.6
    },
    forwardPasses: {
      session: 18.7,
      average: 15.4,
      max: 23.1
    },
    forwardPassesComplete: {
      session: 9.8,
      average: 13.9,
      max: 20.4
    },
    shots: {
      session: 6.5,
      average: 10.2,
      max: 14.3
    },
    shotsConversion: {
      session: 4.1,
      average: 8.5,
      max: 12.7
    },
    duelsWon: {
      session: 15.4,
      average: 12.8,
      max: 19.5
    },
    averageRecycleTime: {
      session: 16.3,
      average: 19.2,
      max: 24.7
    }
  },
  americanFootball: {
    involvements: {
      session: 10.2,
      average: 14.8,
      max: 21.3
    },
    involvementsPerMinute: {
      session: 8.4,
      average: 12.1,
      max: 17.6
    },
    totalPassCompletion: {
      session: 18.7,
      average: 15.4,
      max: 23.1
    },
    averageThrowDistance: {
      session: 9.8,
      average: 13.9,
      max: 20.4
    },
    averageSpeed: {
      session: 6.5,
      average: 10.2,
      max: 14.3
    },
    averageSpin: {
      session: 4.1,
      average: 8.5,
      max: 12.7
    },
    averageEfficiency: {
      session: 15.4,
      average: 12.8,
      max: 19.5
    }
  }
}

export function PlayerMonitoring({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const dispatch = useAppDispatch()

  const language = useLanguage()

  const [showBy, setShowBy] = useState('lastSession')
  const [selectedSessionId, setSelectedSessionId] = useState(null)

  const formattedSession = useFormattedSession(selectedSessionId)

  useEffect(() => {
    if (formattedSession.id) {
      // dispatch(eventActions.getSessionEvents(formattedSession.id))
      // dispatch(subSessionActions.getSubSessions(formattedSession.id))
    }
  }, [formattedSession])

  const teamsState = useTeams()
  const formattedTeam = useFormattedTeam(teamsState.selectedTeam)

  const sport = formattedTeam?.sport || sportTypes.items.soccer

  const unitSystem = useUnitsSystem(sport)

  const {
    sortedSessions: allTeamSessions,
    updateFilterValue: updateAllTeamSessionsFilter
  } = useSessionFilter({
    team: {
      options: teamsState.options,
      value: teamsState.selectedTeam
    },
    sportType: {
      options: [],
      value: sport.value
    }
  })

  const allTeamSessionOptions = useMemo(() => {
    return allTeamSessions.map((session) => ({
      name: session.name,
      value: session.id
    }))
  }, [allTeamSessions])

  useEffect(() => {
    if (formattedTeam) {
      updateAllTeamSessionsFilter('team', teamsState.selectedTeam)
      updateAllTeamSessionsFilter('sportType', sport.value)
    }
  }, [formattedTeam])

  useEffect(() => {
    if (showBy === 'lastSession') {
      updateAllTeamSessionsFilter('type', null)
    } else if (showBy === 'training') {
      updateAllTeamSessionsFilter('type', sessionTypes.items.training.value)
    } else if (showBy === 'match') {
      updateAllTeamSessionsFilter('type', sessionTypes.items.match.value)
    }
  }, [showBy, updateAllTeamSessionsFilter])

  useEffect(() => {
    if (
      !allTeamSessionOptions.some(
        (sessionOption) => sessionOption.value === selectedSessionId
      )
    ) {
      setSelectedSessionId(allTeamSessionOptions[0]?.value)
    }
  }, [allTeamSessionOptions])

  const {
    filteredEvents: filteredPitchEvents,
    filters: pitchEventFilters,
    updateFilterValue: updatePitchEventFilterValue
  } = useEventsFilter(
    {
      team: {
        options: formattedSession.teams.options
      },
      type: {
        type: 'multipleWithAll'
      }
    },
    [
      'flight'
      // 'aussieRules',
      // 'snapGameEvent',
      // 'handOff',
      // 'kickOffGameEvent',
      // 'twoPointConversion',
      // 'touchDown',
      // 'penaltyGameEvent',
      // 'touch',
      // 'try',
      // 'tackle',
      // 'safety',
      // 'defensiveTwoPoint',
      // 'goalLineCrossed',
      // 'soccerGoal',
      // 'soccerBallOverLine',
      // 'manualPass',
      // 'fumble'
    ],
    formattedSession.id
  )

  const pitchFilterOptions = useMemo(() => {
    return pitchEventFilters.type.options.map((option, index) => {
      return {
        label: option.name,
        value: option.value,
        color: option.color
      }
    })
  }, [pitchEventFilters.type.options])

  return (
    <>
      <div className='relative w-[calc(100%+4rem)] -left-8 px-8 py-7 bg-muted'>
        <div className='flex'>
          {/* Stats By Select */}
          <LabelledInput
            label={textData.showStatsBy[language]}
            className='mr-6'
          >
            <Select value={showBy} onValueChange={(value) => setShowBy(value)}>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='lastSession'>
                  {textData.lastSession[language]}
                </SelectItem>
                <SelectItem value='training'>
                  {textData.training[language]}
                </SelectItem>
                <SelectItem value='match'>
                  {textData.match[language]}
                </SelectItem>
              </SelectContent>
            </Select>
          </LabelledInput>
          {/* Session Select */}
          {(showBy === 'training' || showBy === 'match') && (
            <LabelledInput
              label={textData.sessionName[language]}
              className='mr-6'
            >
              <Select
                value={selectedSessionId || '0'}
                onValueChange={(value) => setSelectedSessionId(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder='' />
                </SelectTrigger>
                <SelectContent>
                  {allTeamSessionOptions.map((session) => (
                    <SelectItem key={session.value} value={session.value}>
                      {session.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </LabelledInput>
          )}
          {/* Timeframe Select */}
          {/* <LabelledInput label={textData.timeframe[language]} className='mr-6'>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent></SelectContent>
            </Select>
          </LabelledInput> */}
          {/* Physical Metric Parameter Select */}
          {/* <LabelledInput
            label={textData.physicalMetricParameter[language]}
            className='mr-6'
          >
            <Select>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent></SelectContent>
            </Select>
          </LabelledInput> */}
        </div>
      </div>
      <div className='flex-1 space-y-8 pt-16 pb-8'>
        <div className='flex items-center justify-between'>
          <h2 className='text-3xl'>{textData.physicalMetrics[language]}</h2>
        </div>
        <div className='grid gap-8 grid-cols-7'>
          {sport.props.reportMetricConfig.monitoringCards.physicalMetrics.map(
            (metricKey) => {
              const metricType = sport.props.metrics.items[metricKey]

              const dummyMetricValues =
                dummyPhysicalMetrics[sport.key][metricType.key]

              if (!dummyMetricValues) {
                return null
              }

              const metricInfo = generateMetricTypeClass(metricType)

              const formattedMetric = formatMetric(
                metricType,
                {
                  [metricType.key]: dummyMetricValues.session
                },
                createGroupFromList([formattedTeam], 'id', 'id'),
                formattedTeam.players,
                unitSystem,
                false,
                false
              )

              const label = metricInfo.getMetricDisplayWithUnits(
                unitSystem,
                null,
                null,
                language
              )

              return (
                <MetricCard
                  displayValue={formattedMetric.formattedValue as string}
                  label={label}
                  value={formattedMetric.value as number}
                  markerValue={dummyMetricValues.average}
                  maxValue={dummyMetricValues.max}
                />
              )
            }
          )}
        </div>
        <div className='flex items-center justify-between'>
          <h2 className='text-3xl'>{textData.technicalMetrics[language]}</h2>
        </div>
        <div className='grid gap-8 grid-cols-7'>
          {sport.props.reportMetricConfig.monitoringCards.technicalMetrics.map(
            (metricKey) => {
              const metricType = sport.props.metrics.items[metricKey]

              const dummyMetricValues =
                dummyTechnicalMetrics[sport.key][metricType.key]

              if (!dummyMetricValues) {
                return null
              }

              const metricInfo = generateMetricTypeClass(metricType)

              const formattedMetric = formatMetric(
                metricType,
                {
                  [metricType.key]: dummyMetricValues.session
                },
                createGroupFromList([formattedTeam], 'id', 'id'),
                formattedTeam.players,
                unitSystem,
                false,
                false
              )

              const label = metricInfo.getMetricDisplayWithUnits(
                unitSystem,
                null,
                null,
                language
              )

              return (
                <MetricCard
                  displayValue={formattedMetric.formattedValue as string}
                  label={label}
                  value={formattedMetric.value as number}
                  markerValue={dummyMetricValues.average}
                  maxValue={dummyMetricValues.max}
                />
              )
            }
          )}
        </div>
      </div>
      <div className='flex-1 space-y-4 pt-8'>
        <MetricChart />
      </div>
    </>
  )
}
