import { useEffect, useMemo, useState } from 'react'
import {
  CustomFilterState,
  FilterTypes,
  getFilterState,
  useFilterReducer
} from '../../hooks/filter'
import { useAppDispatch } from '../../store/hooks'
import { SportTypeValues } from '../sports/data_types'
import { useUserFormattedTeams } from '../teams/hooks'
import { SessionTypeValues } from './data_types'
import { useSessions } from './hooks'
import { SessionStateTypeValues, sessionStateTypes } from './states/data_types'
import { SubSessionTypeValues } from './sub_sessions/data_types'
import { SessionFilterRequestBody } from './api'

export type SessionFilterTypeKeys =
  | 'sessionId'
  | 'team'
  | 'startTime'
  | 'endTime'
  | 'limitQuantity'
  | 'type'
  | 'subType'
  | 'searchStr'
  | 'state'
  | 'sportType'

export type SessionFilters = {
  sessionId: FilterTypes<string, 'sessionId', SessionFilterTypeKeys>
  team: FilterTypes<string, 'team', SessionFilterTypeKeys>
  startTime: FilterTypes<number, 'startTime', SessionFilterTypeKeys>
  endTime: FilterTypes<number, 'endTime', SessionFilterTypeKeys>
  limitQuantity: FilterTypes<number, 'limitQuantity', SessionFilterTypeKeys>
  type: FilterTypes<SessionTypeValues, 'type', SessionFilterTypeKeys>
  subType: FilterTypes<SubSessionTypeValues, 'subType', SessionFilterTypeKeys>
  searchStr: FilterTypes<string, 'searchStr', SessionFilterTypeKeys>
  state: FilterTypes<SessionStateTypeValues, 'state', SessionFilterTypeKeys>
  sportType: FilterTypes<SportTypeValues, 'sportType', SessionFilterTypeKeys>
}

// export type SessionFilters = {
//   team: Filter<string | 'All'>
//   startTime: TimeFilter
//   endTime: TimeFilter
//   limitQuantity: Filter<number>
//   type: Filter<SessionTypeValues | 'All'>
//   subType: Filter<SubSessionTypeValues | 'All'>
//   searchStr: SearchStrFilter
//   state: Filter<SessionStateTypeValues | 'All'>
//   sportType: Filter<SportTypeValues | 'All'>
// }

const defaultSessionFilterState: SessionFilters = {
  sessionId: {
    key: 'sessionId',
    label: 'Session ID',
    type: 'single',
    options: [],
    value: ''
  },
  team: {
    key: 'team',
    label: 'Team',
    type: 'singleWithAll',
    options: [],
    value: 'All'
  },
  startTime: {
    key: 'startTime',
    label: 'Start Time',
    type: 'single',
    options: [],
    value: null
  },
  endTime: {
    key: 'endTime',
    label: 'End Time',
    type: 'single',
    options: [],
    value: null
  },
  limitQuantity: {
    key: 'limitQuantity',
    label: 'Limit Quantity',
    type: 'single',
    options: [],
    value: 10
  },
  type: {
    key: 'type',
    label: 'Type',
    type: 'singleWithAll',
    options: [],
    value: 'All'
  },
  subType: {
    key: 'subType',
    label: 'Sub Type',
    type: 'singleWithAll',
    options: [],
    value: 'All'
  },
  searchStr: {
    key: 'searchStr',
    label: 'Search',
    type: 'searchStr',
    options: [],
    value: ''
  },
  state: {
    key: 'state',
    label: 'State',
    type: 'singleWithAll',
    options: [],
    value: 'All'
  },
  sportType: {
    key: 'sportType',
    label: 'Sport Type',
    type: 'singleWithAll',
    options: [],
    value: 'All'
  }
}

// Generate request body for BE filter API request //
const generateFilterOptionsRequestBody = (
  filters: SessionFilters
): SessionFilterRequestBody => {
  const { team, startTime, endTime, limitQuantity, type } = filters

  return {
    teamIds: team.value === 'All' || !team.value ? [] : [team.value as string],
    startTime: startTime.value as number,
    endTime: endTime.value as number,
    limitQuantity: limitQuantity.value as number,
    type:
      type.value === 'All' || !type.value
        ? null
        : (type.value as SessionTypeValues)
  }
}

export const useSessionFilter = (
  customFilterState: CustomFilterState<SessionFilters>,
  test?: boolean
) => {
  const dispatch = useAppDispatch()
  const { rawData } = useSessions()
  const teams = useUserFormattedTeams()

  const filterState = useMemo(
    () => getFilterState(customFilterState, defaultSessionFilterState),
    []
  )

  const { filters, updateFilterValue, updateFilterOptions } =
    useFilterReducer(filterState)

  // If new teams available, update team filter options //
  useEffect(() => {
    updateFilterOptions('team', teams.optionsWithAll)
  }, [teams])

  // Fire API request if BE filter values change or data is no longer valid (session ended) //
  // const { data = [] } = useQuery({
  //   queryKey: [
  //     'filteredSessions',
  //     filters.team.value,
  //     filters.startTime.value,
  //     filters.endTime.value,
  //     filters.limitQuantity.value
  //   ],
  //   queryFn: async () => {
  //     const response = await api.getFilteredSessions(
  //       generateFilterOptionsRequestBody(filters)
  //     )
  //     return response.data
  //   },
  //   enabled: Boolean(filters.team.value) // only run if a team is chosen
  //   // optional: other config options like staleTime, cacheTime, onSuccess, etc.
  // })

  // Sort Sessions //
  const sortedSessions = useMemo(() => {
    let sessions = Object.values(rawData).filter((session) => {
      if (filters.type.value === 'All' || !filters.type.value) return true
      return filters.type.value === session.type
    })

    if (test) console.log('type', [...sessions])

    sessions = sessions.filter((session) => session.id)

    if (test) console.log('id', [...sessions])

    sessions = sessions.filter((session) => {
      if (filters.sessionId.value === 'All' || !filters.sessionId.value)
        return true
      return filters.sessionId.value === session.id
    })

    if (test) console.log('sessionId', [...sessions])

    sessions = sessions.filter((session) => {
      if (filters.sportType.value === 'All' || !filters.sportType.value)
        return true
      return filters.sportType.value === session.sportType
    })

    if (test) console.log('sportType', [...sessions])

    sessions = sessions.filter((session) => {
      if (filters.subType.value === 'All' || !filters.subType.value) return true
      return filters.subType.value === session.subType
    })

    if (test) console.log('subType', [...sessions])

    sessions = sessions.filter((session) => {
      if (filters.state.value === 'All' || !filters.state.value) {
        return session.state !== sessionStateTypes.items.notStarted.value
      }
      return filters.state.value === session.state
    })

    if (test) console.log('state', [...sessions])

    sessions = sessions.filter((session) => {
      if (!filters.searchStr.value) return true

      const sessionName = session.name.toLowerCase()
      const sessionId = session.id
      const searchStrValue = filters.searchStr.value as string
      const searchStrLowerCase = searchStrValue.toLowerCase()

      return (
        sessionName.includes(searchStrLowerCase) ||
        sessionId.startsWith(searchStrValue)
      )
    })

    if (test) console.log('searchStr', [...sessions])

    sessions = sessions.filter((session) => {
      if (filters.team.value === 'All' || !filters.team.value) return true
      return session.teamsSessions.some(
        (teamSession) => teamSession.teamId === filters.team.value
      )
    })

    if (test) console.log('team', [...sessions])

    sessions = sessions.filter((session) => {
      // TODO: this is temporary fix (handle better) - using new filter types logic - see events filter //
      if (!filters.startTime.value) return true
      const startTimeValue = filters.startTime.value as number
      return session.startTime >= startTimeValue
    })

    if (test) console.log('startTime', [...sessions])

    sessions = sessions.filter((session) => {
      // TODO: this is temporary fix (handle better)//
      if (!filters.endTime.value) return true
      const endTimeValue = filters.endTime.value as number
      return session.startTime <= endTimeValue
    })

    if (test) console.log('endTime', [...sessions])

    sessions = sessions.sort((a, b) => {
      if (a.startTime > b.startTime) return -1
      if (a.startTime < b.startTime) return 1
      return 0
    })

    return sessions
  }, [filters, rawData])

  return {
    filters,
    updateFilterValue,
    sortedSessions
  }
}

/*
Saving for the events range filter
sessions = sessions.filter((session) => {
      if (!filters.startTime.value) return true
      if (
        typeof filters.startTime.value[0] !== 'number' ||
        typeof filters.startTime.value[0] !== 'number'
      )
        return true

      const lowerLimit = filters.startTime.value[0]
      const upperLimit = filters.startTime.value[1]

      return session.startTime >= lowerLimit && session.startTime <= upperLimit
    })
*/
