import React, { useMemo } from 'react'
import { FormConfig, UseForm } from './Form.types'

export const useDefaultForm: UseForm<FormConfig<string>> = (formConfig) => {
  return useMemo(() => {
    // Get the initial form key //
    const initialFormKey = Object.keys(formConfig)[0]

    return {
      onSwitchForm: (formKey) => {
        return null
      },
      initialFormKey
    }
  }, [formConfig])
}
