import { TeamsState } from '../types'
import {
  americanFootballTeam,
  rawOfficiatingTeam,
  rawTeamOne,
  rawTeamTwo,
  soccerTeam
} from './api'

export const rawTeamsMap = {
  [rawTeamOne.id]: rawTeamOne,
  [rawTeamTwo.id]: rawTeamTwo,
  [rawOfficiatingTeam.id]: rawOfficiatingTeam
}

export const teamsStateMock: TeamsState = {
  fetched: true,
  hasValidData: true,
  items: {
    [rawTeamOne.id]: rawTeamOne,
    [rawTeamTwo.id]: rawTeamTwo,
    [rawOfficiatingTeam.id]: rawOfficiatingTeam
  },
  options: [
    {
      name: rawTeamOne.name,
      image: {
        logo: rawTeamOne.logo,
        color: rawTeamOne.color
      },
      value: rawTeamOne.id,
      id: rawTeamOne.id
    },
    {
      name: rawTeamTwo.name,
      image: {
        logo: rawTeamTwo.logo,
        color: rawTeamTwo.color
      },
      value: rawTeamTwo.id,
      id: rawTeamTwo.id
    },
    {
      name: rawOfficiatingTeam.name,
      image: {
        logo: rawOfficiatingTeam.logo,
        color: rawOfficiatingTeam.color
      },
      value: rawOfficiatingTeam.id,
      id: rawOfficiatingTeam.id
    }
  ],
  rawData: {
    [rawTeamOne.id]: rawTeamOne,
    [rawTeamTwo.id]: rawTeamTwo,
    [rawOfficiatingTeam.id]: rawOfficiatingTeam
  },
  selectedTeam: rawTeamOne.id,
  selectedBTeam: null
}

// Reporting Tool Test State //
export const reportingToolDummyTeamState: TeamsState = {
  fetched: true,
  hasValidData: true,
  items: {
    [soccerTeam.id]: soccerTeam,
    [rawTeamTwo.id]: americanFootballTeam,
    [rawOfficiatingTeam.id]: rawOfficiatingTeam
  },
  options: [
    {
      name: soccerTeam.name,
      image: {
        logo: soccerTeam.logo,
        color: soccerTeam.color
      },
      value: soccerTeam.id,
      id: soccerTeam.id
    },
    {
      name: americanFootballTeam.name,
      image: {
        logo: americanFootballTeam.logo,
        color: americanFootballTeam.color
      },
      value: americanFootballTeam.id,
      id: americanFootballTeam.id
    }
  ],
  rawData: {
    [soccerTeam.id]: soccerTeam,
    [americanFootballTeam.id]: americanFootballTeam
  },
  selectedTeam: soccerTeam.id,
  selectedBTeam: null
}
