import React, { useEffect, useState } from 'react'
import { AddNewButton } from '../../components/AddNewButton/AddNewButton'
import { CreatePlayerPlaceholder } from '../../components/CreatePlayerPlaceholder/CreatePlayerPlaceholder'
import { MainHeader } from '../../components/MainHeader/MainHeader'
import { SearchableDropdown } from '../../components/SearchableDropdown/SearchableDropdown'
import {
  useSelectedFormattedTeam,
  useUserFormattedTeams
} from '../../metrics_server/teams/hooks'
import styles from './Teams.module.scss'
import BasicSelect from '../../components/Material/Select'
import { useAppDispatch } from '../../store/hooks'
import { setRedirect } from '../../ui/router/actions'
import Scroller from '../../components/Scroller/Scroller'
import { getTeamPlayers, setTeam } from '../../metrics_server/teams/actions'
import { usePlayerFormModal } from '../../metrics_server/players/modals'
import { usePlayerFilter } from '../../metrics_server/players/filter'
import { DefaultPlayer } from '../../metrics_server/players/components/Player/DefaultPlayer/DefaultPlayer'

export function TeamsContent() {
  const dispatch = useAppDispatch()

  const formattedTeam = useSelectedFormattedTeam()

  const teams = useUserFormattedTeams()

  const { filteredPlayers, filters, updateFilterValue } = usePlayerFilter({
    teamId: {
      type: 'single',
      options: teams.options,
      value: formattedTeam.id
    },
    name: {
      type: 'searchStr',
      value: ''
    }
  })

  useEffect(() => {
    dispatch(getTeamPlayers(formattedTeam.id))
    updateFilterValue('teamId', formattedTeam.id)
  }, [formattedTeam.id])

  const { openPlayerFormModal } = usePlayerFormModal()

  const [editMode, setEditMode] = useState(false)

  return (
    <React.Fragment>
      <div className={styles.teamContent}>
        <div className={styles.teamsDropdownContainer}>
          <AddNewButton
            addCallBack={() => dispatch(setRedirect('/createorganisation'))}
            style={{ paddingRight: '20px' }}
            size={45}
          />

          <SearchableDropdown
            name={'teamId'}
            items={filters.teamId.options}
            onSelect={(teamId) => {
              dispatch(setTeam(teamId))
            }}
            value={filters.teamId.value[0]}
            label={'Teams'}
            disabledValue={'Second Team'}
            isMultiple={false}
            minWidth={400}
          />
        </div>
      </div>
      <div className={styles.playerContent}>
        {formattedTeam.players.count === 0 ? (
          <CreatePlayerPlaceholder
            createPlayer={() => openPlayerFormModal(null, formattedTeam.id)}
          />
        ) : (
          <React.Fragment>
            <div className={styles.playerSectionHeader}>
              <MainHeader
                title={editMode ? 'Edit players' : 'Players'}
                options={[
                  {
                    name: editMode ? 'Done' : 'Edit players',
                    callback: () => setEditMode(!editMode)
                  }
                  // {name: 'Add existing player profile', callback: this.modalToggle}
                ]}
              />
              <div style={{ width: '250px', marginRight: '20px' }}>
                <BasicSelect
                  label={'Search Players'}
                  variant='standard'
                  selected={filters.name.value}
                  onChange={(event: string) => updateFilterValue('name', event)}
                  labelShrink={true}
                  size={'medium'}
                  fontSize={20}
                />
              </div>
            </div>
            <div className={styles.playersGridContainer}>
              <div style={{ height: '100%', width: '100%' }}>
                <Scroller
                  scrollerId={`teamGrid-scroller-${formattedTeam.id}`}
                  scrollerClass='form-scroller'
                >
                  <div className={styles.teamGridContainer}>
                    <AddNewButton
                      addCallBack={() =>
                        openPlayerFormModal(null, formattedTeam.id)
                      }
                      editMode={editMode}
                      size={45}
                      title={'Add Player'}
                      horizontal={true}
                    />
                    {filteredPlayers.list.map((formattedPlayer) => {
                      return (
                        <DefaultPlayer
                          editMode={editMode}
                          playerId={formattedPlayer.id}
                          key={formattedPlayer.id}
                        />
                      )
                    })}
                  </div>
                </Scroller>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
