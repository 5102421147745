interface EmptyTileProps {
  onClick: () => void
}

// Testing //

export function EmptyTile({ onClick }: EmptyTileProps) {
  return (
    <button
      onClick={onClick}
      className='text-5xl h-full w-full font-thin text-foreground transition hover:font-black'
    >
      +
    </button>
  )
}
