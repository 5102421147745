import { Options, OptionsWithAll } from './data_types'

export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

export type IDMap<T> = {
  [id: string]: T
}

export type Group<T, ov = string> = {
  list: T[]
  map: IDMap<T>
  options: Options<ov>
  optionsWithAll: OptionsWithAll<ov>
  optionsWithNull: Options<ov | null>
  count: number
}
