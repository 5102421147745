import React, { ReactNode, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import '../index.scss'
import '../tailwind.css'

import { authenticationRoute } from '../index'

// UI Actions
import * as ui from '../ui'

// Components
import Error from '../components/Error/Error'
import Info from '../components/Info/Info'
import Modal from '../components/Modal/Modal'
import Success from '../components/Success/Success'
import { CustomRoutes } from './Routes'
import { Notification } from '../metrics_server/notifications/components/Notification/Notification'

// Types
import { AppConfig, Routes } from './App.types'
import ErrorBoundary from '../components/Error/ErrorBoundary'
import { isLocal } from '../metrics_server/env'

let actions = {}
for (const key in ui.actions) {
  actions = { ...actions, ...ui.actions[key] }
}

export interface AppProps {
  appConfig: AppConfig
  landing?: string
  storybook?: boolean
}

interface RouterProps {
  initialEntries?: string[]
  initialIndex?: number
  children: React.ReactNode
}

const MTRouter = ({
  initialEntries,
  initialIndex,
  children
}: RouterProps): ReactNode => {
  if (isLocal) {
    return (
      <MemoryRouter initialEntries={initialEntries} initialIndex={initialIndex}>
        {children}
      </MemoryRouter>
    )
  } else {
    return <BrowserRouter>{children}</BrowserRouter>
  }
}

function App(props: AppProps) {
  const { appConfig, landing, storybook } = props
  const { routes } = appConfig

  const [appRoutes] = useState<Routes>({
    ...routes,
    // Login route
    login: authenticationRoute
  })

  return (
    <div
      style={{
        // height: appConfig.type.value === 'commentatorTool' ? '100%' : '95%',
        height: '95%'
      }}
    >
      <Modal />
      <Success />
      <Info />
      <Error />
      <Notification />
      <ErrorBoundary>
        <MTRouter initialEntries={[landing]} initialIndex={0}>
          <CustomRoutes {...props} appRoutes={appRoutes} landing={landing} />
          {/* <div
            style={{
              height: '100%',
              border: storybook ? '5px solid #e3e3e3' : 'none'
            }}
            className='mainView'
          ></div> */}
        </MTRouter>
      </ErrorBoundary>
    </div>
  )
}

function mapStateToProps(state, props: AppProps) {
  return {
    user: state.user,
    router: state.router
  }
}

export default connect<any, any, AppProps>(mapStateToProps, actions)(App)
