import React, { useState } from 'react'
import { FormContainer } from '../../../components/Forms/Form/Form'
import styles from './RequestPasswordForm.module.scss'
import { useAppDispatch } from '../../../store/hooks'
import {
  ForgotPasswordRequestBody,
  requestNewPassword
} from '../../../metrics_server/user/actions'
import { AuthFormProps } from '../config'

const validate = (values) => {
  const errors = {
    email: undefined
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  return errors
}

export const RequestPasswordForm = (props: AuthFormProps) => {
  const dispatch = useAppDispatch()

  const [submitted, setSubmitted] = useState(false)
  const [emailAccepted, setEmailAccepted] = useState(false)
  const [email, setEmail] = useState(null)

  const [fields, setFields] = useState(generateFields())

  function generateFields() {
    return [
      {
        name: 'email',
        type: 'text',
        label: 'Email address'
      }
    ]
  }

  const handleSubmit = (values: ForgotPasswordRequestBody) => {
    setEmail(values.email)
    dispatch(
      requestNewPassword(values, (success) => {
        setSubmitted(true)
        setEmailAccepted(success)
      })
    )
  }

  return (
    <FormContainer
      name={'Request Password Reset'}
      fields={fields}
      validate={validate}
      onSubmit={handleSubmit}
      submitText={'Request'}
    >
      {submitted && (
        <div className='section'>
          {emailAccepted ? (
            <div className='row'>
              <p>
                We've sent an email to {email}, to complete the password reset
                please follow the link contained within the email.
              </p>
            </div>
          ) : (
            <div className='row'>
              <p className={styles.resendText}>Email not recognised</p>
            </div>
          )}
        </div>
      )}
    </FormContainer>
  )
}
