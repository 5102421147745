import { Group } from './types'

export function getEmptyGroup<T, ov = string>(): Group<T, ov> {
  return {
    list: [],
    map: {},
    options: [],
    optionsWithAll: [{ name: 'All', value: 'All' }],
    optionsWithNull: [{ name: 'Select', value: null }],
    count: 0
  }
}

export function addItemToGroup<
  T extends Record<ValueKey, string | number>,
  ValueKey extends string
>(
  group: Group<T, T[ValueKey]>,
  item: T,
  groupKey: string,
  valueKey: ValueKey,
  optionName: string,
  sortByKey?: string
) {
  group.map[groupKey] = item
  // Add element to array in alphabetical position based on sort by key - if not provided use value key
  sortByKey = sortByKey || valueKey
  const index = group.list.findIndex(
    (element) => element[sortByKey] > item[sortByKey]
  )

  const option = {
    name: optionName,
    value: item[valueKey] || item['id']
  }

  if (index !== -1) {
    group.list.splice(index, 0, item)
  } else {
    group.list.push(item)
  }

  group.options.push(option)
  group.optionsWithAll.push(option)
  group.optionsWithNull.push(option)

  group.count++
}

export function createGroupFromList<
  T extends Record<ValueKey, T[ValueKey]>,
  ValueKey extends string,
  GroupKey extends string
>(list: T[], valueKey: ValueKey, groupKey: GroupKey): Group<T, T[ValueKey]> {
  const group = getEmptyGroup<T, T[ValueKey]>()
  list.forEach((item) => {
    addItemToGroup(group, item, groupKey, valueKey, item[valueKey] as string)
  })
  return group
}
