import React, { useState } from 'react'
import { FormContainer } from '../../../components/Forms/Form/Form'
import styles from './SignupForm.module.scss'
import { toggleModal } from '../../../ui/modal/actions'
import { useAppDispatch } from '../../../store/hooks'
import { useAuthentication } from '../../../ui/authentication/hooks'
import { DocumentsContainer } from '../../../components/Documents/DocumentsContainer'
import {
  requestEmailVerification,
  ResgisterRequestBody
} from '../../../metrics_server/user/actions'
import { AuthFormProps } from '../config'

const validate = (values) => {
  const errors = {
    email: undefined,
    terms: undefined
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.terms) {
    errors.terms = 'Terms'
  }
  return errors
}

export const SignupForm = (props: AuthFormProps) => {
  const dispatch = useAppDispatch()
  const authentication = useAuthentication()
  const { switchForm } = props

  const openDocument = (docType: 'terms' | 'privacy') => {
    dispatch(
      toggleModal({
        width: '60%',
        active: true,
        type: 'confirm',
        wrapper: true,
        ChildComponent: DocumentsContainer,
        docType,
        className: 'modalSmall',
        title:
          docType === 'terms'
            ? 'Sportable Terms and Conditions (T&C)'
            : 'SPORTABLE TECHNOLOGIES PRIVACY POLICY',
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  const [fields, setFields] = useState(generateFields(true))

  function generateFields(showPassword) {
    return [
      {
        name: 'email',
        type: 'text',
        label: 'Email address'
      },
      {
        name: 'terms',
        type: 'checkbox',
        label: "I agree to Sportable's Terms & Conditions and Privacy Policy"
      }
    ]
  }

  const handleSubmit = (values: ResgisterRequestBody) => {
    dispatch(requestEmailVerification(values, switchToNextForm))
  }

  const switchToNextForm = () => {
    switchForm('verifyEmail')
  }

  return (
    <>
      <FormContainer
        name={'Sign Up'}
        fields={fields}
        validate={validate}
        loading={authentication.isLoading}
        // validateOnBlur={true}
        onSubmit={handleSubmit}
        submitText={'Create'}
      >
        <div className={styles.termsContainer}>
          <p>
            <button
              type='button'
              className='button link'
              onClick={(e) => {
                e.preventDefault()
                openDocument('terms')
              }}
            >
              Terms & Conditions
            </button>{' '}
            and{' '}
            <button
              type='button'
              className='button link'
              onClick={(e) => {
                e.preventDefault()
                openDocument('privacy')
              }}
            >
              Privacy Policy
            </button>
          </p>
        </div>
      </FormContainer>
      <p className='authError'>{authentication && authentication.error}</p>
    </>
  )
}
