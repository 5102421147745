import React, { useCallback, useState } from 'react'
import styles from './DrillsQuickStart.module.scss'
import { Button } from '../../../../components/Button/Button'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../../../store/hooks'
import {
  createNewDrill,
  stopDrill
} from '../../../../metrics_server/drills/thunks'
import { createTimeEvent, endTimeEvent } from '../../../events/actions'
import { useEventsFilter } from '../../../events/filter'
import {
  useActiveFormattedDrill,
  useDrills
} from '../../../../metrics_server/drills/hooks'
import { eventTypes } from '../../../events/types/data_types'
import { parentEventTypes } from '../../../events/data_types'

export function DrillsQuickStart() {
  const dispatch = useAppDispatch()

  const { loading, drillCount } = useDrills()

  const formattedSession = useSelectedFormattedSession()
  const { live } = formattedSession

  const activeFormattedDrill = useActiveFormattedDrill()

  const timeEvents = useEventsFilter({}, ['time'])

  const [drillEventsPaused, setDrillEventsPaused] = useState(false)

  const activePauseButtonText = useCallback(() => {
    return drillEventsPaused ? 'Paused' : 'Active'
  }, [drillEventsPaused])

  const handleDrillQuickStart = async () => {
    dispatch(
      createNewDrill({
        name: `Drill ${drillCount + 1} - Quick Start`,
        quickStart: true
      })
    )
  }

  const handleStopDrill = () => {
    if (activeFormattedDrill) {
      dispatch(stopDrill(activeFormattedDrill.id))
    }
  }

  const handlePauseDrill = () => {
    dispatch(
      createTimeEvent({
        sessionId: formattedSession.id,
        type: eventTypes.items.stoppage.value
      })
    )
    setDrillEventsPaused(true)
  }

  const handleResumeDrill = () => {
    let stoppageEvent
    timeEvents.filteredEvents.forEach((timeEvent) => {
      if (
        timeEvent.eventTypeValue === parentEventTypes.items.time.value &&
        timeEvent.rawData.type === eventTypes.items.stoppage.value &&
        !timeEvent.rawData.timeEnd
      )
        stoppageEvent = timeEvent
    })
    dispatch(endTimeEvent(stoppageEvent.id, { ...stoppageEvent }))
    setDrillEventsPaused(false)
  }

  if (!live) {
    return null
  }

  return (
    <div className={styles.container}>
      <Button
        className='btn--border btn--thin btn--small'
        handleClick={drillEventsPaused ? handleResumeDrill : handlePauseDrill}
        style={{
          backgroundColor: drillEventsPaused
            ? eventTypes.items.stoppage.props.features.buttons.stop.colour
            : eventTypes.items.stoppage.props.features.buttons.start.colour,
          width: '50px'
        }}
        loading={loading}
      >
        {activePauseButtonText()}
      </Button>
      <div className={styles.optionsBorder} />
      {/* <div>-</div> */}
      <Button
        className='btn--border btn--thin btn--small'
        handleClick={
          activeFormattedDrill ? handleStopDrill : handleDrillQuickStart
        }
        style={{
          backgroundColor: activeFormattedDrill
            ? eventTypes.items.stoppage.props.features.buttons.stop.colour
            : eventTypes.items.stoppage.props.features.buttons.start.colour
        }}
        loading={loading}
      >
        {activeFormattedDrill
          ? 'Stop Drill'
          : `Drill ${drillCount + 1} Quick Start`}
      </Button>
    </div>
  )
}
