import { AppRequest, executeAppRequest } from '../../utils/request_handler'
import { SERVICE_ROOT_URL } from '../api/config'
import { SessionTypeValues } from '../sessions/data_types'
import { SessionModeTypeValues } from '../sessions/modes/data_types'
import { SubSessionTypeValues } from '../sessions/sub_sessions/data_types'
import { SportTypeValues } from '../sports/data_types'
import { SeatConfiguration } from './types'

// Hardware service request //
export type HardwareServiceRequestBody = {
  tagIds: number[]
  type: string
}

export function hardwareService({ tagIds, type }: HardwareServiceRequestBody) {
  const options = {
    url: type,
    data: { ids: tagIds },
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Enable RSSI //
export function enableRSSI() {
  const options = {
    url: 'enableRSSI',
    data: {},
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Disable RSSI //
export function disableRSSI() {
  const options = {
    url: 'disableRSSI',
    data: {},
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Sleep all tags and balls except for the ones in the pitch //
export type SleepAllButOnFieldResponseBody = number[]

export const sleepAllButOnField = () => {
  const options = {
    url: 'greylist/offpitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<SleepAllButOnFieldResponseBody>(options)
}

// Get / update seat swapping algorithm state //
export type SeatSwappingStateResponseBody = {
  Enabled: true
  DebugInfo: unknown
}

export type GetSeatSwappingAlgoStateResponseBody = SeatSwappingStateResponseBody

export const getSeatSwappingAlgoState = async () => {
  const options = {
    url: 'seatSwap',
    method: 'get' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<SeatSwappingStateResponseBody>(options)
}

export const updateSeatSwappingAlgoState: AppRequest<
  boolean,
  SeatSwappingStateResponseBody
> = async () => {
  const options = {
    url: 'seatSwap',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<SeatSwappingStateResponseBody>(options)
}
// ===================================/

// Reset seat swapping //
export const resetSeatSwapping = () => {
  const options = {
    url: 'seatSwap/reset',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<SeatSwappingStateResponseBody>(options)
}

// Get / update seat configuration //
export const getSeatConfiguration: AppRequest<
  null,
  SeatConfiguration
> = async () => {
  const options = {
    url: 'seatConfiguration',
    method: 'get' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

export type UpdateSeatConfigurationRequestBody = {
  sportType: SportTypeValues
  sessionType: SessionTypeValues
  sessionSubType: SubSessionTypeValues
}

export const updateSeatConfiguration: AppRequest<
  UpdateSeatConfigurationRequestBody,
  SeatConfiguration
> = async (data) => {
  const options = {
    url: 'seatConfiguration',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true,
    data: data
  }
  return executeAppRequest(options)
}

// Post config to daemon //
export const postSeatConfigurationToDaemon: AppRequest<
  { sessionMode: SessionModeTypeValues },
  SeatConfiguration
> = async (data) => {
  const options = {
    url: 'precreate',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true,
    data: data
  }
  return executeAppRequest(options)
}

// Demote all tags and balls off pitch //
export type DemoteAllOffPitchResponseBody = number[]

export const demoteAllOffPitch = () => {
  const options = {
    url: 'power/demote/offPitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Promote all tags and balls on pitch //
export type PromoteAllOnPitchResponseBody = number[]

export const promoteAllOnPitch = () => {
  const options = {
    url: 'power/promote/onPitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest<PromoteAllOnPitchResponseBody>(options)
}
