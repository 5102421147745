import { useEffect, useMemo } from 'react'
import {
  CustomFilterState,
  FilterTypes,
  getFilterState,
  useFilterReducer
} from '../../hooks/filter'
import { useFormattedSession } from '../sessions/hooks'
import { DeviceTypeValues, deviceTypes, isDeviceType } from './data_types'
import { useFormattedHardware } from './hooks'
import { FormattedDevice, HardwareStates } from './types'
import { addItemToGroup, getEmptyGroup } from '../functions'

export type HardwareFilterTypeKeys =
  | 'searchStr'
  | 'assigned'
  | 'status'
  | 'type'
  | 'team'

export type HardwareFilters = {
  searchStr: FilterTypes<string, 'searchStr', HardwareFilterTypeKeys>
  assigned: FilterTypes<
    'assigned' | 'notAssigned',
    'assigned',
    HardwareFilterTypeKeys
  >
  status: FilterTypes<HardwareStates, 'status', HardwareFilterTypeKeys>
  type: FilterTypes<DeviceTypeValues, 'type', HardwareFilterTypeKeys>
  team: FilterTypes<string, 'team', HardwareFilterTypeKeys>
}

export const defaultHardwareFilterState: HardwareFilters = {
  searchStr: {
    key: 'searchStr',
    label: 'Search',
    type: 'searchStr',
    value: '',
    options: []
  },
  assigned: {
    key: 'assigned',
    label: 'Assigned',
    type: 'singleWithAll',
    options: [
      { name: 'Assigned', value: 'assigned' },
      { name: 'Not Assigned', value: 'notAssigned' }
    ],
    value: 'All'
  },
  status: {
    key: 'status',
    label: 'Status',
    type: 'multipleWithAll',
    options: [
      { name: 'Online', value: 'online' },
      { name: 'Sleep', value: 'sleep' },
      { name: 'Offline', value: 'offline' }
    ],
    value: []
  },
  type: {
    key: 'type',
    label: 'Type',
    type: 'singleWithAll',
    options: deviceTypes.optionsWithAll,
    value: 'All'
  },
  team: {
    key: 'team',
    label: 'Team',
    type: 'singleWithAll',
    options: [],
    value: 'All'
  }
}

export const useHardwareFilter = (
  customFilterState: CustomFilterState<HardwareFilters>,
  sessionId: string
) => {
  const formattedHardware = useFormattedHardware()

  const formattedSession = useFormattedSession(sessionId)

  const filterState = useMemo(
    () => getFilterState(customFilterState, defaultHardwareFilterState),
    []
  )
  // TODO: update this filter type to use the filter reducer properly //
  const { filters, updateFilterValue, updateFilterOptions } =
    useFilterReducer(filterState)

  useEffect(() => {
    updateFilterOptions('team', formattedSession?.teams.options)
  }, [formattedSession])

  const filteredHardware = useMemo(() => {
    const filteredHardware = getEmptyGroup<FormattedDevice, number>()
    formattedHardware.devices.list
      // Filter devices by type
      .filter((device) => {
        if (filters.type.value === 'All') return true
        return device.type.value === filters.type.value
      })
      // Filter by search string
      .filter((device) => {
        if (filters.searchStr.value === '') return true
        const searchString = filters.searchStr.value as string
        const searchStringLowerCase = searchString.toLowerCase()
        const deviceSerial = device.serial.toLowerCase()

        return deviceSerial.includes(searchStringLowerCase)
      })
      // Filter devices by team (remove all devices that are assigned to a team that is not selected)
      .filter((device) => {
        // If team is set to all, return all devices
        if (filters.team.value === 'All') return true
        // If team filter is set, return only player tags
        if (!isDeviceType.playerTag(device.type)) return false
        // Remove device that are already assigned to a different team
        const playerSession =
          formattedSession?.playersSessions.byHardwareId.map[device.id]
        if (
          playerSession &&
          playerSession.teamId &&
          filters.team.value !== playerSession.teamId
        )
          return false
        return true
      })
      // Filter devices by session assignment
      .filter((device) => {
        if (filters.assigned.value === 'All') return true
        const playerSession =
          formattedSession?.playersSessions.byHardwareId.map[device.id]
        if (!playerSession) return true
        if (filters.assigned.value === 'assigned') return playerSession
        if (filters.assigned.value === 'notAssigned') return !playerSession
        return true
      })
      // Filter devices by status
      .filter((device) => {
        if (filters.status.value.length === 0) return true
        if (
          filters.status.type === 'multipleWithAll' ||
          filters.status.type === 'multiple'
        ) {
          return (
            (filters.status.value.includes('online') &&
              device.status.value === 'online') ||
            (filters.status.value.includes('offline') &&
              device.status.value === 'offline') ||
            (filters.status.value.includes('sleep') &&
              device.status.value === 'sleep')
          )
        }
      })

      .forEach((device) => {
        // Add item to all group
        addItemToGroup(
          filteredHardware,
          device,
          device.id.toString(),
          'id',
          device.serial
        )
      })
    return filteredHardware
  }, [formattedSession, formattedHardware, filters])

  return {
    filteredHardware,
    filters,
    updateFilterValue
  }
}
