import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../../store/hooks'
import { Button } from '../../../../components/Button/Button'
import { ConfirmationRequest } from '../../../../components/ConfirmationRequest/ConfirmationRequest'
import {
  createTimeEvent,
  endTimeEvent
} from '../../../../metrics_server/events/actions'
import { useTimeEvents } from '../../../../metrics_server/events/time/hooks'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import { toggleModal } from '../../../../ui/modal/actions'
import styles from '../Coding.module.scss'
import { isSportType } from '../../../../metrics_server/sports/data_types'
import { eventTypes } from '../../../../metrics_server/events/types/data_types'
import { handleName } from '../../../../metrics_server/data_types'

export const PeriodControls = () => {
  // Redux //
  const dispatch = useAppDispatch()
  // ====== //

  // Session //
  const { id, sport, parentEventTypes } = useSelectedFormattedSession()
  const {
    currentPeriod,
    isCurrentPeriodActive,
    activeStoppage,
    nextPeriodTimeEventType,
    isFullTime
  } = useTimeEvents()
  // ======= //

  const startHalf = (type) => {
    dispatch(createTimeEvent({ sessionId: id, type }))

    // TODO: This can be refactored - ideally we don't want any conditionals like if rugby or if cfl  - be setting this sort of stuff in the sport config
    // This particular one could be called something like `periodStartsStopped`
    if (
      isSportType.canadianFootball(sport) ||
      isSportType.americanFootball(sport)
    ) {
      if (!activeStoppage) {
        dispatch(
          createTimeEvent({
            sessionId: id,
            type: eventTypes.items.stoppage.value
          })
        )
      }
    }
  }

  const endStoppageByHalfEnd = useCallback(() => {
    if (activeStoppage) {
      dispatch(endTimeEvent({ id: activeStoppage.id, sessionId: id }, null))
    }
  }, [activeStoppage])

  const endPeriod = useCallback(
    (type) => {
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          handleProceed: () => {
            // TODO: This can be refactored - ideally we don't want any conditionals like if rugby or if cfl  - be setting this sort of stuff in the sport config
            // This particular one could be called something like `periodStartsStopped`
            if (
              isSportType.canadianFootball(sport) ||
              isSportType.americanFootball(sport)
            ) {
              if (!activeStoppage) {
                dispatch(
                  createTimeEvent({
                    sessionId: id,
                    type: eventTypes.items.stoppage.value
                  })
                )
              }
              if (
                currentPeriod &&
                currentPeriod.eventTypeValue ===
                  parentEventTypes.items.time.value
              ) {
                dispatch(
                  endTimeEvent(
                    { ...currentPeriod.rawData, sessionData: id },
                    null
                  )
                )
              }
            } else {
              if (
                currentPeriod &&
                currentPeriod.eventTypeValue ===
                  parentEventTypes.items.time.value
              ) {
                dispatch(
                  endTimeEvent(
                    { ...currentPeriod.rawData, sessionData: id },
                    null
                  )
                )
              }
              endStoppageByHalfEnd()
            }
            dispatch(toggleModal({}))
          },
          ChildComponent: ConfirmationRequest,
          message: 'Are you sure?',
          className: 'modalSmall',
          handleClose: () => {
            dispatch(toggleModal({}))
          }
        })
      )
    },
    [currentPeriod]
  )

  return (
    <>
      {/* <h5>Match time:</h5> */}
      {!isFullTime ? (
        <div className={styles.halfControls}>
          <div>
            {/* <div className={styles.time}>
          {currentPeriod ? (
            <p>
              {milToFormat(currentPeriod.startTime * 1000, {
                colon: true,
                seconds: true
              })}
            </p>
          ) : (
            <p>-- : -- : --</p>
          )}
        </div> */}
            <Button
              id='startPeriod'
              disabled={isCurrentPeriodActive}
              className='btn--primary'
              handleClick={() => startHalf(nextPeriodTimeEventType.value)}
            >
              Start{' '}
              {currentPeriod && isCurrentPeriodActive
                ? handleName(currentPeriod.processedValues.typeName)
                : handleName(nextPeriodTimeEventType.name)}{' '}
              {handleName(
                parentEventTypes.items.time.props.features.periodName
              )}
            </Button>
          </div>
          <div>
            <Button
              disabled={!isCurrentPeriodActive}
              className='btn--primary'
              handleClick={endPeriod}
            >
              End{' '}
              {currentPeriod && isCurrentPeriodActive
                ? handleName(currentPeriod.processedValues.typeName)
                : handleName(nextPeriodTimeEventType.name)}{' '}
              {handleName(
                parentEventTypes.items.time.props.features.periodName
              )}
            </Button>
          </div>
          <div>
            {currentPeriod && isCurrentPeriodActive
              ? handleName(currentPeriod.processedValues.typeName)
              : handleName(nextPeriodTimeEventType.name)}{' '}
            {handleName(parentEventTypes.items.time.props.features.periodName)}
          </div>
        </div>
      ) : (
        <div>Full-time</div>
      )}
    </>
  )
}
