import React, { useEffect, useState } from 'react'
import { CardList } from '../../../components/CardList/CardList'
import { TeamLogoCell } from '../../../components/Table/CustomCells/TeamLogoCell/TeamLogoCell'
import { getItemIdFromRowId, Table } from '../../../components/Table/Table'
import styles from './RecentEvents.module.scss'
import moment from '../../../utils/moment'
import { RecentEventsFlightDetailCard } from '../../../components/RecentEventsFlightDetailCard/RecentEventsFlightDetailCard'
import { getMetricTableHeadersWithUnits } from '../../../metrics_server/metrics/functions'
import { useMetricColumnSelectModal } from '../modals/column_select'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { sessionStates } from '../../../constants/sessionStates'
import { EventFiltersForm } from '../../../components/EventFilters/EventFilters'
import { useAppDispatch } from '../../../store/hooks'
import {
  useEvents,
  useSelectedFormattedEvent
} from '../../../metrics_server/events/hooks'
import {
  setSelectedEvent,
  updateSelectedColumns
} from '../../../metrics_server/events/actions'
import { parentEventTypes } from '../../../metrics_server/events/data_types'
import { StrackEvents } from '../../../components/Strack/StrackEvents/StrackEvents'

export function RecentEvents({
  // Strack Canvas
  strack,
  strackReady,
  canvasStyle,
  canvasView,

  active,

  // filter
  validationEventsFilters
}) {
  // Redux //
  const dispatch = useAppDispatch()
  const events = useEvents()
  // ===== //

  // Session //
  const { id, sessionData, sport, flightMetrics, players } =
    useSelectedFormattedSession()
  // ===== //

  // Modals //
  const { openMetricColumnSelectModal } = useMetricColumnSelectModal(
    flightMetrics,
    events.columns,
    (columns) => {
      dispatch(updateSelectedColumns(columns))
    }
  )
  // ====== //

  // Units //
  const unitSystem = useUnitsSystem(sport)

  // Events //
  const formattedSelectedEvent = useSelectedFormattedEvent()
  // ======= //

  // Event Filter //
  const {
    filteredEvents,
    eventsFilter,
    flightsFilter,
    aussieRulesEventsFilter
  } = validationEventsFilters
  // ===== //

  const [highlightedFlight, setHighlightedFlight] = useState(null)
  const [highlightedRow, setHighlightedRow] = useState(null)

  const [showTracking, setShowTracking] = useState(false)

  const [showEventLabel, setShowEventLabel] = useState(false)

  const [selectedLiveFlights, setSelectedLiveFlights] = useState([])

  const [tableHeaders, setTableHeaders] = useState([])

  const [maxNumberOfKicks, setMaxNumberOfKicks] = useState(5)

  useEffect(() => {
    if (active) {
      if (formattedSelectedEvent) {
        const rowId = formattedSelectedEvent.id + '-overviewFlightTable'
        setHighlightedRow(rowId)
      }
      if (strackReady) {
        updateKicks(strack, filteredEvents, maxNumberOfKicks)
      }
    } else {
      setShowEventLabel(false)
    }
  }, [active])

  useEffect(() => {
    if (strackReady) {
      strack.showSessionTracking = showTracking
    }
  }, [showTracking])

  // Function call on strack ready
  useEffect(() => {
    if (strackReady) {
      strack.initiateEventsCanvas(() => {
        updateKicks(strack, filteredEvents, maxNumberOfKicks)
        // Add highlight kick event
        strack.events.eventHandleCanvas.addEventListener('click', (e) => {
          strack.eventsCanvasClickHandle(
            e,
            (flightId) => {
              setHighlightedFlight(flightId)
              const rowId = flightId + '-overviewFlightTable'
              setHighlightedRow(rowId)
            },
            true
          )
        })
      })
    }
  }, [strackReady])

  useEffect(() => {
    if (filteredEvents && strackReady) {
      updateKicks(strack, filteredEvents, maxNumberOfKicks)
    }
  }, [selectedLiveFlights])

  const updateKicks = (s, flights, maxNumberOfKicks) => {
    // Draw events on canvas via Strack

    if (sessionData.state === sessionStates.started.value) {
      const fArray = flights.filter((x, i) => {
        if (selectedLiveFlights.indexOf(x.id) >= 0) {
          return true
        } else if (i >= flights.length - maxNumberOfKicks) {
          return true
        }
        return false
      })
      s.events.events = fArray
    } else {
      s.events.events = flights
    }
    s.plotEventsOnCanvas(null, true)
    s.renderKicks(null, null, true)
  }

  useEffect(() => {
    if (highlightedFlight) {
      dispatch(setSelectedEvent(highlightedFlight))
      if (canvasView === '2D') {
        setShowEventLabel(true)
      }
      if (strackReady) {
        strack.highlightFlight(highlightedFlight)
      }
    } else {
      setShowEventLabel(false)
      if (strackReady) {
        strack.unhighlightFlight()
      }
      setHighlightedRow(null)
    }
    if (selectedLiveFlights.indexOf(highlightedFlight) < 0) {
      //Remove the first value from the array to clear old selected flight
      selectedLiveFlights.shift()
      setSelectedLiveFlights([highlightedFlight, ...selectedLiveFlights])
    }
  }, [highlightedFlight])

  useEffect(() => {
    setShowEventLabel(false)
  }, [canvasView])

  useEffect(() => {
    if (highlightedRow) {
      const flightId = getItemIdFromRowId(highlightedRow)
      setHighlightedFlight(flightId)
    }
  }, [highlightedRow])

  useEffect(() => {
    if (filteredEvents && strackReady) {
      updateKicks(strack, filteredEvents, maxNumberOfKicks)
    }
  }, [filteredEvents])

  useEffect(() => {
    setTableHeaders(getTableHeaders())
  }, [events.columns, unitSystem])

  const getTableHeaders = () => {
    // TODO: Headers without ignore for MT. Insert conditional when merging component in comp lib
    const selectedColumns = getMetricTableHeadersWithUnits(
      flightMetrics,
      sport.props.features.recentEventTableMetrics,
      unitSystem,
      18,
      10
    )

    return [
      { name: 'Time', key: 'sessionStartTime', width: 10, type: 'time' },
      {
        name: 'Team',
        key: 'teamLogo',
        width: 10,
        type: 'component',
        CustomComponent: TeamLogoCell
      },
      { name: 'Player', key: 'fromPlayer', width: 15 },
      { name: 'Type', key: 'typeName', width: 20 },
      ...selectedColumns
    ]
  }

  const highlightedKick = filteredEvents.find((x) => x.id === highlightedFlight)

  // Get player and time of highlighted kick
  let fromPlayer, flightTime
  if (highlightedKick) {
    fromPlayer = players.All.map[highlightedKick.fromPlayerId]
    flightTime = moment(highlightedKick.startTime * 1000).format('HH:mm:ss')
  }

  return (
    <React.Fragment>
      {/* <div className={styles.canvasOptionsContainer}>
        <CardList col={12} items={[{}]} scrollerId={`scroller-${1}`} className="maxHeight">
        </CardList>
      </div> */}
      <StrackEvents
        events={filteredEvents}
        active={active}
        canvasView={canvasView}
        strack={strack}
        canvasStyle={canvasStyle}
        strackReady={strackReady}
      />
      <div className={styles.filterContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <EventFiltersForm
            sessionId={id}
            eventsFilter={validationEventsFilters}
          />
        </CardList>
      </div>
      <div className={styles.flightDetailCard}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          {highlightedKick &&
          highlightedKick.event.type === parentEventTypes.items.flight.value ? (
            <RecentEventsFlightDetailCard flightTime={flightTime} />
          ) : (
            <></>
          )}
        </CardList>
      </div>
      <div className={styles.tableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            <Table
              // Row props
              smallHead={true}
              highlightRow={setHighlightedRow}
              highlightedRow={highlightedRow}
              id='overviewFlightTable'
              // controls
              controls={[
                // {
                //   name: 'Toggle Time',
                //   callback: () => {
                //     timeColumn === 'sessionStartTime'
                //       ? setTimeColumn('startTimeMil')
                //       : setTimeColumn('sessionStartTime')
                //   }
                // },
                {
                  name: !eventsFilter.filters.ignored.value
                    ? 'Show Ignored'
                    : 'Show Only Valid',
                  callback: () => {
                    eventsFilter.updateFilterValue(
                      'ignored',
                      !eventsFilter.filters.ignored.value
                    )
                  }
                },
                {
                  name: eventsFilter.filters.highlighted.value
                    ? 'Show All'
                    : 'Show Key Events',
                  callback: () => {
                    eventsFilter.updateFilterValue(
                      'highlighted',
                      !eventsFilter.filters.highlighted.value
                    )
                  }
                },
                {
                  name: 'Add/Remove Metrics',
                  callback: () => openMetricColumnSelectModal(2)
                }
              ]}
              // Table props
              options={{
                initialOrder:
                  sessionData.state === sessionStates.started.value
                    ? 'dec'
                    : 'asc',
                initialSortBy: 'startTime',
                sortActive: true
              }}
              data={[]}
              headerFont={13}
              tableClass={'minimalistBlack'}
              className={'container'}
              headers={tableHeaders}
            />
          </div>
        </CardList>
      </div>
    </React.Fragment>
  )
}
