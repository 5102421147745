import {
  InsightsState,
  InsightsTableConfig,
  InsightsCardConfig,
  InsightsDashboardLayout
} from './types'
import { Sport, sportTypes } from '../sports/data_types'
import { metricTypes } from '../metrics/data_types'
import { eventTypes } from '../events/types/data_types'

export const defaultLayout: InsightsDashboardLayout = {
  id: 'default',
  userId: null,
  name: 'Default Layout',
  layout: {
    reactGridLayouts: {
      tables: {
        layout: [
          { i: '1', x: 0, y: 0, w: 4, h: 2 }
          // { i: '2', x: 0, y: 2, w: 2, h: 2 },
          // { i: '3', x: 2, y: 2, w: 2, h: 2 },
          // { i: '4', x: 0, y: 4, w: 4, h: 2 }
        ],
        contentMap: {
          '1': 'default3'
          // '2': 'default0',
          // '3': 'default1',
          // '4': 'default2'
        }
      },
      cards: {
        layout: [
          { i: '1', x: 0, y: 0, w: 1, h: 1, isResizable: false }
          // { i: '2', x: 1, y: 0, w: 1, h: 1, isResizable: false },
          // { i: '3', x: 2, y: 0, w: 1, h: 1, isResizable: false },
          // { i: '4', x: 3, y: 0, w: 1, h: 1, isResizable: false },
          // { i: '5', x: 4, y: 0, w: 1, h: 1, isResizable: false }
        ],
        contentMap: {
          '1': 'default0'
          // '2': 'default1',
          // '3': 'default2',
          // '4': 'default3',
          // '5': 'default4'
        }
      }
    }
  }
}

export function generateDefaultTableConfigs(sport: Sport): {
  [cardId: string]: InsightsTableConfig
} {
  return {
    default0: {
      id: 'default0',
      name: 'Kick Events',
      userId: null,
      config: {
        sessionId: null,
        type: 'tables',
        tableType: 'Custom',
        length: 10,
        tableRows: {
          allTeams: true,
          playerIds: null
        },
        columnQuery: [
          {
            aggregation: 'Mean',
            stat: 'Distance',
            flightType: eventTypes.items.kick.value,
            flightSubTypes:
              eventTypes.items.kick.props.types?.getAllValues() || []
          },
          {
            aggregation: 'Mean',
            stat: 'Speed',
            flightType: eventTypes.items.kick.value,
            flightSubTypes:
              eventTypes.items.kick.props.types?.getAllValues() || []
          }
        ]
      }
    },
    default1: {
      id: 'default1',
      name: 'Lineout Events',
      userId: null,
      config: {
        sessionId: null,
        type: 'tables',
        tableType: 'Custom',
        length: null,
        tableRows: {
          allTeams: true,
          playerIds: null
        },
        columnQuery: [
          {
            aggregation: 'Mean',
            stat: 'Speed',
            flightType: eventTypes.items.lineout.value,
            flightSubTypes: null
          },
          {
            aggregation: 'Mean',
            stat: 'Spin',
            flightType: eventTypes.items.lineout.value,
            flightSubTypes: null
          },
          {
            aggregation: 'Mean',
            stat: 'LineoutDeviation',
            flightType: eventTypes.items.lineout.value,
            flightSubTypes: null
          }
        ]
      }
    },
    default2: {
      id: 'default2',
      name: 'Recent Events',
      userId: null,
      config: {
        sessionId: null,
        type: 'tables',
        tableType: 'RecentEvents',
        length: 10,
        tableRows: null,
        flightType: eventTypes.items.pass.value,
        // flightSubTypes: null,
        columnQuery: [
          {
            aggregation: 'NoOp',
            stat: 'Distance'
          },
          {
            aggregation: 'NoOp',
            stat: 'Speed'
          },
          {
            aggregation: 'NoOp',
            stat: 'Speed'
          }
        ]
      }
    },
    default3: {
      id: 'default3',
      name: 'Custom Stats (Default)',
      userId: null,
      config: {
        sessionId: null,
        tableType: 'Custom',
        length: null,
        tableRows: {
          playerIds: [],
          allTeams: true
        },
        columnQuery: [
          {
            stat: 'Distance',
            aggregation: 'Mean',
            flightType: 1,
            flightSubTypes:
              eventTypes.items.kick.props.types?.getAllValues() || []
          },
          {
            stat: null,
            aggregation: 'Count',
            flightType: 0,
            flightSubTypes: null
          },
          {
            stat: 'Distance',
            aggregation: 'Mean',
            flightType: 0
          },
          {
            stat: metricTypes.items.territorialGain.value,
            aggregation: 'Sum',
            flightType: 1,
            flightSubTypes: [
              eventTypes.items.kick.props.types?.items.penaltyToTouch.value
            ]
          }
        ],
        type: 'tables'
        // sessionId: '1675254985819012788'
      }
    }
  }
}

export function generateDefaultCardConfigs(sport: Sport): {
  [tableId: string]: InsightsCardConfig
} {
  return {
    default0: {
      id: 'default0',
      name: 'Distance of all kicks',
      userId: null,
      colour: '0',
      config: {
        sessionId: null,
        type: 'cards',
        flightType: 1,
        flightSubTypes: eventTypes.items.kick.props.types?.getAllValues() || [],
        groupBy: {
          type: 'All',
          ids: null,
          numbers: null
        },
        aggregation: 'Sum',
        stat: 'Distance'
      }
    },
    default1: {
      id: 'default1',
      name: 'Average pass speed',
      userId: null,
      colour: '1',
      config: {
        sessionId: null,
        type: 'cards',
        flightType: 0,
        flightSubTypes: null,
        groupBy: {
          type: 'Teams',
          ids: null,
          numbers: null
        },
        aggregation: 'Mean',
        stat: 'Speed'
      }
    },
    default2: {
      id: 'default2',
      name: 'Sum of kick distance for flyhalves',
      userId: null,
      colour: '2',
      config: {
        sessionId: null,
        type: 'cards',
        flightType: 1,
        flightSubTypes: eventTypes.items.kick.props.types?.getAllValues() || [],
        groupBy: {
          type: 'Players',
          ids: null,
          numbers: [10, 10]
        },
        aggregation: 'Mean',
        stat: 'Speed'
      }
    },
    default3: {
      id: 'default3',
      name: 'Sum of box kick distance for scrum half',
      userId: null,
      colour: '3',
      config: {
        sessionId: null,
        type: 'cards',
        flightType: 1,
        flightSubTypes: [
          eventTypes.items.kick.props.types?.items.boxKick.value
        ],
        groupBy: {
          type: 'Players',
          ids: null,
          numbers: [9]
        },
        aggregation: 'Mean',
        stat: 'Speed'
      }
    },
    default4: {
      id: 'default4',
      name: 'Average kick speed per team',
      userId: null,
      colour: '4',
      config: {
        sessionId: null,
        type: 'cards',
        flightType: 1,
        flightSubTypes: eventTypes.items.kick.props.types?.getAllValues() || [],
        groupBy: {
          type: 'Teams',
          ids: null,
          numbers: null
        },
        aggregation: 'Mean',
        stat: 'Speed'
      }
    }
  }
}

export const insightsDummyState: InsightsState = {
  layouts: {
    selectedId: {
      sessionLayout: defaultLayout.id,
      competitionLayout: null,
      homeLayout: null
    },
    items: {
      [defaultLayout.id]: defaultLayout
    },
    editedItems: {}
  },
  tables: {
    configs: generateDefaultTableConfigs(sportTypes.items.rugbyUnion),
    data: {}
  },
  cards: {
    configs: generateDefaultCardConfigs(sportTypes.items.rugbyUnion),
    data: {}
  },
  dataFetchQueue: {}
}

export const cardColorSchemes = {
  0: {
    backgroundColor: '#FAFAFA',
    titleColor: '#3F4059',
    statColor: '#A2C0B1',
    attributionColor: 'black'
  },
  1: {
    backgroundColor: '#FDF5E5',
    titleColor: '#3F4059',
    statColor: '#A2C0B1',
    attributionColor: 'black'
  },
  2: {
    backgroundColor: '#A2C0B1',
    titleColor: '#3F4059',
    statColor: '#FFFFFF',
    attributionColor: 'black'
  },
  3: {
    backgroundColor: '#FAFAFA',
    titleColor: '#3F4059',
    statColor: '#A2C0B1',
    attributionColor: 'black'
  },
  4: {
    backgroundColor: '#FAFAFA',
    titleColor: '#3F4059',
    statColor: '#F0D9AE',
    attributionColor: 'black'
  },
  5: {
    backgroundColor: '#A2C0B1',
    titleColor: '#3F4059',
    statColor: '#F0D9AE',
    attributionColor: 'black'
  },
  6: {
    backgroundColor: '#FFECEA',
    titleColor: '#3F4059',
    statColor: '#A2C0B1',
    attributionColor: 'black'
  }
}
