import { rawTeamOne, rawTeamTwo } from '../../teams/mocks/api'
import { RawPlayerData } from '../types'

// Players //

export const rawPlayerOne: RawPlayerData = {
  id: 'player-1',
  firstName: 'Player',
  lastName: 'One',
  weight: 60,
  height: 150,
  position: '1',
  number: 1,
  teamId: rawTeamOne.id,
  img: 'img1',
  color: 'red'
}

export const rawPlayerTwo: RawPlayerData = {
  id: 'player-2',
  firstName: 'Player',
  lastName: 'Two',
  weight: 60,
  height: 150,
  position: '2',
  number: 2,
  teamId: rawTeamOne.id,
  img: 'img2',
  color: 'blue'
}

export const rawPlayerThree: RawPlayerData = {
  id: 'player-3',
  firstName: 'Player',
  lastName: 'Three',
  weight: 60,
  height: 150,
  position: '3',
  number: 3,
  teamId: rawTeamTwo.id,
  img: 'img3',
  color: 'green'
}

export const rawPlayerFour: RawPlayerData = {
  id: 'player-4',
  firstName: 'Player',
  lastName: 'Four',
  weight: 60,
  height: 150,
  position: '4',
  number: 4,
  teamId: rawTeamTwo.id,
  img: 'img4',
  color: 'yellow'
}

// Reporting Tool Dummy Players //

export const rawArsenalPlayers = {}

const lastNames = [
  'jesus',
  'gabriel',
  'saliba',
  'martinelli',
  'jorginho',
  'odegaard',
  'rice',
  'tierney',
  'timber',
  'partey',
  'white',
  'sterling'
]

const arsenalPlayers = [
  { name: 'Gabriel Jesus', number: 9, position: 'Forward', img: 'jesus' },
  {
    name: 'Gabriel Martinelli',
    number: 35,
    position: 'Forward',
    img: 'martinelli'
  },
  { name: 'Gabriel Gabriel', number: 6, position: 'Forward', img: 'gabriel' },
  { name: 'William Saliba', number: 4, position: 'Defender', img: 'saliba' },
  {
    name: 'Raheem Sterling',
    number: 7,
    position: 'Midfielder',
    img: 'sterling'
  },
  { name: 'Jurrien Timber', number: 22, position: 'Defender', img: 'timber' },
  { name: 'Kieran Tierney', number: 3, position: 'Defender', img: 'tierney' },
  { name: 'Ben White', number: 6, position: 'Defender', img: 'white' },
  {
    name: 'Martin Odegaard',
    number: 11,
    position: 'Midfielder',
    img: 'odegaard'
  },
  { name: 'Declan Rice', number: 41, position: 'Midfielder', img: 'rice' },
  { name: 'Thomas Partey', number: 5, position: 'Midfielder', img: 'partey' },
  {
    name: 'J. Jorginho',
    number: 8,
    position: 'Midfielder',
    img: 'jorginho'
  }
]

arsenalPlayers.forEach((player, index) => {
  const [firstName, lastName] = player.name.split(' ')
  rawArsenalPlayers[`player-${index + 1}`] = {
    id: `player-${index + 1}`,
    firstName,
    lastName,
    weight: 60,
    height: 150,
    position: player.position,
    number: player.number,
    teamId: rawTeamOne.id,
    img: player.img,
    color: 'red'
  }
})

export const rawPittsburghSteelersPlayers = {}

const steelersPlayers = [
  { name: 'James Daniels', number: 78, position: 'OL', img: 'daniels' },
  { name: 'Chris Boswell', number: 9, position: 'K', img: 'boswell' },
  {
    name: 'Kyle Allen',
    number: 4,
    position: 'QB',
    img: 'allen'
  },
  {
    name: 'Calvin Anderson',
    number: 67,
    position: 'OT',
    img: 'anderson'
  },
  {
    name: 'Spencer Anderson',
    number: 74,
    position: 'OL',
    img: 'spanderson'
  },
  { name: 'Justin Fields', number: 2, position: 'QB', img: 'fields' },
  { name: 'James Daniels', number: 78, position: 'OL', img: 'daniels' },
  { name: 'Chris Boswell', number: 9, position: 'K', img: 'boswell' },
  {
    name: 'Kyle Allen',
    number: 4,
    position: 'QB',
    img: 'allen'
  },
  {
    name: 'Calvin Anderson',
    number: 67,
    position: 'OT',
    img: 'anderson'
  },
  {
    name: 'Spencer Anderson',
    number: 74,
    position: 'OL',
    img: 'spanderson'
  }
]

steelersPlayers.forEach((player, index) => {
  const [firstName, lastName] = player.name.split(' ')
  rawPittsburghSteelersPlayers[`player-${index + 12}`] = {
    id: `player-${index + 12}`,
    firstName,
    lastName,
    weight: 60,
    height: 150,
    position: player.position,
    number: player.number,
    teamId: rawTeamTwo.id,
    img: player.img,
    color: 'yellow'
  }
})
