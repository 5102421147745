import React, { useMemo } from 'react'
import { Button } from '../../../components/Button/Button'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { getTeamOptions } from '../../../metrics_server/teams/functions'
import { useOrganisations } from '../../../metrics_server/organisations/hooks'
import { useAppDispatch } from '../../../store/hooks'
import { useTeams } from '../../../metrics_server/teams/hooks'
import { JoinOrganisationAndTeamFormProps } from '../config'
import { joinTeam } from '../../../metrics_server/teams/actions'
import { goBack, setRedirect } from '../../../ui/router/actions'
import { useNewOrganisationCheck } from '../hooks'

const validate = (values) => {
  const errors = {} as any
  if (!values.team) {
    errors.team = 'Required'
  }
  return errors
}

export const JoinTeamForm = ({
  switchForm
}: JoinOrganisationAndTeamFormProps) => {
  const dispatch = useAppDispatch()

  const { checkedOrganisation } = useOrganisations()
  const teams = useTeams()

  const isNewOrganisation = useNewOrganisationCheck()

  const teamOptions = useMemo(() => {
    const teamsToJoin = checkedOrganisation.teams.filter((team) => {
      return !teams.items[team.id]
    })
    return getTeamOptions(teamsToJoin)
  }, [])

  const handleJoinTeam = (teamId: string) => {
    const data = {
      id: teamId,
      organisationId: checkedOrganisation.id,
      threeWords: checkedOrganisation.threeWords
    }

    dispatch(joinTeam(data, () => dispatch(setRedirect('/teams'))))
  }

  const joinAllTeams = () => {
    teamOptions.map((team) => {
      handleJoinTeam(team.value)
    })
  }

  const fields = useMemo(
    () => [
      {
        name: 'team',
        type: 'searchable',
        label: 'Select Teams',
        options: teamOptions,
        initialValue: teamOptions[0]?.value
      }
    ],
    [teamOptions]
  )

  return (
    <div style={{ width: '80%' }}>
      <div className='section' style={{ height: '15px' }}>
        {isNewOrganisation ? (
          <Button
            className='link'
            handleClick={() => switchForm('joinOrganisation')}
          >
            &lt; Not your organisation
          </Button>
        ) : (
          <Button className='link' handleClick={() => dispatch(goBack())}>
            &lt; Back
          </Button>
        )}
      </div>

      {teamOptions.length > 0 ? (
        <>
          <FormContainer
            name='Join Team'
            fields={fields}
            validate={validate}
            onSubmit={(values) => {
              handleJoinTeam(values.team)
            }}
            submitText={'Join'}
            title={`${checkedOrganisation.name} teams`}
            secondaryButtonText={'Or add a new team'}
            secondaryButtonHandleClick={() => switchForm('createTeam')}
          />
          <div>
            <Button className='link-2' handleClick={() => joinAllTeams()}>
              Join All Teams
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <Button
              className='link'
              handleClick={() => switchForm('createTeam')}
            >
              Create Team
            </Button>
          </div>
          <span>No Teams Available</span>
        </>
      )}
    </div>
  )
}
