import React from 'react'
import styles from './EventProfile.module.scss'
import playerAvatar from '../../../assets/img/player_profile_avatar.svg'
import { EditPencilIcon } from '../../Icons/EditPencilIcon/EditPencilIcon'
import { SportableTimePicker } from '../../TimePicker/TimePicker'
import { EventOperatorNotes } from '../../EventOperatorNotes/EventOperatorNotes'
import { useChangeFromPlayerModal } from '../../../views/Session/modals/change_from_player'
import { useChangeFromTeamModal } from '../../../views/Session/modals/change_from_team'
import { useFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useFormattedEvent } from '../../../metrics_server/events/hooks'
import { updateEvent } from '../../../metrics_server/events/actions'
import { useAppDispatch } from '../../../store/hooks'
import { AdjustedFontSizeType } from '../hooks'
import moment from 'moment'
import { debounce } from 'lodash'
// switch operatorNotes for event in EventOperatorNotes props

export interface EventProfileProps {
  eventId: string
  zoomIn: () => void
  zoomOut: () => void
  adjustedFontSize: AdjustedFontSizeType
  saveFlash?: boolean
}

export const EventProfile = ({
  eventId,
  zoomIn,
  zoomOut,
  adjustedFontSize,
  saveFlash
}: EventProfileProps) => {
  const dispatch = useAppDispatch()
  const formattedEvent = useFormattedEvent(eventId)
  const formattedSession = useFormattedSession(formattedEvent?.sessionId)

  // Modals //
  const { openChangeFromPlayerModal } = useChangeFromPlayerModal()
  const { openChangeFromTeamModal } = useChangeFromTeamModal()
  // ====== //

  const eventTimeSinceStart = moment
    .unix(formattedEvent?.startTime - formattedSession.startTime.unixSeconds)
    .utc()
    .format('HH:mm:ss')

  const eventStartTime = moment
    .unix(formattedEvent?.startTime)
    .utc()
    .format('HH:mm:ss')

  return (
    <div className={styles.profileContainer}>
      <div className={styles.imgAndTextWrapper}>
        <div className={styles.imgAndNotesContainer}>
          {formattedEvent.player?.selected ? (
            <img
              className={formattedEvent.player?.selected.img && styles.img}
              src={
                !formattedEvent.player?.selected.img
                  ? playerAvatar
                  : formattedEvent.player?.selected.img
              }
              alt=''
            />
          ) : (
            <img className={styles.img} src={playerAvatar} alt='' />
          )}

          <EventOperatorNotes
            operatorNotes={formattedEvent.operator}
            updateEventHighlight={(value) => {
              const data = { id: formattedEvent.id, operatorNotes: {} }
              if (formattedEvent.operator) {
                data.operatorNotes = {
                  ...formattedEvent.operator,
                  highlight: value
                }
              } else {
                data.operatorNotes = {
                  highlight: value
                }
              }
              dispatch(updateEvent(data))
            }}
          />
        </div>

        <div className={styles.nameAndTeamtextContainer}>
          <div
            data-cy='event-team-name'
            className={styles.teamContainer}
            onClick={openChangeFromTeamModal}
          >
            <div>
              <div>
                {formattedEvent.team?.selected ? (
                  <h3 style={{ fontSize: adjustedFontSize?.h3 }}>
                    {formattedEvent.team?.selected.name}
                  </h3>
                ) : (
                  <h3 style={{ fontSize: adjustedFontSize?.h3 }}>No team</h3>
                )}
              </div>
            </div>
            <EditPencilIcon id={'edit-team'} scale={0.3} />
          </div>
          <div
            data-cy='event-player-name'
            className={styles.nameContainer}
            onClick={openChangeFromPlayerModal}
          >
            {formattedEvent.player?.selected ? (
              <h5 style={{ fontSize: adjustedFontSize?.h5 }}>
                {`${formattedEvent.player?.selected?.number}`
                  ? `${formattedEvent.player?.selected.number}. `
                  : ''}
                {formattedEvent.player?.selected.firstName}{' '}
                {formattedEvent.player?.selected.lastName}
              </h5>
            ) : (
              <h5 style={{ fontSize: adjustedFontSize?.h5 }}>No player</h5>
            )}
            <EditPencilIcon id={'edit-player'} scale={0.3} />
          </div>
        </div>
      </div>

      <div className={styles.timeContainer}>
        <div className={styles.time}>
          {/* From Operator notes */}
          <p style={{ fontSize: adjustedFontSize?.p }}>Ev. Start Time</p>
          {/* <div style={{ fontSize: adjustedFontSize?.p }}> */}
          {!!formattedEvent?.operator?.matchTime && (
            <SportableTimePicker
              onChange={debounce((value) => {
                dispatch(
                  updateEvent({
                    id: formattedEvent.id,
                    operatorNotes: {
                      ...formattedEvent.operator,
                      matchTime: value
                    }
                  })
                )
              }, 500)}
              value={formattedEvent?.operator?.matchTime || '00:00:00'}
              format={'HH:mm:ss'}
              disableClock={true}
              clearIcon={null}
              maxDetail={'second'}
            />
          )}
          {eventStartTime && (
            <SportableTimePicker
              // onChange={updateMatchTime}
              onChange={() => console.log('test')}
              value={eventStartTime || '00:00:00'}
              format={'HH:mm:ss'}
              disableClock={true}
              clearIcon={null}
              maxDetail={'second'}
            />
          )}
          {/* </div> */}
        </div>
        <div className={styles.time}>
          <p style={{ fontSize: adjustedFontSize?.p }}>Time Since Start</p>
          {/* <div style={{ fontSize: adjustedFontSize?.p }}> */}
          <SportableTimePicker
            onChange={() => console.log('test')}
            value={eventTimeSinceStart || '00:00:00'}
            format={'HH:mm:ss'}
            disableClock={true}
            clearIcon={null}
            maxDetail={'second'}
          />
          {/* </div> */}
        </div>
      </div>
      <div className={styles.zoomButtons}>
        <button
          className={styles.zoomButton}
          onClick={zoomIn}
          aria-label='Increase Text Size'
          title='Increase Text Size'
        >
          A+
        </button>
        <button
          className={styles.zoomButton}
          onClick={zoomOut}
          aria-label='Decrease Text Size'
          title='Decrease Text Size'
        >
          A-
        </button>
      </div>
    </div>
  )
}
