import React, { useState } from 'react'
import {
  useSelectedLayout,
  useLayoutOptions
} from '../../../metrics_server/insights/hooks'
import { AddNewWidgetButton } from '../../../components/AddNewWidgetButton/AddNewWidgetButton'
import { CommentatorTableGrid } from '../../../components/CommentatorTableGrid/CommentatorTableGrid'
import { CommentatorWidgetGrid } from '../../../components/CommentatorWidgetGrid/CommentatorWidgetGrid'
import { Separator } from '../../../components/Separator/Separator'
import styles from './MatchDashboard.module.scss'

// types
import { InsightsState } from '../../../metrics_server/insights/types'

import Scroller from '../../../components/Scroller/Scroller'
import { SessionData } from '../../../metrics_server/sessions/types'
import { LayoutType } from '../../../metrics_server/insights/types'
import { useInsightsCardFormModal } from '../modals/insights_forms'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'

export interface MatchDashboardProps {
  insights: InsightsState
  session: SessionData
}

export const MatchDashboard = (props: MatchDashboardProps) => {
  // Modals //
  const { openInsightsCardFormModal } = useInsightsCardFormModal()
  // ====== //

  // Session //
  const { sessionData } = useSelectedFormattedSession()
  // ======== //

  return (
    <Scroller padding={'0'} scrollerId={'table'} scrollerClass='form-scroller'>
      <div className={styles.matchDashboardContainer}>
        <div className={styles.matchDashboardWidgetGrid}>
          <div className={styles.AddNewWidgetButtonWrapper}>
            <AddNewWidgetButton
              handleClick={() => openInsightsCardFormModal(null)}
            />
          </div>
          <div className={styles.widgetGridWrapper}>
            <CommentatorWidgetGrid session={sessionData} />
          </div>
        </div>
        <Separator
          style={{
            position: 'relative',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          horizontal={true}
        />
        <div className={styles.matchDashboardTableGrid}>
          <CommentatorTableGrid layoutType={LayoutType.sessionLayout} />
        </div>
      </div>
    </Scroller>
  )
}
