'use client'

import * as React from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'

import { ArrowUpDown, ChevronDown } from 'lucide-react'

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Input,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  ScrollableTabs
} from '@frontend/ui'

import {
  Star,
  ArrowUpRight,
  ArrowDownRight,
  Target,
  Move,
  Swords,
  Shield,
  Boxes,
  CrosshairIcon,
  Grab,
  Goal,
  MonitorIcon as Running,
  Flag,
  BellIcon as Whistle,
  Clock,
  Users,
  Zap,
  Award,
  TrendingUp,
  BarChart2,
  Percent
} from 'lucide-react'
import {
  generateMetricTypeClass,
  selectDrill,
  useAppDispatch,
  useDrills,
  useFormattedSession,
  useLanguage,
  useUnitsSystem
} from '@frontend/sportable'

export const textData = {
  columns: {
    en: 'Columns',
    fr: 'Colonnes'
  },
  noResults: {
    en: 'No results.',
    fr: 'Aucun résultat.'
  }
}

const iconStringMap = {
  pass: ArrowUpRight,
  soccerPass: ArrowDownRight,
  soccerShot: Target,
  soccerCarry: Move,
  soccerCross: CrosshairIcon,
  soccerCorner: Clock,
  soccerThrowIn: Users,
  soccerKickOff: Zap,
  soccerDeflection: Award,
  snapFlightEvent: Zap,
  kick: Goal,
  sprint: Running,
  offside: Flag,
  foul: Whistle,
  possession: Clock,
  formation: Users,
  pressure: Zap,
  performance: Award,
  progression: TrendingUp,
  xg: BarChart2,
  conversion: Percent
}

interface PlayerPerformanceTableProps {
  sessionId: string
  className?: string

  // metrics: { metricKeys: string[]; groupName: string }[]
  // data: ({ [metricKey: string]: number } & {
  //   name: string
  //   duration: string
  // })[]
  // groups: string[]
}

const getRandomInt = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getRandomDecimal = (min = 0, max = 100, decimals = 1) => {
  const factor = Math.pow(10, decimals)
  return Math.round((Math.random() * (max - min) + min) * factor) / factor
}

const generateDummyMetrics = () => {
  return {
    involvements: getRandomInt(),
    involvementsPerMinute: getRandomInt(),
    soccerPass: getRandomDecimal(),
    soccerDribble: getRandomDecimal(),
    soccerCross: getRandomDecimal(),
    soccerShotAtGoal: getRandomDecimal(),
    soccerPenalty: getRandomDecimal(),
    soccerClearance: getRandomDecimal(),
    soccerCorner: getRandomDecimal(),

    pass: getRandomDecimal(),
    kick: getRandomDecimal(),
    snapFlightEvent: getRandomDecimal()
  }
}

export const PlayerPerformanceTable = ({
  sessionId
}: PlayerPerformanceTableProps) => {
  const language = useLanguage()

  const dispatch = useAppDispatch()

  const formattedSession = useFormattedSession(sessionId)
  const drills = useDrills()

  const unitSystem = useUnitsSystem(formattedSession.sport)

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  React.useEffect(() => {
    const row = table.getRowModel().rows[Object.keys(rowSelection)[0]]
    const drillId = row?.original?.drillId || null
    dispatch(selectDrill(drillId))
    console.log(row)
  }, [rowSelection])

  React.useEffect(() => {
    dispatch(selectDrill(null))
  }, [formattedSession.id])

  const tableConfig = formattedSession.sport?.props.reportMetricConfig
    ?.playerPerformanceTable?.all || {
    metrics: { keys: [] },
    eventMetricGroup: { eventTypes: [], groupName: '' }
  }

  const data = React.useMemo(() => {
    const rows = []

    if (formattedSession.type.value === 3) {
      Object.values(formattedSession.timeEventTypes.items).forEach(
        (timeEventType) => {
          if (timeEventType.props.features.period) {
            const periodName =
              formattedSession.parentEventTypes.items.time.props.features
                .periodName[language]
            rows.push({
              ...generateDummyMetrics(),
              name: `${timeEventType.name[language]} ${
                timeEventType.props.features.hidePeriodName ? '' : periodName
              }`,
              duration: '00:45:00'
            })
          }
        }
      )
    } else if (formattedSession.type.value === 4) {
      Object.values(drills.processedData).forEach((drill) => {
        rows.push({
          ...generateDummyMetrics(),
          name: `${drill.name}`,
          duration: '00:45:00',
          drillId: drill.id
        })
      })
    }

    return rows
  }, [formattedSession])

  const columns: ColumnDef<
    {
      [metricKey: string]: number | string
    } & {
      name: string
      duration: string
    }
  >[] = React.useMemo(() => {
    const columns: ColumnDef<
      {
        [metricKey: string]: number | string
      } & {
        name: string
        duration: string
      }
    >[] = [
      {
        id: 'select',
        header: ({ table }) => (
          <div style={{ width: '20px' }}>
            {formattedSession.type.value === 3 && (
              <Checkbox
                checked={
                  table.getIsAllPageRowsSelected() ||
                  ((table.getIsSomePageRowsSelected() &&
                    'indeterminate') as any)
                }
                onCheckedChange={(value) =>
                  table.toggleAllPageRowsSelected(!!value)
                }
                aria-label='Select all'
              />
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label='Select row'
          />
        ),
        enableSorting: false,
        enableHiding: false
      },
      {
        accessorKey: 'name',
        header: 'Period',
        cell: ({ row }) => <div>{row.getValue('name')}</div>
      },
      {
        accessorKey: 'duration',
        header: 'Duration',
        cell: ({ row }) => <div>{row.getValue('duration')}</div>
      }
    ]

    tableConfig.metrics.keys.forEach((metricKey) => {
      if (!formattedSession.sport) return

      const metricType = formattedSession.sport.props.metrics.items[metricKey]

      const metricInfo = generateMetricTypeClass(metricType)

      if (!metricInfo) return

      const metricHeader = metricInfo.getMetricDisplayWithUnits(
        unitSystem,
        null,
        false,
        language
      )

      columns.push({
        accessorKey: metricKey,

        getUniqueValues: () => ['select'],

        header: ({ column }) => {
          return (
            <Button
              variant='ghost'
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
              className='w-full px-0 text-left justify-between'
            >
              {metricHeader}
              <ArrowUpDown className='w-4' />
            </Button>
          )
        },
        cell: ({ row }) => (
          <div className='w-1/12'>
            {row.getValue(metricKey) ? row.getValue(metricKey) : '-'}
          </div>
        )
      })
    })

    tableConfig.eventMetricGroup.eventTypes.forEach((eventTypeKey) => {
      if (!formattedSession.sport) return

      let eventType = formattedSession.flightTypes.items[eventTypeKey]

      if (!eventType)
        eventType = formattedSession.gameEventTypes.items[eventTypeKey]

      let label = eventType?.name

      if (label && typeof label === 'object') {
        label = eventType?.name[language] || eventTypeKey
      }

      columns.push({
        accessorKey: eventTypeKey,
        header: ({ column }) => {
          return (
            <Button
              variant='ghost'
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
              className='w-full px-0 text-left justify-between'
            >
              {typeof label === 'object' || !label ? eventTypeKey : label}
              <ArrowUpDown className='w-4' />
            </Button>
          )
        },
        cell: ({ row }) => (
          <div>
            {row.getValue(eventTypeKey) ? row.getValue(eventTypeKey) : '-'}
          </div>
        )
      })
    })

    return columns
  }, [formattedSession, language])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: formattedSession.type.value === 4 ? false : true,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection
    }
  })

  const columnsCount =
    3 +
    tableConfig.metrics.keys.length +
    tableConfig.eventMetricGroup.eventTypes.length

  const scrollableTabs = React.useMemo(() => {
    if (!formattedSession.sport?.props.parentEventTypes?.items.flight) return []
    return Object.values(
      formattedSession.sport.props.parentEventTypes.items.flight.props.types
        .items
    ).map((eventType: any) => {
      let name = eventType.name

      if (name && typeof name === 'object') {
        name = eventType.name[language] || 'No Name Found'
      }

      return {
        value: eventType.key,
        label: eventType.name[language] || eventType.name,
        icon: iconStringMap[eventType.key] || Star
      }
    })
  }, [formattedSession])

  return (
    <div className='w-full'>
      <div className='pb-8'>
        <ScrollableTabs tabs={scrollableTabs} />
      </div>
      <div className='flex items-center py-4'>
        <Input
          placeholder='Filter...'
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
          className='max-w-sm'
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline' className='ml-auto'>
              {textData.columns[language]} <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                const HeaderComponent = column.columnDef.header
                if (typeof HeaderComponent === 'function') {
                  const headerLabel =
                    HeaderComponent({} as any)?.props.children?.[0] || column.id
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className='capitalize'
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {headerLabel}
                    </DropdownMenuCheckboxItem>
                  )
                }
                return null
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className='p-8 w-full'>
        <Table className=' table-fixedw-full'>
          <TableHeader className='w-full'>
            <TableRow>
              <TableHead></TableHead>
              <TableHead></TableHead>
              <TableHead></TableHead>
              <TableHead></TableHead>
              <TableHead></TableHead>
              <TableHead className='text-center' colSpan={7} arms={true}>
                {tableConfig.eventMetricGroup.groupName[language]}
              </TableHead>
            </TableRow>

            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className='w-full' key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  // Calculate width //
                  let width
                  if (index === 0) {
                    width = '2.5%'
                  } else {
                    width = 97.5 / columnsCount + '%'
                  }

                  return (
                    <TableHead style={{ width }} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  rowContrasting
                  isTotalRow={index === data.length - 1}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  {textData.noResults[language]}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className='flex items-center justify-end space-x-2 py-4'>
        {/* <div className='flex-1 text-sm text-muted-foreground'>
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div> */}
        {/* <div className='space-x-2'>
          <Button
            variant='outline'
            size='sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant='outline'
            size='sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div> */}
      </div>
    </div>
  )
}
