import moment from 'moment'
import { SessionData, SessionsState } from '../types'
import {
  rawPittsburghSteelersMatch,
  rawPittsburghSteelersTrainingSession,
  rawSessionArsenalMatch,
  rawSessionArsenalTrainingSession,
  rawSessionOne
} from './api'

export const mockSessionsState: SessionsState = {
  // Setup form
  useCustomName: true,
  customName: '',
  rawData: {
    // [rawSessionOne.id]: rawSessionOne,
    [rawSessionArsenalTrainingSession.id]: rawSessionArsenalTrainingSession,
    [rawSessionArsenalMatch.id]: rawSessionArsenalMatch,
    [rawPittsburghSteelersTrainingSession.id]:
      rawPittsburghSteelersTrainingSession,
    [rawPittsburghSteelersMatch.id]: rawPittsburghSteelersMatch
  },
  selectedId: rawSessionOne.id,
  creatingSession: false,
  selected: {} as SessionData,
  filter: {
    items: [],
    options: {
      team: 'All',
      startTime:
        moment(new Date().getTime() - 2629743833)
          .startOf('day')
          .valueOf() / 1000,
      endTime:
        moment(new Date().getTime()).endOf('day').valueOf() / 1000 + 2628000,
      limitQuantity: 200,
      type: 'All',
      subType: 'All',
      searchStr: '',
      state: 'All',
      sportType: 'All'
    },
    fetching: false,
    hasValidData: false
  },
  recent: {
    items: [],
    quantity: 10
  },
  graph: {
    items: {}
  },
  activeSession: null,

  activityListScrollHeight: 0,

  autoSleepEnabled: null,

  scoreboard: {
    firstTeam: {
      scoreSum: 0,
      teamId: '0',
      teamName: ''
    },
    secondTeam: {
      scoreSum: 0,
      teamId: '0',
      teamName: ''
    }
  },
  isKeyboardShortcutEnabled: true
}
