import { MetricTypeValues } from '../metrics/data_types'
import { AggregationOperatorTypeValues } from '../metrics/aggregation_operators/data_types'
import { EventTypeValues } from '../events/types/data_types'
import { EventSubTypeValues } from '../events/subTypes/data_types'

export type Options<k> = Option<k>[]

export type Option<k> = {
  name: string
  value: k
}

export namespace LayoutType {
  export const sessionLayout = 'sessionLayout'
  export const competitionLayout = 'competitionLayout'
  export const homeLayout = 'homeLayout'
}

export type LayoutTypes = keyof typeof LayoutType

export namespace ConfigType {
  export const tables = 'tables'
  export const cards = 'cards'
}

export type ConfigTypes = keyof typeof ConfigType

export interface InsightsState {
  layouts: InsightsDashboardLayouts
  [ConfigType.tables]: {
    configs: {
      [tableId: string]: InsightsTableConfig
    }
    data: {
      [tableId: string]: InsightsTableResponse
    }
  }
  [ConfigType.cards]: {
    configs: {
      [cardId: string]: InsightsCardConfig
    }
    data: {
      [cardId: string]: InsightsCardResponse
    }
  }
  dataFetchQueue: {
    [shortId: string]: {
      id: string
      shortId: string
    }
  }
}

export interface InsightsDashboardLayouts {
  selectedId: {
    [key in LayoutTypes]: string
  }
  items: {
    [layoutId: string]: InsightsDashboardLayout
  }
  editedItems: {
    [layoutId: string]: InsightsDashboardLayout
  }
}

export interface InsightsDashboardLayout {
  id: string
  userId: string
  name: string
  layout: {
    reactGridLayouts: {
      [key in ConfigTypes]: {
        layout: ReactGridLayout
        contentMap: {
          [layoutItemId: string]: string
        }
      }
    }
  }
}

export type ReactGridLayout = ReactGridItem[]

export interface ReactGridItem {
  i: string
  x: number
  y: number
  w: number
  h: number
  isResizable?: boolean
}

export interface InsightsTableConfig {
  id: string
  userId: string
  name: string
  colour?: string
  config: InsightsTableRequest
}

export interface InsightsCardConfig {
  id: string
  userId: string
  name: string
  colour?: string
  config: InsightsCardRequest
}

export type ColumnQuery = {
  id?: string
  name?: string
  flightType?: EventTypeValues
  flightSubTypes?: EventSubTypeValues[]
  aggregation: AggregationOperatorTypeValues
  stat: MetricTypeValues
  filter?: any
}

export type InsightsTableRow = {
  allTeams: boolean
  playerIds: string[]
}

export namespace InsightsTableFormTypeKey {
  export const custom = 'Custom',
    topN = 'TopN',
    recentEvents = 'RecentEvents'
}

export type InsightsTableFormTypeKeys = keyof typeof InsightsTableFormTypeKey
export type InsightsTableFormTypeValues =
  (typeof InsightsTableFormTypeKey)[InsightsTableFormTypeKeys]

export type InsightsTableRequest = {
  type: 'tables'
  sessionId: string
  tableType: InsightsTableFormTypeValues
  length: number
  tableRows: InsightsTableRow
  flightType?: EventTypeValues
  flightSubTypes?: EventSubTypeValues[]
  columnQuery: ColumnQuery[]
}

export type ColumnHeading = {
  name: string
  format: string
}

export interface InsightsTableResponse {
  id: string
  shortId: string
  title: string
  columnHeadings: ColumnHeading[]
  cells: string[][]
}

export interface InsightsTableColumn {
  name: string
  query: ColumnQuery
}

export type InsightsCardRequest = {
  type: 'cards'
  sessionId: string
  flightType?: EventTypeValues
  flightSubTypes?: EventSubTypeValues[]
  groupBy: {
    type: string
    ids: string[]
    numbers: number[]
  }
  aggregation: string
  stat: MetricTypeValues
}

export interface InsightsCardResponse {
  id: string
  shortId: string
  title: string
  stats: InsightsCardStat[]
}

export interface InsightsCardStat {
  heading: string
  stat: {
    unit: string
    value: number
  }
}

export namespace InsightsActionType {
  export const SET_INSIGHTS_LAYOUTS = 'SET_INSIGHTS_LAYOUTS'
  export const UPDATE_INSIGHTS_LAYOUT = 'UPDATE_INSIGHTS_LAYOUT'
  export const DELETE_INSIGHTS_LAYOUT = 'DELETE_INSIGHTS_LAYOUT'
  export const SET_SELECTED_INSIGHTS_LAYOUT = 'SET_SELECTED_INSIGHTS_LAYOUT'
  export const EDIT_INSIGHTS_LAYOUT = 'EDIT_INSIGHTS_LAYOUT'
  export const DELETE_EDITED_INSIGHTS_LAYOUT = 'DELETE_EDITED_INSIGHTS_LAYOUT'
  export const SET_INSIGHTS_CONFIGS = 'SET_INSIGHTS_CONFIGS'
  export const UPDATE_INSIGHTS_CONFIG = 'UPDATE_INSIGHTS_CONFIG'
  export const UPDATE_INSIGHTS_TABLE_DATA = 'UPDATE_INSIGHTS_TABLE_DATA'
  export const UPDATE_INSIGHTS_CARD_DATA = 'UPDATE_INSIGHTS_CARD_DATA'
  export const UPDATE_INSIGHTS_DATA_FETCH_QUEUE =
    'UPDATE_INSIGHTS_DATA_FETCH_QUEUE'
  export const ADD_INSIGHTS_LAYOUT_ITEM = 'ADD_INSIGHTS_LAYOUT_ITEM'
  export const REMOVE_INSIGHTS_LAYOUT_ITEM = 'REMOVE_INSIGHTS_LAYOUT_ITEM'
  export const UPDATE_INSIGHTS_LAYOUT_CONTENT_ID =
    'UPDATE_INSIGHTS_LAYOUT_CONTENT_ID'
}

export type InsightsActionTypes = keyof typeof InsightsActionType
