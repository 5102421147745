import { useState, useCallback, useMemo } from 'react'

export type AdjustedFontSizeType = {
  h3: string
  h5: string
  p: string
  span: string
  selectFontSize: string
  selectLabelMarginTop: string
  selectPadding: string
}

export type ZoomControls = {
  zoomLevel: number
  zoomIn: () => void
  zoomOut: () => void
  adjustedFontSize: AdjustedFontSizeType
}

type UseZoomAndFontSizeReturn = ZoomControls & {
  getCappedZoomLevel: (cap?: number) => number
  uncappedAdjustedFontSize: Omit<
    AdjustedFontSizeType,
    'selectFontSize' | 'selectLabelMarginTop' | 'selectPadding'
  >
}

export const useZoomAndFontSize = (
  initialZoom = 1,
  maxZoom = 1.5
): UseZoomAndFontSizeReturn => {
  const [zoomLevel, setZoomLevel] = useState<number>(initialZoom)

  const handleZoomIn = useCallback(() => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, maxZoom))
  }, [maxZoom])

  const handleZoomOut = useCallback(() => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.1))
  }, [])

  const getCappedZoomLevel = useCallback(
    (cap: number = maxZoom) => Math.min(zoomLevel, cap),
    [zoomLevel, maxZoom]
  )

  const adjustedFontSize = useMemo<AdjustedFontSizeType>(
    () => ({
      h3: `${18 * getCappedZoomLevel()}px`,
      h5: `${14 * getCappedZoomLevel()}px`,
      p: `${12 * getCappedZoomLevel()}px`,
      span: `${12 * getCappedZoomLevel()}px`,
      selectFontSize: `${12 * getCappedZoomLevel()}px`,
      selectLabelMarginTop: `${8 * (0.7 - (getCappedZoomLevel() - 1))}px`,
      selectPadding: `${0 * getCappedZoomLevel()}px`
    }),
    [getCappedZoomLevel]
  )

  const uncappedAdjustedFontSize = useMemo(
    () => ({
      h3: `${18 * zoomLevel}px`,
      h5: `${14 * zoomLevel}px`,
      p: `${12 * zoomLevel}px`,
      span: `${12 * zoomLevel}px`
    }),
    [zoomLevel]
  )

  return {
    zoomLevel,
    zoomIn: handleZoomIn,
    zoomOut: handleZoomOut,
    getCappedZoomLevel,
    adjustedFontSize,
    uncappedAdjustedFontSize
  }
}
