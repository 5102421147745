import { sportTypes } from '../../sports/data_types'
import { RawTeamData } from '../types'

// Raw Teams //

export const rawTeamOne: RawTeamData = {
  id: 'team-1',
  name: 'Team One',
  type: '0',
  color: '#6F263D',
  logo: 'logoOne',
  organisationId: '1',
  sportType: sportTypes.items.australianRules.value,
  gender: 'female'
}

export const rawTeamTwo: RawTeamData = {
  id: 'team-2',
  name: 'Team Two',
  type: '0',
  color: '#75FDFF',
  logo: 'logoTwo',
  organisationId: '1',
  sportType: sportTypes.items.australianRules.value,
  gender: 'male'
}

export const rawOfficiatingTeam: RawTeamData = {
  id: 'team-3',
  name: 'Officitating Team',
  type: '0',
  color: '#75FDFF',
  logo: 'logoO',
  organisationId: '1',
  sportType: sportTypes.items.australianRules.value,
  gender: 'male'
}

// Reporting Test Teams //
export const soccerTeam: RawTeamData = {
  id: 'team-1',
  name: 'Arsenal',
  type: '0',
  color: '#6F263D',
  logo: 'logoOne',
  organisationId: '1',
  sportType: sportTypes.items.soccer.value,
  gender: 'male'
}

export const americanFootballTeam: RawTeamData = {
  id: 'team-2',
  name: 'Pittsburgh Steelers',
  type: '0',
  color: '#75FDFF',
  logo: 'arsenal',
  organisationId: '1',
  sportType: sportTypes.items.americanFootball.value,
  gender: 'male'
}
