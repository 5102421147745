import React from 'react'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Card,
  CardContent
} from '@frontend/ui'
import { useLanguage } from '@frontend/sportable'

interface MetricCardProps {
  value: number
  label: string
  topPerformerName?: string
  topPerformerValue?: string
  topPerformerAvatar?: string
}

const textData = {
  topPerformer: {
    en: 'Top performer',
    fr: 'Meilleur performeur'
  }
}

export function TopPerformerCard({
  value,
  label,
  topPerformerName,
  topPerformerValue,
  topPerformerAvatar
}: MetricCardProps) {
  const language = useLanguage()

  return (
    <Card key={label} className='w-1/3'>
      <CardContent className='p-6 space-y-4'>
        <div className='space-y-3'>
          <p className='text-3xl'>{label}</p>
          <p className='text-4xl font-semibold'>{value ?? 'TBD'}</p>
        </div>
        <div className='space-y-1'>
          <p>
            {' '}
            <span className='mr-1'>🏅</span>
            <span>{textData.topPerformer[language]}</span>
          </p>
          {topPerformerName && (
            <div className='flex items-center gap-2'>
              <Avatar className='h-6 w-6'>
                <AvatarImage src={topPerformerAvatar} alt={topPerformerName} />
                <AvatarFallback className='bg-secondary'>
                  {topPerformerName.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <span>
                {topPerformerName} ({topPerformerValue ?? 'N/A'} km)
              </span>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
