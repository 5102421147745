import { sportableColors } from '../../constants/sportableColors'
import { Sport } from '../../metrics_server/sports/data_types'
import { isSoccer } from '../../metrics_server/sports/functions'
import { FormattedTeam } from '../../metrics_server/teams/types'
import styles from '../../components/Strack/StrackOverlays/Teams/Teams.module.scss'

export const getOverlayStyle = (
  teams: FormattedTeam[],
  index: number,
  highlightedSide: number
) => {
  const team = teams[index]
  let overlayStyle = {
    backgroundColor:
      highlightedSide === index ? sportableColors.colors.blue : 'black',
    opacity: highlightedSide === index ? 0.6 : 0.3
  }

  if (highlightedSide === null && team) {
    overlayStyle = {
      backgroundColor: team.color,
      opacity: 0.3
    }
  }

  return overlayStyle
}

export const getAFLTeamNameColor = (
  teams: FormattedTeam[],
  index: number,
  highlightedSide: number
) => {
  const team = teams[index]

  const teamColor =
    highlightedSide === index ? sportableColors.colors.blue : undefined

  if (highlightedSide === null && team) {
    return team.color
  }
  return teamColor
}

export const highlightTeamName = (
  teams: FormattedTeam[],
  index: number,
  highlightedSide: number,
  sport: Sport
) => {
  if (!isSoccer(sport)) return undefined
  const team = teams[index]
  let overlayStyle = {
    color: highlightedSide === index ? sportableColors.colors.blue : 'white',
    opacity: highlightedSide === index ? 1 : 0.6
  }

  if (highlightedSide === null && team) {
    overlayStyle = {
      color: team.color,
      opacity: 0.6
    }
  }

  return overlayStyle
}

export const placeHolderLogo = (color) => (
  <div className={styles.teamImg}>
    <svg viewBox='0 0 46 50' height={'25px'} version='1.1'>
      <defs />
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Placeholder/team'
          transform='translate(-2.000000, 0.000000)'
          strokeWidth='2'
        >
          <g id='Group' transform='translate(3.000000, 2.000000)'>
            <path
              stroke={color || 'black'}
              d='M44,11.5 C44,28.3666667 36.6666667,39.8666667 22,46 C7.33333333,39.8666667 1.05167746e-15,28.3666667 0,11.5 L22,0 L44,11.5 Z'
              id='Path-3'
              fill='#FFFFFF'
            />
            <path
              stroke={color || 'black'}
              d='M22,42.0628377 C27.6726224,39.5180015 32.2147397,35.6056758 35.6263519,30.3258606'
              id='Path-16'
            />
            <path
              stroke={color || 'black'}
              d='M5.50059699,22.6050326 C4.82812972,20.4344044 4.32793073,17.8993935 4,15 L22,5.77165568 L25.7541204,7.80200704'
              id='Path-15'
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
)
