import {
  Bar,
  CartesianGrid,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  Legend
} from 'recharts'
import {
  Card,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  LabelledInput
} from '@frontend/ui'
import { useLanguage } from '@frontend/sportable'

const textData = {
  metric: {
    en: 'Metric',
    fr: 'Métrique'
  },
  lastSession: {
    en: 'Last session',
    fr: 'Dernière session'
  },
  dark: {
    en: 'Dark',
    fr: 'Sombre'
  },
  system: {
    en: 'System',
    fr: 'Système'
  }
}

// Sample data - replace with your actual data
const data = [
  { date: '2024-01-01', daily: 120, sevenDay: 115, twentyEightDay: 100 },
  { date: '2024-01-02', daily: 45, sevenDay: 110, twentyEightDay: 95 },
  { date: '2024-01-03', daily: 25, sevenDay: 100, twentyEightDay: 90 },
  { date: '2024-01-04', daily: 95, sevenDay: 95, twentyEightDay: 95 },
  { date: '2024-01-05', daily: 65, sevenDay: 98, twentyEightDay: 98 },
  { date: '2024-01-06', daily: 130, sevenDay: 102, twentyEightDay: 97 },
  { date: '2024-01-01', daily: 120, sevenDay: 115, twentyEightDay: 100 },
  { date: '2024-01-02', daily: 45, sevenDay: 110, twentyEightDay: 95 },
  { date: '2024-01-03', daily: 25, sevenDay: 100, twentyEightDay: 90 },
  { date: '2024-01-04', daily: 95, sevenDay: 95, twentyEightDay: 95 },
  { date: '2024-01-05', daily: 65, sevenDay: 98, twentyEightDay: 98 },
  { date: '2024-01-06', daily: 130, sevenDay: 102, twentyEightDay: 97 },
  { date: '2024-01-01', daily: 120, sevenDay: 115, twentyEightDay: 100 },
  { date: '2024-01-02', daily: 45, sevenDay: 110, twentyEightDay: 95 },
  { date: '2024-01-03', daily: 25, sevenDay: 100, twentyEightDay: 90 },
  { date: '2024-01-04', daily: 95, sevenDay: 95, twentyEightDay: 95 },
  { date: '2024-01-05', daily: 65, sevenDay: 98, twentyEightDay: 98 },
  { date: '2024-01-06', daily: 130, sevenDay: 102, twentyEightDay: 97 },
  { date: '2024-01-01', daily: 120, sevenDay: 115, twentyEightDay: 100 },
  { date: '2024-01-02', daily: 45, sevenDay: 110, twentyEightDay: 95 },
  { date: '2024-01-03', daily: 25, sevenDay: 100, twentyEightDay: 90 },
  { date: '2024-01-04', daily: 95, sevenDay: 95, twentyEightDay: 95 },
  { date: '2024-01-05', daily: 65, sevenDay: 98, twentyEightDay: 98 },
  { date: '2024-01-06', daily: 130, sevenDay: 102, twentyEightDay: 97 }
  // Add more data points as needed
]

const legend: {
  [key: string]: { color: string; label: { en: string; fr: string } }
} = {
  daily: {
    color: '#00b0f0',
    label: {
      en: 'Daily',
      fr: 'Quotidien'
    }
  },
  sevenDay: {
    color: '#32d74b',
    label: {
      en: '7-day avg.',
      fr: 'Moyenne sur 7 jours'
    }
  },
  twentyEightDay: {
    color: '#ff453a',
    label: {
      en: 'Rolling 28-day avg.',
      fr: 'Moyenne mobile sur 28 jours'
    }
  }
}

const fontSize = 12

type MetricChartProps = {
  className?: string
}

export function MetricChart({ className }: MetricChartProps) {
  const language = useLanguage()

  return (
    <Card className='h-full p-8'>
      <div className='flex items-center justify-between'>
        {/* Stats By Select */}
        <LabelledInput label={textData.metric[language]} className='mr-6'>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder='' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='light'>
                {textData.lastSession[language]}
              </SelectItem>
              <SelectItem value='dark'>{textData.dark[language]}</SelectItem>
              <SelectItem value='system'>
                {textData.system[language]}
              </SelectItem>
            </SelectContent>
          </Select>
        </LabelledInput>
      </div>
      <div className={'h-[460px] pt-16'}>
        <ResponsiveContainer width='100%' height='100%'>
          <ComposedChart
            data={data || []}
            margin={{
              top: 0,
              right: 50,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis dataKey='date' tick={{ fontSize }} />
            <YAxis tick={{ fontSize }} />
            <Tooltip />
            <Legend content={<RenderLegend />} verticalAlign='top' />
            <Area
              dot={false}
              type='monotone'
              dataKey='twentyEightDay'
              stroke='#ff453a'
              fill='#ff453a'
              fillOpacity={0.2}
            />
            <Bar dataKey='daily' barSize={20} fill='#00b0f0' />
            <Line
              dot={false}
              type='monotone'
              dataKey='sevenDay'
              stroke='#32d74b'
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Card>
  )
}
export const RenderLegend = (props: any) => {
  const { payload } = props

  const language = useLanguage()

  return (
    <div className='absolute right-0 -top-10 flex items-center gap-4 text-sm'>
      {payload.map((entry: any) => {
        const data = legend[entry.value]
        return (
          <div className='flex items-center gap-2'>
            <div
              style={{ background: data.color }}
              className='h-3 w-3 rounded-full'
            />
            <span>{data.label[language]}</span>
          </div>
        )
      })}
    </div>
  )
}
