import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import * as Sentry from '@sentry/react'
import { JsonForm } from '../Forms/JsonForm/JsonForm'

interface FaultyFormattedEventProps {
  err: Error
  formattedEvent: any
}

export const FaultyFormattedEvent = ({
  err,
  formattedEvent
}: FaultyFormattedEventProps) => {
  const er = `event ${formattedEvent?.id} failed to render: ${JSON.stringify(
    formattedEvent
  )}\n${err}`
  console.error(er)
  Sentry.captureException(er)
  return (
    <Sentry.ErrorBoundary>
      <Accordion style={{ overflowY: 'scroll' }}>
        <AccordionSummary>
          <h3>
            Something has gone really wrong. This event is broken and will need
            investigation.
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <div>${err.message}</div>
            <div>${err.stack}</div>
            <JsonForm
              stylingEnabled={false}
              disableFileInput={true}
              defaultValue={formattedEvent}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Sentry.ErrorBoundary>
  )
}
