import React, { useCallback } from 'react'
import { useInsightsFormLogic } from './InsightsTable.logic'

import { CommentatorButton } from '../../CommentatorButton/CommentatorButton'
import { ColumnForm } from '../ColumnForm/ColumnForm'
import { Toggle } from '../Fields/Toggle/Toggle'
import MultipleSelectChip from '../../Material/MultipleSelect'
import BasicSelect from '../../Material/Select'
import BasicTextField from '../../Material/Text'
import BasicTabs from '../../Material/Tabs'
import styles from './InsightsTableForm.module.scss'
import {
  InsightsTableFormTypeKey,
  InsightsTableFormTypeValues,
  InsightsTableConfig
} from '../../../metrics_server/insights/types'
import { aggregationOperatorTypes } from '../../../metrics_server/metrics/aggregation_operators/data_types'
import { useMetricOptionsWithUnits } from '../../../metrics_server/metrics/hooks'
import { UnitsState } from '../../../metrics_server/units/types'
import { InsightsTableColumnForm } from './ColumnForm/ColumnForm'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'

export interface InsightsTableFormProps {
  configId: string
  handleProceed: (tableConfig: InsightsTableConfig) => void
  handleSecondaryButton: () => void
}

export const InsightsTableForm = (props: InsightsTableFormProps) => {
  const { handleProceed, handleSecondaryButton, configId } = props

  const { sport, flightMetrics, flightTypes, players } =
    useSelectedFormattedSession()
  const unitSystem = useUnitsSystem(sport)

  const metricOptionsWithUnits = useMetricOptionsWithUnits(
    flightMetrics,
    unitSystem
  )

  const {
    state,
    dispatch,
    updateTableConfig,
    updateRequest,
    formType,
    setFormType
  } = useInsightsFormLogic(configId)

  const recentEventsFlightType = flightTypes.getTypeByValue(
    state.requests[InsightsTableFormTypeKey.recentEvents].flightType
  )
  const topNFlightType = flightTypes.getTypeByValue(
    state.requests[InsightsTableFormTypeKey.topN].flightType
  )

  const renderFormButtons = useCallback(() => {
    return (
      <div className={styles.formButtons}>
        <CommentatorButton
          title='save'
          handleClick={() =>
            handleProceed({
              ...state.tableConfig,
              config: state.requests[formType]
            })
          }
        />
        <CommentatorButton title='cancel' handleClick={handleSecondaryButton} />
      </div>
    )
  }, [state])

  const checkTableType = useCallback(
    (type: InsightsTableFormTypeValues) => {
      return formType === type
    },
    [formType]
  )

  return (
    <div className={styles.commentatorModalFormWrapper}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h4 className={styles.title}>Create Table</h4>
        <div style={{ width: '60%' }}>
          <Toggle
            size='s'
            input={{
              value: formType,
              onChange: (value) => setFormType(value)
            }}
            options={[
              {
                name: 'Recent Events',
                value: InsightsTableFormTypeKey.recentEvents
              },
              { name: 'Top N', value: InsightsTableFormTypeKey.topN },
              { name: 'Custom', value: InsightsTableFormTypeKey.custom }
            ]}
            toggleColor='#55A55E'
          />
        </div>
      </div>
      <div className={styles.formContainer}>
        <BasicTextField
          label='Name'
          value={state.tableConfig.name}
          onChange={(value) => dispatch(updateTableConfig({ name: value }))}
          mb={2}
          size='small'
        />
        {checkTableType('TopN') && (
          <div>
            <BasicTextField
              label='Length'
              value={state.requests[InsightsTableFormTypeKey.topN].length}
              onChange={(value) =>
                dispatch(
                  updateRequest(InsightsTableFormTypeKey.topN, {
                    length: parseInt(value)
                  })
                )
              }
              type='number'
              mb={2}
              size='small'
            />
            <BasicSelect
              label='Flight type'
              options={flightTypes.options}
              selected={
                state.requests[InsightsTableFormTypeKey.topN].flightType
              }
              onChange={(value) => {
                if (
                  value !==
                  state.requests[InsightsTableFormTypeKey.topN].flightType
                ) {
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.topN, {
                      flightType: value
                    })
                  )
                }
              }}
              mb={2}
              size='small'
            />
            {topNFlightType.props.types?.options.length > 0 && (
              <MultipleSelectChip
                label='Flight sub type'
                options={topNFlightType.props.types?.options}
                selected={
                  state.requests[InsightsTableFormTypeKey.topN].flightSubTypes
                }
                onChange={(selected) => {
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.topN, {
                      flightSubTypes: selected
                    })
                  )
                }}
                mb={2}
                size='small'
              />
            )}
            <BasicSelect
              label='Aggregation'
              options={aggregationOperatorTypes.options}
              selected={
                state.requests[InsightsTableFormTypeKey.topN].columnQuery[0]
                  ?.aggregation
              }
              onChange={(value) => {
                dispatch(
                  updateRequest(InsightsTableFormTypeKey.topN, {
                    columnQuery: state.requests[
                      InsightsTableFormTypeKey.topN
                    ].columnQuery.map((metric) => ({
                      ...metric,
                      aggregation: value
                    }))
                  })
                )
              }}
              mb={2}
              size='small'
            />
            <ColumnForm
              title={'Select Metric:'}
              options={metricOptionsWithUnits}
              selected={state.requests[
                InsightsTableFormTypeKey.topN
              ].columnQuery.map((cq) => cq.stat)}
              maxSelections={1}
              handleChange={(selected) => {
                dispatch(
                  updateRequest(InsightsTableFormTypeKey.topN, {
                    columnQuery: selected.map((metric) => ({
                      stat: metric,
                      aggregation:
                        state.requests[InsightsTableFormTypeKey.topN]
                          .columnQuery[0]?.aggregation || 'NoOp'
                    }))
                  })
                )
              }}
            />
          </div>
        )}
        {checkTableType('RecentEvents') && (
          <div>
            <BasicTextField
              label='Length'
              value={
                state.requests[InsightsTableFormTypeKey.recentEvents].length
              }
              onChange={(value) =>
                dispatch(
                  updateRequest(InsightsTableFormTypeKey.recentEvents, {
                    length: parseInt(value)
                  })
                )
              }
              type='number'
              mb={2}
              size='small'
            />
            <BasicSelect
              label='Event type'
              options={flightTypes.options}
              selected={
                state.requests[InsightsTableFormTypeKey.recentEvents].flightType
              }
              onChange={(value) => {
                const flightType = flightTypes.getTypeByValue(value)
                if (
                  flightType.value !==
                  state.requests[InsightsTableFormTypeKey.recentEvents]
                    .flightType
                ) {
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.recentEvents, {
                      flightType: flightType.value
                    })
                  )
                }
              }}
              mb={2}
              size='small'
            />
            {recentEventsFlightType.props.types?.options.length > 0 && (
              <MultipleSelectChip
                label='Kick type'
                options={recentEventsFlightType.props.types?.options || []}
                selected={
                  state.requests[InsightsTableFormTypeKey.recentEvents]
                    .flightSubTypes
                }
                onChange={(selected) => {
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.recentEvents, {
                      flightSubTypes: selected
                    })
                  )
                }}
                mb={2}
                size='small'
              />
            )}
            <ColumnForm
              title={'Select Metrics:'}
              options={metricOptionsWithUnits}
              selected={state.requests[
                InsightsTableFormTypeKey.recentEvents
              ].columnQuery.map((cq) => cq.stat)}
              maxSelections={10}
              handleChange={(selected) => {
                dispatch(
                  updateRequest(InsightsTableFormTypeKey.recentEvents, {
                    columnQuery: selected.map((metric) => ({
                      stat: metric,
                      aggregation: 'NoOp'
                    }))
                  })
                )
              }}
            />
          </div>
        )}
        {checkTableType('Custom') && (
          <BasicTabs tabs={['Rows', 'Columns']}>
            <div>
              {/* <BasicSelect
                label='Type'
                options={[
                  { name: 'All', value: 'All' },
                  { name: 'Teams', value: 'Teams' },
                  { name: 'Players', value: 'Players' }
                ]}
                selected={
                  state.requests[InsightsTableFormTypeKey.custom].tableRows
                    .allTeams
                }
                onChange={(value) => {
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.custom, {
                      tableRows: {
                        ...state.requests[InsightsTableFormTypeKey.custom]
                          .tableRows,
                        allTeams: value
                      }
                    })
                  )
                }}
                mb={2}
                size='small'
              /> */}
              <MultipleSelectChip
                label='Players'
                options={players.All.options}
                selected={
                  state.requests[InsightsTableFormTypeKey.custom].tableRows
                    .playerIds
                }
                onChange={(selected) => {
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.custom, {
                      tableRows: {
                        ...state.requests[InsightsTableFormTypeKey.custom]
                          .tableRows,
                        playerIds: selected
                      }
                    })
                  )
                }}
                mb={2}
                size='small'
              />
            </div>
            <div style={{ width: '100%' }}>
              <InsightsTableColumnForm
                sport={sport}
                initialColumns={
                  state.requests[InsightsTableFormTypeKey.custom].columnQuery
                }
                onChange={(columns) => {
                  // const updatedColumns =
                  //   convertColumnRequestKeysToValues(columns)
                  dispatch(
                    updateRequest(InsightsTableFormTypeKey.custom, {
                      columnQuery: columns
                    })
                  )
                }}
              />
            </div>
          </BasicTabs>
        )}
      </div>

      <div>{renderFormButtons()}</div>
    </div>
  )
}
