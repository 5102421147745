import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../Button/Button'

import styles from './Error.module.scss'

import { HandleClose, HandleProceed } from '../../ui/modal/types'
import { ErrorState } from '../../ui/error/types'
import { ExclamationIcon } from '../Icons/ExclamationIcon/ExclamationIcon'

interface ErrorMessageProps {
  error: ErrorState
  handleProceed?: HandleProceed
  handleClose?: HandleClose
  nonReduxError: ErrorState
}

export class ErrorMessage extends Component<ErrorMessageProps> {
  render() {
    const { error, nonReduxError } = this.props

    const warningError = error.type === 'warning'
    const imageSizeError = error.type === 'oversized image'

    return (
      <div className={`${styles.errorContainer} bg-background`}>
        <h3 className={`${styles.errorTitle} text-foreground`}>
          {warningError
            ? 'Are you sure you want to proceed?'
            : imageSizeError
            ? 'Error uploading image'
            : 'Oops, something went wrong...'}
        </h3>
        <ExclamationIcon />
        <h5 className={styles.errorMessage}>
          {nonReduxError ? nonReduxError.message : error.message}
        </h5>
        {!nonReduxError && (
          <h5 className={styles.errorMessage}>
            {imageSizeError
              ? 'File exceeds size limits. Please upload an image under 400kb.'
              : 'Please contact support@sportable.com for more help.'}
          </h5>
        )}
        {!nonReduxError && (
          <Button
            handleClick={this.props.handleProceed}
            className='btn--primary'
            type='button'
          >
            Ok
          </Button>
        )}
        {!nonReduxError && error.type === 'warning' && (
          <Button
            handleClick={this.props.handleClose}
            className='btn--plain'
            type='button'
          >
            Cancel
          </Button>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.error
  }
}

export default connect(mapStateToProps)(ErrorMessage)
