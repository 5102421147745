import { useMemo } from 'react'
import { useSelectedFormattedSession } from '../../sessions/hooks'
import { getActiveStoppage, getCurrentPeriod } from './functions'
import { EventType, EventTypeKeys } from '../types/data_types'
import { useEvents } from '../hooks'

export function useTimeEvents() {
  // Session //
  const { timeEventTypes } = useSelectedFormattedSession()
  // ======= //

  const { rawData } = useEvents()

  const activeStoppage = getActiveStoppage(Object.values(rawData))

  const { currentPeriod, nextPeriodTimeEventType, isCurrentPeriodActive } =
    useCurrentPeriod()

  const isFullTime =
    currentPeriod && !nextPeriodTimeEventType && currentPeriod.rawValues.endTime

  return {
    activeStoppage,
    isFullTime,
    currentPeriod,
    isCurrentPeriodActive,
    nextPeriodTimeEventType,
    timeEventTypes
  }
}

export function useCurrentPeriod() {
  // Session //
  const { sport } = useSelectedFormattedSession()
  const timeEventTypes = sport.props.parentEventTypes.items.time.props.types

  const { rawData } = useEvents()
  // ======= //

  const currentPeriod = useMemo(() => {
    return getCurrentPeriod(Object.values(rawData))
  }, [rawData])

  const nextPeriodTimeEventType = useMemo(() => {
    let nextPeriodType: EventType

    for (const key in timeEventTypes.items) {
      const timeEventType = timeEventTypes.items[key as EventTypeKeys]
      if (timeEventType.props.features.period) {
        // If there is no current period, then the next period is the first period //
        if (!currentPeriod) {
          nextPeriodType = timeEventType
          break
        }

        // If the current period is a time event, then the next period is the next time event //
        if (timeEventType.value > currentPeriod?.rawData.type) {
          nextPeriodType = timeEventType
          break
        }
      }
    }

    return nextPeriodType
  }, [currentPeriod])

  const isCurrentPeriodActive = currentPeriod
    ? !currentPeriod.rawValues.endTime
    : false

  return {
    currentPeriod,
    nextPeriodTimeEventType,
    isCurrentPeriodActive
  }
}
