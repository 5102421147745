import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { useFormattedSession } from '../sessions/hooks'
import { formatEventData } from './functions'
import {
  EMPTY_PROCESSED_EVENT,
  FormattedEventData,
  ProcessedRawEventData
} from './types'
import { useUnitsSystem } from '../units/hooks'
import { Group } from '../types'
import { addItemToGroup, getEmptyGroup } from '../functions'

export const useEvents = () => {
  const events = useAppSelector((state) => state.events)
  return events
}

export const useSelectedEvent = () => {
  const { selectedEventId, rawData } = useEvents()
  return rawData[selectedEventId] || EMPTY_PROCESSED_EVENT
}

export const useSelectedFormattedEvent = () => {
  const { selectedEventId } = useEvents()
  return useFormattedEvent(selectedEventId)
}

export const useFormattedEvent = (eventId: string) => {
  // Get raw event data //
  const { rawData } = useEvents()
  const event = rawData[eventId]

  // Get session data + unit system //
  const sessionId = event?.sessionId
  const formattedSession = useFormattedSession(sessionId)
  const unitSystem = useUnitsSystem(formattedSession?.sport)

  // Format and memoize event //
  const formattedEvent = useMemo(() => {
    if (formattedSession && formattedSession.id === sessionId && unitSystem) {
      return formatEventData(event, formattedSession, unitSystem)
    }
  }, [event, formattedSession, unitSystem])

  return formattedEvent
}

export const useFormattedEvents = (
  events: ProcessedRawEventData[],
  sessionId: string
) => {
  const formattedSession = useFormattedSession(sessionId)
  const { sport } = formattedSession
  const unitSystem = useUnitsSystem(sport)

  const [formattedEvents, setFormattedEvents] = useState<
    Group<FormattedEventData>
  >(getEmptyGroup<FormattedEventData>())

  // Update events if session or unit system changes //
  useEffect(() => {
    const newFormattedEvents = getEmptyGroup<FormattedEventData>()
    if (unitSystem && formattedSession) {
      events.forEach((event) => {
        const formattedEvent = formatEventData(
          event,
          formattedSession,
          unitSystem
        )
        if (formattedEvent) {
          addItemToGroup(
            newFormattedEvents,
            formattedEvent,
            formattedEvent.id,
            'id',
            formattedEvent.id
          )
        }
      })
    }
    setFormattedEvents(newFormattedEvents)
  }, [unitSystem, formattedSession])

  // Only format new or updated events by storing events in ref //
  const eventsRef = useRef({})
  useEffect(() => {
    const newFormattedEvents = getEmptyGroup<FormattedEventData>()
    events.forEach((event) => {
      const eventRef = eventsRef.current[event.id]
      const currentFormattedEvent = formattedEvents.map[event.id]
      const formattedEvent =
        eventRef !== event || !currentFormattedEvent
          ? formatEventData(event, formattedSession, unitSystem)
          : currentFormattedEvent

      if (formattedEvent) {
        addItemToGroup(
          newFormattedEvents,
          formattedEvent,
          formattedEvent.id,
          'id',
          formattedEvent.id
        )
      }

      eventsRef.current[event.id] = event
    })
    setFormattedEvents(newFormattedEvents)
  }, [events])

  return formattedEvents
}
