import { SessionConfig } from '../../sessions/types'
import { RawTimeEventData } from './types'
import { parentEventTypes } from '../data_types'
import { ProcessedRawEventData } from '../types'
import { eventTypes } from '../types/data_types'

export const getCurrentPeriod = (
  events: ProcessedRawEventData[]
): ProcessedRawEventData => {
  let currentPeriod: ProcessedRawEventData

  events.forEach((event) => {
    if (event)
      if (!currentPeriod && event.processedValues.type.props.features?.period) {
        return (currentPeriod = event)
      }
    if (
      currentPeriod &&
      event.processedValues.type.props.features?.period &&
      event.rawValues.type > currentPeriod.rawValues.type
    )
      currentPeriod = event
  })

  return currentPeriod
}

export const getStoppages = (
  events: ProcessedRawEventData[]
): ProcessedRawEventData[] => {
  return events.filter((event) => {
    if (event.eventTypeValue !== parentEventTypes.items.time.value) return false
    return event.rawValues.type === eventTypes.items.stoppage.value
  })
}

export const getActiveStoppage = (
  events: ProcessedRawEventData[]
): ProcessedRawEventData => {
  const stoppages = getStoppages(events)
  return stoppages.find((x) => {
    if (x.eventTypeValue !== parentEventTypes.items.time.value) return false
    if (!x.rawValues.endTime) {
      return true
    }
    return false
  })
}
