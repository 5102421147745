import {
  GET_APP,
  GET_DEAMON_VERSION,
  GET_HEALTH,
  GET_SERVER_VERSION,
  SET_MS_CONNECTION_STATUS,
  SET_MS_ERRORS,
  SET_LANGUAGE,
  SWITCH_UI
} from './action_types'

import { VersionState } from './types'

const regionMap = {
  London: 'london',
  Ireland: 'ireland',
  Sydney: 'sydney'
}

function getWebAppDomain(app) {
  const { Env, Region } = app
  return `match-tracker.${Env}.${regionMap[Region]}.sportable.com`
}

const initialState: VersionState = {
  ui: null,
  uiType: null,
  server: null,
  fetched: false,
  health: null,
  connected: false,
  app: {
    Product: null,
    Env: null,
    FeatureFlags: null,
    Region: null,
    Mode: null,
    EnableAutoSleepToggle: true,
    UnitSystems: [],
    GeniusIntegration: false,
    Algorithms: {
      SeatSwapping: {
        Enabled: true
      }
    },
    AFLWIntegration: {
      Enabled: false
    }
  },
  webAppDomain: null,
  deamon: null,
  errors: null,
  msVersion: null,
  language: 'en'
}

export default function (state = initialState, action) {
  let webAppDomain, app
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      }
    case GET_SERVER_VERSION:
      return {
        ...state,
        msVersion: action.payload,
        server: `${action.payload}`,
        fetched: true
      }
    case GET_APP:
      app = action.payload
      webAppDomain = getWebAppDomain(app)
      return {
        ...state,
        app: action.payload,
        webAppDomain,
        server: `${state.msVersion} (${app.Env})`
      }

    case GET_HEALTH:
      return {
        ...state,
        health: action.payload
      }

    case SET_MS_CONNECTION_STATUS:
      return {
        ...state,
        connected: action.payload
      }

    case SET_MS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }

    case SWITCH_UI:
      window.localStorage.setItem('uiTypeKey', action.payload.key)
      return { ...state, uiType: action.payload }
    case GET_DEAMON_VERSION:
      return {
        ...state,
        deamon: `${action.payload}`
      }
    default:
      return state
  }
}
