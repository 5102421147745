import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  CollapsibleSection,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  LabelledInput,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@frontend/ui'
import {
  useAppDispatch,
  setRedirect,
  useRouter,
  useTeams,
  teamsActions,
  signoutUser,
  useLanguage,
  setLanguage
} from '@frontend/sportable'
import { useEffect, useMemo, useState } from 'react'

export const textData = {
  dashboard: {
    en: 'Dashboard',
    fr: 'Tableau de bord'
  },
  player: {
    en: 'Player',
    fr: 'Joueur'
  },
  teamSelect: {
    en: 'Team Select',
    fr: "Sélection d'équipe"
  },
  liveSession: {
    en: 'Live Session',
    fr: 'Session en direct'
  },
  currentlyEmpty: {
    en: 'Currently empty',
    fr: 'Actuellement vide'
  },
  theme: {
    en: 'Theme',
    fr: 'Thème'
  },
  lightTheme: {
    en: 'Light',
    fr: 'Clair'
  },
  darkTheme: {
    en: 'Dark',
    fr: 'Sombre'
  },
  language: {
    en: 'Language',
    fr: 'Langue'
  },
  logOut: {
    en: 'Log out',
    fr: 'Se déconnecter'
  },
  selectTeamPlaceholder: {
    en: 'Select a team',
    fr: 'Sélectionnez une équipe'
  }
}

export function ReportingHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const language = useLanguage()

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark')

  useEffect(() => {
    document.body.classList.remove('light', 'dark')
    document.body.classList.add(`${theme}`)

    if (typeof theme === 'string') {
      localStorage.setItem('theme', theme)
    }
  }, [theme])

  const menuItems = useMemo(() => {
    return [
      {
        label: textData.dashboard[language],
        value: '/dashboard',
        type: 'link',
        callback: (value) => {
          dispatch(setRedirect(value))
        }
      },
      {
        label: textData.player[language],
        value: '/player',
        type: 'link',
        callback: (value) => {
          dispatch(setRedirect(value))
        }
      }
    ]
  }, [language])

  const selectedRoute = router.redirect || router.initialRoute

  return (
    <div className='hidden flex-col md:flex bg-secondary'>
      <div>
        <div className='flex h-16 items-center px-8'>
          <Avatar className='h-8 w-8 mr-4'>
            <AvatarImage src='/avatars/01.png' alt='@shadcn' />
            <AvatarFallback className='bg-primary text-primary-foreground'>
              TH
            </AvatarFallback>
          </Avatar>
          <NavigationMenu className='mx-6'>
            <NavigationMenuList>
              {menuItems.map((item) => (
                <NavigationMenuItem key={item.value}>
                  <Button
                    onClick={() => item.callback(item.value)}
                    variant='link'
                    className={`${
                      selectedRoute === item.value
                        ? 'text-foreground underline'
                        : 'text-foreground'
                    }`}
                  >
                    {item.label}
                  </Button>
                </NavigationMenuItem>
              ))}
              <NavigationMenuItem>
                <NavigationMenuTrigger disabled>
                  {textData.liveSession[language]}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <NavigationMenuLink>
                    {textData.currentlyEmpty[language]}
                  </NavigationMenuLink>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <div className='text-sm ml-auto flex items-center space-x-6'>
            <p>Tom Duckworth</p>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant='ghost'
                  className='relative h-8 w-8 rounded-full'
                >
                  <Avatar className='h-8 w-8'>
                    <AvatarImage src='/avatars/01.png' alt='@shadcn' />
                    <AvatarFallback className='bg-background'>
                      SC
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='w-56' align='end' forceMount>
                <DropdownMenuLabel className='font-normal'>
                  <div className='flex flex-col space-y-1'>
                    <p className='text-sm font-medium leading-none'>
                      Tom Duckworth
                    </p>
                    <p className='text-xs leading-none text-muted-foreground'>
                      tom@sportable.com
                    </p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuLabel className='font-normal'>
                  {textData.theme[language]}
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                  value={theme}
                  onValueChange={(value) => {
                    setTheme(value)
                  }}
                >
                  <DropdownMenuRadioItem value='light'>
                    {textData.lightTheme[language]}
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value='dark'>
                    {textData.darkTheme[language]}
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
                <DropdownMenuLabel className='font-normal'>
                  {textData.language[language]}
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                  value={language}
                  onValueChange={(value) => {
                    dispatch(setLanguage(value))
                  }}
                >
                  <DropdownMenuRadioItem value='en'>EN</DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value='fr'>FR</DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
                <DropdownMenuItem onClick={() => dispatch(signoutUser())}>
                  {textData.logOut[language]}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CollapsibleTeamSelectHeader = ({
  header,
  message
}: {
  header: string
  message: string
}) => {
  const teams = useTeams()
  const dispatch = useAppDispatch()
  const language = useLanguage()

  useEffect(() => {
    if (teams.selectedTeam) {
      // dispatch(teamsActions.getTeamPlayers(teams.selectedTeam))
    }
  }, [teams.selectedTeam])

  const [isOpen, setIsOpen] = useState(
    localStorage.getItem('collapsible-header-open') === 'false' ? false : true
  )

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      localStorage.setItem('collapsible-header-open', isOpen.toString())
    }
  }, [isOpen])

  return (
    <div className='flex-1 space-y-4 p-8 pt-8'>
      <CollapsibleSection isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className='flex items-center justify-between space-y-2 pb-5'>
          <div className='space-y-2'>
            <h1 className='text-5xl leading-loose'>{header}</h1>
            <h2 className='text-3xl'>{message}</h2>
          </div>
          <LabelledInput label={textData.teamSelect[language]}>
            <Select
              value={teams.selectedTeam}
              onValueChange={(value) => dispatch(teamsActions.setTeam(value))}
            >
              <SelectTrigger className='w-full'>
                <SelectValue
                  placeholder={textData.selectTeamPlaceholder[language]}
                />
              </SelectTrigger>
              <SelectContent>
                {teams.options.map((team) => (
                  <SelectItem key={team.id} value={team.id}>
                    {team.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </LabelledInput>
        </div>
      </CollapsibleSection>
    </div>
  )
}
