import { getPercentValue } from '../../utils/helpers/index'
import {
  MetricTypeConfig,
  generateMetricTypeClass
} from '../metrics/data_types'
import { FormattedPlayerSession } from '../sessions/types'
import { Sport } from '../sports/data_types'
import { UnitSystem } from '../units/types'
import { Challenge, OperatorsState, Target, TargetsState } from './types'

export const getSelectedTarget = (
  challenge: Challenge,
  challenges: TargetsState
) => {
  return challenge.Targets?.find(
    (target) => target.id === challenges.selected.selectedTarget
  )
}

export const operatorTypes = {
  greaterThan: 2,
  lessThan: 1
}

export type TargetMetricTableRow = {
  id: string
  key: MetricTypeConfig['key']
  name: MetricTypeConfig['name']
  operator: number
  percentage: boolean
  value: number | string
}

// Format data for target setup metric table
export const generateMetricTableRow = (
  target: Target,
  metricInfo: MetricTypeConfig,
  percentage = false,
  unitSystem: UnitSystem,
  operators: OperatorsState
): TargetMetricTableRow => {
  const row = {} as TargetMetricTableRow
  const metricKey = metricInfo.key
  const metricType = generateMetricTypeClass(metricInfo)
  const name = metricType.getMetricDisplayWithUnits(unitSystem, 9)
  row.name = name
  row.key = metricKey
  row.percentage = percentage
  row.id = `${target.id}-${metricKey}`
  if (target[metricKey]) {
    if (target[metricKey].greaterThan) {
      row.value = percentage
        ? // floating point number error workaround. This equates to * 100
          getPercentValue(target[metricKey].greaterThan)
        : target[metricKey].greaterThan
      row.operator = operatorTypes.greaterThan
    } else if (target[metricKey].lessThan) {
      row.value = percentage
        ? // floating point number error workaround. This equates to * 100
          getPercentValue(target[metricKey].lessThan)
        : target[metricKey].lessThan
      row.operator = operatorTypes.lessThan
    } else {
      row.value = ''
      row.operator = operators[metricKey]
    }
  } else {
    row.value = ''
    row.operator = operators[metricKey]
  }
  return row
}

export const getTargetMetricsTableData = (
  target: Target,
  unitSystem: UnitSystem,
  operators: OperatorsState,
  sport: Sport
) => {
  const data = []
  if (target) {
    const flightMetrics =
      sport.props.parentEventTypes.items.flight.props.metricTypes

    if (flightMetrics.items.hangTime) {
      data.push(
        generateMetricTableRow(
          target,
          flightMetrics.items.hangTime,
          false,
          unitSystem,
          operators
        )
      )
    }
    if (flightMetrics.items.dist) {
      data.push(
        generateMetricTableRow(
          target,
          flightMetrics.items.dist,
          false,
          unitSystem,
          operators
        )
      )
    }
    if (flightMetrics.items.efficiency) {
      data.push(
        generateMetricTableRow(
          target,
          flightMetrics.items.efficiency,
          true,
          unitSystem,
          operators
        )
      )
    }
    if (flightMetrics.items.endOverEndEfficiency) {
      data.push(
        generateMetricTableRow(
          target,
          flightMetrics.items.endOverEndEfficiency,
          true,
          unitSystem,
          operators
        )
      )
    }
    if (flightMetrics.items.spin) {
      data.push(
        generateMetricTableRow(
          target,
          flightMetrics.items.spin,
          false,
          unitSystem,
          operators
        )
      )
    }
  }
  return data
}

// Format data for target setup radius table
export const getTargetRadiusTableData = (target) => {
  const data = []
  if (target) {
    if (!target.radii) return data
    target.radii.forEach((radius: number, i: number) => {
      let color
      switch (i) {
        case 0:
          color = 'rgba(244, 247, 99, 0.5)'
          break
        case 1:
          color = 'rgba(100,0,0,0.5)'
          break
        case 2:
          color = 'rgba(0,0,100,0.5)'
          break
        default:
          break
      }
      data.push({
        id: i,
        area: color,
        radius
      })
    })
  }
  return data
}

// Format data for target summary metrics table
export const getTargetSummaryMetricsTableRowData = (
  target: Target,
  playerSession: FormattedPlayerSession
) => {
  const { player } = playerSession
  const row = {
    id: playerSession.id,
    player: player.firstName
  }
  for (const statKey in target.targetSummary[playerSession.id].statsTable) {
    const stat = target.targetSummary[playerSession.id].statsTable[statKey]
    if (
      target[statKey] &&
      (target[statKey].greaterThan || target[statKey].lessThan)
    ) {
      row[statKey] = `${(stat * 100).toFixed(0)}%`
    } else {
      row[statKey] = '-'
    }
  }
  return row
}

// Format data for target summary performance table
export const getTargetSummaryPerformanceTableRowData = (
  target: Target,
  playerSession: FormattedPlayerSession
) => {
  const { player } = playerSession
  const row = {
    id: playerSession.id,
    player: player.firstName
  }
  for (const statKey in target.targetSummary[playerSession.id].accuracyTable) {
    const stat = target.targetSummary[playerSession.id].accuracyTable[statKey]
    if (statKey === 'averageDist') {
      if (stat === 0) {
        row[statKey] = null
      } else {
        row[statKey] = `${stat.toFixed(2)}m`
      }
    } else if (statKey === 'count') {
      row[statKey] = `${stat}`
    } else {
      row[statKey] = `${(stat * 100).toFixed(0)}%`
    }
  }
  return row
}
