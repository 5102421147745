import { UnitsState, UnitSystem } from './types'
import { Sport } from '../sports/data_types'
import { getSport } from '../sports/functions'
import * as unitSystems from './unit_systems'
import { SessionsState } from '../sessions/types'

// Get unit system from state and session id //
export function getUnitSystemFromState(
  sessionId: string,
  { sessions, units }: { sessions: SessionsState; units: UnitsState }
) {
  const session = sessions.rawData[sessionId]
  const sport = getSport(session.sportType)
  const unitSystem = getUnitSystem(units, sport)

  return unitSystem
}

// Get unit system
export function getUnitSystem(units: UnitsState, sport: Sport): UnitSystem {
  const selectedUnitSystem = units.systemOptions.find(
    (option) => option.value === units.selected
  )
  const sportUnitSystem = units.systemOptions.find(
    (option) => sport && option.value === sport.props.features.defaultUnitSystem
  )
  // Use default unit system if selected is 'Default' and sport has a default unit system that is available
  // Otherwise use selected unit system if available
  // Otherwise use SI (Always available)
  if (units.selected === 'Default' && sportUnitSystem) {
    return unitSystems[sportUnitSystem.value]
  } else if (selectedUnitSystem) {
    return unitSystems[selectedUnitSystem.value]
  } else {
    return unitSystems.SI
  }
}

// used to get unit system from session in various actions files
export function getUnitSystemFromSession(session, units) {
  const sport = getSport(session.sportType)
  const unitSystem = getUnitSystem(units, sport)

  return unitSystem
}
