'use client'

import * as React from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'

import { ArrowUpDown, ChevronDown } from 'lucide-react'

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Input,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  ScrollableTabs
} from '@frontend/ui'

import {
  Star,
  ArrowUpRight,
  ArrowDownRight,
  Target,
  Move,
  Swords,
  Shield,
  Boxes,
  CrosshairIcon,
  Grab,
  Goal,
  MonitorIcon as Running,
  Flag,
  BellIcon as Whistle,
  Clock,
  Users,
  Zap,
  Award,
  TrendingUp,
  BarChart2,
  Percent
} from 'lucide-react'
import {
  createGroupFromList,
  formatMetric,
  generateMetricTypeClass,
  useFormattedSession,
  useFormattedTeam,
  useLanguage,
  useTeams,
  useUnitsSystem
} from '@frontend/sportable'

const textData = {
  inputPlaceholder: {
    en: 'Filter...',
    fr: 'Filtrer...'
  },
  columns: {
    en: 'Columns',
    fr: 'Colonnes'
  },
  noResults: {
    en: 'No results.',
    fr: 'Aucun résultat.'
  },
  tableHeaders: {
    number: {
      en: '#',
      fr: '#'
    },
    player: {
      en: 'Player',
      fr: 'Joueur'
    },
    position: {
      en: 'Pos.',
      fr: 'Poste'
    }
  }
}

const iconStringMap = {
  pass: ArrowUpRight,
  soccerPass: ArrowDownRight,
  soccerShot: Target,
  soccerCarry: Move,
  soccerCross: CrosshairIcon,
  soccerCorner: Clock,
  soccerThrowIn: Users,
  soccerKickOff: Zap,
  soccerDeflection: Award,
  snapFlightEvent: Zap,
  kick: Goal,
  sprint: Running,
  offside: Flag,
  foul: Whistle,
  possession: Clock,
  formation: Users,
  pressure: Zap,
  performance: Award,
  progression: TrendingUp,
  xg: BarChart2,
  conversion: Percent
}

interface PlayerComparisonTableProps {
  sessionId: string
  className?: string

  playerId: string
  comparisonPlayerIds: string[]
}

const getRandomInt = (min = 50, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getRandomDecimal = (min = 50, max = 100, decimals = 1) => {
  const factor = Math.pow(10, decimals)
  return Math.round((Math.random() * (max - min) + min) * factor) / factor
}

const generateDummyMetrics = () => {
  return {
    totalForwardPasses: getRandomInt(),
    forwardPassesCompletionRate: getRandomDecimal(),
    totalBackwardPasses: getRandomInt(),
    backwardPassesCompletionRate: getRandomDecimal(),
    totalSidewaysPasses: getRandomInt(),
    sidewaysPassesCompletionRate: getRandomDecimal(),

    averageThrowDistance: getRandomDecimal(),
    averageSpeed: getRandomDecimal(),
    averageSpin: getRandomDecimal(),
    averageEfficiency: getRandomDecimal(),
    totalPasses: getRandomInt(),
    totalPassCompletion: getRandomDecimal()
  }
}

const selectedPlayerMetrics = generateDummyMetrics()

export const PlayerComparisonTable = ({
  sessionId,
  playerId,
  comparisonPlayerIds
}: PlayerComparisonTableProps) => {
  const language = useLanguage()

  const teamsState = useTeams()
  const formattedTeam = useFormattedTeam(teamsState.selectedTeam)

  const formattedSession = useFormattedSession(sessionId)
  const unitSystem = useUnitsSystem(formattedSession.sport)

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const tableConfig = formattedSession.sport?.props.reportMetricConfig
    ?.playerComparisonTable?.pass || {
    metrics: []
  }

  const comparisonPlayerDummyMetrics = React.useMemo(() => {
    const metrics = {}
    comparisonPlayerIds.forEach((playerId) => {
      metrics[playerId] = generateDummyMetrics()
    })
    return metrics
  }, [comparisonPlayerIds])

  const data = React.useMemo(() => {
    const rows = []
    comparisonPlayerIds.forEach((playerId) => {
      const player = formattedTeam.players.map[playerId]
      console.log(player)
      rows.push({
        ...comparisonPlayerDummyMetrics[playerId],
        name: player.fullName,
        position: player.position,
        number: player.number,
        id: playerId
      })
    })
    return rows
  }, [formattedSession, comparisonPlayerDummyMetrics])

  const columns: ColumnDef<
    {
      [metricKey: string]: number | string
    } & {
      name: string
      position: string
      number: string
    }
  >[] = React.useMemo(() => {
    const columns: ColumnDef<
      {
        [metricKey: string]: number | string
      } & {
        name: string
        position: string
        number: string
      }
    >[] = [
      {
        accessorKey: 'number',
        header: textData.tableHeaders.number[language],
        cell: ({ row }) => <div>{row.getValue('number')}</div>
      },
      {
        accessorKey: 'name',
        header: textData.tableHeaders.player[language],
        cell: ({ row }) => <div>{row.getValue('name')}</div>
      },
      {
        accessorKey: 'position',
        header: textData.tableHeaders.position[language],
        cell: ({ row }) => <div>{row.getValue('position')}</div>
      }
    ]

    tableConfig.metrics.forEach((metricKeyPair) => {
      const metricKey = metricKeyPair[0]
      const secondaryMetricKey = metricKeyPair[1]

      console.log(metricKey, secondaryMetricKey)

      if (!formattedSession.sport) return

      console.log(formattedSession)

      const metricType = formattedSession.sport.props.metrics.items[metricKey]
      const metricInfo = generateMetricTypeClass(metricType)

      if (!metricInfo) return

      const metricHeader = metricInfo.getMetricDisplayWithUnits(
        unitSystem,
        null,
        false,
        language
      )

      const secondaryMetricType =
        formattedSession.sport.props.metrics.items[secondaryMetricKey]

      const secondaryMetricInfo = generateMetricTypeClass(secondaryMetricType)

      if (!secondaryMetricInfo) return

      const secondaryMetricHeader =
        secondaryMetricInfo.getMetricDisplayWithUnits(
          unitSystem,
          null,
          true,
          language
        )

      const selectedPlayerMetricValue = selectedPlayerMetrics[metricKey]

      columns.push({
        accessorKey: metricKey,

        getUniqueValues: () => ['select'],

        header: ({ column }) => {
          return (
            <Button
              variant='ghost'
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
              className='w-full px-0 text-left justify-between whitespace-normal'
            >
              {metricHeader}{' '}
              {secondaryMetricHeader && `(${secondaryMetricHeader})`}
              <ArrowUpDown className='w-4' />
            </Button>
          )
        },
        cell: ({ row }) => {
          const playerId = row.original.id

          const comparisonPlayerMetricValue =
            comparisonPlayerDummyMetrics[playerId][metricKey]

          const formattedMetric = formatMetric(
            metricType,
            {
              [metricType.key]: comparisonPlayerMetricValue
            },
            formattedSession.teams,
            formattedSession.players.All,
            unitSystem,
            false,
            false
          )

          let markerPercent = null,
            barColor = null,
            valuePercent = null

          if (
            !formattedMetric.value ||
            typeof formattedMetric.value !== 'number'
          ) {
            return {
              valuePercent,
              markerPercent,
              barColor
            }
          }

          valuePercent = (formattedMetric.value / 110) * 100

          if (
            !formattedMetric.value ||
            typeof formattedMetric.value !== 'number'
          ) {
            return {
              valuePercent,
              markerPercent,
              barColor
            }
          }

          if (selectedPlayerMetricValue) {
            markerPercent = (selectedPlayerMetricValue / 110) * 100

            // Determine color based on value compared to average
            barColor = 'bg-yellow-500'

            if (formattedMetric.value >= selectedPlayerMetricValue) {
              barColor = 'bg-green-500'
            }
            const percentBelowAverage =
              ((selectedPlayerMetricValue - formattedMetric.value) /
                selectedPlayerMetricValue) *
              100
            if (percentBelowAverage > 50) {
              barColor = 'bg-red-500'
            }
          }

          return (
            <div className='inline w-1/12'>
              {row.getValue(metricKey) ? row.getValue(metricKey) : '-'}{' '}
              {secondaryMetricKey && row.original[secondaryMetricKey]
                ? `(${row.original[secondaryMetricKey]})`
                : ''}
              {valuePercent && (
                <div className='absolute left-0 bottom-0 w-full h-1 bg-muted bg-background'>
                  <div
                    className={`absolute left-0 top-0 h-full ${barColor}`}
                    style={{ width: `${valuePercent}%` }}
                  />
                  {markerPercent && (
                    <div
                      className='absolute top-0 w-2 h-full bg-foreground/80'
                      style={{ left: `${markerPercent}%` }}
                    />
                  )}
                </div>
              )}
            </div>
          )
        }
      })
    })

    return columns
  }, [formattedSession, comparisonPlayerDummyMetrics])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection
    }
  })

  const scrollableTabs = React.useMemo(() => {
    if (!formattedSession.sport?.props.parentEventTypes?.items.flight) return []
    return Object.values(
      formattedSession.sport.props.parentEventTypes.items.flight.props.types
        .items
    ).map((eventType: any) => {
      console.log(eventType)
      let name = eventType.name

      if (name && typeof name === 'object') {
        name = eventType.name[language] || 'No Name Found'
      }

      return {
        value: eventType.key,
        label: eventType.name[language] || eventType.name,
        icon: iconStringMap[eventType.key] || Star
      }
    })
  }, [formattedSession, language])

  const columnsCount = 3 + tableConfig.metrics.length

  return (
    <div className='w-full'>
      <div className='pb-8'>
        <ScrollableTabs tabs={scrollableTabs} />
      </div>
      <div className='flex items-center py-4'>
        <Input
          placeholder={textData.inputPlaceholder[language]}
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
          className='max-w-sm'
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline' className='ml-auto'>
              {textData.columns[language]} <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                const HeaderComponent = column.columnDef.header
                if (typeof HeaderComponent === 'function') {
                  const headerLabel =
                    HeaderComponent({} as any)?.props.children?.[0] || column.id
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className='capitalize'
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {headerLabel}
                    </DropdownMenuCheckboxItem>
                  )
                }
                return null
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className='p-8 w-full'>
        <Table className=' table-fixed w-full'>
          <TableHeader className='w-full'>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className='w-full' key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  // Calculate width //
                  let width
                  if (index === 0) {
                    width = '2.5%'
                  } else {
                    width = 97.5 / columnsCount + '%'
                  }

                  return (
                    <TableHead style={{ width }} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  rowContrasting
                  isTotalRow={index === data.length - 1}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className='relative' key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  {textData.noResults[language]}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
