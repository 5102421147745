export namespace AppTypeKey {
  export const matchTracker = 'matchTracker'
  export const commentatorTool = 'commentatorTool'
  export const reportingTool = 'reportingTool'
}
export namespace AppName {
  export const matchTracker = 'Match Tracker'
  export const commentatorTool = 'Commentator Tool'
  export const reportingTool = 'Reporting Tool'
}

export type AppTypeKeys = keyof typeof AppTypeKey
export type AppTypeValues = (typeof AppTypeKey)[keyof typeof AppTypeKey]

export type AppType = {
  name: string
  value: AppTypeValues
}

export type AppTypes = {
  [key in AppTypeKeys]: AppType
}
