import React, { useState, useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import {
  useInsights,
  useSelectedLayout
} from '../../metrics_server/insights/hooks'
import { useSportscaster } from '../../metrics_server/sportscaster/hooks'
import { requestInsightsData } from '../../metrics_server/insights/actions'
import {
  getInsightsTableData,
  getInsightsTableHeaders
} from '../../metrics_server/insights/functions'

import { ReactGridItem } from '../../metrics_server/insights/types'
import { CardList } from '../CardList/CardList'

import styles from './InsightsCard.module.scss'
import { cardColorSchemes } from '../../metrics_server/insights/data'
import { TeamLogo } from '../TeamLogo/TeamLogo'
import { SessionData } from '../../metrics_server/sessions/types'

export interface InsightsCardProps {
  layoutItem: ReactGridItem
  contentId: string
  session: SessionData
  removeItem
}

export const InsightsCard = ({
  layoutItem,
  contentId,
  session,
  removeItem
}: InsightsCardProps) => {
  const [pulse, setPulse] = useState(false)

  const { locationName } = useSportscaster()
  const { cards, dataFetchQueue, layouts } = useInsights()
  const dispatch = useAppDispatch()
  const { configs, data } = cards
  const cardConfig = configs[contentId]
  const cardData = data[contentId]

  useEffect(() => {
    if (cardConfig && locationName) {
      // SET PLAYER IDS FROM NUMBERS
      const data = { ...cardConfig }
      const { groupBy } = data.config
      if (groupBy.numbers && groupBy.numbers.length > 0) {
        groupBy.ids = groupBy.numbers.map((number, index) => {
          // TODO: Use formatted session //
          let playerSession = null
          if (!playerSession)
            playerSession = session.playersSessions.find((ps) => ps.playerId)
          return playerSession.playerId
        })
      }

      dispatch(requestInsightsData(cardConfig, session.id, 'card'))
    }
  }, [
    cardConfig,
    dataFetchQueue[cardData ? cardData.shortId : null],
    locationName
  ])

  const handlePulse = () => {
    setPulse(true)
    setTimeout(() => {
      setPulse(false)
    }, 2000)
  }

  let cardColorScheme = cardColorSchemes[cardConfig.colour]

  if (!cardColorScheme) cardColorScheme = cardColorSchemes[0]

  // if (type === 'head2Head') {
  if (true) {
    return (
      <CardList
        // key={`card-${layoutItem.contentId}-${layoutItem.i}`}
        col={12}
        items={[{}]}
        scrollerId={`scroller-${layoutItem.i}`}
        className='maxHeight'
        backgroundColor={cardColorScheme.backgroundColor}
      >
        <div className={styles.cardInner}>
          {cardData ? (
            <div
              className={
                pulse
                  ? styles.CommentatorWidgetContainerPulse
                  : styles.CommentatorWidgetContainer
              }
              key={cardData.id}
            >
              <div className={styles.CommentatorWidgetHeader}>
                <h4
                  className={styles.CommentatorWidgetTitle}
                  style={{ color: cardColorScheme.titleColor }}
                >
                  {cardConfig.name}
                </h4>
                <p
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    marginRight: '5px'
                  }}
                  onClick={removeItem}
                >
                  x
                </p>
              </div>
              <div className={styles.statContainer}>
                {cardData.stats &&
                  cardData.stats.map((stat, i, array) => {
                    return (
                      <div className={styles.innerStatContainer} key={i}>
                        <p
                          className={styles.CommentatorWidgetValue}
                          style={{ color: cardColorScheme.statColor }}
                        >
                          {stat.stat.value} {stat.stat.unit}
                        </p>

                        <div
                          className={
                            styles.CommentatorWidgetAttributionContainer
                          }
                        >
                          <p
                            className={styles.CommentatorWidgetAttributionName}
                          >
                            {stat.heading}
                          </p>
                          {/* LOGO */}
                          {cardConfig.config.groupBy.type === 'Teams' && (
                            <TeamLogo color={'red'} logo={null} size={17} />
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          ) : (
            <h5>No data</h5>
          )}
        </div>
      </CardList>
    )
  }
  // return (
  //   <div
  //     className={
  //       pulse
  //         ? styles.CommentatorWidgetContainerPulse
  //         : styles.CommentatorWidgetContainer
  //     }
  //     onClick={handlePulse}
  //     key={item.id}
  //   >
  //     <h4 className={styles.CommentatorWidgetTitle}>{item.title}</h4>
  //     <div className={styles.statContainer}>
  //       <h1 className={styles.CommentatorWidgetValue}>14m</h1>
  //       <div>
  //         {/* LOGO */}
  //         <div
  //           style={{
  //             width: 30,
  //             height: 30,
  //             backgroundColor: 'black',
  //             margin: '0 auto'
  //           }}
  //         />
  //         <h5 className={styles.CommentatorWidgetValueAttribution}>
  //           Rory Collins
  //         </h5>
  //       </div>
  //     </div>
  //   </div>
  // )
}
