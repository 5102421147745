import { useState, useReducer } from 'react'
import { useInsights } from '../../../metrics_server/insights/hooks'
import {
  InsightsCardConfig,
  InsightsCardRequest
} from '../../../metrics_server/insights/types'

export const useInsightsFormLogic = (configId: string) => {
  const { cards } = useInsights()

  const selectedCardConfig = cards.configs[configId]

  type State = {
    cardConfig: InsightsCardConfig
  }

  const actionTypes = {
    UPDATE_CARD_CONFIG: 'UPDATE_CARD_CONFIG',
    UPDATE_REQUEST: 'UPDATE_REQUEST'
  }

  type ActionTypeKeys = keyof typeof actionTypes

  type Action = { type: ActionTypeKeys; payload }

  function updateCardConfig(value): Action {
    return {
      type: 'UPDATE_CARD_CONFIG',
      payload: value
    }
  }

  function updateRequest(data): Action {
    return {
      type: 'UPDATE_REQUEST',
      payload: data
    }
  }

  function insightsRequestReducer(state: State, action: Action): State {
    switch (action.type) {
      case actionTypes.UPDATE_CARD_CONFIG:
        return {
          ...state,
          cardConfig: {
            ...state.cardConfig,
            ...action.payload
          }
        }
      case actionTypes.UPDATE_REQUEST:
        return {
          ...state,
          cardConfig: {
            ...state.cardConfig,
            config: {
              ...state.cardConfig.config,
              ...action.payload
            }
          }
        }
      default:
        return state
    }
  }

  const initialState: State = {
    cardConfig: {
      userId: null,
      id: configId,
      name: selectedCardConfig?.name || '',
      colour: null,
      config: selectedCardConfig
        ? selectedCardConfig.config
        : ({
            flightType: 0,
            flightSubTypes: [],
            groupBy: {
              type: selectedCardConfig?.config.groupBy.type || 'All',
              ids: [],
              numbers: []
            },
            aggregation: 'NoOp',
            stat: 'Distance'
          } as InsightsCardRequest)
    }
  }

  const [state, dispatch] = useReducer(insightsRequestReducer, initialState)

  return {
    state,
    dispatch,
    updateCardConfig,
    updateRequest
  }
}
