import React from 'react'
import { useAppDispatch } from '../../store/hooks'
import bin from '../../assets/img/bin.svg'
import {
  deleteTarget,
  setSelectedTarget
} from '../../metrics_server/targets/actions'
import { Target } from '../../metrics_server/targets/types'
import { useUnitsSystem } from '../../metrics_server/units/hooks'
import { getPercentValue } from '../../utils/helpers'
import { ProfileIcon } from '../Icons/ProfileIcon/ProfileIcon'
import styles from './TargetCard.module.scss'
import { useSelectedFormattedSession } from '../../metrics_server/sessions/hooks'

export interface TargetCardProps {
  item?: Target
  size?: string
}

export const TargetCard = ({ item, size }: TargetCardProps) => {
  const dispatch = useAppDispatch()
  const { sport } = useSelectedFormattedSession()

  const unitSystem = useUnitsSystem(sport)

  const statObjs = {
    hangTime: {
      unit: 's',
      name: 'Hang Time'
    },
    dist: {
      unit: unitSystem.units.distance.abbreviation,
      name: 'Distance'
    },
    efficiency: {
      unit: '%',
      name: 'Spiral'
    },
    endOverEndEfficiency: {
      unit: '%',
      name: 'EoE'
    },
    spin: {
      unit: unitSystem.units.revs.abbreviation,
      name: 'Spin'
    }
  }

  const renderStat = ({ stat, key }) => {
    const statObj = statObjs[key]
    let value = stat.greaterThan ? stat.greaterThan : stat.lessThan
    if (statObj.unit === '%') value = getPercentValue(value)
    return (
      <div key={key}>
        {statObj.name}{' '}
        {`${stat.greaterThan ? '>' : '<'} ${value}${statObj.unit}`}
      </div>
    )
  }

  const renderStats = (target) => {
    const leftColumn = []
    const rightColumn = []
    let count = 0
    for (const targetKey in target) {
      // TODO hack for end over end targets
      if (targetKey === 'end_over_end_efficiency') continue
      const stat = target[targetKey]
      if (stat && (stat.greaterThan || stat.lessThan)) {
        if (count % 2 === 0) {
          leftColumn.push({ stat, key: targetKey })
        } else {
          rightColumn.push({ stat, key: targetKey })
        }
        count++
      }
    }
    if (leftColumn.length < 1) {
      return <div className={styles.noStats}>No metrics set</div>
    } else {
      return (
        <React.Fragment>
          <div
            style={{ height: `${(leftColumn.length / 3) * 100}%` }}
            className={styles.stats}
          >
            {leftColumn.map(renderStat)}
          </div>
          <div
            style={{ height: `${(rightColumn.length / 3) * 100}%` }}
            className={styles.stats}
          >
            {rightColumn.map(renderStat)}
          </div>
        </React.Fragment>
      )
    }
  }

  if (size === 'large') {
    return (
      <div className={`${styles.targetCard} ${styles.large} ${styles.dark}`}>
        <div className={styles.cardHeader}>
          <h5>{item.name}</h5>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.players}>
            <ProfileIcon color={'#F64645'} scale={0.5} />
            <p>
              {item.targetPlayerSessions ? item.targetPlayerSessions.length : 0}
            </p>
          </div>
          {renderStats(item)}
        </div>
      </div>
    )
  } else {
    return (
      <div
        onClick={() => dispatch(setSelectedTarget(item.id))}
        className={styles.targetCard}
      >
        <div className={styles.cardHeader}>
          <h5>{item.name}</h5>
          <img
            className={styles.bin}
            src={bin}
            alt='bin'
            onClick={() => dispatch(deleteTarget(item.id, item.subSessionId))}
          />
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.players}>
            <ProfileIcon color={'#F64645'} scale={0.5} />
            <p>
              {item.targetPlayerSessions ? item.targetPlayerSessions.length : 0}
            </p>
          </div>
          {renderStats(item)}
        </div>
      </div>
    )
  }
}
