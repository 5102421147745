import _ from 'lodash'
import { timestampToFomattedDate } from '../../utils/helpers'
import { FormattedPlayer, PlayerData, RawPlayerData } from '../players/types'
import { AppTeam, FormattedTeam, RawTeamData, Team } from '../teams/types'
import {
  SessionsState,
  SessionPlayers,
  SessionSetup,
  FormattedSessionPlayers,
  FormattedSession,
  RawSessionData,
  RawTeamSessionData,
  FormattedPlayerSession,
  RawPlayerSessionData,
  FormattedTeamSession,
  EMPTY_FORMATTED_SESSION
} from './types'
import { SessionTypeValues, sessionTypes } from './data_types'
import { formatTeam, formatTeams } from '../teams/functions'
import { getSport } from '../sports/functions'
import { sessionModeTypes } from './modes/data_types'
import { Group, IDMap } from '../types'
import { subSessionTypes } from './sub_sessions/data_types'
import { sessionStateTypes } from './states/data_types'
import { Anchor, Pitch } from '../pitches/types'
import { FormattedHardware } from '../hardware/types'
import { SessionBroadcastIntergrationCompletion } from '../broadcast_integration/functions'
import { getStrackPitchAndAnchorConfig } from '../../utils/strack/functions'
import { RawBroadcastingState } from '../broadcasting/types'
import moment from '../../utils/moment'
import { sportTypeKey } from '../sports'
import { AppTypeCheck } from '../user/types'
import { RootState } from '../../store'
import { addItemToGroup, getEmptyGroup } from '../functions'
import {
  EventTypeGroup,
  EventTypeKeys,
  EventTypes,
  getEventTypeGroup
} from '../events/types/data_types'

export function formatTeamSession(
  teamSession: RawTeamSessionData,
  team: FormattedTeam,
  oppositionTeamId?: string
): FormattedTeamSession {
  return {
    id: teamSession.id,
    teamId: teamSession.teamId,
    sessionId: teamSession.sessionId,
    homeAway: teamSession.homeAway,
    team,
    rawData: teamSession,
    oppositionTeamId
  }
}

export function formatTeamSessions(
  teamsSessions: RawTeamSessionData[],
  formattedTeams: Group<FormattedTeam>
): Group<FormattedTeamSession> {
  console.log(teamsSessions, formattedTeams)
  const formattedTeamSessions = getEmptyGroup<FormattedTeamSession>()
  teamsSessions.forEach((teamSession) => {
    const team = formattedTeams.map[teamSession.teamId]
    const oppositionTeamSession = teamsSessions.find((ts) => {
      return ts.teamId !== teamSession.teamId
    })
    const formattedTeamSession = formatTeamSession(
      teamSession,
      team,
      oppositionTeamSession?.teamId
    )
    addItemToGroup(
      formattedTeamSessions,
      formattedTeamSession,
      teamSession.teamId,
      'id',
      team.name
    )
  })
  return formattedTeamSessions
}

export function formatPlayerSession(
  playerSession: RawPlayerSessionData,
  player?: FormattedPlayer
): FormattedPlayerSession {
  // Overwrite player number with session number
  const newPlayerObject = player ? { ...player } : null
  if (newPlayerObject) {
    newPlayerObject.number = playerSession.number
    newPlayerObject.nameAndNumber = `${player.firstName} ${player.lastName} - ${playerSession.number}`
  }
  return {
    id: playerSession.id,
    number: playerSession.number,
    playerId: playerSession.playerId,
    teamId: playerSession.teamId,
    sessionId: playerSession.sessionId,
    hardwareId: playerSession.tag?.id,
    hardware: playerSession.tag,
    player: newPlayerObject
  }
}

export function formatPlayerSessions(
  playersSessions: RawPlayerSessionData[],
  teams: Group<FormattedTeam>,
  officiationTeam: FormattedTeam
): {
  byId: Group<FormattedPlayerSession>
  byHardwareId: Group<FormattedPlayerSession, number>
  byPlayerId: Group<FormattedPlayerSession>
  byNumber: { [teamId: string]: Group<FormattedPlayerSession, number> }
  balls: Group<FormattedPlayerSession>
} {
  // Generate empty groups //
  const byId = getEmptyGroup<FormattedPlayerSession>(),
    byHardwareId = getEmptyGroup<FormattedPlayerSession, number>(),
    byPlayerId = getEmptyGroup<FormattedPlayerSession>(),
    byNumber = {} as {
      [teamId: string]: Group<FormattedPlayerSession, number>
    },
    balls = getEmptyGroup<FormattedPlayerSession>()

  // Sort player sessions into relevant groups //
  playersSessions.forEach((playerSession) => {
    let team = teams.map[playerSession.teamId]

    // Check if it's an official (no team found in teamsSessions but has a teamId) //
    // TODO: this is going to cause issues with the new archive team //
    if (!team && playerSession.teamId) team = officiationTeam

    // Format player session //
    const formattedPlayerSession = formatPlayerSession(
      playerSession,
      team?.players.map[playerSession.playerId]
    )

    // Add player session to general group (byId) //
    addItemToGroup(
      byId,
      formattedPlayerSession,
      playerSession.id,
      'id',
      playerSession.id
    )

    // If the player session is linked to hardware - add to hardware group (byHardwareId) //
    if (formattedPlayerSession.hardwareId) {
      addItemToGroup(
        byHardwareId,
        formattedPlayerSession,
        formattedPlayerSession.hardwareId.toString(),
        'hardwareId',
        formattedPlayerSession.hardware?.serial
      )
    }

    // If the player session is linked to a player - add to player group (byPlayerId) //
    if (formattedPlayerSession.player) {
      addItemToGroup(
        byPlayerId,
        formattedPlayerSession,
        formattedPlayerSession.playerId,
        'id',
        formattedPlayerSession.player?.fullName
      )
    }
  })

  // Add player sessions to teams by number //
  teams.list.forEach((team) => {
    const players = team.players
    byNumber[team.id] = getEmptyGroup<FormattedPlayerSession, number>()
    playersSessions.forEach((playerSession) => {
      const player = players.map[playerSession.playerId]
      if (!player) return
      const formattedPlayerSession = formatPlayerSession(playerSession, player)

      // If the player session is part of a team and has a number - Add player session to team group (byNumber) //
      if (
        formattedPlayerSession.teamId === team.id &&
        formattedPlayerSession.number
      ) {
        addItemToGroup(
          byNumber[team.id],
          formattedPlayerSession,
          formattedPlayerSession.number.toString(),
          'number',
          formattedPlayerSession.player?.fullName
        )
      }
    })
  })

  // Add player sessions to officiation team by number //
  if (officiationTeam) {
    const players = officiationTeam.players
    byNumber[officiationTeam.id] = getEmptyGroup<
      FormattedPlayerSession,
      number
    >()
    playersSessions.forEach((playerSession) => {
      const player = players.map[playerSession.playerId]
      if (!player) return
      const formattedPlayerSession = formatPlayerSession(playerSession, player)

      // If the player session is part of a team and has a number - Add player session to team group (byNumber) //
      if (
        formattedPlayerSession.teamId === officiationTeam.id &&
        formattedPlayerSession.number
      ) {
        addItemToGroup(
          byNumber[officiationTeam.id],
          formattedPlayerSession,
          formattedPlayerSession.number.toString(),
          'number',
          formattedPlayerSession.id
        )
      }
    })
  }

  // Add balls to balls group //
  playersSessions.forEach((playerSession) => {
    if (playerSession.tag?.id && !playerSession.playerId) {
      const formattedPlayerSession = formatPlayerSession(playerSession, null)
      addItemToGroup(
        balls,
        formattedPlayerSession,
        formattedPlayerSession.hardwareId.toString(),
        'id',
        formattedPlayerSession.hardware?.serial
      )
    }
  })

  return {
    byId,
    byHardwareId,
    byPlayerId,
    byNumber,
    balls
  }
}

export const filterGameEventsForOfficiation = (
  gameEventTypes: EventTypeGroup,
  isOfficiatingMode: boolean
): EventTypeGroup => {
  const gameEventTypesConfig: Partial<EventTypes> = {}

  for (const key in gameEventTypes.items) {
    const gameEventType = gameEventTypes.items[key as EventTypeKeys]
    if (
      gameEventType.props.features.officiatingModeOnly &&
      !isOfficiatingMode
    ) {
      continue
    }
    gameEventTypesConfig[key] = gameEventType
  }

  return getEventTypeGroup(gameEventTypesConfig)
}

export function formatSessionData(
  session: RawSessionData,
  rawTeams: IDMap<RawTeamData>,
  rawPlayers: IDMap<RawPlayerData>,
  live: boolean,
  pitchInUse: Pitch,
  rawOfficiationTeam: RawTeamData
): FormattedSession {
  if (!session) return EMPTY_FORMATTED_SESSION

  try {
    const teamsArray = session.teamsSessions.map(
      (teamsSession) => rawTeams[teamsSession.teamId] || teamsSession.team
    )

    const playersArray = session.playersSessions
      .filter((playerSession) => playerSession.player)
      .map(
        (playerSession) =>
          rawPlayers[playerSession.playerId] || {
            ...playerSession.player,
            teamId: playerSession.teamId
          }
      )

    const teams = formatTeams(teamsArray, playersArray)

    const officiationTeam = rawOfficiationTeam
      ? formatTeam(rawOfficiationTeam, playersArray)
      : null

    // Get sport from session sportType //
    const sport = getSport(session.sportType)
    // ================================= //
    const isTrainingMode = isTraining(session.type)
    const isMatchMode = isMatch(session.type)

    // Teams and Players //
    const teamsSessions = formatTeamSessions(session.teamsSessions, teams)
    const playersSessions = formatPlayerSessions(
      session.playersSessions,
      teams,
      officiationTeam
    )
    const players = {
      All: getEmptyGroup<FormattedPlayer>()
    }
    teams.list.forEach((team) => {
      players[team.id] = getEmptyGroup<FormattedPlayer>()
      team.players.list.forEach((player) => {
        addItemToGroup(
          players.All,
          player,
          player.id,
          'id',
          player.nameAndNumber
        )
        addItemToGroup(
          players[team.id],
          player,
          player.id,
          'id',
          player.nameAndNumber
        )
      })
    })

    // Add officiation players - this is a bit hacky //
    if (officiationTeam) {
      players[officiationTeam.id] = getEmptyGroup<FormattedPlayer>()
      officiationTeam.players.list.forEach((player) => {
        addItemToGroup(
          players[officiationTeam.id],
          player,
          player.id,
          'id',
          player.nameAndNumber
        )
      })
    }

    // ================================= //

    // Get home and away team ids //
    let homeTeamId, awayTeamId
    teamsSessions.list.forEach((teamSession, index) => {
      if (teamSession.homeAway === 'HOME') {
        homeTeamId = teamSession.teamId
      } else if (teamSession.homeAway === 'AWAY') {
        awayTeamId = teamSession.teamId
      } else {
        if (index === 0) {
          homeTeamId = teamSession.teamId
        } else if (index === 1) {
          awayTeamId = teamSession.teamId
        }
      }
    })
    // ================================= //

    // Event Types //
    const parentEventTypes = sport.props.parentEventTypes
    // Flight //
    const flightTypes = sport.props.parentEventTypes.items.flight.props.types
    const flightMetrics =
      sport.props.parentEventTypes.items.flight.props.metricTypes
    // Game //
    const gameEventTypes = filterGameEventsForOfficiation(
      sport.props.parentEventTypes.items.game.props.types,
      session.officiatingAlgosEnable
    )
    const gameEventMetrics =
      sport.props.parentEventTypes.items.game.props.metricTypes
    // Time //
    const timeEventTypes = sport.props.parentEventTypes.items.time.props.types
    // Australian Rules //
    const australianRulesEventTypes =
      sport.props.parentEventTypes.items.aussieRules?.props.types
    const australianRulesEventMetrics =
      sport.props.parentEventTypes.items.aussieRules?.props.metricTypes
    // ================================= //

    // Player Metrics //
    const playerSummaryMetricTypes = sport.props.playerSummaryMetricTypes

    // Pitch and anchors //
    const { pitch, anchorConfig } = getStrackPitchAndAnchorConfig(
      session.pitch || pitchInUse,
      session.sportType
    )
    const anchors = getEmptyGroup<Anchor>()
    for (const anchorId in anchorConfig) {
      const anchor = anchorConfig[anchorId]
      addItemToGroup(anchors, anchor, anchor.id, 'id', anchor.serial)
    }
    // ================================= //

    // Status //
    const isSessionUploaded =
      session.uploadStatus?.dump || session.uploadStatus?.daemon
    // ================================= //

    const returnData = {
      sessionData: session,

      isSetup: !session.id,

      id: session.id || 'setup',
      name: session.name,
      startTime: timestampToFomattedDate(session.startTime),
      endTime: timestampToFomattedDate(session.endTime),
      sport,
      type: sessionTypes.getTypeByValue(session.type),
      subType: subSessionTypes.getTypeByValue(session.subType),
      mode: sessionModeTypes.getTypeByValue(session.mode),
      state: sessionStateTypes.getTypeByValue(session.state),
      isTrainingMode,
      isMatchMode,
      live,
      hostname: session.hostname,
      locationName: session.locationName,
      uploadStatus: session.uploadStatus,
      isSessionUploaded,
      isOfficiatingMode: session.officiatingAlgosEnable,

      // Teams and Players
      teams,
      teamsSessions,
      players: players,
      playersSessions,
      homeTeam: teams.map[homeTeamId],
      awayTeam: teams.map[awayTeamId],
      officiationTeam,

      // Player Metrics
      playerSummaryMetricTypes,

      // Balls
      balls: playersSessions.balls,

      // Event types and metrics
      parentEventTypes,
      flightTypes,
      flightMetrics,
      timeEventTypes,
      gameEventTypes,
      gameEventMetrics,
      australianRulesEventTypes,
      australianRulesEventMetrics,

      // Pitch and anchors
      pitchId: session.pitchId,
      pitch,
      anchorConfig,
      anchors,

      // Features
      homeSideCheck: sport.props.features.homeSideCheck && isMatchMode
    }
    return returnData
  } catch (e) {
    console.log('here', e)
    return EMPTY_FORMATTED_SESSION
  }
}

// format session data - turn enums into data type classes
export function formatSessionSetupData(session: SessionSetup) {
  const isTrainingMode = isTraining(session.type)

  // Get sport from session sportType
  const sport = getSport(session.sportType)

  // Get session type
  const sessionType = sessionTypes.getTypeByValue(session.type)
  const subSessionType = sessionType.props.subTypes.getTypeByValue(
    session.subType
  )
  const sessionModeType = sessionModeTypes.getTypeByValue(session.mode)

  // Create team options and teams map
  const teamMap: IDMap<Team> = _.keyBy(
    session.teamsSessions.map((teamSession) => teamSession.team),
    'id'
  )

  const teamOptions = session.teamsSessions.map((x) => {
    return {
      name: x.team.name,
      value: x.team.id
    }
  })

  // Filter players from balls
  const sessionPlayersArray = session.playersSessions.filter(
    (playerSession) => {
      if (playerSession.player?.id) {
        return playerSession.player
      }
    }
  )

  // Create player sessions object and array for select inputs
  const sessionPlayers: SessionPlayers = _.keyBy(
    sessionPlayersArray,
    'playerId'
  )
  const playerOptions = sessionPlayersArray.map((x) => {
    return {
      name: `${x.player.firstName} ${x.player.lastName} - ${x.number}`,
      value: x.playerId,
      number: x.number
    }
  })

  const playerMap: IDMap<PlayerData> = _.keyBy(
    sessionPlayersArray.map((playerSession) => ({
      ...playerSession.player,
      teamId: playerSession.teamId
    })),
    'id'
  )

  // formatted session players
  const formattedSessionPlayers: FormattedSessionPlayers = {
    options: playerOptions,
    map: {}
  }

  sessionPlayersArray.forEach((playerSession) => {
    formattedSessionPlayers.map[playerSession.playerId] = {
      playerData: playerMap[playerSession.playerId],
      playerSession
    }
  })

  // New team map
  const newTeamMap: IDMap<AppTeam> = {}

  function getOppositionTeamId(teamId: string) {
    const oppositionTeam = session.teamsSessions.find((teamSession) => {
      return teamSession.teamId !== teamId
    })
    return oppositionTeam?.teamId
  }

  session.teamsSessions.forEach((teamSession) => {
    const teamPlayersArray = sessionPlayersArray.filter(
      (player) => player.teamId === teamSession.teamId
    )
    const playerList = teamPlayersArray.map((playerSession) => ({
      ...playerSession.player,
      teamId: playerSession.teamId
    }))
    const playerOptions = teamPlayersArray.map((x) => {
      return {
        name: `${x.number}. ${x.player.firstName} ${x.player.lastName}`,
        value: x.playerId,
        number: x.number
      }
    })
    newTeamMap[teamSession.teamId] = {
      data: teamSession.team,
      players: {
        // list: playerList,
        map: _.keyBy(playerList, 'id'),
        options: playerOptions
      },
      oppositionTeamId: getOppositionTeamId(teamSession.teamId)
    }
  })

  const newPlayerMap: IDMap<AppTeam> = {}

  return {
    session,
    isTrainingMode,
    sport,
    sessionModeType,
    sessionType,
    subSessionType,
    teamOptions,
    sessionPlayers,
    playerOptions,
    playerMap,
    teamMap,
    newTeamMap,

    formattedSessionPlayers
  }
}

export function isMatch(sessionTypeValue: SessionTypeValues) {
  return sessionTypeValue === sessionTypes.items.match.value
}

export function isTraining(sessionTypeValue: SessionTypeValues) {
  return sessionTypeValue === sessionTypes.items.training.value
}

export function isActiveSessionOnDevice(
  sessionId: string,
  sessions: SessionsState
) {
  return sessions.activeSession?.id === sessionId
}

export const getSessionTeamSides = (
  broadcastState: RawBroadcastingState,
  formattedSession: FormattedSession
) => {
  if (
    broadcastState?.session.id === formattedSession.id &&
    broadcastState?.sessionControl?.sides?.[0].id &&
    broadcastState?.sessionControl?.sides?.[0].id !== '0'
  ) {
    return broadcastState.sessionControl.sides.map((side) => {
      const team = formattedSession.teams.map[side.id]
      return team
    })
  } else {
    return formattedSession.teams.list
  }
}

export const getPlayerSessionByHardwareSerial = (playersSessions, serial) => {
  return playersSessions.find((playerSession) => {
    return playerSession.tag && playerSession.tag.serial === serial
  })
}

export function formatSessionDate(unixTime) {
  if (typeof unixTime !== 'number') {
    return null // Return null if the input is not a valid Unix timestamp
  }
  const zeroIt = (num: number): string => {
    if (num < 10) {
      return `0${num}`
    }
    return `${num}`
  }

  const date = new Date(unixTime)
  const year = date.getFullYear()

  const month = zeroIt(date.getMonth() + 1)
  const day = zeroIt(date.getDate())
  const hour = zeroIt(date.getHours())
  const minute = zeroIt(date.getMinutes())
  const second = zeroIt(date.getSeconds())

  return `${day}${month}${year}-${hour}${minute}${second}`
}

// Session Setup Status //
export type StatusCheck = {
  type: 'error' | 'warning'
  message: string
}

export const getSessionSetupStatus = (
  formattedSession: FormattedSession,
  formattedHardware: FormattedHardware,
  sessionBroadcastIntegrationCompletion: SessionBroadcastIntergrationCompletion
) => {
  const status = {
    broadcastIntegration: {
      type: 'error',
      message: null
    },
    playersSessions: {
      type: 'error',
      message: null
    },
    hardwareQA: {
      type: 'warning',
      message: null
    },
    offlineBalls: {
      type: 'warning',
      message: null
    }
  }

  // Remove devices that are not included in formatted hardware (not being sent by the server)
  const playersSessionsWithHardwareList =
    formattedSession.playersSessions.byHardwareId.list.filter(
      (playerSession) => {
        return formattedHardware.devices.map[playerSession.hardwareId]
      }
    )

  // Check if broadcast integration is complete
  if (!sessionBroadcastIntegrationCompletion.isComplete) {
    status.broadcastIntegration.message =
      sessionBroadcastIntegrationCompletion.errors[0]
  }

  // Check if enough tags are selected
  if (playersSessionsWithHardwareList.length < 1) {
    status.playersSessions.message = 'Not enough tags selected'
  }

  // QA check on devices
  const qaFailDeviceList = playersSessionsWithHardwareList
    .filter((playerSession) => {
      const device = formattedHardware.devices.map[playerSession.hardwareId]
      if (!device) return false
      return (
        device.reports.fieldQA.passed === false ||
        device.reports.hwQA.passed === false ||
        device.reports.investigate.passed === true ||
        device.reports.retired.passed === true
      )
    })
    .map((playerSession) => {
      const device = formattedHardware.devices.map[playerSession.hardwareId]
      return device.serial
    })

  const generateQAMessage = (qaFailDeviceList) => {
    if (qaFailDeviceList.length === 0) return null

    if (qaFailDeviceList.length === 1) {
      return `Device ${qaFailDeviceList.join(
        ' '
      )} failed QA. Are you sure you want to start a session?`
    }

    if (qaFailDeviceList.length < 5) {
      return `These devices failed QA: ${qaFailDeviceList.join(
        ', '
      )}. Are you sure you want to start a session?`
    }

    return 'Multiple devices failed QA. Are you sure you want to start a session?'
  }

  // Check if enough tags are selected
  status.hardwareQA.message = generateQAMessage(qaFailDeviceList)

  // Check if offline balls are in the session //
  playersSessionsWithHardwareList.forEach((playerSession) => {
    const device =
      formattedHardware.types.ball.status.offline.devices.map[
        playerSession.hardwareId
      ]
    if (device)
      status.offlineBalls.message =
        'Offline balls were added to the session. Are you sure you want to start a session?'
  })

  return status
}

// Session Assignment Status //
// Check if players tags have been assigned to the session
export const hasPlayerTagsAssigned = (
  formattedSession: FormattedSession,
  formattedHarware: FormattedHardware
) => {
  return (
    formattedSession?.playersSessions.byPlayerId.list.some((playerSession) => {
      return formattedHarware.devices.map[playerSession.hardwareId]
    }) || false
  )
}

// Check if players have been assigned to the session
export const hasPlayersAssigned = (formattedSession: FormattedSession) => {
  return formattedSession?.playersSessions.byPlayerId.count > 0
}

// Check if online balls have been assigned to the session
export const hasOnlineBallsAssigned = (
  formattedSession: FormattedSession,
  formattedHarware: FormattedHardware
) => {
  return (
    formattedSession?.balls.list.some((playerSession) => {
      return (
        formattedHarware.status.online.devices.map[playerSession.hardwareId] ||
        formattedHarware.status.sleep.devices.map[playerSession.hardwareId]
      )
    }) || false
  )
}

// Check if offline balls have been assigned to the session
export const hasOfflineBallsAssigned = (
  formattedSession: FormattedSession,
  formattedHarware: FormattedHardware
) => {
  return (
    formattedSession?.balls.list.some((playerSession) => {
      return formattedHarware.status.offline.devices.map[
        playerSession.hardwareId
      ]
    }) || false
  )
}

// Check if pitch setup is complete
export const pitchSetupComplete = (
  formattedSession: FormattedSession
): boolean => {
  if (!formattedSession) return false
  return (
    !!formattedSession.pitch.coordinates && formattedSession.anchors.count > 1
  )
}
// ================================= //

// Get blank session based on permissions //
export const getBlankSession = ({
  isExternalSoccerTraining
}: AppTypeCheck): RawSessionData => {
  return {
    id: null,
    startTime: moment(new Date().getTime()).startOf('day').valueOf() / 1000,
    endTime: null,
    name: '',
    playersSessions: [],
    teamsSessions: [],
    pitch: null,
    type: sessionTypes.items.match.value,
    subType: isExternalSoccerTraining
      ? subSessionTypes.items.broadcast.value
      : subSessionTypes.items.dataGathering.value,
    mode: sessionModeTypes.items.live.value,
    sportType: isExternalSoccerTraining
      ? sportTypeKey.soccer
      : sportTypeKey.rugbyUnion,
    state: null,
    pitchId: null,
    womensMode: false,
    uploadStatus: null,
    locationName: null,
    hostname: null,
    officiatingAlgosEnable: false
  }
}

// Generate a random number from a range that is not already contained in a set of numbers
export const generateRandomNumberNotInList = (
  list: number[],
  max: number,
  initialNumber: number
) => {
  // If list is full return null
  if (list.length === max) return null

  let number = initialNumber
  let isValidNumber = false
  while (!isValidNumber) {
    if (typeof number === 'number' && !list.includes(number)) {
      isValidNumber = true
    }
    if (!isValidNumber) {
      number = Math.floor(Math.random() * max)
    }
  }

  return number
}

// Get the lowest available number greater than 0 from an array of numbers
export const getFirstAvailableNumber = (list: number[]) => {
  let number = 1
  while (list.includes(number)) {
    number++
  }
  return number
}

// Download json file containing all of the playersSessions in a session - (contains links between players and devices)
export const downloadSessionPlayersJsonFile = (filename, sessionPlayers) => {
  const jsonString = JSON.stringify(sessionPlayers, null, 2)
  const blob = new Blob([jsonString], { type: 'application/json' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
  URL.revokeObjectURL(url)
}

export const getLatestSessionBySportType = (
  sessions: IDMap<RawSessionData>,
  sportType: string,
  currentSessionId: string
): RawSessionData => {
  const sessionsArray = Object.values(sessions)
  const filteredSessions = sessionsArray.filter(
    (session: RawSessionData) =>
      session.sportType === sportType &&
      session.id !== currentSessionId &&
      isTraining(session.type)
  )

  // Sort the filtered sessions by endTime in descending order
  filteredSessions.sort(
    (a: RawSessionData, b: RawSessionData) => b.endTime - a.endTime
  )

  return filteredSessions.length > 0 ? filteredSessions[0] : null
}

// Get list of player ids in session //
export const getPlayerIdsInSession = (session: RawSessionData) => {
  if (!session?.playersSessions) return []
  return session.playersSessions
    .filter((playerSession) => playerSession.playerId)
    .map((playerSession) => playerSession.playerId)
}

// Get formatted session from state //
export function getFormattedSession(
  sessionId: string,
  {
    teams,
    players,
    pitches,
    sessions
  }: {
    teams: RootState['teams']
    players: RootState['players']
    pitches: RootState['pitches']
    sessions: RootState['sessions']
  }
) {
  const session = sessions.rawData[sessionId]

  const officiationTeam = Object.values(teams.rawData).find(
    (team) => team?.type === 'match_officials'
  )

  return formatSessionData(
    session,
    teams.rawData,
    players.rawData,
    sessions.activeSession?.id === session?.id,
    pitches.inUse,
    officiationTeam
  )
}
