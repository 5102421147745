import { Item } from './item-tile'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '../../ui/dialog'

interface ItemSelectModalProps {
  open: boolean
  onClose: () => void
  items: Item[]
  onSelect: (item: Item) => void
}

export function ItemSelectModal({
  open,
  onClose,
  items,
  onSelect
}: ItemSelectModalProps) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{''}</DialogTitle>
        </DialogHeader>
        <div className='grid gap-4 py-4 h-96 overflow-y-auto'>
          {items.map((item) => (
            <button
              key={item.id}
              onClick={() => {
                onSelect(item)
                onClose()
              }}
              className='flex items-center gap-4 rounded-lg p-2 hover:bg-accent'
            >
              <div className='h-12 w-12 overflow-hidden rounded-full'>
                <img
                  src={item.image}
                  alt={item.label}
                  className='h-full w-full object-cover'
                />
              </div>
              <div className='flex flex-col items-start'>
                <div className='font-medium'>{item.label}</div>
                <div className='text-sm text-muted-foreground'>
                  {item.labelTwo}
                </div>
              </div>
            </button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}
