import { useAppSelector } from '../../store/hooks'

export const useVersion = () => {
  const version = useAppSelector((state) => state.version)
  return version
}

export const useLanguage = () => {
  const language = useAppSelector((state) => state.version.language)
  return language || 'en'
}
