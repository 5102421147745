import { PlayerPerformance } from './performances'
import { IconTabs } from '@frontend/ui'
import { BarChartIcon, Target, GitCompareArrowsIcon } from 'lucide-react'
import { PlayerMonitoring } from './monitoring'
import { PlayerCompare } from './compare'
import { CollapsibleTeamSelectHeader } from '../../app/header'
import { useEffect, useState } from 'react'
import { useFormattedTeam, useLanguage, useTeams } from '@frontend/sportable'
import { PlayerCarousel } from '../../components/player-carousel'

const textData = {
  playerViewHeader: {
    en: 'Player View',
    fr: 'Vue joueur'
  },
  playerViewMessage: {
    en: 'View and compare individual players and metrics',
    fr: 'Voir et comparer les joueurs individuels et les métriques'
  },
  performancesTab: {
    en: 'Performances',
    fr: 'Performances'
  },
  monitoringTab: {
    en: 'Monitoring',
    fr: 'Surveillance'
  },
  compareTab: {
    en: 'Compare',
    fr: 'Comparer'
  }
}

export function Player({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const language = useLanguage()

  const teamsState = useTeams()

  const formattedTeam = useFormattedTeam(teamsState.selectedTeam)

  const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(
    formattedTeam.players.list[0]?.id
  )

  useEffect(() => {
    setSelectedPlayerId(formattedTeam.players.list[0]?.id)
  }, [formattedTeam])

  console.log(formattedTeam)

  return (
    <>
      <CollapsibleTeamSelectHeader
        header={textData.playerViewHeader[language]}
        message={textData.playerViewMessage[language]}
      />
      <div className='flex-1 p-8 pt-0'>
        <PlayerCarousel
          selectedId={selectedPlayerId}
          onChange={(playerId) => setSelectedPlayerId(playerId)}
          players={formattedTeam.players.list.map((player) => {
            return {
              ...player,
              img: new URL(
                `/src/assets/players/${player.img}.png`,
                import.meta.url
              ).href
            }
          })}
        />
      </div>
      <div className='p-8 pt-0'>
        <IconTabs
          tabs={[
            {
              value: 'performances',
              label: textData.performancesTab[language],
              icon: BarChartIcon
            },
            {
              value: 'monitoring',
              label: textData.monitoringTab[language],
              icon: Target
            },
            {
              value: 'compare',
              label: textData.compareTab[language],
              icon: GitCompareArrowsIcon
            }
          ]}
        >
          <PlayerPerformance />
          <PlayerMonitoring />
          <PlayerCompare selectedPlayerId={selectedPlayerId} />
        </IconTabs>
      </div>
    </>
  )
}
