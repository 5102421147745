import * as React from 'react'
import { Label } from './label'

export type LabelledInputProps = {
  className?: string
  children: React.ReactNode
  label: string
}

export const LabelledInput = ({
  className,
  children,
  label
}: LabelledInputProps) => (
  <div className={`min-w-[280px] ${className}`}>
    <Label>{label}</Label>
    {children}
  </div>
)
