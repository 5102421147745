export interface Challenge {
  Targets?: Array<Target>
  id?: string
  name: string
  sessionId: string
  startTime?: number
  timeEnd?: number
  type: number
}

export type Target = {
  dist: TargetMetric
  div?: HTMLCanvasElement
  efficiency: TargetMetric
  endOverEndEfficiency: TargetMetric
  id: string
  name?: string
  hangTime: TargetMetric
  radii: Array<number>
  spin: TargetMetric
  x: number
  y: number
  subSessionId: string
  targetPlayerSessions?: TargetPlayerSessions[]
  targetSummary: any
}

export interface TargetPlayerSessions {
  id: string
  playersSessionsId: string
  targetId: string
}

export type TargetMetric = {
  greaterThan: number
  lessThan: number
}

export type Selected = {
  id: string
  selectedTarget: string
}

export interface TargetsState {
  items: {
    [id: string]: Challenge
  }
  sessionId: string
  selected: Selected
  blankTarget: Target
}

export type OperatorsState = {
  hangTime: number
  dist: number
  efficiency: number
  endOverEndEfficiency: number
  spin: number
}

export const SET_SESSION_CHALLENGES = 'SET_SESSION_CHALLENGES'
export const UPDATE_CHALLENGE = 'UPDATE_CHALLENGE'
export const CREATE_CHALLENGE = 'CREATE_CHALLENGE'
export const SET_SELECTED_CHALLENGE = 'SET_SELECTED_CHALLENGE'
export const SET_SELECTED_TARGET = 'SET_SELECTED_TARGET'
export const UPDATE_TARGET = 'UPDATE_TARGET'
export const DELETE_TARGET = 'DELETE_TARGET'
export const CREATE_TARGET = 'CREATE_TARGET'
export const ADD_TARGET_PLAYER_SESSION = 'ADD_TARGET_PLAYER_SESSION'
export const REMOVE_TARGET_PLAYER_SESSION = 'REMOVE_TARGET_PLAYER_SESSION'
