import { defaultPitch } from './pitch'
import { canadianFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
import { parentEventTypes } from '../../events/data_types'
import { eventTypes } from '../../events/types/data_types'
import { eventSubTypes } from '../../events/subTypes/data_types'

const sportMetrics = getMetricTypeGroup(
  getMetricTypesConfigFromJson([], metricTypesConfig)
)

export const canadianFootballProps = {
  parentEventTypes: canadianFootballEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  metrics: sportMetrics,
  reportMetricConfig: {
    playerPerformanceTable: {
      all: {
        metrics: {
          keys: []
        },
        eventMetricGroup: {
          eventTypes: [],
          groupName: {
            en: 'Success %',
            fr: 'Succès %'
          }
        }
      }
    },
    playerComparisonTable: {
      pass: {
        metrics: [[], [], []]
      }
    },
    monitoringCards: {
      technicalMetrics: [],
      physicalMetrics: []
    },
    dashboardMetrics: {
      performanceAgainstAverage: [],
      cardGrid: [[], []]
    }
  },
  pitch: {
    default: defaultPitch,
    pitchPointCount: 34,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 13,
    tenYardMarkings: 11,
    enableBench: true
  },
  features: {
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'Canadian',
    keyboardShortcuts: {
      shortcuts: {
        c: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              ((formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
                formattedEvent.type.selected?.value ===
                  eventTypes.items.pass?.value) ||
                (formattedEvent.eventTypeValue ===
                  parentEventTypes.items.game?.value &&
                  formattedEvent.type.selected?.value ===
                    eventTypes.items.manualPass?.value) ||
                (formattedEvent.eventTypeValue ===
                  parentEventTypes.items.game?.value &&
                  formattedEvent.type.selected?.value ===
                    eventTypes.items.touchDown?.value))
            )
          },
          outcome: outcomeTypes.items.complete?.value,
          subType: eventSubTypes.items.manualPassComplete?.value
        },
        x: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              ((formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
                formattedEvent.type.selected?.value ===
                  eventTypes.items.pass?.value) ||
                (formattedEvent.eventTypeValue ===
                  parentEventTypes.items.game?.value &&
                  formattedEvent.type.selected?.value ===
                    eventTypes.items.manualPass?.value))
            )
          },
          outcome: outcomeTypes.items.incomplete?.value,
          subType: eventSubTypes.items.manualPassIncomplete?.value
        },
        i: {
          condition: (formattedEvent: FormattedEventData) => {
            return (
              formattedEvent &&
              ((formattedEvent.eventTypeValue ===
                parentEventTypes.items.flight?.value &&
                formattedEvent.type.selected?.value ===
                  eventTypes.items.pass?.value) ||
                (formattedEvent.eventTypeValue ===
                  parentEventTypes.items.game?.value &&
                  formattedEvent.type.selected?.value ===
                    eventTypes.items.manualPass?.value))
            )
          },
          outcome: outcomeTypes.items.interception?.value,
          subType: eventSubTypes.items.manualPassIntercepted?.value
        }
      },
      codingShortcuts: {
        p: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.manualPass
        },
        s: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.handOff
        },
        f: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.tackle
        },
        n: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items
              .penaltyGameEvent
        },
        w: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.huddleBreak
        },
        e: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.lineSet
        },
        d: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.qbRush
        },
        g: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.qbSack
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: true,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: true,
    bibs: {}
  } as const
}

export const canadianFootballConfig = {
  key: 'canadianFootball' as const,
  value: 'CanadianFootball',
  name: 'Canadian Football',
  props: canadianFootballProps
}
