import React from 'react'
import { EditPencilIcon } from '../../../../../components/Icons/EditPencilIcon/EditPencilIcon'
import { PlayerAvatar } from '../../PlayerAvatar/PlayerAvatar'
import styles from '../Player.module.scss'
import { useFormattedPlayer } from '../../../../../metrics_server/players/hooks'
import { usePlayerFormModal } from '../../../../../metrics_server/players/modals'

export interface DefaultPlayerProps {
  playerId: string
  editMode: boolean
  size?: 's' | 'm' | 'l'
}

export const DefaultPlayer = (props: DefaultPlayerProps) => {
  const { playerId, editMode, size = 's' } = props

  const formattedPlayer = useFormattedPlayer(playerId)

  const { openPlayerFormModal } = usePlayerFormModal()

  const tileSize =
    size === 's'
      ? styles.tileSmall
      : size === 'm'
      ? styles.tileMedium
      : styles.tileLarge

  return (
    <div
      className={`${styles.playerTileContainer} ${tileSize}`}
      id={formattedPlayer.id}
      key={formattedPlayer.id}
    >
      {formattedPlayer.img ? (
        <div className={styles.imageContainer}>
          {editMode && (
            <div
              id='edit-player'
              onClick={() =>
                openPlayerFormModal(playerId, formattedPlayer.teamId)
              }
              className={styles.editBox}
            >
              <EditPencilIcon scale={0.35} />
            </div>
          )}
          <img
            draggable={false}
            id={formattedPlayer.id}
            className={`${styles.img}`}
            src={formattedPlayer.img}
            alt=''
          />
        </div>
      ) : (
        <div className={styles.avatarContainer} id={formattedPlayer.id}>
          {editMode && (
            <div
              id='edit-player'
              onClick={() =>
                openPlayerFormModal(playerId, formattedPlayer.teamId)
              }
              className={styles.editBox}
            >
              <EditPencilIcon scale={0.35} />
            </div>
          )}
          <PlayerAvatar autoScale />
        </div>
      )}

      <div id='player-name' className={styles.playerDetailsContainer}>
        <p className={styles.nameInfo}>
          <b>{formattedPlayer.lastName}</b>,
        </p>
        <div className={styles.tagAndNumberContainer}>
          <div className={styles.tagInfo}>{formattedPlayer.firstName}</div>
          <p className={styles.number}>{formattedPlayer?.number}</p>
        </div>
      </div>
    </div>
  )
}
