import * as React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(name: string, selected: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

interface MultipleSelectChipProps {
  label
  options
  selected
  onChange
  chips?
  size?
  mb?
  name?
}

export default function MultipleSelectChip<v>(props: MultipleSelectChipProps) {
  const { name, label, options, selected, onChange, size, mb, chips } = props

  const theme = useTheme()

  const handleChange = (event: SelectChangeEvent<v>) => {
    const {
      target: { value }
    } = event
    onChange(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <FormControl sx={{ mb, width: '100%' }}>
      <InputLabel size={size} id='demo-multiple-chip-label'>
        {label}
      </InputLabel>
      <Select
        labelId='demo-multiple-chip-label'
        id='demo-multiple-chip'
        multiple
        name={name}
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput id='select-multiple-chip' label={label} />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              overflow: 'hidden'
            }}
          >
            {chips
              ? selected.map((selectedOption) => {
                  const option = options.find(
                    (option) => option.value === selectedOption
                  )
                  // Chip broken - needs fixing
                  // return <Chip key={selectedOption} label={option.name} />
                  return option.name.join(', ')
                })
              : selected
                  .map((item) => {
                    const option = options.find(
                      (option) => option.value === item
                    )
                    return option.name
                  })
                  .join(', ')}
          </Box>
        )}
        MenuProps={MenuProps}
        size={size}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            value={option.value}
            style={getStyles(option.value, selected, theme)}
          >
            <Checkbox checked={selected.indexOf(option.value) > -1} />
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
