import { useEffect, useRef, useState } from 'react'
import { useEvents, useFormattedEvent } from '../../metrics_server/events/hooks'
import { useAppDispatch } from '../../store/hooks'
import { updateEvent } from '../../metrics_server/events/actions'
import { EventProfile } from './EventProfile/EventProfile'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import { Button, Grid } from '@mui/material'
import BasicSelect from '../Material/Select'
import { Statistic } from '../Statistic/Statistic'
import { parentEventTypes } from '../../metrics_server/events/data_types'
import { isEventTypeData } from '../../metrics_server/events/functions'
import { useBroadcasting } from '../../metrics_server/broadcasting/hooks'
import { sportableColors } from '../../constants/sportableColors'
import { manualPublish } from '../../metrics_server/broadcasting/actions'
import { useLatestKick } from '../../metrics_server/events/flight/hooks'
import Loader from '../Loader/Loader'
import * as Sentry from '@sentry/react'
import { sortArrayOfObjectsByKey } from '../../utils/helpers'
import { eventTypes } from '../../metrics_server/events/types/data_types'
import { EventChart } from '../../metrics_server/events/components/Chart'
import { Close } from '@mui/icons-material'
import { FaultyFormattedEvent } from './FaultyFormattedEvent'
import { useZoomAndFontSize } from './hooks'
import { isSportType } from '../../metrics_server/sports/data_types'
import { handleName } from '../../metrics_server/data_types'

export type AdjustedFontSizeType = {
  h3: string
  h5: string
  p: string
  span: string
}

export const FormattedEventDetailCard = () => {
  const dispatch = useAppDispatch()

  const broadcasting = useBroadcasting()
  const { selectedEventId } = useEvents()
  const formattedEvent = useFormattedEvent(selectedEventId)
  const formattedSession = useFormattedSession(formattedEvent?.sessionId)

  const [showLineChart, setShowLineChart] = useState(false)

  // Manual publish //

  const latestKick = useLatestKick()

  const isLastValidFlight = latestKick && formattedEvent?.id === latestKick.id
  const isPublishedFlight =
    broadcasting.publishedFlights.length >= 1 &&
    broadcasting.publishedFlights.includes(formattedEvent?.id)

  const [showValidKickDataRequestButton, setShowValidKickDataRequestButton] =
    useState(false)

  const [publishButtonLoading, setPublishButtonLoading] = useState(false)

  const handleLastValidKickClick = (flight) => {
    setPublishButtonLoading(true)
    dispatch(manualPublish(flight, () => setPublishButtonLoading(false)))
    timerStart()
    setShowValidKickDataRequestButton(!showValidKickDataRequestButton)
  }

  const countRef = useRef(null)
  const [timer, setTimer] = useState(0)
  const tenSeconds = 10000

  const timerStart = () => {
    clearInterval(countRef.current)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const { zoomIn, zoomOut, adjustedFontSize, uncappedAdjustedFontSize } =
    useZoomAndFontSize()

  useEffect(() => {
    function keyDownHandler(e: globalThis.KeyboardEvent) {
      e.preventDefault()

      Object.values(
        formattedEvent?.type.selected?.props?.types?.items || {}
      ).forEach((subType) => {
        console.log(subType)

        if (!subType.props.features.shortcut) return

        console.log([subType.props.features.shortcut], e.key)

        if (subType.props.features.shortcut === e.key) {
          dispatch(
            updateEvent({
              id: formattedEvent.id,
              subType: subType.value
            })
          )
        }
      })
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [formattedEvent])

  useEffect(() => {
    if (
      latestKick &&
      latestKick.eventTypeValue === parentEventTypes.items.flight.value
    ) {
      if (isLastValidFlight) {
        timerStart()
      }
      // show button if latest kick is within 10 seconds of current time. timeEnd is in seconds, so we multiply by 1000 to get milliseconds
      if (tenSeconds + latestKick.rawValues.endTime * 1000 >= Date.now()) {
        setShowValidKickDataRequestButton(true)
      } else {
        setShowValidKickDataRequestButton(false)
      }
    }

    return () => {
      clearInterval(countRef.current)
    }
  }, [timer, isLastValidFlight, latestKick])

  const renderFlightPublishFeatures = () => {
    if (publishButtonLoading) {
      return <Loader />
    }
    if (isPublishedFlight) {
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            height: '30px',
            color: sportableColors.colors.success,
            lineHeight: '1'
          }}
          disabled
        >
          Data Sent
        </Button>
      )
    }
    if (
      !broadcasting.autoBroadcastEnabled &&
      isLastValidFlight &&
      showValidKickDataRequestButton
    )
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            height: '30px',
            color: sportableColors.colors.sportableRed,
            lineHeight: '1'
          }}
          onClick={() => handleLastValidKickClick(latestKick)}
        >
          Send Data
        </Button>
      )

    return null
  }

  // If there is no formatted event selected, return null
  if (!formattedEvent) return null
  // If there is no formatted session for the event, return null
  if (!formattedSession) return null

  const { sport, isOfficiatingMode } = formattedSession

  const isSoccer = isSportType.soccer(sport)

  const toggleGraph = () => {
    setShowLineChart(!showLineChart)
  }

  const metricsWithOptions = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter((metric) => {
        return metric.options && !metric.readonly
      })
    : null
  const metricsWithoutOptions = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter(
        (metric) => (!metric.options || metric.readonly) && !metric.group
      )
    : null
  const metricsWithoutOptionsWithGroup = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter(
        (metric) => (!metric.options || metric.readonly) && metric.group
      )
    : null

  const combinedMetrics = [
    ...(metricsWithoutOptions || []),
    ...(metricsWithoutOptionsWithGroup || [])
  ]

  const sortedCombinedMetrics = sortArrayOfObjectsByKey(
    [...combinedMetrics],
    'key'
  )

  if (formattedEvent.hasFailedToRender) {
    return (
      <FaultyFormattedEvent
        err={formattedEvent.hasFailedToRender}
        formattedEvent={formattedEvent}
      />
    )
  }

  try {
    return (
      <Sentry.ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Grid container columnSpacing={1}>
          <Grid item xs={2.6}>
            {showLineChart && (
              <div
                style={{
                  height: '400px',
                  width: '800px',
                  overflow: 'hidden',
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  transform: 'translate(-50%, 0%)',
                  zIndex: 100,
                  backgroundColor: 'white',
                  boxShadow: '0 1px 15px 1px rgba(0, 0, 0, 0.5)',
                  borderRadius: '10px',
                  padding: '20px',
                  border: '1px #e3e3e3 solid',
                  marginBottom: '30px'
                }}
              >
                <div style={{ height: '20px' }}>
                  <Close
                    onClick={toggleGraph}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      cursor: 'pointer'
                    }}
                  />
                </div>
                <div style={{ height: 'calc(100% - 20px)' }}>
                  {' '}
                  <EventChart eventId={formattedEvent.id} />
                </div>
              </div>
            )}
            {formattedEvent.team || formattedEvent.player ? (
              <EventProfile
                eventId={selectedEventId}
                adjustedFontSize={adjustedFontSize}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
              />
            ) : null}
          </Grid>
          <Grid item xs={2.4}>
            {formattedEvent.type && (
              <BasicSelect
                name={'type'}
                label={'Type'}
                selected={formattedEvent.type.selected.value}
                options={formattedEvent.type.options}
                size={'small'}
                variant={'standard'}
                readonly={!formattedEvent.type.options}
                onChange={(value) =>
                  dispatch(
                    updateEvent({
                      id: formattedEvent.id,
                      type: value
                    })
                  )
                }
                fontSize={adjustedFontSize.selectFontSize}
                labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                padding={adjustedFontSize.selectPadding}
              />
            )}
            {formattedEvent.subType &&
              formattedEvent.subType.options &&
              formattedEvent.subType.selected && (
                <BasicSelect
                  name={'subType'}
                  label={'Sub Type'}
                  options={formattedEvent.subType?.options}
                  selected={formattedEvent.subType?.selected.value}
                  onChange={(value) =>
                    dispatch(
                      updateEvent({
                        id: formattedEvent.id,
                        subType: value
                      })
                    )
                  }
                  size={'small'}
                  variant={'standard'}
                  fontSize={adjustedFontSize.selectFontSize}
                  labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                  padding={adjustedFontSize.selectPadding}
                />
              )}
            {formattedEvent.outcome &&
              formattedEvent.outcome.options &&
              formattedEvent.outcome.selected && (
                <BasicSelect
                  name={'outcome'}
                  label={'Outcome'}
                  options={formattedEvent.outcome?.options}
                  selected={formattedEvent.outcome?.selected.value}
                  onChange={(value) => {
                    if ('outcome' in formattedEvent.rawData) {
                      dispatch(
                        updateEvent({
                          id: formattedEvent.id,
                          outcome: value
                        })
                      )
                    }
                  }}
                  size={'small'}
                  variant={'standard'}
                  fontSize={adjustedFontSize.selectFontSize}
                  labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                  padding={adjustedFontSize.selectPadding}
                />
              )}
            {metricsWithOptions &&
              metricsWithOptions.map((metric) => {
                return (
                  <Grid container direction='row' key={metric.key}>
                    <Grid item xs={metric.tagOnClick && !metric.value ? 8 : 12}>
                      <BasicSelect
                        className={'metric-options-dropdown'}
                        name={metric.key}
                        key={metric.key}
                        label={metric.name}
                        options={metric.options}
                        selected={metric.value}
                        onChange={(value) => {
                          const newValue = value === '' ? null : value
                          dispatch(
                            updateEvent({
                              id: formattedEvent.id,
                              [metric.key]: newValue
                            })
                          )
                        }}
                        size='small'
                        variant='standard'
                        fontSize={adjustedFontSize.selectFontSize}
                        labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                        padding={adjustedFontSize.selectPadding}
                      />
                    </Grid>
                    {metric.tagOnClick && !metric.value ? (
                      <Grid item xs={2} alignSelf={'flex-end'}>
                        <Button
                          size='small'
                          onClick={() =>
                            metric.tagOnClick((key, value) => {
                              dispatch(
                                updateEvent({
                                  id: formattedEvent.id,
                                  [key]: value
                                })
                              )
                            })
                          }
                        >
                          {handleName(metric.name)}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                )
              })}
          </Grid>
          <Grid item xs={5.5}>
            <Grid container spacing={2.5}>
              {sortedCombinedMetrics &&
                sortedCombinedMetrics.map((metric) => {
                  if (!metric.hideOnDetailCard) {
                    return (
                      <Grid item xs={2.4} key={metric.key}>
                        <Statistic
                          id={metric.key}
                          title={handleName(metric.name)}
                          stat={metric.display || '-'}
                          adjustedFontSize={uncappedAdjustedFontSize}
                          tag={metric.tag}
                          tagOnClick={
                            metric.tagOnClick
                              ? () => {
                                  metric?.tagOnClick((key, value) => {
                                    dispatch(
                                      updateEvent({
                                        id: formattedEvent.id,
                                        [key]: value
                                      })
                                    )
                                  })
                                }
                              : null
                          }
                        />
                      </Grid>
                    )
                  }
                })}
            </Grid>
          </Grid>

          {formattedEvent.eventType ===
            parentEventTypes.items.aussieRules.value && (
            <Grid item xs={1}>
              {formattedEvent.ballSerial && (
                <Statistic
                  id={'ballSerial'}
                  title={'Ball Serial'}
                  stat={formattedEvent.ballSerial || '-'}
                  adjustedFontSize={uncappedAdjustedFontSize}
                />
              )}
              {formattedEvent.position && (
                <Statistic
                  id={'position'}
                  title={'Position'}
                  stat={formattedEvent.position}
                  adjustedFontSize={uncappedAdjustedFontSize}
                />
              )}
            </Grid>
          )}

          {isOfficiatingMode &&
            formattedEvent.eventType !== parentEventTypes.items.time.value &&
            formattedEvent?.features?.graph && (
              <Grid item xs={1}>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => toggleGraph()}
                  style={{ height: '30px' }}
                >
                  Graph
                </Button>
                <Grid item xs={1} style={{ marginTop: '5px' }}>
                  {renderFlightPublishFeatures()}
                </Grid>
              </Grid>
            )}
        </Grid>
      </Sentry.ErrorBoundary>
    )
  } catch (e) {
    return <FaultyFormattedEvent err={e} formattedEvent={formattedEvent} />
  }
}
