import { ViewType } from '../../components/Views/Views.types'
import { AuthFormConfig, formMatrix, formConfig } from './form_config'
import { useAuthenticationForm } from './form'
import { FormViewProps } from '../../components/Views/Form/Form.types'
import { Route } from '../../App/App.types'

export const path = '/login'

export type AuthFormProps = FormViewProps<AuthFormConfig>

export const authenticationRoute: Route<AuthFormProps> = {
  path: path,
  view: {
    type: ViewType.FORM,
    name: 'Form Test',
    side: 1,
    formMatrix,
    formConfig,
    useForm: useAuthenticationForm
  }
}
