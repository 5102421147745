import { useMemo } from 'react'
import { useOrganisations } from '../../metrics_server/organisations/hooks'
import { useRouter } from '../../ui/router/hooks'
import { isPropEmpty } from '../../utils/helpers'

export const useNewOrganisationCheck = () => {
  const router = useRouter()
  const organisations = useOrganisations()

  const isNewOrganisation = useMemo(() => {
    console.log(router.prevRoute, organisations.items)
    if (router.prevRoute === '/login' || isPropEmpty(organisations.items)) {
      return true
    }
    return false
  }, [router.prevRoute, organisations.items])

  return isNewOrganisation
}
