import { env } from '@frontend/sportable'
import * as DashboardRouteConfig from '../views/dashboard/config'
import * as PlayerRouteConfig from '../views/player/config'

export const appConfig = {
  landing: DashboardRouteConfig.path,
  type: {
    name: 'Reporting Tool',
    value: 'reportingTool'
  },
  isLocal: env.isLocal,
  routes: {
    dashboard: {
      ...DashboardRouteConfig.dashboardRoute,
      authenticate: true
    },
    player: {
      ...PlayerRouteConfig.playerRoute,
      authenticate: true
    }
  },
  theme: null
}

function applyThemeOnBodyLoad(theme) {
  // Function to apply the theme after the body has loaded
  function applyTheme() {
    document.body.classList.remove('light', 'dark')
    document.body.classList.add(`${theme}`)
  }

  // Check if the body is already loaded
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    applyTheme()
  } else {
    // Add an event listener to wait for the body to load
    document.addEventListener('DOMContentLoaded', applyTheme)
  }
}

// Example usage
const theme = 'dark' // Replace with your desired theme
applyThemeOnBodyLoad(theme)
