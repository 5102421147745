import React, { useMemo } from 'react'
import { FormContainer } from '../../../components/Forms/Form/Form'
import TextInput from '../../../components/Forms/Inputs/TextInput/TextInput'
import { ImageInput } from '../../../components/Forms/Inputs/Image/Image'
import { Button } from '../../../components/Button/Button'
import { JoinOrganisationAndTeamFormProps } from '../config'
import { updateNewOrgansationState } from '../../../metrics_server/organisations/actions'
import { useAppDispatch } from '../../../store/hooks'
import { useOrganisations } from '../../../metrics_server/organisations/hooks'
import styles from '../../../components/Forms/Form/Form.module.scss'

const validate = (values) => {
  const errors = {} as { name?: string }
  if (!values.name) {
    errors.name = 'Required'
  }
  return errors
}

export const CreateOrganisationForm = ({
  switchForm,
  change
}: JoinOrganisationAndTeamFormProps) => {
  const dispatch = useAppDispatch()

  const organisations = useOrganisations()

  function handleSubmit(values) {
    dispatch(updateNewOrgansationState(values))
    switchForm('createTeam')
  }

  const fields = useMemo(
    () => [
      {
        name: 'logo',
        label: 'Upload logo',
        component: ImageInput,
        onImageChange: (base64) => change('img', base64),
        img: organisations.new?.logo || '',
        type: 'img'
      },
      {
        name: 'name',
        label: 'First name',
        placeholder: 'Name',
        component: TextInput,
        type: 'text'
      }
    ],
    [organisations.new]
  )

  return (
    <>
      <FormContainer
        name={'Create Organisation'}
        fields={fields}
        validate={validate}
        onSubmit={(values) => handleSubmit(values)}
        submitText={'Create'}
        title={'Create organisation'}
      />

      <div className={styles.buttonsContainer}>
        <Button
          handleClick={() => switchForm('joinOrganisation')}
          className='btn--plain'
        >
          Join existing organisation
        </Button>
      </div>
    </>
  )
}
