import React from 'react'
import playerAvatar from '../../../../assets/img/player_profile_avatar.svg'
import Checkbox from '../../../../components/Forms/Inputs/Checkbox/Checkbox'
import styles from '../../../../components/ListInset/ListInset.module.scss'
import { PlayerKeyItemType } from '../../types'

export interface PlayerItemProps {
  item?: PlayerKeyItemType
  onUpdate?: (playerIds: string, newState: any, item: PlayerKeyItemType) => void
  playerIds: Array<string | undefined>
}

export const PlayerKeyItem: React.FC<PlayerItemProps> = ({
  item,
  onUpdate,
  playerIds
}) => {
  const checked = playerIds.some((playerId) => {
    return playerId === item.id
  })

  const updatePlayerIds = (bool) => {
    let newState
    if (checked) {
      newState = playerIds.filter((playerId) => playerId !== item.id)
    } else {
      newState = [...playerIds, item.id]
    }
    onUpdate('playerIds', newState, item)
  }

  return (
    <div className={`${styles.playerKey} ${styles.playerContainer}`}>
      <div>
        <div
          style={{ borderColor: item.color }}
          className={styles.imgContainer}
        >
          <img
            className={item.img && styles.img}
            src={!item.img ? playerAvatar : item.img}
            alt=''
          />
        </div>
        <p>{`${item.firstName} ${item.lastName}`}</p>
      </div>
      <div>
        <Checkbox
          onClicked={(value) => updatePlayerIds(checked)}
          checked={checked}
        />
      </div>
    </div>
  )
}
