import {
  // API actions
  SET_EVENT_UPDATE_REQUEST_PENDING,
  DELETE_EVENT,
  SELECT_EVENT,
  UPDATE_SELECTED_COLUMNS,
  SET_PENALTY_COUNTDOWN_ACTIVE,
  ADD_EVENT,
  SET_SESSION_EVENTS,
  SET_FLIGHT_RAW_GRAPH_DATA,
  RawEventData,
  LineChartCoordinates,
  ProcessedRawEventData,
  UPDATE_SESSION_EVENTS
} from './types'

import * as api from './api'

import { getFullTarget } from '../targets/actions'

import {
  isEventTypeData,
  modifyEventForUpdate,
  modifyFlightBasedOnNewFields
} from './functions'
import { handleAPIError } from '../api/request'
import { UnitSystemTypeValues } from '../units/types'
import { AppThunk } from '../../store'
import { AxiosResponse } from 'axios'
import { RawTimeEventData } from './time/types'
import { CreateGameEventRequestData, CreateTimeEventRequestBody } from './api'
import { MetricTypeKeys } from '../metrics/data_types'
import { subSessionTypes } from '../sessions/sub_sessions/data_types'
import { sportTypes } from '../sports/data_types'
import { FormattedSession } from '../sessions/types'
import { getFormattedSession } from '../sessions/functions'
import { parentEventTypes } from './data_types'
import { eventTypes } from './types/data_types'
import { getUnitSystem, getUnitSystemFromState } from '../units/functions'
import { RawGameEventData } from './game/types'
import { RawFlightEventData } from './flight/types'

// ACTION CREATORS //

export function setSessionEvents(
  events: RawEventData[],
  unitSystemValue: UnitSystemTypeValues,
  formattedSession: FormattedSession
) {
  return {
    type: SET_SESSION_EVENTS,
    payload: {
      data: events,
      formattedSession,
      unitSystemValue: unitSystemValue as UnitSystemTypeValues
    }
  }
}
export type SetSessionEventsAction = ReturnType<typeof setSessionEvents>

export function updateSessionEvents(
  events: RawEventData[],
  unitSystemValue: UnitSystemTypeValues,
  formattedSession: FormattedSession
) {
  return {
    type: UPDATE_SESSION_EVENTS,
    payload: {
      data: events,
      formattedSession,
      unitSystemValue: unitSystemValue as UnitSystemTypeValues
    }
  }
}
export type UpdateSessionEventsAction = ReturnType<typeof updateSessionEvents>

export function addEvent(
  event: RawEventData,
  formattedSession: FormattedSession,
  unitSystemValue: UnitSystemTypeValues
) {
  return {
    type: ADD_EVENT,
    payload: {
      event,
      formattedSession,
      unitSystemValue: unitSystemValue as UnitSystemTypeValues
    }
  }
}
export type AddEventAction = ReturnType<typeof addEvent>

export function deleteEvent(id: string) {
  return {
    type: DELETE_EVENT,
    payload: id
  }
}
export type DeleteEventAction = ReturnType<typeof deleteEvent>

export function setSelectedEvent(eventId: string) {
  return {
    type: SELECT_EVENT,
    payload: eventId
  }
}
export type SetSelectedEventAction = ReturnType<typeof setSelectedEvent>

export function setEventUpdateRequestPending(bool: boolean) {
  return {
    type: SET_EVENT_UPDATE_REQUEST_PENDING,
    payload: bool
  }
}

export type SetEventUpdateRequestPendingAction = ReturnType<
  typeof setEventUpdateRequestPending
>

export function updateSelectedColumns(columns: MetricTypeKeys[]) {
  return {
    type: UPDATE_SELECTED_COLUMNS,
    payload: columns
  }
}

export type UpdateSelectedColumnsAction = ReturnType<
  typeof updateSelectedColumns
>

export function setPenaltyCountdownActive(bool: boolean) {
  return {
    type: SET_PENALTY_COUNTDOWN_ACTIVE,
    payload: bool
  }
}

export type SetPenaltyCountdownActiveAction = ReturnType<
  typeof setPenaltyCountdownActive
>

export function setFlightRawGraphData(
  data: {
    events: RawGameEventData[]
    imuData: LineChartCoordinates[]
  },
  flightId: string
) {
  return {
    type: SET_FLIGHT_RAW_GRAPH_DATA,
    payload: {
      data,
      flightId
    }
  }
}

export type SetFlightRawGraphDataAction = ReturnType<
  typeof setFlightRawGraphData
>

// =============//

// THUNKS //

// Handle live event //
export function handleNewEventOnTopic(event: RawEventData): AppThunk {
  return (dispatch, getState) => {
    let sessionId: string
    if ('sessionId' in event) sessionId = event.sessionId
    if (!sessionId && 'event' in event) sessionId = event.event?.sessionId

    const unitSystem = getUnitSystemFromState(sessionId, getState())

    dispatch(handleNewEvent(event, unitSystem.key))
  }
}

// Handle new event - refresh data that could be dependent on the new event data //
export function handleNewEvent(
  event: RawEventData | RawEventData[],
  unitSystemValue: UnitSystemTypeValues,
  sessionId?: string
): AppThunk {
  return (dispatch, getState) => {
    // Reload target if selected
    const { selected } = getState().targets

    if (selected.selectedTarget) {
      // Timeout delay to allow time for flight to be saved in the db before fetching target data
      setTimeout(() => {
        dispatch(getFullTarget(selected.selectedTarget, selected.id))
      }, 500)
    }
    // Refresh penalty countdown state - this is for a rugby penalty
    dispatch(getPenaltyCountdownState())

    // Add events to store //

    if (Array.isArray(event)) {
      if (sessionId) {
        const formattedSession = getFormattedSession(sessionId, getState())
        event.forEach((e) => {
          dispatch(addEvent(e, formattedSession, unitSystemValue))
        })
      } else {
        console.warn('No session ID provided for multiple events')
      }
    }

    if (event && !Array.isArray(event)) {
      let sessionId: string
      if ('sessionId' in event) sessionId = event.sessionId
      if (!sessionId && 'event' in event) sessionId = event.event?.sessionId

      const formattedSession = getFormattedSession(sessionId, getState())

      dispatch(addEvent(event, formattedSession, unitSystemValue))

      // Set as selected event automically if a CFL match (broadcast / test) and the event is a pass or manual pass //
      // TODO: we need parent type here - this will also fire if receive a oz or game event with type = 0 //
      const isPass =
        event?.type === eventTypes.items.pass.value ||
        event?.type === eventTypes.items.manualPass.value
      const isBroadcast =
        formattedSession.subType.value === subSessionTypes.items.broadcast.value
      const isTest =
        formattedSession.subType.value === subSessionTypes.items.test.value
      const isCFL =
        formattedSession.sport.value === sportTypes.items.canadianFootball.value
      if (event && 'ignore' in event) {
        if (isPass && (isBroadcast || isTest) && isCFL && !event.ignore) {
          dispatch(setSelectedEvent(event.id))
        }
      }
    }
  }
}

// Get all of a sessions events //
export function getSessionEvents(sessionId: string): AppThunk {
  return async (dispatch, getState) => {
    try {
      const formattedSession = getFormattedSession(sessionId, getState())
      const unitSystem = getUnitSystem(getState().units, formattedSession.sport)

      const response = await api.getSessionEvents({
        sessionId,
        unitSystemValue: unitSystem.key
      })

      dispatch(
        setSessionEvents(response.data, unitSystem.key, formattedSession)
      )

      // Refresh penalty countdown state - this is for a rugby penalty
      dispatch(getPenaltyCountdownState())
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Get event, handle triggers and update store //
export function getEvent(eventId: string, sessionId: string): AppThunk {
  return async (dispatch, getState) => {
    try {
      const unitSystem = getUnitSystemFromState(sessionId, getState())

      const response = await api.getEvent(eventId, unitSystem.key)
      dispatch(handleNewEvent(response.data, unitSystem.key))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Check event type and update //
export function updateEvent(
  data: Partial<RawEventData> & { id: string } & Partial<{
      teamId: string
      playerId: string
    }>
): AppThunk {
  return async (dispatch, getState) => {
    try {
      const processedEvent = getState().events.rawData[data.id]

      const unitSystem = getUnitSystemFromState(
        processedEvent.sessionId,
        getState()
      )

      const modifiedEvent = modifyEventForUpdate(data, processedEvent)

      let response: AxiosResponse<RawEventData>
      // Update flight event type //
      if (
        processedEvent.rawValues.parentType ===
        parentEventTypes.items.flight.value
      ) {
        response = await api.updateFlight(
          modifiedEvent as RawFlightEventData,
          unitSystem.key
        )
      }
      // Update other event type //
      else if (
        isEventTypeData.game(modifiedEvent) ||
        isEventTypeData.time(modifiedEvent) ||
        isEventTypeData.aussieRules(modifiedEvent)
      ) {
        response = await api.updateEvent(
          modifiedEvent,
          processedEvent.sessionId,
          unitSystem.key
        )
      }
      dispatch(handleNewEvent(response.data, unitSystem.key))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Get flight, handle triggers and update store //
export function getFlight(flightId: string, sessionId: string): AppThunk {
  return async (dispatch, getState) => {
    try {
      const unitSystem = getUnitSystemFromState(sessionId, getState())
      const response = await api.getFlight(flightId, unitSystem.key)
      dispatch(handleNewEvent(response.data, unitSystem.key))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Time events //
export function createTimeEvent(data: CreateTimeEventRequestBody): AppThunk {
  return async (dispatch, getState) => {
    const { selectedId } = getState().sessions
    const unitSystem = getUnitSystemFromState(
      data.sessionId || selectedId,
      getState()
    )

    // Set event update request pending to prevent multiple requests //
    dispatch(setEventUpdateRequestPending(true))
    try {
      const response = await api.createTimeEvent(data)
      dispatch(
        handleNewEvent(
          Object.values(response.data?.timeEvents || []),
          unitSystem.key
        )
      )

      // Timeout to allow time for event to be saved in the db before allowing the user to update another event //
      setTimeout(() => {
        dispatch(setEventUpdateRequestPending(false))
      }, 500)
    } catch (error) {
      dispatch(setEventUpdateRequestPending(false))
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function endTimeEvent(
  data,
  gameEventQueryValue: 'KICKOFF' | 'SNAP'
): AppThunk {
  return async (dispatch, getState) => {
    const { selectedId } = getState().sessions
    const unitSystem = getUnitSystemFromState(
      data.sessionId || selectedId,
      getState()
    )

    // Set event update request pending to prevent multiple requests //
    dispatch(setEventUpdateRequestPending(true))
    try {
      const response = await api.endTimeEvent({ data, gameEventQueryValue })
      dispatch(
        handleNewEvent(
          Object.values(response.data?.timeEvents || []),
          unitSystem.key
        )
      )

      // Timeout to allow time for event to be saved in the db before allowing the user to update another event //
      setTimeout(() => {
        dispatch(setEventUpdateRequestPending(false))
      }, 500)
    } catch (error) {
      dispatch(setEventUpdateRequestPending(false))
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function updateTimeEvent(data: Partial<RawTimeEventData>): AppThunk {
  return async (dispatch, getState) => {
    const { selectedId } = getState().sessions
    const unitSystem = getUnitSystemFromState(
      data.sessionId || selectedId,
      getState()
    )

    try {
      const response = await api.updateTimeEvent({ data })
      dispatch(
        handleNewEvent(
          Object.values(response.data?.timeEvents || []),
          unitSystem.key
        )
      )
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function deleteTimeEvent(eventId: string): AppThunk {
  return async (dispatch) => {
    try {
      await api.deleteTimeEvent(eventId)
      dispatch(handleNewEvent(null, null))
      dispatch(deleteEvent(eventId))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// ==================//

// Game events //

export function createGameEvent(data: CreateGameEventRequestData): AppThunk {
  return async (dispatch, getState) => {
    try {
      const { selectedId } = getState().sessions
      const unitSystem = getUnitSystemFromState(
        data.sessionId || selectedId,
        getState()
      )

      const response = await api.createGameEvent(data)
      dispatch(handleNewEvent(response.data, unitSystem.key))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// ==================//

// Rugby penalty countdown //

export function getPenaltyCountdownState() {
  return async (dispatch) => {
    try {
      const response = await api.getPenaltyCountdownState()
      dispatch(setPenaltyCountdownActive(response.data.enabled))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function startPenaltyCountdown() {
  return async (dispatch) => {
    try {
      await api.startPenaltyCountdown()
      dispatch(getPenaltyCountdownState())
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function clearPenaltyCountdown() {
  return async (dispatch) => {
    try {
      await api.clearPenaltyCountdown()
      dispatch(getPenaltyCountdownState())
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// ================== //

// Ball in play

export function setBallInPlay(ballId) {
  return async (dispatch) => {
    try {
      await api.setBallInPlay(ballId)
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// ================== //

// Get spin graph for flight //

export function getLineChartData(flightId) {
  return async (dispatch) => {
    try {
      const response = await api.getFlightRawGraphData(flightId)
      dispatch(setFlightRawGraphData(response.data, flightId))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}
