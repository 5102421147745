import React, { useCallback, useEffect, useMemo } from 'react'
import { EventsFilter } from '../filter'
import {
  setSelectedEvent,
  updateEvent,
  updateSelectedColumns
} from '../actions'
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { useEvents, useSelectedFormattedEvent } from '../hooks'
import { useAppDispatch } from '../../../store/hooks'
import { useMetricColumnSelectModal } from '../../../views/Session/modals/column_select'
import { getMostRecentKick } from '../flight/functions'
import { useSelectedFormattedSession } from '../../sessions/hooks'
import { useUnitsSystem } from '../../units/hooks'
import { NewTable } from '../../../components/Table/NewTable'
import Checkbox from '../../../components/Forms/Inputs/Checkbox/Checkbox'
import { TeamLogo } from '../../../components/TeamLogo/TeamLogo'
import { dataTypes } from '../../../components/Table/data_types'
import { RawEventData } from '../types'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { isParentEventType } from '../data_types'
import * as Sentry from '@sentry/react'
import { generateMetricTypeClass } from '../../metrics/data_types'
import { Warning } from '@mui/icons-material'
import { sportableColors } from '../../../const'

export interface ValidationTableProps {
  eventsFilter: EventsFilter
  tableId: string
  active: boolean
  updateDisplayedEvents?: (events: RawEventData[]) => void
  maxDisplayedEvents?: number
  validationTableSorting?: SortingState
  setValidationTableSorting?: (sorting: SortingState) => void
  timeColumn: string
  setTimeColumn: (timeColumn: string) => void
  isKeyboardShortcutEnabled?: boolean
}
export const ValidationTable = (props: ValidationTableProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <h3>Something went really wrong, contact support immediately</h3>
      }
      onError={console.error}
    >
      <ValidTable {...props} />
    </Sentry.ErrorBoundary>
  )
}

const ValidTable = ({
  eventsFilter,
  tableId,
  active,
  updateDisplayedEvents,
  maxDisplayedEvents,
  validationTableSorting,
  setValidationTableSorting,
  timeColumn,
  setTimeColumn,
  isKeyboardShortcutEnabled
}: ValidationTableProps) => {
  const dispatch = useAppDispatch()

  const formattedSession = useSelectedFormattedSession()

  const { sport, flightMetrics } = formattedSession

  const { filters, updateFilterValue, filteredEvents } = eventsFilter

  const formattedEvent = useSelectedFormattedEvent()

  // ====== //

  // Redux //
  const events = useEvents()
  const unitSystem = useUnitsSystem(sport)
  // ====== //

  // Modals //
  const { openMetricColumnSelectModal } = useMetricColumnSelectModal(
    flightMetrics,
    events.columns,
    (columns) => {
      dispatch(updateSelectedColumns(columns))
    }
  )
  // ====== //

  // Set most recent kick as selected flight
  const selectedMostRecentKick = useCallback(() => {
    const mostRecentKick = getMostRecentKick(filteredEvents)
    if (mostRecentKick) {
      dispatch(setSelectedEvent(mostRecentKick.id))
    }
    return mostRecentKick
  }, [filteredEvents])

  const selectedColumns = useMemo(() => {
    return events.columns.map((key) => {
      const metricInfo = generateMetricTypeClass(flightMetrics?.items?.[key])

      let name = 'Unknown'
      if (metricInfo) {
        name = metricInfo.getMetricDisplayWithUnits(unitSystem, 12)
      }

      const headers = {
        accessorKey: key,
        header: name,
        name,
        key,
        width: 12.5,
        size: 12.5,
        minSize: 12.5
      }
      return headers
    })
  }, [events.columns, unitSystem])

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'potentialEvent',
        header: '',
        size: 5,
        minSize: 5,
        enableResizing: true,
        cell: ({ row }) => {
          const item = row.original

          if (item.potentialEvent) {
            return (
              <Warning
                sx={{
                  width: 'auto',
                  height: '15px',
                  color: sportableColors.colors.colorError
                }}
              />
            )
          }

          return null
        }
      },
      {
        accessorKey: 'highlighted',
        header: '',
        size: 5,
        minSize: 5,
        enableResizing: true,
        cell: ({ row }) => {
          const item = row.original

          const handleCheckboxChange = (value) => {
            dispatch(
              updateEvent({
                id: item.id,
                operatorNotes: { ...item.operatorNotes, highlight: value }
              })
            )
          }
          const value = item.highlight
          return (
            <Checkbox
              size='small'
              onClicked={handleCheckboxChange}
              checked={value}
              input={{ value }}
              type='highlight'
            />
          )
        }
      },
      {
        accessorKey: timeColumn,
        header: 'Time',
        size: 14,
        minSize: 14,
        cell: ({ row }) => {
          const item = row.original
          const type = timeColumn === 'sessionStartTime' ? 'time' : 'date'

          const formattedTime = dataTypes[type].display(item[timeColumn])

          return formattedTime
        }
      },
      {
        accessorKey: 'team',
        header: '',
        size: 5,
        minSize: 5,
        cell: ({ row }) => {
          const item = row.original
          if (!item.teamLogo) return
          return (
            <TeamLogo color={item.teamLogo} logo={item.teamLogo} size={16} />
          )
        }
      },
      {
        accessorKey: 'player',
        header: 'Player',
        size: 26.5
      },
      {
        accessorKey: 'typeName',
        header: 'Type',
        size: 19.5
      },
      ...selectedColumns,
      {
        accessorKey: 'ignore',
        header: 'Ignored',
        size: 5,
        minSize: 5,
        cell: ({ row }) => {
          const item = row.original
          const value = item.ignore

          const handleCheckboxChange = () => {
            dispatch(
              updateEvent({ id: row.original.rawData.id, ignore: !item.ignore })
            )
          }
          return (
            <Checkbox
              size='small'
              onClicked={handleCheckboxChange}
              checked={value}
              input={{ value }}
              type='checkbox'
              disabled={item.__disabled?.[item.key]}
            />
          )
        }
      }
    ]
  }, [filteredEvents, timeColumn, selectedColumns, unitSystem])

  const newTableData = useMemo(() => {
    return filteredEvents.map((event) => {
      return {
        id: event.id,
        sessionStartTime: event.rawValues.sessionStartTime,
        startTimeMil: event.rawValues.startTime * 1000,
        ignore: event.rawValues.ignore,
        highlighted: event.rawValues.highlighted,

        teamLogo: event.processedValues.team?.logo,
        teamColor: event.processedValues.team?.color,

        ...event.formattedMetrics,

        parentType: event.rawValues.parentType,

        typeName: event.primaryType?.name,

        rawData: event.rawData,
        __color: event.rawValues.ignore ? 'rgba(0, 0, 0, 0.2)' : '#323233'

        // id: event.id,
        // rawData: event.rawData,
        // eventType: event.eventType,
        // player: event.player?.selected?.nameAndNumber,
        // team: event.team?.selected?.name,
        // teamLogo: event.team?.selected,
        // operator: event?.operator,
        // highlight: event?.operator?.highlight,
        // outcome: event?.outcome?.selected?.name,
        // time:
        //   timeColumn === 'sessionStartTime'
        //     ? event?.sessionStartTime
        //     : event?.startTime * 1000,
        // typeName: event?.typeName,
        // ignore: event?.ignore,
        // __color: event?.ignore ? 'rgba(0, 0, 0, 0.2)' : '#323233',
        // hasFailedToRender:
        //   'hasFailedToRender' in event ? event?.hasFailedToRender : null,
        // ...displayMetric
      }
    })
  }, [filteredEvents, timeColumn])

  const table = useReactTable({
    data: newTableData,
    columns,
    state: {
      sorting: validationTableSorting
    },
    onSortingChange: setValidationTableSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
    // debugTable: true
  })

  const { flatRows } = table.getRowModel()

  // Update displayed events on table page change
  useEffect(() => {
    if (!updateDisplayedEvents) return
    // Filter out first maxDisplayedEvents unignored events //

    let count = 0
    const eventType = formattedSession.parentEventTypes.getTypeByValue(
      formattedEvent?.eventType
    )

    const firstMaxRows = flatRows.filter((row, i) => {
      const eventType = formattedSession.parentEventTypes.getTypeByValue(
        row.original?.parentType
      )
      const isFlight = isParentEventType.flight(eventType)
      if (!row.original.ignore && isFlight && count < maxDisplayedEvents) {
        count++
        return true
      }
      return false
    })
    let rawEvents = firstMaxRows.map((row) => row.original.rawData)

    // Add selected event to displayed events if its a flight //
    if (formattedEvent && isParentEventType.flight(eventType)) {
      rawEvents = [...rawEvents, formattedEvent.rawData]
    }

    updateDisplayedEvents(rawEvents)
  }, [
    flatRows,
    updateDisplayedEvents,
    maxDisplayedEvents,
    active,
    formattedEvent,
    validationTableSorting
  ])

  // Generate table controls //
  const controls = useMemo(() => {
    return [
      {
        name: 'Toggle Time',
        callback: () => {
          timeColumn === 'sessionStartTime'
            ? setTimeColumn('startTimeMil')
            : setTimeColumn('sessionStartTime')
        }
      },
      {
        name: !filters.ignore.value ? 'Show Ignored' : 'Show Only Valid',
        callback: () => {
          updateFilterValue('ignore', !filters.ignore.value)
        }
      },
      {
        name: filters.highlighted.value ? 'Show All' : 'Show Key Events',
        callback: () => {
          updateFilterValue('highlighted', !filters.highlighted.value)
        }
      },
      {
        name: 'Add/Remove Metrics',
        callback: () => openMetricColumnSelectModal(2)
      }
    ]
  }, [filters, updateFilterValue, timeColumn, openMetricColumnSelectModal])

  // TAB favourite star
  const updateFavouriteHotKey = useCallback(() => {
    if (active && isKeyboardShortcutEnabled) {
      if (formattedEvent && 'operatorNotes' in formattedEvent.rawData) {
        const data = {
          id: formattedEvent.id,
          operatorNotes: {
            matchTime: formattedEvent.rawData.operatorNotes?.matchTime,
            notes: formattedEvent.rawData.operatorNotes?.notes,
            id: formattedEvent.rawData.operatorNotes?.id,
            highlight: !formattedEvent.rawData.operatorNotes?.highlight
          }
        }
        dispatch(updateEvent(data))
      }
    }
  }, [formattedEvent])

  useKeyboardShortcut(['f'], updateFavouriteHotKey, {
    overrideSystem: isKeyboardShortcutEnabled
  })

  // Tab shortcut - ignore event
  const updateIgnoreCheckboxHotKey = useCallback(() => {
    if (active && isKeyboardShortcutEnabled) {
      if (formattedEvent && 'ignore' in formattedEvent.rawData) {
        const data = {
          id: formattedEvent.id,
          ignore: !formattedEvent.rawData.ignore
        }
        dispatch(updateEvent(data))
      }
    }
  }, [formattedEvent])

  useKeyboardShortcut(['Tab'], updateIgnoreCheckboxHotKey, {
    overrideSystem: isKeyboardShortcutEnabled
  })

  return (
    <NewTable
      active={active}
      table={table}
      title={`${filteredEvents.length}`}
      handleShiftUpShortcut={selectedMostRecentKick}
      controls={controls}
      id={'validation'}
    />
  )
}
