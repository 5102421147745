import {
  emptyFormattedMetricTypeValues,
  emptyProcessedMetricTypeValues,
  emptyRawMetricTypeValues,
  FormattedMetricTypeValues,
  MetricTypeKeys,
  ProcessedMetricTypeValues,
  RawMetricTypeValues
} from '../metrics/data_types'
import { Coordinate, RawFlightEventData } from './flight/types'
import { RawTimeEventData } from './time/types'
import { RawGameEventData } from './game/types'
import { RawAussieRulesEventData } from './aussie_rules/types'
import { FormattedTeam } from '../teams/types'
import { Options } from '../data_types'
import { OutcomeType, OutcomeTypeValues } from '../outcomes/data_types'
import { FormattedPlayer } from '../players/types'
import {
  EventType,
  EventTypeKeys,
  eventTypes,
  EventTypeValues
} from './types/data_types'
import { FormattedMetric } from '../metrics/types'
import { UnitSystem, UnitSystemTypeValues } from '../units/types'
import { EventFeatures } from './features'
import {
  ParentEventType,
  ParentEventTypeKeys,
  parentEventTypes,
  ParentEventTypeValues
} from './data_types'
import {
  EventSubType,
  EventSubTypeKeys,
  eventSubTypes,
  EventSubTypeValues
} from './subTypes/data_types'

// Redux State //
export interface EventsState {
  rawData: {
    [k: string]: ProcessedRawEventData
  }
  items: {
    [k: string]:
      | RawFlightEventData
      | RawTimeEventData
      | RawGameEventData
      | RawAussieRulesEventData
  }
  columns: MetricTypeKeys[]
  flights: {
    [id: string]: RawFlightEventData
  }
  flightGraphs: {
    [id: string]: {
      data: {
        events: RawGameEventData[]
        imuData: LineChartCoordinates[]
      }
    }
  }
  selectedEventId: string
  sessionId: string
  penaltyCountdownActive: boolean
  eventUpdateRequestPending: boolean
}

export type RawEventData =
  | RawFlightEventData
  | RawGameEventData
  | RawAussieRulesEventData
  | RawTimeEventData

export type ProcessedEventFields<
  Data,
  ExtraFields extends { eventType; type?; subType?; outcome? }
> = {
  id: string
  sessionId: string

  unitSystemValue: UnitSystemTypeValues

  eventTypeValue: ExtraFields['eventType']

  primaryType: ParentEventType | EventType | EventSubType

  rawData: Data

  operatorNotes: OperatorNotes

  rawValues: {
    ignore: boolean
    highlighted: boolean

    sessionId: string
    startTime: number
    endTime: number
    sessionStartTime: number

    parentType: ParentEventTypeValues
    parentTypeName:
      | string
      | {
          en: string
          fr: string
        }

    type: EventTypeValues
    typeName:
      | string
      | {
          en: string
          fr: string
        }

    subType: EventSubTypeValues
    subTypeName:
      | string
      | {
          en: string
          fr: string
        }

    ballSerial: string
  } & RawMetricTypeValues

  processedValues: {
    ignore: boolean
    highlighted: boolean

    sessionId: string
    startTime: number
    endTime: number
    sessionStartTime: number

    parentType: ParentEventType
    parentTypeName:
      | string
      | {
          en: string
          fr: string
        }

    type: EventType
    typeName:
      | string
      | {
          en: string
          fr: string
        }

    subType: EventSubType
    subTypeName:
      | string
      | {
          en: string
          fr: string
        }

    ballSerial: string
  } & ProcessedMetricTypeValues

  formattedMetrics: Partial<FormattedMetricTypeValues>
}

export type RawEventValues = Omit<
  ProcessedEventFields<any, any>['rawValues'],
  keyof RawMetricTypeValues
>

export type ProcessedEventValues = Omit<
  ProcessedEventFields<any, any>['processedValues'],
  keyof ProcessedMetricTypeValues
>

const eventType = parentEventTypes.getTypeByValue(null)
const type = eventTypes.getTypeByValue(null)
const subType = eventSubTypes.getTypeByValue(null)

export const EMPTY_PROCESSED_EVENT: ProcessedEventFields<null, null> = {
  id: '',
  unitSystemValue: 'SI',
  sessionId: null,
  eventTypeValue: null,
  primaryType: eventType,

  rawData: null,

  operatorNotes: {
    id: 0,
    notes: '',
    highlight: false,
    matchTime: 0
  },

  rawValues: {
    ignore: false,
    highlighted: false,

    sessionId: '',
    startTime: 0,
    endTime: 0,
    sessionStartTime: 0,

    parentType: 0,
    parentTypeName: '',

    type: 0,
    typeName: '',

    subType: 0,
    subTypeName: '',

    ballSerial: '',

    ...emptyRawMetricTypeValues
  },
  processedValues: {
    ignore: false,
    highlighted: false,

    sessionId: '',
    startTime: 0,
    endTime: 0,
    sessionStartTime: 0,

    parentType: eventType,
    parentTypeName: 'Unknown',

    type: type,
    typeName: 'Unknown',

    subType: subType,
    subTypeName: 'Unknown',

    ballSerial: '',

    ...emptyProcessedMetricTypeValues
  },

  formattedMetrics: emptyFormattedMetricTypeValues
}

export type ProcessedRawEventData =
  | ProcessedEventFields<
      RawFlightEventData,
      {
        eventType: typeof parentEventTypes.items.flight.value
      }
    >
  | ProcessedEventFields<
      RawGameEventData,
      {
        eventType: typeof parentEventTypes.items.game.value
      }
    >
  | ProcessedEventFields<
      RawAussieRulesEventData,
      {
        eventType: typeof parentEventTypes.items.aussieRules.value
        subType: RawAussieRulesEventData['crossSection']
        outcome: OutcomeTypeValues
      }
    >
  | ProcessedEventFields<
      RawTimeEventData,
      {
        eventType: typeof parentEventTypes.items.time.value
        subType: number
        outcome: OutcomeTypeValues
      }
    >

export interface BaseFormattedEventData {
  id: string
  sessionId: string
  unitSystem: UnitSystem
  sessionStartTime: number
  ignore: boolean
  startTime: number
  endTime: number
  typeName:
    | string
    | {
        en: string
        fr: string
      }
  // TODO: test operator notes //
  operator: OperatorNotes
  operatorNotes?: {
    id: number
    notes: string
    highlight: boolean
    matchTime: number
  }
  metrics: {
    [key in MetricTypeKeys]?: FormattedMetric
  }
  outcome?: {
    selected: OutcomeType
    options: Options<OutcomeTypeValues>
  }
  team: {
    selected: FormattedTeam
    options: Options<string>
  }
  player: {
    selected: FormattedPlayer
    options: Options<string>
  }
  // compareTagPosition: {
  //   label: string
  //   x: number
  //   y: number
  //   z: number
  // }
  hasFailedToRender?: Error
  position?: Coordinate

  potentialEvent: number

  eventType: EventTypeValues
  eventTypeValue: EventTypeValues

  rawData: RawEventData

  goalLineGraph: {
    spinGraph: { X: number; Y: number }[]
    impactGraph: { X: number; Y: number }[]
    impactEvents: number[]
    hitPost: boolean
  }

  features: EventFeatures

  type: {
    selected: EventType
    options: Options<EventTypeValues>
  }
  subType: {
    selected: EventSubType
    options: Options<EventSubTypeValues>
  }

  ballSerial: string

  data: { pos: { x; y; z } }[]
}

export type FormattedEventData = BaseFormattedEventData

// =============//

// Event Data //

export interface EventData<t> {
  id: string
  sessionId: string
  startTime: number
  timeEnd: number
  type: t
  hasFailedToRender?: Error
}

export type OperatorNotes = {
  highlight: boolean
  id: number
  matchTime: number
  notes: string
}

export interface LineChartCoordinates {
  time: number
  acceleration: number
  gyro?: number
}

export type AllEventTypeKeys =
  | ParentEventTypeKeys
  | EventTypeKeys
  | EventSubTypeKeys

// =============//

// Redux Action Types //

export const SET_EVENTS = 'SET_EVENTS' as const
export const ADD_EVENT = 'ADD_EVENT' as const
export const UPDATE_EVENT = 'UPDATE_EVENT' as const
export const DELETE_EVENT = 'CREATE_EVENT' as const
export const SELECT_EVENT = 'SELECT_EVENT' as const
export const SET_SESSION_EVENTS = 'SET_SESSION_EVENTS' as const
export const UPDATE_SESSION_EVENTS = 'UPDATE_SESSION_EVENTS' as const
export const UPDATE_SELECTED_COLUMNS = 'UPDATE_SELECTED_COLUMNS' as const
export const SET_PENALTY_COUNTDOWN_ACTIVE =
  'SET_PENALTY_COUNTDOWN_ACTIVE' as const
export const SET_EVENT_UPDATE_REQUEST_PENDING =
  'SET_EVENT_UPDATE_REQUEST_PENDING' as const
export const SET_FLIGHT_RAW_GRAPH_DATA = 'SET_FLIGHT_RAW_GRAPH_DATA' as const

// =============//
