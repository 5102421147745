import {
  GET_TEAM_PLAYERS,
  CREATE_PLAYER,
  EDIT_PLAYER,
  DELETE_PLAYER,
  GET_PLAYER_USERS,
  SET_PLAYER
} from './types'

import { REMOVE_USER_DATA } from '../types'

import _ from 'lodash'
import { getColor } from '../../utils/helpers/index'
import { PlayersState } from './types'

const initialState: PlayersState = {
  fetched: false,
  hasValidData: false,
  selectedPlayer: null,
  items: {},
  rawData: {}
}

export function playersReducer(state = initialState, action) {
  let players, player, rawData
  switch (action.type) {
    case GET_TEAM_PLAYERS:
      players = { ...state.items }
      rawData = { ...state.rawData }
      action.payload.data.forEach((player, index) => {
        players[player.id] = player
        players[player.id].color = getColor(index)
        players[player.id].teamId = action.payload.teamId
        rawData[player.id] = player
        rawData[player.id].color = getColor(index)
        rawData[player.id].teamId = action.payload.teamId
      })
      return {
        ...state,
        items: {
          ...players
        },
        rawData: {
          ...rawData
        },
        hasValidData: true,
        fetched: true
      }
    case CREATE_PLAYER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload
        },
        rawData: {
          ...state.rawData,
          [action.payload.id]: action.payload
        }
      }
    case EDIT_PLAYER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload
        },
        rawData: {
          ...state.rawData,
          [action.payload.id]: action.payload
        }
      }
    case DELETE_PLAYER:
      players = { ...state.items }
      delete players[action.payload.id]
      rawData = { ...state.rawData }
      delete rawData[action.payload.id]
      return { ...state, items: players, rawData }

    case SET_PLAYER:
      return { ...state, selectedPlayer: action.payload }

    // Remove user data
    case REMOVE_USER_DATA:
      // TODO: Removed for rep tool dummy data purposes. Uncomment when ready to
      return state
      return initialState

    default:
      return state
  }
}

export function playerUsersReducer(state = {}, action) {
  switch (action.type) {
    case GET_PLAYER_USERS:
      return state
    default:
      return state
  }
}
