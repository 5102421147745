import React from 'react'

import { CreateSessionForm } from './CreateSessionForm/CreateSessionForm'
import { FormConfig } from '../../components/Views/Form/Form.types'

export type CreateSessionFormKeys = 'createSession'

export type CreateSessionFormConfig = FormConfig<CreateSessionFormKeys>

export const formConfig: CreateSessionFormConfig = {
  createSession: {
    key: 'createSession',
    name: 'Create Session',
    Component: CreateSessionForm,
    title: () => {
      return (
        <h1>
          Create <span>your session</span>
        </h1>
      )
    }
  }
}

export const formMatrix = [[formConfig.createSession]]
