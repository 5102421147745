import React, { useState } from 'react'
import { FormContainer } from '../Form/Form'

import { Option } from '../Fields/SelectField/SelectField'

export interface SelectFormProps {
  handleSubmit: (values: any) => void
  options: Option[]
  initialValue: string | number
  title?: string
  submitText?: string
}

export const SelectForm: React.FC<SelectFormProps> = ({
  handleSubmit,
  initialValue,
  options,
  title,
  submitText
}) => {
  const [fields] = useState([
    {
      name: 'selected',
      type: 'select',
      label: 'Select',
      options: options,
      initialValue
    }
  ])

  return (
    <FormContainer
      name={'selectForm'}
      fields={fields}
      onSubmit={handleSubmit}
      title={title}
      submitText={submitText ? submitText : 'Select'}
    />
  )
}
