import { RAW_MOCK_EVENT_METRICS } from '../../../metrics/__mocks__'
import { OutcomeTypeValues } from '../../../outcomes/data_types'
import { SportType } from '../../../sports/data_types'
import { eventTypes, EventTypeValues } from '../../types/data_types'
import { EventSubTypeKeys, EventSubTypeValues } from '../../subTypes/data_types'
import { RawFlightEventData } from '../types'
import { v1 } from 'uuid'
import { rawSessionOne } from '../../../sessions/mocks/api'
import { rawTeamOne } from '../../../teams/mocks/api'
import { rawPlayerOne, rawPlayerTwo } from '../../../players/mocks/api'

export const mockRawFlightEvent: RawFlightEventData = {
  id: 'flightEvent-1',
  eventId: 'flightEvent-1',
  sessionId: rawSessionOne.id,
  startTime: rawSessionOne.startTime + 10,
  timeEnd: rawSessionOne.startTime + 10 + RAW_MOCK_EVENT_METRICS.hangTime,
  ignore: false,

  type: 0,
  subType: 0,

  ballId: null,
  teamID: null,
  fromId: 0,
  fromPlayerId: null,
  toId: 0,
  toPlayerId: RAW_MOCK_EVENT_METRICS.toPlayerId,

  hangTime: RAW_MOCK_EVENT_METRICS.hangTime,
  dist: RAW_MOCK_EVENT_METRICS.dist,
  speed: RAW_MOCK_EVENT_METRICS.speed,
  maxHeight: RAW_MOCK_EVENT_METRICS.maxHeight,
  spin: RAW_MOCK_EVENT_METRICS.spin,
  efficiency: RAW_MOCK_EVENT_METRICS.efficiency,
  endOverEndEfficiency: RAW_MOCK_EVENT_METRICS.endOverEndEfficiency,
  success: RAW_MOCK_EVENT_METRICS.success,
  reloadTime: RAW_MOCK_EVENT_METRICS.reloadTime,
  groundReloadTime: RAW_MOCK_EVENT_METRICS.groundReloadTime,
  territorialGain: RAW_MOCK_EVENT_METRICS.territorialGain,
  lineoutDeviation: RAW_MOCK_EVENT_METRICS.lineoutDeviation,
  inPitchDistance: RAW_MOCK_EVENT_METRICS.inPitchDistance,
  forward: RAW_MOCK_EVENT_METRICS.forward,
  carryXVelocity: RAW_MOCK_EVENT_METRICS.carryXVelocity,
  passXVelocity: RAW_MOCK_EVENT_METRICS.passXVelocity,
  relativeVelX: RAW_MOCK_EVENT_METRICS.relativeVelX,
  probabilityForward: RAW_MOCK_EVENT_METRICS.probabilityForward,
  outcome: RAW_MOCK_EVENT_METRICS.outcome,
  distanceToCorner: RAW_MOCK_EVENT_METRICS.distanceToCorner,
  inPitchHangTime: RAW_MOCK_EVENT_METRICS.inPitchHangTime,
  lineoutDeviated: RAW_MOCK_EVENT_METRICS.lineoutDeviated,
  lineoutDeviationAngle: RAW_MOCK_EVENT_METRICS.lineoutDeviationAngle,
  targetPlayerId: RAW_MOCK_EVENT_METRICS.targetPlayerId,
  snapToPass: RAW_MOCK_EVENT_METRICS.snapToPass,
  confirmed: RAW_MOCK_EVENT_METRICS.confirmed,
  passDirection: RAW_MOCK_EVENT_METRICS.passDirection,
  timeToTarget: RAW_MOCK_EVENT_METRICS.timeToTarget,

  hitPost: false,

  offsetX: 0,
  offsetY: 0,
  offsetZ: 0,
  data: [],
  polynomialCoefficients: [
    [1.972000002861023, 13.840999603271484, 0],
    [17.38414825827877, -2.0425700721018565, 20.734068007271937],
    [-2.27477823215105, 0.3705118050364553, -4.89768402547568]
  ],
  timeExitedPitch: null,
  positionExitedPitch: {
    x: 0,
    y: 0,
    z: 0
  },
  latencyTestSent: '0001-01-01T00:00:00Z',
  timeAtTouch: null,
  positionAtTouch: {
    x: 0,
    y: 0,
    z: 0
  },
  operatorNotes: null,
  classificationConfidence: 99,
  initialVelocity: null,
  potentialEvent: null
}

export const getMockFlightEvent = (
  id: string,
  typeValue: EventTypeValues,
  sessionId: string,
  teamId: string,
  playerId: string,
  toPlayerId: string,
  outcomeValue: OutcomeTypeValues,
  subTypeValue?: EventSubTypeValues,
  startTime?: number
): RawFlightEventData => {
  return {
    ...mockRawFlightEvent,

    toPlayerId: toPlayerId,

    id: id,
    eventId: id,
    sessionId: sessionId,
    type: typeValue,
    subType: subTypeValue,
    teamID: teamId,
    fromPlayerId: playerId,
    outcome: outcomeValue,

    startTime: startTime,
    timeEnd: startTime + mockRawFlightEvent.hangTime
  }
}

export const mockSportFlightEvents = (
  sport: SportType,
  sessionId: string,
  teamId: string,
  playerId: string,
  toPlayerId: string
) => {
  const events: RawFlightEventData[] = []

  const parentEventTypes = sport.props.parentEventTypes

  const types = parentEventTypes.items.flight.props.types
  // Loop through all flight types
  for (const typeKey in types.items) {
    const type = types.items[typeKey as EventSubTypeKeys]
    const subTypes = type.props.types
    // Loop through all subtypes
    for (const subTypeKey in subTypes.items) {
      const subType = subTypes.items[subTypeKey as EventSubTypeKeys]
      const event = getMockFlightEvent(
        v1().toString(),
        type.value,
        sessionId,
        teamId,
        playerId,
        toPlayerId,
        0,
        subType.value,
        0
      )
      events.push(event)
    }
  }

  return events
}

export const mockPassEvent = getMockFlightEvent(
  'passEvent-1',
  eventTypes.items.pass.value,
  rawSessionOne.id,
  rawTeamOne.id,
  rawPlayerOne.id,
  rawPlayerTwo.id,
  0,
  0,
  rawSessionOne.startTime + 10
)

export const mockKickEvent = getMockFlightEvent(
  'kickEvent-1',
  eventTypes.items.kick.value,
  rawSessionOne.id,
  rawTeamOne.id,
  rawPlayerOne.id,
  rawPlayerTwo.id,
  0,
  0,
  rawSessionOne.startTime + 10
)

export const mockLineoutEvent = getMockFlightEvent(
  'lineoutEvent-1',
  eventTypes.items.lineout.value,
  rawSessionOne.id,
  rawTeamOne.id,
  rawPlayerOne.id,
  rawPlayerTwo.id,
  0,
  0,
  rawSessionOne.startTime + 10
)
