import {
  SET_DIAGNOSTICS,
  SET_FORMATTED_HARDWARE,
  TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL,
  ADD_SERVICE_ACTION_PENDING_FOR_DEVICES,
  REMOVE_SERVICE_ACTION_PENDING_FOR_DEVICES,
  TOGGLE_COLLAPSED_HARDWARE,
  TOGGLE_COLLAPSE_ALL_HARDWARE,
  SET_SEAT_SWAPPING_ALGO_ENABLED,
  FormattedHardware,
  SET_SELECTED_DEVICE,
  HardwareState,
  SeatConfiguration,
  SET_SEAT_CONFIGURATION
} from './types'
import * as api from './api'
import { setError } from '../../ui/error/actions'
import { API_ROOT_URL } from '../../const'
import { request } from '../../utils/request_handler'
import { handleAPIError, requestAction } from '../api/request'
import { setSuccess } from '../../ui/success/actions'
import { formatHardwareData } from './functions'
import { initialState } from './reducer'
import {
  HardwareServiceRequestBody,
  UpdateSeatConfigurationRequestBody
} from './api'
import { AppThunk } from '../../store'
import { SessionModeTypeValues } from '../sessions/modes/data_types'

// ACTION CREATORS //

export function setDiagnositcs(data: HardwareState['diagnostics']) {
  return {
    type: SET_DIAGNOSTICS,
    payload: data
  }
}

export type SetDiagnosticsAction = ReturnType<typeof setDiagnositcs>

export function setFormattedHardware(data: FormattedHardware) {
  return {
    type: SET_FORMATTED_HARDWARE,
    payload: data
  }
}

export type SetFormattedHardwareAction = ReturnType<typeof setFormattedHardware>

export function setSelectedDevice(data: number) {
  return {
    type: SET_SELECTED_DEVICE,
    payload: data
  }
}

export type SetSelectedDeviceAction = ReturnType<typeof setSelectedDevice>

export function addServiceActionPendingForDevices(deviceIds: number[]) {
  return {
    type: ADD_SERVICE_ACTION_PENDING_FOR_DEVICES,
    payload: deviceIds
  }
}

export type AddServiceActionPendingForDevicesAction = ReturnType<
  typeof addServiceActionPendingForDevices
>

export function removeServiceActionPendingForDevices(deviceIds: number[]) {
  return {
    type: REMOVE_SERVICE_ACTION_PENDING_FOR_DEVICES,
    payload: deviceIds
  }
}

export type RemoveServiceActionPendingForDevicesAction = ReturnType<
  typeof removeServiceActionPendingForDevices
>

export function setSeatSwappingAlgoEnabled(data: boolean) {
  return {
    type: SET_SEAT_SWAPPING_ALGO_ENABLED,
    payload: data
  }
}

export type SetSeatSwappingAlgoEnabledAction = ReturnType<
  typeof setSeatSwappingAlgoEnabled
>

export function toggleIgnoreTagForSleepAndWakeAll(data: number) {
  return {
    type: TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL,
    payload: data
  }
}

export type ToggleIgnoreTagForSleepAndWakeAllAction = ReturnType<
  typeof toggleIgnoreTagForSleepAndWakeAll
>

export function toggleCollapsedHardware(encodedId: string) {
  return {
    type: TOGGLE_COLLAPSED_HARDWARE,
    payload: encodedId
  }
}

export type ToggleCollapsedHardwareAction = ReturnType<
  typeof toggleCollapsedHardware
>

export function toggleCollapseAllHardware(collapse: boolean) {
  return {
    type: TOGGLE_COLLAPSE_ALL_HARDWARE,
    payload: collapse
  }
}

export type ToggleCollapseAllHardwareAction = ReturnType<
  typeof toggleCollapseAllHardware
>

export function setSeatConfiguration(data: SeatConfiguration) {
  return {
    type: SET_SEAT_CONFIGURATION,
    payload: data
  }
}

export type SetSeatConfigurationAction = ReturnType<typeof setSeatConfiguration>

// ====== //

export function formatDiagnosticsWebsocketAndUpdateStore(
  diagnostics: HardwareState['diagnostics']
) {
  return (dispatch, getState) => {
    // Convert null status (online, sleep, offline) values to empty arrays
    // Move this to formatHardwareData function
    for (const type in diagnostics.status.online) {
      if (!diagnostics.status.online[type]) diagnostics.status.online[type] = []
    }
    for (const type in diagnostics.status.offline) {
      if (!diagnostics.status.offline[type])
        diagnostics.status.offline[type] = []
    }
    for (const type in diagnostics.status.sleep) {
      if (!diagnostics.status.sleep[type]) diagnostics.status.sleep[type] = []
    }

    if (!diagnostics.hardwareState)
      diagnostics.hardwareState = initialState.diagnostics.hardwareState

    dispatch(setDiagnositcs(diagnostics))

    const pitches = getState().pitches
    const formattedHardware = formatHardwareData(
      diagnostics,
      pitches?.inUse?.anchors
    )
    dispatch(setFormattedHardware(formattedHardware))
  }
}

export function updateQaState(data) {
  return (dispatch) => {
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update qa state. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `devices/${data.id}`,
      method: 'PUT',
      baseURL: API_ROOT_URL,
      withCredentials: true,
      data
    }
    return request(options, null, error, dispatch)
  }
}

export function serviceAction(
  tagIds: HardwareServiceRequestBody['tagIds'],
  type: HardwareServiceRequestBody['type'],
  hideSuccessModal?
): AppThunk {
  return async (dispatch, getState) => {
    // Add list of devices to service action pending list
    // - this is to avoid spamming the MS and for displaying loaders over the buttons //
    addServiceActionPendingForDevices(tagIds)
    try {
      await api.hardwareService({ tagIds, type })
      removeServiceActionPendingForDevices(tagIds)
      if (!hideSuccessModal) {
        dispatch(setSuccess({ message: `${type} success` }))
      }
    } catch (error) {
      removeServiceActionPendingForDevices(tagIds)
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

export function enableRSSIAction() {
  return (dispatch) => {
    return dispatch(
      requestAction(
        api.enableRSSI,
        () => {
          dispatch(setSuccess({ message: `RSSI enabled` }))
        },
        null
      )
    )
  }
}

export function disableRSSIAction() {
  return (dispatch) => {
    return dispatch(
      requestAction(
        api.disableRSSI,
        () => {
          dispatch(setSuccess({ message: `RSSI disabled` }))
        },
        null
      )
    )
  }
}

// Seat Swapping //

// Get seat swapping algo state //
export function getSeatSwappingAlgoState() {
  return async (dispatch) => {
    try {
      const response = await api.getSeatSwappingAlgoState()
      dispatch(setSeatSwappingAlgoEnabled(response.data.Enabled))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Update seat swapping algo state //
export function updateSeatSwappingAlgoState() {
  return async (dispatch) => {
    try {
      const response = await api.updateSeatSwappingAlgoState()
      dispatch(setSeatSwappingAlgoEnabled(response.data.Enabled))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Get seat configuration //
export function getSeatConfiguration() {
  return async (dispatch) => {
    try {
      const response = await api.getSeatConfiguration()
      dispatch(setSeatConfiguration(response.data))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Update seat Configuration//
export function updateSeatConfiguration({
  sportType,
  sessionType,
  sessionSubType
}: UpdateSeatConfigurationRequestBody) {
  return async (dispatch) => {
    try {
      const response = await api.updateSeatConfiguration({
        sportType,
        sessionType,
        sessionSubType
      })
      dispatch(setSeatConfiguration(response.data))
    } catch (error) {
      dispatch(setSeatConfiguration(null))
    }
  }
}

// Post daemon seat configuration //
export function postSeatConfigurationToDaemon(
  sessionMode: SessionModeTypeValues
) {
  return async (dispatch) => {
    try {
      const response = await api.postSeatConfigurationToDaemon({ sessionMode })
      return response
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Reset seat swapping //
export function resetSeatSwapping() {
  return async (dispatch) => {
    try {
      await api.resetSeatSwapping()
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Demote all tags and balls off pitch //
export function demoteAllOffPitch() {
  return async (dispatch) => {
    try {
      await api.demoteAllOffPitch()
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Promote all tags and balls on pitch //
export function promoteAllOnPitch(successMessage?: boolean) {
  return async (dispatch) => {
    try {
      await api.promoteAllOnPitch()
      if (successMessage)
        dispatch(setSuccess({ message: `Promote all on pitch success` }))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}
