import React from 'react'
import downloadIcon from '../../../../../assets/img/download-inverse.svg'
import styles from '../Notification.module.scss'
import { useAppDispatch } from '../../../../../store/hooks'
import { clearNotification } from '../../../actions'
import { NotificationType } from '../../../types'

export interface DownloadNotificationProps {
  notification: NotificationType
}

export const DownloadNotification = (props: DownloadNotificationProps) => {
  const dispatch = useAppDispatch()

  const { notification } = props

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${downloadIcon})` }}
        className={styles.icon}
      ></div>
      <div className={styles.messageContainer}>
        <div style={{ display: 'flex' }} className={styles.title}>
          <h5>{notification.text}!</h5>
          <h5
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(clearNotification(notification))}
          >
            X
          </h5>
        </div>
      </div>
    </div>
  )
}
