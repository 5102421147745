import React, { useCallback } from 'react'
import { TagIcon } from '../../../../../components/Icons/TagIcon/TagIcon'
import { PlayerAvatar } from '../../PlayerAvatar/PlayerAvatar'
import styles from '../Player.module.scss'
import {
  useFormattedSession,
  useTagAssignmentDragAndDrop
} from '../../../../sessions/hooks'
import { useFormattedHardware } from '../../../../hardware/hooks'
import {
  addPlayerSession,
  removePlayerSession
} from '../../../../sessions/actions'
import { useAppDispatch } from '../../../../../store/hooks'
import { useFormattedPlayer } from '../../../hooks'
import { FormattedSession } from '../../../../sessions/types'
import { FormattedPlayer } from '../../../types'
import { setError } from '../../../../../ui/error/actions'
import {
  generateRandomNumberNotInList,
  getFirstAvailableNumber
} from '../../../../sessions/functions'

export interface SessionPlayerProps {
  size?: string
  sessionId: string
  playerId: string
}

export const getPlayerNumber = (
  player: FormattedPlayer,
  formattedSession: FormattedSession
) => {
  const checkIfNumberIsTaken = (number) => {
    return formattedSession.playersSessions.byNumber[player.teamId].map[number]
  }
  const getRandomNumber = () => {
    return Math.floor(Math.random() * 100)
  }
  let playerNumber = player.number || getRandomNumber()

  while (checkIfNumberIsTaken(playerNumber)) {
    playerNumber = getRandomNumber()
  }
  return playerNumber
}

export const SessionPlayer = ({
  playerId,
  sessionId,
  size = 's'
}: SessionPlayerProps) => {
  const dispatch = useAppDispatch()

  const formattedSession = useFormattedSession(sessionId)
  const { isTrainingMode, sport, playersSessions, officiationTeam } =
    formattedSession
  const formattedHardware = useFormattedHardware()
  const player = useFormattedPlayer(playerId)
  const playerSession = playersSessions.byPlayerId.map[playerId]
  const device =
    playerSession && formattedHardware.devices.map[playerSession.hardwareId]

  const { onDragLeave, onDragOver, onTagDrop, onPlayerDrag, draggable } =
    useTagAssignmentDragAndDrop('setup')

  const handleClick = useCallback(() => {
    if (playerSession) {
      // If the player is assigned to a shirt, remove the player session
      dispatch(removePlayerSession(playerSession.playerId))
    } else if (
      isTrainingMode ||
      !sport.props.features.positionNumbers ||
      (officiationTeam && officiationTeam.id === player.teamId)
    ) {
      const teamPlayersSessions = playersSessions.byNumber[player.teamId]
      const numbersUsed = teamPlayersSessions.list.map((playerSession) => {
        return playerSession.number
      })
      // If it's a training mode or Sport type has no position numbers, add the player session
      const playerNumber = sport.props.features.positionNumbers
        ? getFirstAvailableNumber(numbersUsed)
        : generateRandomNumberNotInList(numbersUsed, 100, player.number)

      // If the player number is null, don't add the player session and alert user max players reached
      if (playerNumber === null) {
        dispatch(setError({ message: 'Max players reached' }))
        return
      }

      numbersUsed.push(playerNumber)

      dispatch(
        addPlayerSession({
          id: null,
          sessionId: null,
          player: player.rawData,
          tag: null,
          playerId: player.id,
          teamId: player.teamId,
          number: playerNumber
        })
      )
    }
  }, [formattedSession, playerSession, player, dispatch, isTrainingMode, sport])

  const tileSize =
    size === 's'
      ? styles.tileSmall
      : size === 'm'
      ? styles.tileMedium
      : styles.tileLarge

  return (
    <div
      className={`${styles.playerTileContainer}  ${tileSize} ${
        playerSession && styles.linked
      }`}
      key={player.id}
      id={player.id}
      onDragStart={(e) => onPlayerDrag(e)}
      onDrop={(e) => onTagDrop(e, null, player.id)}
      onDragLeave={(e) => onDragLeave(e)}
      onDragOver={(e) => onDragOver(e)}
      draggable={draggable}
      onClick={handleClick}
    >
      {player.img ? (
        <div className={styles.imageContainer}>
          {device && (
            <div className={styles.assignedTag}>
              <TagIcon color={'white'} scale={0.3} strokeWidth={4} />
            </div>
          )}
          <img
            draggable={false}
            id={player.id}
            className={`${styles.img}`}
            src={player.img}
            alt=''
          />
        </div>
      ) : (
        <div className={styles.avatarContainer} id={player.id}>
          {device && (
            <div className={styles.assignedTag}>
              <TagIcon color={'white'} scale={0.3} strokeWidth={4} />
            </div>
          )}
          <PlayerAvatar autoScale />
        </div>
      )}

      <div id='player-name' className={styles.playerDetailsContainer}>
        <p className={styles.nameInfo}>
          <b>{player.lastName}</b>,
        </p>
        <div className={styles.tagAndNumberContainer}>
          <div className={styles.tagInfo}>
            {/* {playersSession.tag && playersSession.tag.serial} */}
            {player.firstName}
          </div>
          {!isTrainingMode && (
            <p className={styles.number}>{`${
              playerSession?.number || player.number || ''
            }`}</p>
          )}
        </div>
      </div>
    </div>
  )
}
