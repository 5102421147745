import React, { memo, useMemo } from 'react'
import styles from './HardwareGrid.module.scss'
import { HardwareItem } from './HardwareItem/HardwareItem'
import { useHardwareFilter } from '../../filter'
import { DeviceTypeValues, deviceTypes, isDeviceType } from '../../data_types'
import {
  useBallAssignmentFunctions,
  usePlayerAssignmentFunctions
} from '../../../sessions/hooks'
import { Button } from '../../../../components/Button/Button'
import { Grid } from '@mui/material'
import { SearchableDropdown } from '../../../../components/SearchableDropdown/SearchableDropdown'
import BasicSelect from '../../../../components/Material/Select'
import { HardwareStates } from '../../types'

export interface HardwareGridProps {
  deviceTypeValue: DeviceTypeValues
  hardwareStates?: HardwareStates[]
  teamId?: string
  isSessionAssignment?: boolean
}

export const HardwareGrid = memo((props: HardwareGridProps) => {
  const {
    deviceTypeValue,
    isSessionAssignment = false,
    teamId,
    hardwareStates
  } = props

  const deviceType = deviceTypes.getTypeByValue(deviceTypeValue)

  const { filteredHardware, filters, updateFilterValue } = useHardwareFilter(
    {
      type: {
        value: deviceType.value
      },
      team: {
        value: teamId
      },
      status: {
        value: hardwareStates
      }
    },
    'setup'
  )

  const { addAllBallsToSession, removeAllBallsFromSession } =
    useBallAssignmentFunctions()
  const { randomTagAssign } = usePlayerAssignmentFunctions(teamId)

  const handleSearchChange = (event) => {
    updateFilterValue('searchStr', event)
  }

  const header = useMemo(() => {
    if (isSessionAssignment) {
      if (isDeviceType.playerTag(deviceType)) {
        return 'Drag and Drop Tags onto Players'
      }
      if (isDeviceType.ball(deviceType)) {
        return 'Select balls to add to session'
      }
    }
    return null
  }, [isSessionAssignment, deviceType])

  return (
    <div className={styles.hardwardGridContainer}>
      <div className={styles.hardwareFiltersContainer}>
        {header && <h5 className={styles.title}>{header}</h5>}
        {isSessionAssignment && (
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={2}>
              <SearchableDropdown
                name='status'
                fontSize={15}
                items={filters.status.options}
                onSelect={(value) => updateFilterValue('status', value)}
                filterName={'status'}
                value={filters.status.value}
                label={'Status'}
                isMultiple={true}
                inputVariant='standard'
                labelShrink={true}
              />
            </Grid>
            <Grid item xs={2}>
              <BasicSelect
                variant='standard'
                options={filters.assigned.options}
                onChange={(value) => updateFilterValue('assigned', value)}
                label={'Assigned'}
                selected={filters.assigned.value}
                size={'small'}
                fontSize={15}
              />
            </Grid>
            <Grid item xs={2}>
              <BasicSelect
                label={'Search Tags'}
                variant='standard'
                selected={filters.searchStr.value}
                onChange={(e) => handleSearchChange(e)}
                labelShrink={true}
                size={'small'}
                fontSize={20}
              />
            </Grid>
            {isDeviceType.playerTag(deviceType) && (
              <Grid item xs={2}>
                <Button
                  className={'btn--primary-thin'}
                  handleClick={randomTagAssign}
                  style={{ width: '100%' }}
                >
                  Quick Assign Tags
                </Button>
              </Grid>
            )}
          </Grid>
        )}

        {isDeviceType.ball(deviceType) && isSessionAssignment && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginTop: '5px' }}>
              <Button
                id={'addAllBallsButton'}
                className={'btn--primary-thin'}
                handleClick={addAllBallsToSession}
                style={{ alignSelf: 'center' }}
              >
                Add All Balls
              </Button>
            </div>
            <div style={{ marginTop: '5px' }}>
              <Button
                className={'btn--border-thin'}
                handleClick={removeAllBallsFromSession}
                style={{ alignSelf: 'center' }}
              >
                Remove All Balls
              </Button>
            </div>
          </div>
        )}
      </div>

      {filteredHardware.count < 1 ? (
        <div className={styles.noHardware}>
          <h5>
            {isDeviceType.anchor(deviceType) && 'No anchors found...'}
            {isDeviceType.playerTag(deviceType) && 'No player tags found...'}
            {isDeviceType.ball(deviceType) && 'No balls found...'}
          </h5>
        </div>
      ) : (
        <div className={styles.hardwareScroller}>
          <div className={styles.hardwardGrid}>
            {filteredHardware.list.map((device) => (
              <HardwareItem
                key={'hardwareItem-' + device.id}
                device={device}
                isSessionAssignment={isSessionAssignment}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
})
