import { Team } from '../teams/types'
import { IDMap } from '../types'

export interface PlayersState {
  fetched: boolean
  hasValidData: boolean
  selectedPlayer: string
  items: IDMap<PlayerData>
  rawData: IDMap<RawPlayerData>
}

export interface PlayerData {
  id: string
  firstName: string
  lastName: string
  number?: number
  color?: string
  position?: string
  weight?: number
  height?: number
  teamId?: string
  img?: string
  sessionId?: string
}

export interface RawPlayerData {
  id: string
  firstName: string
  lastName: string
  number: number
  position: string
  weight: number
  height: number
  teamId: string
  img: string
  color: string
}

export const EMPTY_RAW_PLAYER_DATA: RawPlayerData = {
  id: null,
  firstName: '',
  lastName: '',
  number: 0,
  position: '',
  weight: 0,
  height: 0,
  teamId: '',
  img: '',
  color: ''
}

export interface FormattedPlayer {
  rawData: RawPlayerData
  id: string
  firstName: string
  lastName: string
  fullName: string
  abbreviatedName: string
  initials: string
  number: number
  position: string
  weight: number
  height: number
  teamId: string
  img: string
  nameAndNumber: string
  color: string
}

export const EMPTY_FORMATTED_PLAYER: FormattedPlayer = {
  rawData: EMPTY_RAW_PLAYER_DATA,
  id: '',
  firstName: '',
  lastName: '',
  fullName: 'Not Found',
  abbreviatedName: '',
  initials: '',
  number: 0,
  position: '',
  weight: 0,
  height: 0,
  teamId: '',
  img: '',
  nameAndNumber: '',
  color: ''
}

export interface FormattedPlayerData extends PlayerData {
  team: Team
}

export type AppPlayer = {
  data: PlayerData
}

export interface PlayerFilterType {
  name: string
  teamId: string
  value: string
}

export type ValidatePlayerValues = {
  firstName: string
  lastName: string
  position: string
  number: string
  weight: number
  height: number
  img: string
}

export interface PlayerKeyItemType {
  id: string
  firstName: string
  lastName: string
  weight: number
  height: number
  position: string
  img: string
  number: number
  teamId: string
  color: string
}

export const GET_PLAYERS = 'GET_PLAYERS'
export const CREATE_PLAYER = 'CREATE_PLAYER'
export const EDIT_PLAYER = 'EDIT_PLAYER'
export const DELETE_PLAYER = 'DELETE_PLAYER'
export const SET_PLAYER = 'SET_PLAYER'
export const GET_PLAYER_USERS = 'GET_PLAYER_USERS'
export const GET_TEAM_PLAYERS = 'GET_TEAM_PLAYERS'
