import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '../../../ui/config/hook'
import Loader from '../../Loader/Loader'
import { Controls } from '../../Strack/StrackOverlays/Controls/Controls'
import { StrackOverlay } from '../../Strack/StrackOverlays/StrackOverlay'
import {
  Canvases,
  CanvasStyle,
  CanvasViews,
  InitiateStrack,
  StrackControllerProps,
  StrackOptions
} from '../Strack.types'
import styles from './StrackContainer.module.scss'
import { StrackTeams } from '../StrackOverlays/Teams/Teams'
import { StrackCenterLogo } from '../StrackOverlays/CenterLogo/CenterLogo'
import { StrackAnchors } from '../StrackAnchors/StrackAnchors'
import { StrackInfo } from '../StrackInfo/StrackInfo'
import { StrackTagInfo } from '../StrackOverlays/InfoContainer/Tag'
import { StrackTargets } from '../StrackTargets/StrackTargets'
import { StrackTracking } from '../StrackTracking/StrackTracking'
import { StrackDrillZone } from '../StrackOverlays/DrillZone/DrillZone'

interface StrackCanvas {
  canvases?: Canvases
  canvasViews?: CanvasViews
  windowWidth?: number
  windowHeight?: number
  initiateStrack?: InitiateStrack
  setSelectedCanvas?: (canvasId: string) => void
  toggleCanvasView?: (canvasId: string, view: string) => void
  toggleCanvasVisibility?: (canvasId: string) => void
  selectedCanvas?: string
  active?: boolean
  selected?: boolean
  canvasId: string
  strackOptions: StrackOptions
  canvasStyle: CanvasStyle
  children?: React.ReactElement<any>
  fetchingPitch?: boolean
  hidden?: boolean
  hiddenCanvases?: string[]
  live?: boolean
  updateCanvasWidth?: (canvasId: string, width: string) => void
  setCanvasStyle?: (canvasId: string, width: string) => void
  hideCanvas?: boolean
  canvasWidths?: { [canvasId: string]: string }
  canvasHeights?: { [canvasId: string]: string }
}

export function StrackCanvas({
  // Strack
  initiateStrack,
  setSelectedCanvas,
  selectedCanvas,
  canvases,
  canvasStyle,
  canvasId,
  strackOptions,
  children,
  windowWidth,
  windowHeight,
  fetchingPitch,

  // Component
  active,
  selected,

  toggleCanvasView,
  toggleCanvasVisibility,
  canvasViews,
  hiddenCanvases,
  live,
  updateCanvasWidth,
  setCanvasStyle,
  hideCanvas,
  canvasWidths,
  canvasHeights
}: StrackCanvas) {
  const [strackReady, setStrackReady] = useState(false)
  const [resetStrack, setResetStrack] = useState(false)
  const [resetStrackOnActive, setResetStrackOnActive] = useState(false)
  const [canvasInitiated, setCanvasInitiated] = useState(false)
  const [strackUpdateSwitch, setStrackUpdateSwitch] = useState(false)
  const viewContainer = useRef(null)

  /*=============== Canvas / Strack effects ===============*/
  // Initiate strack
  useEffect(() => {
    if (!canvasInitiated && selectedCanvas === canvasId && !fetchingPitch) {
      setCanvasInitiated(true)
      initiateStrack(strackOptions, canvasStyle, () => {
        setStrackReady(true)
      })
    }
  }, [canvasInitiated, canvasStyle, selectedCanvas, fetchingPitch])

  // Reset Strack
  useEffect(() => {
    if (resetStrack) {
      setResetStrack(false)
      if (canvases[canvasId]) {
        const strack = canvases[canvasId].strack
        strack.end(() => {
          setStrackReady(false)
          setCanvasInitiated(false)
        })
      }
    }
  }, [resetStrack])

  // Rebuild canvas on screen resize
  useEffect(() => {
    if (active) {
      setResetStrack(true)
    } else if (strackReady) {
      setResetStrackOnActive(true)
    }
  }, [windowWidth, windowHeight])

  // Switch view based on tab selected
  useEffect(() => {
    if (active) {
      viewContainer.current.style.display = 'block'
      viewContainer.current.style.pointerEvents = 'auto'

      // Set correct canvas
      setSelectedCanvas(canvasId)
    }
  }, [active])

  useEffect(() => {
    if (!selected) {
      viewContainer.current.style.display = 'none'
      viewContainer.current.style.pointerEvents = 'none'
      if (active) {
        setSelectedCanvas(null)
      }
    }
  }, [selected])

  useEffect(() => {
    if (selectedCanvas === canvasId && strackReady) {
      if (resetStrackOnActive) {
        setResetStrackOnActive(false)
        setResetStrack(true)
      }
    }
  }, [selectedCanvas])

  useEffect(() => {
    const canvasWidth = canvasWidths[canvasId]
    const canvasHeight = canvasHeights[canvasId]
    if (canvasWidth || canvasHeight) {
      setResetStrack(true)
    }
  }, [canvasWidths[canvasId], canvasHeights[canvasId]])

  const toggleThisCanvasView = (view) => {
    toggleCanvasView(canvasId, view)
  }

  const renderChildrenWithProps = (strackReady, canvases) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const newProps: StrackControllerProps = {
          active,
          strack: canvases[canvasId] ? canvases[canvasId].strack : null,
          strackReady,
          setResetStrack,
          canvasView: canvasViews[canvasId],
          toggleCanvasView: toggleThisCanvasView,
          toggleCanvasVisibility: toggleCanvasVisibility,
          canvasStyle,
          show2DOnly: strackOptions.show2DOnly,
          updateCanvasWidth,
          setCanvasStyle,
          hideCanvas,
          canvasId
        }
        return React.cloneElement(child, newProps)
      }
    })
  }

  const isHidden = hiddenCanvases.indexOf(canvasId) >= 0

  const theme = useTheme()

  const strack = canvases[canvasId] ? canvases[canvasId].strack : null
  const canvasView = canvasViews[canvasId]

  return (
    <div ref={viewContainer} className={styles.viewContainer}>
      {!isHidden && (
        <>
          <></>
          {strackReady ? (
            <>
              {/* Features */}
              {strackOptions.tracking && (
                <StrackTracking
                  strack={strack}
                  strackReady={strackReady}
                  canvasId={canvasId}
                />
              )}
              {strackOptions.targets && (
                <StrackTargets
                  active={active}
                  canvasView={canvasView}
                  strack={strack}
                  canvasStyle={canvasStyle}
                  strackReady={strackReady}
                />
              )}
              {/* Overlays */}
              <StrackOverlay
                canvasStyle={canvasStyle}
                strackReady={strackReady}
                canvasWidth={canvasWidths[canvasId]}
                canvasHeight={canvasHeights[canvasId]}
              >
                <Controls
                  strack={strack}
                  strackReady={strackReady}
                  canvasView={canvasView}
                  toggleCanvasView={toggleThisCanvasView}
                  strackOptions={strackOptions}
                  live={live}
                  updateStrack={() =>
                    setStrackUpdateSwitch(!strackUpdateSwitch)
                  }
                />
                {strackOptions.teams && (
                  <StrackTeams strack={strack} canvasView={canvasView} />
                )}
                {strack?.anchorSetup?.active && (
                  <StrackAnchors strack={strack} />
                )}
                {strackOptions.tagInfo && (
                  <StrackInfo>
                    <StrackTagInfo strackReady={strackReady} strack={strack} />
                  </StrackInfo>
                )}
                {/* DRILLs */}
                {strackOptions.drillRegions && (
                  <StrackDrillZone
                    strack={strack}
                    active={active}
                    strackUpdateSwitch={strackUpdateSwitch}
                  />
                )}
              </StrackOverlay>
            </>
          ) : (
            <div
              style={{
                ...canvasStyle,
                zIndex: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loader message={'loading pitch...'} color={theme?.appColor} />
            </div>
          )}
        </>
      )}
      {renderChildrenWithProps(strackReady, canvases)}
    </div>
  )
}
