import { ViewType } from '../../components/Views/Views.types'
import {
  formMatrix,
  formConfig,
  JoinOrganisationAndTeamFormConfig
} from './form_config'
import { FormViewProps } from '../../components/Views/Form/Form.types'
import { Route } from '../../App/App.types'
import { useJoinOrganisationAndTeamForm } from './form'

export const path = '/joinorganisationandteam'

export type JoinOrganisationAndTeamFormProps =
  FormViewProps<JoinOrganisationAndTeamFormConfig>

export const joinOrganisationAndTeamRoute: Route<JoinOrganisationAndTeamFormProps> =
  {
    path: path,
    view: {
      type: ViewType.FORM,
      name: 'Join Organsiation And Team',
      side: 0,
      formMatrix,
      formConfig,
      useForm: useJoinOrganisationAndTeamForm
    }
  }
