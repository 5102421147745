import React, { Component } from 'react'

import styles from './Checkbox.module.scss'
import { StarIcon } from '../../../Icons/StarIcon/StarIcon'

type OnClicked = (value: boolean) => void

interface Input {
  value: boolean
}

interface Meta {
  touched: boolean
  error: boolean
  initial: any
}

export interface CheckboxProps {
  checked: boolean
  onClicked: OnClicked
  input?: Input
  meta?: Meta
  size?: string
  scale?: number
  label?: string
  disabled?: boolean
  checkedColor?: string
  type?: string
  name?: string
  textColour?: string
}

const Checkbox = ({
  size,
  scale,
  checked,
  input,
  disabled,
  label,
  meta,
  checkedColor = '#F64645',
  textColour = '#000000',
  onClicked,
  type,
  name
}: CheckboxProps) => {
  function handleClick() {
    if (checked === undefined) {
      checked = input.value
    }

    onClicked(!checked)
  }

  const containerStyle = {
    margin: size === 'small' ? '0' : '10px 0'
  }
  const checkboxStyle = {
    width: size === 'small' ? '15px' : '17px',
    height: size === 'small' ? '15px' : '17px'
  }

  if (checked === undefined) {
    checked = input.value
  }

  return (
    <div
      style={containerStyle}
      className={`checkbox-container ${disabled ? styles.disabled : ''}`}
      onClick={handleClick}
    >
      <input
        type='checkbox'
        name={name}
        checked={checked}
        style={{ display: 'none' }}
        readOnly={true}
      />
      {type === 'highlight' ? (
        <StarIcon checked={checked} scale={scale} />
      ) : (
        <div style={checkboxStyle} className='checkbox' role='checkbox'>
          <svg
            width='15px'
            height='13px'
            viewBox='0 0 15 14'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
          >
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g
              id='Symbols'
              stroke='none'
              strokeWidth='1'
              fill='none'
              fillRule='evenodd'
            >
              <g
                id='selected'
                transform='translate(-4.000000, -5.000000)'
                fillRule='nonzero'
              >
                {checked && (
                  <polyline
                    style={{
                      strokeDashoffset: `${0}`,
                      strokeDasharray: `100%`,
                      opacity: `${1}`
                    }}
                    id='tick'
                    stroke={checkedColor}
                    strokeWidth='3'
                    points='6.5,13.5 9.5,16.5 16.5,8.5 '
                    strokeLinejoin='round'
                    strokeLinecap='round'
                  />
                )}
              </g>
            </g>
          </svg>
        </div>
      )}
      {label && (
        <div className='checkbox-label-container'>
          <p style={{ color: textColour }}>{label}</p>
          {meta && meta.touched && meta.error && (
            <p className='checkbox-error'>*</p>
          )}
        </div>
      )}
    </div>
  )
}

export default Checkbox
