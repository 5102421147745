import { Card, CardContent } from '@frontend/ui'

interface TopPerformersCardProps {
  label: string
  topPerformers: {
    name: string
    value: number
    avatar: string
  }[]
}

export function TopPerformersCard({
  label,
  topPerformers
}: TopPerformersCardProps) {
  return (
    <Card className='w-1/3'>
      <CardContent className='p-6 space-y-4'>
        <div className='space-y-2'>
          {' '}
          <p className='text-3xl'>{label}</p>
        </div>
        <div className='space-y-2'>
          <p className='text-4xl font-semibold'>
            {topPerformers[0].value ?? 'N/A'} 🔥
          </p>
          <p>{topPerformers[0].name ?? 'N/A'}</p>
        </div>
        <div className='items-center gap-2'>
          {topPerformers
            .filter((_, index) => index !== 0)
            .map((performer, index) => {
              return (
                <p key={performer.name}>
                  {performer.name ?? 'N/A'} ({performer.value ?? 'N/A'})
                </p>
              )
            })}
        </div>
      </CardContent>
    </Card>
  )
}
