import React, { useContext } from 'react'
import { ProviderWrapper } from '../store/Provider'
import { MSUserHOC } from './MSUserHOC'
import App from '../App/App'
import { AppConfig } from '../App/App.types'
import '../index.scss'
import { ReactReduxContext } from 'react-redux'

export function createSportableApp(
  initialState,
  appConfig: AppConfig,
  storybook?
) {
  return ProviderWrapper(
    (props) => {
      return (
        <SportableApp
          storybook={storybook}
          appConfig={appConfig}
          landing={appConfig.landing}
        />
      )
    },
    { ...initialState, config: { application: appConfig } }
  )
}

export const SportableApp = ({ storybook, appConfig, landing }) => {
  // Metrics Server User Higher Order Component //
  const MSApp = MSUserHOC(App)

  const { store } = useContext(ReactReduxContext)
  // expose store when run in Cypress
  if ('Cypress' in window && 'store' in window && window.Cypress) {
    window.store = store
  }

  return (
    <MSApp
      storybook={storybook}
      appConfig={appConfig}
      landing={appConfig.landing}
    />
  )
}
