import { AnchorIcon } from '../../components/Icons/AnchorIcon/AnchorIcon'
import { SportableBall } from '../../components/Icons/SportableBall/SportableBall'
import { TagIcon } from '../../components/Icons/TagIcon/TagIcon'
import { sportableColors } from '../../constants/sportableColors'
import { css } from '@emotion/css'
import { SportTypeValues } from '../sports/data_types'

export const renderDeviceIcon = (
  deviceType: string,
  sportType?: SportTypeValues
) => {
  if (deviceType === 'Ball') {
    return <SportableBall scale={1} sportType={sportType} />
  } else if (deviceType === 'PlayerTag') {
    return <TagIcon scale={1} />
  } else {
    return <AnchorIcon />
  }
}

// Check for incorrect Low Battery: 300% warnings
export const checkLowBattLevelAndReturnCorrectText = (description) => {
  const splitDescription = description.split(' ')
  const percentageLevelAsString = splitDescription[splitDescription.length - 1]
  const percentageLevelAsInt = parseInt(percentageLevelAsString.slice(0, -1))

  return percentageLevelAsInt >= 100 ? 'Low Battery!' : description
}

export const getScrollHeight = (numOfAlerts: number) => {
  let scrollHeight: string | undefined
  if (numOfAlerts === 1) {
    scrollHeight = '85px'
  } else if (numOfAlerts === 2) {
    scrollHeight = '165px'
  } else if (numOfAlerts === 3) {
    scrollHeight = '245px'
  } else if (numOfAlerts >= 4) {
    scrollHeight = '325px'
  } else {
    scrollHeight = undefined
  }
  return scrollHeight
}

export const badgeStyle = (count: number) => css`
  ::after {
    content: '${count}';
    background: ${sportableColors.colors.sportableBlue};
    color: white;
    position: absolute;
    right: 0;
    top: 0px;
    width: ${count < 10 ? '16px' : '18px'};
    height: ${count < 10 ? '16px' : '18px'};
    border-radius: 100%;
    font-size: ${count < 10 ? '10px' : '12px'};
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
`
