import { useEffect } from 'react'
import { DeviceTypeValues } from '../hardware/data_types'
import { useAppDispatch } from '../../store/hooks'
import { addNotification } from './actions'

const timeNow = new Date().getTime() / 1000

export const diagNotificationMock = {
  type: 'LowIMUDataRateNotification',
  deviceType: 'Ball',
  timestamp: timeNow,
  title: 'Ball CA8008',
  description: 'IMU data rate: 33Hz',
  arrivalTime: timeNow,
  id: 123
}

export const generateMockDiagnosticsNotification = (
  id,
  type:
    | 'LowIMUDataRateNotification'
    | 'DeviceResetNotification'
    | 'MaximumSeatAllocationNotification'
    | 'LowBatteryNotification',
  deviceType: DeviceTypeValues,
  deviceId: string,
  pecentage: number
) => {
  const timeNow = new Date().getTime() / 1000
  return {
    type,
    deviceType: deviceType,
    timestamp: timeNow,
    title: `${deviceType} ${deviceId}`,
    description: `Low Battery: ${pecentage}%`,
    arrivalTime: timeNow,
    id
  }
}

export const useMockNotificationsInterval = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    let count = 1
    const interval = setInterval(() => {
      dispatch(
        addNotification(
          generateMockDiagnosticsNotification(
            count,
            'LowBatteryNotification',
            'Ball',
            'CA676G',
            25 + count
          )
        )
      )
      count++
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])
}

export const notificationsMock = {
  notificationsHistory: [
    {
      type: 'LowIMUDataRateNotification',
      deviceType: 'Ball',
      timestamp: timeNow,
      title: 'Ball CA8008',
      description: 'IMU data rate: 33Hz',
      arrivalTime: timeNow,
      id: 123
    },
    {
      type: 'DeviceResetNotification',
      deviceType: 'Anchor',
      timestamp: timeNow,
      title: 'Anchor CA8008',
      description: 'Device reset, uptime: 12s',
      arrivalTime: timeNow,
      id: 456
    },
    {
      type: 'MaximumSeatAllocationNotification',
      timestamp: timeNow,
      title: 'Maximum seat allocation',
      arrivalTime: timeNow,
      id: 789
    },
    {
      type: 'LowBatteryNotification',
      deviceType: 'PlayerTag',
      timestamp: timeNow,
      title: 'PlayerTag CA8008',
      description: 'Low Battery: 25%',
      arrivalTime: timeNow,
      id: 101112
    }
  ],
  allNotificationsHistory: [
    {
      type: 'LowIMUDataRateNotification',
      deviceType: 'Ball',
      timestamp: timeNow,
      title: 'Ball CA8008',
      description: 'IMU data rate: 33Hz',
      arrivalTime: timeNow,
      id: 123
    },
    {
      type: 'DeviceResetNotification',
      deviceType: 'Anchor',
      timestamp: timeNow,
      title: 'Anchor CA8008',
      description: 'Device reset, uptime: 12s',
      arrivalTime: timeNow,
      id: 456
    },
    {
      type: 'MaximumSeatAllocationNotification',
      timestamp: timeNow,
      title: 'Maximum seat allocation',
      arrivalTime: timeNow,
      id: 789
    },
    {
      type: 'LowBatteryNotification',
      deviceType: 'PlayerTag',
      timestamp: timeNow,
      title: 'PlayerTag CA8008',
      description: 'Low Battery: 25%',
      arrivalTime: timeNow,
      id: 101112
    }
  ]
}
