import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../data_types'
import { ParentEventTypeProps } from '../data_types'
import { defaultEventFeatures } from '../types/data_types'

export const eventSubTypesConfig = {
  // Flight Event Sub Types //
  unclassifiedFlightEvent: {
    key: 'unclassifiedFlightEvent' as const,
    value: 0,
    name: 'Unclassified',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  boxKick: {
    key: 'boxKick',
    value: 1,
    name: 'Box Kick',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  chip: {
    key: 'chip',
    value: 2,
    name: 'Chip',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  clearance: {
    key: 'clearance',
    value: 3,
    name: 'Clearance',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  crossfield: {
    key: 'crossfield',
    value: 4,
    name: 'Crossfield',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  conversion: {
    key: 'conversion',
    value: 5,
    name: 'Conversion',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  restart: {
    key: 'restart',
    value: 6,
    name: 'Restart',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  grubber: {
    key: 'grubber',
    value: 7,
    name: 'Grubber',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultEventFeatures, shortcut: 'g' }
    }
  },
  dropGoal: {
    key: 'dropGoal',
    value: 8,
    name: 'Drop Goal',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  penaltyFlightEvent: {
    key: 'penaltyFlightEvent',
    value: 9,
    name: 'Penalty',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  bomb: {
    key: 'bomb',
    value: 10,
    name: 'Bomb',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  restart22: {
    key: 'restart22',
    value: 11,
    name: '22m Restart',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  penaltyToTouch: {
    key: 'penaltyToTouch',
    value: 12,
    name: 'Penalty To Touch',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  upAndUnder: {
    key: 'upAndUnder',
    value: 13,
    name: 'Up & Under',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fiftyTwentyTwo: {
    key: 'fiftyTwentyTwo',
    value: 14,
    name: '50/22',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  goalLineDropOut: {
    key: 'goalLineDropOut',
    value: 15,
    name: 'Goal Line Drop Out',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  punt: {
    key: 'punt',
    value: 16,
    name: 'Punt',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fortyTwenty: {
    key: 'fortyTwenty',
    value: 17,
    name: 'Forty Twenty',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  kickOffFlightEvent: {
    key: 'kickOffFlightEvent',
    value: 18,
    name: 'Kick Off',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fieldGoal: {
    key: 'fieldGoal',
    value: 19,
    name: 'Field Goal',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  pat: {
    key: 'pat',
    value: 20,
    name: 'Point After Touchdown',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  onSideKick: {
    key: 'onSideKick',
    value: 21,
    name: 'On Side Kick',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fieldKick: {
    key: 'fieldKick',
    value: 22,
    name: 'Kick',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  rebound50: {
    key: 'rebound50',
    value: 23,
    name: 'Rebound 50',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  setShot: {
    key: 'setShot',
    value: 24,
    name: 'Set Shot',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  inside50: {
    key: 'inside50',
    value: 26,
    name: 'Inside 50',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  run: {
    key: 'run',
    value: 27,
    name: 'Run',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  ground: {
    key: 'ground',
    value: 28,
    name: 'Ground',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  markPlayOn: {
    key: 'markPlayOn',
    value: 29,
    name: 'Mark Play On',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  freeKick: {
    key: 'freeKick',
    value: 30,
    name: 'Free Kick',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  quickLineout: {
    key: 'quickLineout',
    value: 256,
    name: 'Quick Lineout',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  shovel: {
    key: 'shovel',
    value: 512,
    name: 'Shovel',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  lateral: {
    key: 'lateral',
    value: 513,
    name: 'Lateral',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  forward: {
    key: 'forward',
    value: 514,
    name: 'Forward',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  longSnap: {
    key: 'longSnap',
    value: 515,
    name: 'Long Snap',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  shotgunFlightEvent: {
    key: 'shotgunFlightEvent',
    value: 516,
    name: 'Shotgun',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  underCentre: {
    key: 'underCentre',
    value: 1024,
    name: 'Under Centre',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  puntSnap: {
    key: 'puntSnap',
    value: 1025,
    name: 'Punt Snap',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fieldGoalSnap: {
    key: 'fieldGoalSnap',
    value: 1026,
    name: 'Field Goal Snap',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  soccerAerialPass: {
    key: 'soccerAerialPass',
    value: 768,
    name: 'Aerial Pass',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  soccerFloorPass: {
    key: 'soccerFloorPass',
    value: 769,
    name: 'Floor Pass',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  // soccerFreeKick: {
  //   key: 'soccerFreeKick',
  //   value: 770,
  //   name: 'Free Kick',
  //   props: null
  // }
  // ======= //

  // Game Event Sub Types //
  unclassifiedGameEvent: {
    key: 'unclassifiedGameEvent',
    value: 'UNCLASSIFIED',
    name: 'Unclassified',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  scrum: {
    key: 'scrum',
    value: 'PENALTY_SCRUM',
    name: 'Scrum',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  penaltyKickToTouch: {
    key: 'penaltyKickToTouch',
    value: 'PENALTY_KICK_TO_TOUCH',
    name: 'Penalty Kick to Touch',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  penaltyGoalSuccessful: {
    key: 'penaltyGoalSuccessful',
    value: 'PENALTY_GOAL_SUCCESSFUL',
    name: 'Penalty Goal Successful',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  penaltyGoalUnsuccessful: {
    key: 'penaltyGoalUnsuccessful',
    value: 'PENALTY_GOAL_UNSUCCESSFUL',
    name: 'Penalty Goal Unsuccessful',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  tapPenalty: {
    key: 'tapPenalty',
    value: 'PENALTY_TAP',
    name: 'Tap Penalty',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  penaltyTry: {
    key: 'penaltyTry',
    value: 'PENALTY_TRY',
    name: 'Penalty Try',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  // ======= //
  // Snap //
  shotgunGameEvent: {
    key: 'shotgunGameEvent',
    value: 'SHOTGUN',
    name: 'Shotgun',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  underCenter: {
    key: 'underCenter',
    value: 'UNDER_CENTRE',
    name: 'Under Center',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  long: {
    key: 'long',
    value: 'LONG',
    name: 'Long',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  // Manual Pass //
  manualPassStarted: {
    key: 'manualPassStarted',
    value: 'MANUAL_PASS_STARTED',
    name: 'Pass Started',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  manualPassIntercepted: {
    key: 'manualPassIntercepted',
    value: 'MANUAL_PASS_INTERCEPTED',
    name: 'Pass Intercepted',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  manualPassComplete: {
    key: 'manualPassComplete',
    value: 'MANUAL_PASS_COMPLETE',
    name: 'Pass Complete',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  manualPassIncomplete: {
    key: 'manualPassIncomplete',
    value: 'MANUAL_PASS_INCOMPLETE',
    name: 'Pass Incomplete',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  // ==== //
  fumbleRecovery: {
    key: 'fumbleRecovery',
    value: 'FUMBLE_RECOVERY',
    name: 'Recovery',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  fumbleDefensiveRecovery: {
    key: 'fumbleDefensiveRecovery',
    value: 'FUMBLE_DEFENSIVE_RECOVERY',
    name: 'Defensive Recovery',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  // ======= //

  // Australian Rules Event Sub Types //
  goal: {
    key: 'goal',
    value: 1,
    name: 'Goal',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  leftBehind: {
    key: 'leftBehind',
    value: 2,
    name: 'Left Behind',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  rightBehind: {
    key: 'rightBehind',
    value: 3,
    name: 'Right Behind',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  leftOutOfBounds: {
    key: 'leftOutOfBounds',
    value: 4,
    name: 'Left Out of Bounds',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  },
  rightOutOfBounds: {
    key: 'rightOutOfBounds',
    value: 5,
    name: 'Right Out of Bounds',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultEventFeatures
    }
  }
  // ======= //
} as const

export type EventSubTypeProps = ParentEventTypeProps<null>

export type EventSubTypes = DataTypes<typeof eventSubTypesConfig>

export type EventSubTypeKey = DataTypeKey<EventSubTypes>

export type EventSubTypeKeys = DataTypeKeys<EventSubTypeKey>

export type EventSubTypeValues = DataTypeValues<EventSubTypeKey>

export const getEventSubTypeGroup = (items: {
  [key in EventSubTypeKeys]?: EventSubTypes[key]
}) => {
  return getDataTypeGroup<
    EventSubTypeKeys,
    EventSubTypeValues,
    EventSubTypes,
    EventSubTypeProps
  >(items)
}

export type EventSubTypeGroup = ReturnType<typeof getEventSubTypeGroup>

export type EventSubTypeConfig = DataTypeConfig<
  EventSubTypeKeys,
  EventSubTypeValues,
  EventSubTypeProps
>

export const eventSubTypes = getEventSubTypeGroup(eventSubTypesConfig)

export type EventSubType = ReturnType<typeof eventSubTypes.getTypeByValue>
