import {
  Anchor,
  Anchors,
  PitchArcs,
  PitchCoordinates,
  PitchTypes,
  RawPitchData
} from '../pitches/types'
import {
  AppTeam,
  EMPTY_FORMATTED_TEAM,
  FormattedTeam,
  RawTeamData,
  Team
} from '../teams/types'
import { RawTimeEventData } from '../events/time/types'
import { RawGameEventData } from '../events/game/types'
import { FormattedPlayer, PlayerData, RawPlayerData } from '../players/types'
import { Options } from '../data_types'
import { getMetricTypeGroup, MetricTypeGroup } from '../metrics/data_types'
import { SessionType, sessionTypes, SessionTypeValues } from './data_types'
import {
  SubSessionType,
  subSessionTypes,
  SubSessionTypeValues
} from './sub_sessions/data_types'
import {
  SessionModeType,
  sessionModeTypes,
  SessionModeTypeValues
} from './modes/data_types'
import { Sport, SportTypeValues } from '../sports/data_types'
import {
  ParentEventTypeGroup,
  getParentEventTypeGroup
} from '../events/data_types'
import { Group, IDMap } from '../types'
import { HardwareProductNames } from '../hardware/types'
import { DeviceTypeValues } from '../hardware/data_types'
import {
  SessionStateType,
  sessionStateTypes,
  SessionStateTypeValues
} from './states/data_types'
import { FormattedDate } from '../../utils/helpers'
import { getEmptyGroup } from '../functions'
import { EventTypeGroup, getEventTypeGroup } from '../events/types/data_types'
// Session State

export interface SessionsState {
  // Session Form //
  useCustomName: boolean
  customName: string
  // ============ //
  rawData: IDMap<RawSessionData>
  selectedId: string
  creatingSession: boolean
  selected: SessionData
  filter: {
    items: SessionBasic[]
    options: SessionFilterRequestBody
    fetching: boolean
    hasValidData: boolean
  }
  recent: {
    items: SessionBasic[]
    quantity: number
  }
  graph: {
    items: {
      [id: string]: SessionData
    }
  }
  activeSession: RawSessionData

  activityListScrollHeight: 0

  autoSleepEnabled: boolean
  scoreboard: ScoreboardType
  isKeyboardShortcutEnabled: boolean
}

export type SessionFilterRequestBody = {
  team: string
  startTime: number
  endTime: number
  limitQuantity: number
  type: SessionTypeValues | 'All'
  subType: SubSessionTypeValues | 'All'
  searchStr: string
  state: SessionStateTypeValues | 'All'
  sportType: SportTypeValues | 'All'
}

export interface DropdownTeam {
  name: string
  value: string
}

export interface SessionBasic {
  id: string
  name: string
  startTime: number
  endTime: number
  playersSessions: RawPlayerSessionData[]
  teamsSessions: RawTeamSessionData[]
  type: number
  sportType: SportTypeValues
  state: string
  pitchId: string
  gender: 'male' | 'female'
}

export interface SessionData extends SessionSetup {
  id: string
  startTime: number
  endTime: number
  timeEvents: {
    [id: string]: RawTimeEventData
  }
  gameEvents: {
    [id: string]: RawGameEventData
  }
  flights: string[]
  sessionControl: SessionControl
  fetch?: boolean
}

export interface SessionSetup {
  id?: string
  name: string
  pitch?: PitchTypes
  playersSessions: RawPlayerSessionData[]
  teamsSessions: RawTeamSessionData[]
  type: SessionTypeValues
  subType: SubSessionTypeValues
  mode: SessionModeTypeValues
  sportType: SportTypeValues
  state?: 'Finished' | 'NotStarted' | 'Started'
  pitchId?: string
  gender: 'male' | 'female'
  teamA?: Team
  teamB?: Team
  hostname?: string
  locationName?: string
  uploadStatus?
  isCustomName?: boolean
  customName?: string
}

export type SessionControl = {
  possession: Possession
  sides: Side[]
}

export type Possession = Team
export type Side = Team

export interface SessionPlayers {
  [key: string]: RawPlayerSessionData
}

export interface FormattedSessionPlayers {
  map: {
    [id: string]: FormattedSessionPlayer
  }
  options: Options<string>
}

export interface FormattedSessionPlayer {
  playerData: PlayerData
  playerSession: RawPlayerSessionData
}

export interface SessionTeams {
  [key: string]: RawTeamSessionData
}

export interface Scoreboard {
  teamId: string
  scoreSum: number
  teamName: string
}

export interface ScoreboardType {
  [key: string]: Scoreboard
}

export interface SessionConfig {
  team: Team
  teamB: Team
  session: SessionData
  playersSessions: any
  pitch: {
    type: SportTypeValues
    coordinates: PitchCoordinates
  }
  anchorConfig: Anchors
  sessionPlayers: SessionPlayers
  playerOptions: Options<string>
  teamOptions: Options<string>
  sessionTeams: SessionTeams
  sessionTeamsArray: Team[]
  live: boolean
  sport: Sport
  isTrainingMode: boolean
  isMatchMode: boolean
  getPlayerSession: (playerSessionId: string) => RawPlayerSessionData
  parentEventTypes: ParentEventTypeGroup
  flightTypes: EventTypeGroup
  flightMetrics: MetricTypeGroup
  gameEventTypes: EventTypeGroup
  gameEventMetrics: MetricTypeGroup
  timeEventTypes: EventTypeGroup
  australianRulesEventTypes: EventTypeGroup
  australianRulesEventMetrics: MetricTypeGroup
  playerMap: IDMap<PlayerData>
  teamMap: IDMap<Team>
  newTeamMap: IDMap<AppTeam>
  formattedSessionPlayers: FormattedSessionPlayers
}

export interface FormattedPlayerSession {
  id: string
  playerId: string
  teamId: string
  sessionId: string
  player: FormattedPlayer
  hardwareId: number
  number: number
  hardware: {
    id: number
    serial: string
    type: DeviceTypeValues
    productName: HardwareProductNames
  }
}

export interface FormattedTeamSession {
  rawData: RawTeamSessionData
  id: string
  teamId: string
  sessionId: string
  team: FormattedTeam
  homeAway: 'HOME' | 'AWAY'
  oppositionTeamId: string
}

export type FormattedPlayersSessions = {
  byHardwareId: Group<FormattedPlayerSession, number>
  byNumber: {
    [teamId: string]: Group<FormattedPlayerSession, number>
  }
  byPlayerId: Group<FormattedPlayerSession>
  byId: Group<FormattedPlayerSession>
  balls: Group<FormattedPlayerSession>
}

export type RawSessionData = {
  id: string
  startTime: number
  endTime: number
  name: string
  type: SessionTypeValues
  subType: SubSessionTypeValues
  mode: SessionModeTypeValues
  sportType: SportTypeValues
  state: SessionStateTypeValues
  pitchId: string
  pitch: RawPitchData
  womensMode: boolean
  locationName: string
  hostname: string
  teamsSessions: RawTeamSessionData[]
  playersSessions: RawPlayerSessionData[]
  uploadStatus: {
    dump
    daemon
    session
    sessionId
  }
  officiatingAlgosEnable: boolean
}

export const EMPTY_RAW_SESSION: RawSessionData = {
  id: '',
  startTime: 0,
  endTime: 0,
  name: '',
  type: null,
  subType: null,
  mode: null,
  sportType: null,
  state: null,
  womensMode: false,
  pitchId: null,
  pitch: null,
  locationName: '',
  hostname: '',
  officiatingAlgosEnable: false,
  teamsSessions: [],
  playersSessions: [],
  uploadStatus: {
    dump: null,
    daemon: null,
    session: null,
    sessionId: null
  }
}

export interface FormattedSession {
  sessionData: RawSessionData

  isSetup: boolean

  // General
  id: string
  name: string
  startTime: FormattedDate
  endTime: FormattedDate
  sport: Sport
  type: SessionType
  subType: SubSessionType
  mode: SessionModeType
  state: SessionStateType
  live: boolean
  isTrainingMode: boolean
  isMatchMode: boolean
  hostname: string
  locationName: string
  uploadStatus
  isSessionUploaded: boolean
  isOfficiatingMode: boolean

  // Teams and Players
  teams: Group<FormattedTeam>
  teamsSessions: Group<FormattedTeamSession>
  players: {
    [teamId: string]: Group<FormattedPlayer>
    All: Group<FormattedPlayer>
  }
  playersSessions: FormattedPlayersSessions
  homeTeam: FormattedTeam
  awayTeam: FormattedTeam
  officiationTeam: FormattedTeam

  // Balls
  balls: Group<FormattedPlayerSession>

  // Events types and metrics
  parentEventTypes: ParentEventTypeGroup
  flightTypes: EventTypeGroup
  flightMetrics: MetricTypeGroup
  gameEventTypes: EventTypeGroup
  gameEventMetrics: MetricTypeGroup
  timeEventTypes: EventTypeGroup
  australianRulesEventTypes: EventTypeGroup
  australianRulesEventMetrics: MetricTypeGroup

  playerSummaryMetricTypes: MetricTypeGroup

  // Pitch and anchors
  pitchId: string
  pitch: {
    type: SportTypeValues
    coordinates: PitchCoordinates
    arcs: PitchArcs
  }
  anchorConfig: Anchors
  anchors: Group<Anchor>

  // Features
  homeSideCheck: boolean
}

export const EMPTY_FORMATTED_SESSION: FormattedSession = {
  sessionData: EMPTY_RAW_SESSION,
  isSetup: false,
  id: null,
  name: '',
  startTime: null,
  endTime: null,
  sport: null,
  type: sessionTypes.getTypeByValue(null),
  subType: subSessionTypes.getTypeByValue(null),
  mode: sessionModeTypes.getTypeByValue(null),
  state: sessionStateTypes.getTypeByValue(null),
  live: false,
  isTrainingMode: false,
  isMatchMode: false,
  hostname: '',
  locationName: '',
  uploadStatus: null,
  isSessionUploaded: false,
  isOfficiatingMode: false,
  teams: getEmptyGroup(),
  teamsSessions: getEmptyGroup(),
  players: {
    All: getEmptyGroup()
  },
  playersSessions: {
    byHardwareId: getEmptyGroup(),
    byNumber: {},
    byPlayerId: getEmptyGroup(),
    byId: getEmptyGroup(),
    balls: getEmptyGroup()
  },
  homeTeam: EMPTY_FORMATTED_TEAM,
  awayTeam: EMPTY_FORMATTED_TEAM,
  officiationTeam: EMPTY_FORMATTED_TEAM,
  balls: getEmptyGroup(),
  parentEventTypes: getParentEventTypeGroup({}),
  flightTypes: getEventTypeGroup({}),
  flightMetrics: getMetricTypeGroup({}),
  gameEventTypes: getEventTypeGroup({}),
  gameEventMetrics: getMetricTypeGroup({}),
  timeEventTypes: getEventTypeGroup({}),
  australianRulesEventTypes: getEventTypeGroup({}),
  australianRulesEventMetrics: getMetricTypeGroup({}),
  playerSummaryMetricTypes: getMetricTypeGroup({}),
  pitchId: '',
  pitch: {
    type: null,
    coordinates: {},
    arcs: []
  },
  anchorConfig: {},
  anchors: getEmptyGroup(),
  homeSideCheck: false
}

export type RawTeamSessionData = {
  id: string
  teamId: string
  team: RawTeamData
  sessionId: string
  homeAway: 'HOME' | 'AWAY'
}

export type RawPlayerSessionTag = {
  id: number
  serial: string
  type: DeviceTypeValues
  productName: HardwareProductNames
}

export type RawPlayerSessionData = {
  id: string
  playerId: string
  teamId: string
  sessionId: string
  number: number
  player: Omit<RawPlayerData, 'teamId'>
  tag: RawPlayerSessionTag
}

export namespace SessionsActionType {
  export const CHECK_ACTIVE_SESSION = 'CHECK_ACTIVE_SESSION',
    GET_RECENT_SESSIONS = 'GET_RECENT_SESSIONS',
    UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS',
    FETCH_FILTERED_SESSIONS = 'FETCH_FILTERED_SESSIONS',
    SET_FILTERED_SESSIONS = 'SET_FILTERED_SESSIONS',
    FETCH_FILTERED_SESSIONS_FAILED = 'FETCH_FILTERED_SESSIONS_FAILED',
    SET_SESSION = 'SET_SESSION',
    UPDATE_SELECTED_SESSION = 'UPDATE_SELECTED_SESSION',
    SET_SETUP_SESSION = 'SET_SETUP_SESSION',
    UPDATE_SETUP_SESSION = 'UPDATE_SETUP_SESSION',
    ADD_PLAYER_SESSION = 'ADD_PLAYER_SESSION',
    UPDATE_PLAYER_SESSION = 'UPDATE_PLAYER_SESSION',
    REMOVE_PLAYER_SESSION = 'REMOVE_PLAYER_SESSION',
    REMOVE_BALL_FROM_SESSION = 'REMOVE_BALL_FROM_SESSION',
    CREATE_SESSION = 'CREATE_SESSION',
    CREATING_SESSION = 'CREATING_SESSION',
    STOP_SESSION = 'STOP_SESSION',
    START_SESSION = 'START_SESSION',
    SET_ACTIVITY_LIST_SCROLL_HEIGHT = 'SET_ACTIVITY_LIST_SCROLL_HEIGHT',
    UPDATE_SESSION = 'UPDATE_SESSION',
    SET_ACTIVE_SESSION = 'SET_ACTIVE_SESSION',
    UPDATE_BROADCAST_STATE = 'UPDATE_BROADCAST_STATE',
    GET_SCOREBOARD = 'GET_SCOREBOARD',
    SET_AUTO_SLEEP = 'SET_AUTO_SLEEP',
    SET_SESSION_UPLOAD_STATUS = 'SET_SESSION_UPLOAD_STATUS',
    GET_PLAYER_SESSION = 'GET_PLAYER_SESSION',
    UPDATE_USE_CUSTOM_NAME = 'UPDATE_USE_CUSTOM_NAME',
    SET_CUSTOM_NAME = 'SET_CUSTOM_NAME',
    UPDATE_SET_OFFICIATION = 'UPDATE_SET_OFFICIATION',
    TOGGLE_KEYBOARD_SHORTCUTS = 'TOGGLE_KEYBOARD_SHORTCUTS',
    UPDATE_SESSIONS = 'UPDATE_SESSIONS'
}
