import { Dashboard } from '.'
import { ReportingHeader } from '../../app/header'

export const path = '/dashboard'

export const dashboardRoute = {
  path,
  view: {
    type: 'PAGE',
    name: 'Dashboard',
    Content: Dashboard,
    Header: ReportingHeader
  }
}
