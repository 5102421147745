import { DataType } from '../data_types'
import { ProcessedRawEventData } from '../events/types'
import {
  aggregationOperatorTypes,
  AggregationOperatorTypeValues
} from '../metrics/aggregation_operators/data_types'
import { EMPTY_FORMATTED_PLAYER, FormattedPlayer } from '../players/types'
import { EMPTY_FORMATTED_TEAM, FormattedTeam } from '../teams/types'
import { Group } from '../types'
import { UnitSystem } from '../units/types'
import {
  FormattedMetricTypeValues,
  MetricType,
  MetricTypeConfig,
  ProcessedMetricTypeValues,
  RawMetricTypeValues
} from './data_types'
import { FormattedMetricAggregation } from './types'

export function getFormattedValue<T extends MetricTypeConfig>(
  metricType: T,
  value: ProcessedMetricTypeValues[T['key']]
) {
  const { type } = metricType.props

  if (!value) return null

  if (type === 'number' && typeof value === 'number') {
    const formattedValue: number = value
    return formattedValue as FormattedMetricTypeValues[T['key']]
  }

  if (type === 'text' && typeof value === 'string') {
    const formattedValue: string = value
    return formattedValue as FormattedMetricTypeValues[T['key']]
  }

  if (
    (type === 'option' || type === 'enum' || type === 'boolean') &&
    typeof value === 'object' &&
    'value' in value &&
    'name' in value
  ) {
    return value.name as FormattedMetricTypeValues[T['key']]
  }

  if (
    type === 'team' &&
    typeof value === 'object' &&
    'id' in value &&
    'name' in value
  ) {
    return value.name as FormattedMetricTypeValues[T['key']]
  }

  if (
    type === 'player' &&
    typeof value === 'object' &&
    'id' in value &&
    'fullName' in value
  ) {
    return value.fullName as FormattedMetricTypeValues[T['key']]
  }

  return null as FormattedMetricTypeValues[T['key']]
}

export class MetricHandler<C extends MetricTypeConfig> extends DataType<
  C['key'],
  C['value'],
  C['props']
> {
  props: C['props']

  constructor(props) {
    super(props)

    for (const key in props) {
      this[key] = props[key]
    }
  }

  getMetricUnits(unitSystem: UnitSystem) {
    if (this.props.type === 'number') {
      const { unitType } = this.props
      const unit = unitSystem.units[unitType]
      return unit ? unit.abbreviation : ''
    }
    return ''
  }

  getMetricValueWithUnits(
    value: RawMetricTypeValues[C['key']],
    unitSystem: UnitSystem,
    teams: Group<FormattedTeam>,
    players: Group<FormattedPlayer>,
    rawMetrics: Partial<RawMetricTypeValues>,
    customDecimal?: number
  ) {
    const { type } = this.props
    const processedValue = this.processMetric(
      value,
      rawMetrics,
      teams,
      players,
      customDecimal
    )

    const formattedValue = this.getFormattedValue(processedValue)

    // Apply units //
    if (type === 'number') {
      const { unitType } = this.props
      const unit = unitSystem.units[unitType]
      return `${formattedValue}\u2009${unit ? `${unit.abbreviation}` : ''}`
    } else {
      return formattedValue?.toString() || 'Undefined value'
    }
  }

  getRawValue(
    value: ProcessedMetricTypeValues[C['key']]
  ): RawMetricTypeValues[C['key']] {
    const { type } = this.props

    if (typeof value === 'number' && this.props.type === 'number') {
      return value as RawMetricTypeValues[C['key']]
    }

    if (typeof value === 'string' && this.props.type === 'text') {
      return value as RawMetricTypeValues[C['key']]
    }

    if (
      (type === 'option' || type === 'enum' || type === 'boolean') &&
      typeof value === 'object' &&
      'value' in value
    ) {
      return value.value as RawMetricTypeValues[C['key']]
    }

    if (type === 'team' && typeof value === 'object' && 'id' in value) {
      return value.id as RawMetricTypeValues[C['key']]
    }

    if (type === 'player' && typeof value === 'object' && 'id' in value) {
      return value.id as RawMetricTypeValues[C['key']]
    }

    return null
  }

  getFormattedValue<T extends MetricTypeConfig>(
    value: ProcessedMetricTypeValues[T['key']]
  ) {
    const { type } = this.props

    if (!value) return null

    if (type === 'number' && typeof value === 'number') {
      const formattedValue: number = value
      return formattedValue as FormattedMetricTypeValues[T['key']]
    }

    if (type === 'text' && typeof value === 'string') {
      const formattedValue: string = value
      return formattedValue as FormattedMetricTypeValues[T['key']]
    }

    if (
      (type === 'option' || type === 'enum' || type === 'boolean') &&
      typeof value === 'object' &&
      'value' in value &&
      'name' in value
    ) {
      return value.name as FormattedMetricTypeValues[T['key']]
    }

    if (
      type === 'team' &&
      typeof value === 'object' &&
      'id' in value &&
      'name' in value
    ) {
      return value.name as FormattedMetricTypeValues[T['key']]
    }

    if (
      type === 'player' &&
      typeof value === 'object' &&
      'id' in value &&
      'fullName' in value
    ) {
      return value.fullName as FormattedMetricTypeValues[T['key']]
    }

    return null as FormattedMetricTypeValues[T['key']]
  }

  processMetric<T extends MetricType>(
    value: RawMetricTypeValues[T['key']],
    rawMetrics: Partial<RawMetricTypeValues>,
    teams: Group<FormattedTeam>,
    players: Group<FormattedPlayer>,
    customDecimal?: number
  ) {
    const { type } = this.props

    if (value === null || value === undefined) return null

    if (type === 'number' && typeof value === 'number') {
      let processedValue: number

      const { decimal, unitType, getValue } = this.props
      // If metric has a custom value generator //
      if (getValue && rawMetrics) {
        processedValue = getValue(rawMetrics)
      } else {
        processedValue = value
      }

      // Change any strings to numbers //
      // This should probably be fixed in the MS //
      if (typeof value === 'string' && type === 'number') {
        processedValue = parseFloat(value)
      }

      // Convert decimal to percentage //
      if (
        typeof value === 'number' &&
        unitType === 'percentage' &&
        // Probability forward - we are being sent a percentage, but are expecting decimals and backend cannot update this
        // TODO: Remove this when backend is updated
        // TODO: we should probably implement 2 types, percentage and decimal //
        this.key !== 'probabilityForward'
      ) {
        processedValue = value * 100
      }

      if (customDecimal) {
        processedValue = parseFloat(processedValue.toFixed(customDecimal))
      } else if (!isNaN(decimal)) {
        processedValue = parseFloat(processedValue.toFixed(decimal))
      }

      return processedValue as ProcessedMetricTypeValues[T['key']]
    }

    if (type === 'boolean' && typeof value === 'boolean') {
      const { options } = this.props
      const option = options.find((option) => option.value === value)
      const processedValue = option || { name: 'Unknown value', value: null }
      return processedValue as ProcessedMetricTypeValues[T['key']]
    }

    if (type === 'team' && typeof value === 'string') {
      const team = teams.map[value]
      const processedValue = team || EMPTY_FORMATTED_TEAM
      return processedValue as ProcessedMetricTypeValues[T['key']]
    }

    if (type === 'player' && typeof value === 'string') {
      const player = players.map[value]
      const processedValue = player || EMPTY_FORMATTED_PLAYER
      return processedValue as ProcessedMetricTypeValues[T['key']]
    }

    if (type === 'option') {
      const option = this.props.options.find((option) => option.value === value)
      const processedValue = option || { name: 'Unknown value', value }
      return processedValue as ProcessedMetricTypeValues[T['key']]
    }

    if (type === 'enum') {
      const option = this.props.options.find((option) => option.value === value)
      const processedValue = option || { name: 'Unknown value', value }
      return processedValue as ProcessedMetricTypeValues[T['key']]
    }

    return null
  }

  getMetricDisplayWithUnits(
    unitSystem: UnitSystem,
    maxLength: number,
    useTypeInferredName?: boolean,
    language = 'en'
  ) {
    const {
      name,
      props: { type, abbr, typeInferredName, unitType }
    } = this
    const unit = unitSystem.units[unitType]

    let translatedName = name
    if (translatedName && typeof name === 'object') {
      translatedName = translatedName[language]
    }

    if (useTypeInferredName && typeInferredName) {
      translatedName = typeInferredName

      if (typeof typeInferredName === 'object') {
        translatedName = typeInferredName[language]
      }
    }

    let displayName = `${translatedName} ${
      unit
        ? typeInferredName
          ? `${unit.abbreviation}`
          : `(${unit.abbreviation})`
        : ''
    }`
    if (maxLength && typeof translatedName === 'string') {
      if (translatedName.length > maxLength)
        displayName = `${abbr} ${
          unit
            ? typeInferredName
              ? `${unit.abbreviation}`
              : `(${unit.abbreviation})`
            : ''
        }`
    }
    return displayName
  }

  isBoolean() {
    return this.props.type === 'boolean'
  }

  getFormattedMetric(
    initialValue,
    unitSystem: UnitSystem,
    aggregation: AggregationOperatorTypeValues,
    teams: Group<FormattedTeam>,
    players: Group<FormattedPlayer>
  ): FormattedMetricAggregation {
    let name
    switch (aggregation) {
      case aggregationOperatorTypes.items.Mean.value:
        name = 'Average ' + this.name
        break
      case aggregationOperatorTypes.items.Maximum.value:
        name = 'Maximum ' + this.name
        break
      case aggregationOperatorTypes.items.Minimum.value:
        name = 'Minimum ' + this.name
        break
      case aggregationOperatorTypes.items.Sum.value:
        name = 'Total ' + this.name
        break
      default:
        name = this.name
        break
    }
    return {
      name,
      value: initialValue ? initialValue : 0,
      // TODO: refactor this code to formatted session and events //
      formattedValue: initialValue
        ? this.getMetricValueWithUnits(
            initialValue,
            unitSystem,
            teams,
            players,
            null
          )
        : '-',
      count: initialValue ? 1 : 0,
      aggregation
    }
  }

  updateFormattedMetricValue(
    formattedMetric: FormattedMetricAggregation,
    newValue: number,
    unitSystem: UnitSystem,
    rawMetrics: RawMetricTypeValues,
    teams: Group<FormattedTeam>,
    players: Group<FormattedPlayer>
  ) {
    if (typeof formattedMetric.value === 'number') {
      switch (formattedMetric.aggregation) {
        case aggregationOperatorTypes.items.Mean.value:
          formattedMetric.value =
            (formattedMetric.value * formattedMetric.count + newValue) /
            (formattedMetric.count + 1)
          break
        case aggregationOperatorTypes.items.Maximum.value:
          if (newValue > formattedMetric.value) formattedMetric.value = newValue
          break
        case aggregationOperatorTypes.items.Minimum.value:
          if (newValue < formattedMetric.value) formattedMetric.value = newValue
          break
        case aggregationOperatorTypes.items.Sum.value:
          formattedMetric.value = formattedMetric.value + newValue
          break
        default:
          formattedMetric.value = newValue
          break
      }
      // TODO: refactor this code to formatted session and events //
      formattedMetric.formattedValue = this.getMetricValueWithUnits(
        formattedMetric.value as any,
        unitSystem,
        teams,
        players,
        rawMetrics
      )
      formattedMetric.count++
    }

    return formattedMetric
  }

  getMetricFromFlights(
    events: ProcessedRawEventData[],
    unitSystem: UnitSystem,
    aggregation: AggregationOperatorTypeValues,
    teams: Group<FormattedTeam>,
    players: Group<FormattedPlayer>
  ) {
    let formattedMetric = this.getFormattedMetric(
      0,
      unitSystem,
      aggregation,
      teams,
      players
    )
    if (this.props.type !== 'number') return formattedMetric
    events.forEach((event) => {
      const value = event[this.key as string]
      if (typeof value === 'number') {
        formattedMetric = this.updateFormattedMetricValue(
          formattedMetric,
          value,
          unitSystem,
          event.rawValues,
          teams,
          players
        )
      }
    })
    return formattedMetric
  }
}
