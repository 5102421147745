import { defaultPitch } from './pitch'
import { australianRulesEventTypes } from './events'
import { pitchSetupTypes } from '../../pitches/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

export const defaultAustralianRulesPitch = defaultPitch

const sportMetrics = getMetricTypeGroup(
  getMetricTypesConfigFromJson([], metricTypesConfig)
)

export const australianRulesProps = {
  parentEventTypes: australianRulesEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  metrics: sportMetrics,
  reportMetricConfig: {
    playerPerformanceTable: {
      all: {
        metrics: {
          keys: []
        },
        eventMetricGroup: {
          eventTypes: [],
          groupName: {
            en: 'Success %',
            fr: 'Succès %'
          }
        }
      }
    },
    playerComparisonTable: {
      pass: {
        metrics: [[], [], []]
      }
    },
    monitoringCards: {
      technicalMetrics: [],
      physicalMetrics: []
    },
    dashboardMetrics: {
      performanceAgainstAverage: [],
      cardGrid: [[], []]
    }
  },
  pitch: {
    default: defaultPitch,
    pitchPointCount: 37,
    setupTypes: pitchSetupTypes,
    minPadding2D: 50,
    minPadding3D: 50,
    cameraYScale: 0,
    textureCanvasScale: 8,
    enableBench: true
  },
  features: {
    targetEvents: ['kick'],
    positionNumbers: false,
    maxPlayers: 22,
    defaultUnitSystem: 'Metric',
    keyboardShortcuts: {},
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {}
  } as const
}

export const australianRulesConfig = {
  key: 'australianRules' as const,
  value: 'AustralianRules',
  name: 'Australian Rules',
  props: australianRulesProps
}
