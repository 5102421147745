'use client'

import { ItemTile, Item } from './item-tile'
import { EmptyTile } from './empty-tile'
import { ItemSelectModal } from './item-select-modal'
import { useState } from 'react'
import { Card, CardContent } from '../../ui/card'

interface GroupSelectorGridProps {
  selectedItemIds: string[]
  availableItems: Item[]
  maxSelectedItems: number
  onChange: (selectedItemIds: string[]) => void
}

export function GroupSelectorGrid({
  selectedItemIds,
  availableItems,
  maxSelectedItems,
  onChange
}: GroupSelectorGridProps) {
  // The child only manages local UI state like the modal being open or not
  const [isModalOpen, setIsModalOpen] = useState(false)

  // When an empty slot is clicked, show the modal
  const handleEmptyTileClick = (index: number) => {
    setIsModalOpen(true)
  }

  // When a item is chosen in the modal
  const handleAddSelectedItem = (item: Item) => {
    const newSelectedItemIds = [...selectedItemIds]
    newSelectedItemIds.push(item.id)
    onChange(newSelectedItemIds)
    // Close modal
    setIsModalOpen(false)
  }

  // When an item is removed
  const handleRemoveSelectedItem = (item: Item) => {
    const newSelectedItemIds = [...selectedItemIds].filter(
      (selectedItemId) => item.id !== selectedItemId
    )
    onChange(newSelectedItemIds)
  }

  return (
    <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-8'>
      {selectedItemIds.map((selectedItemId, index) => {
        const item = availableItems.find((item) => item.id === selectedItemId)
        if (!item) {
          return (
            <Card className='h-48 flex items-center justify-center'>
              <CardContent>
                <EmptyTile
                  key={`empty-${index}`}
                  onClick={() => handleEmptyTileClick(index)}
                />
              </CardContent>
            </Card>
          )
        }
        return (
          <Card className='h-48 group'>
            <CardContent className='p-4'>
              <ItemTile
                key={item.id}
                item={item}
                // Just call the callback provided by the parent
                onRemove={() => handleRemoveSelectedItem(item)}
              />
            </CardContent>
          </Card>
        )
      })}
      {
        /* Empty tiles */
        Array.from({ length: maxSelectedItems - selectedItemIds.length }).map(
          (_, index) => {
            return (
              <Card className='h-48 flex items-center justify-center relative'>
                <EmptyTile
                  key={`empty-${index}`}
                  onClick={() => handleEmptyTileClick(index)}
                />
              </Card>
            )
          }
        )
      }

      <ItemSelectModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        items={availableItems}
        onSelect={handleAddSelectedItem}
      />
    </div>
  )
}
