import { PlayersState } from '../types'
import {
  rawArsenalPlayers,
  rawPittsburghSteelersPlayers,
  rawPlayerFour,
  rawPlayerOne,
  rawPlayerThree,
  rawPlayerTwo
} from './api'

export const playersStateMock: PlayersState = {
  rawData: {
    [rawPlayerOne.id]: rawPlayerOne,
    [rawPlayerTwo.id]: rawPlayerTwo,
    [rawPlayerThree.id]: rawPlayerThree,
    [rawPlayerFour.id]: rawPlayerFour
  },
  fetched: true,
  hasValidData: true,
  selectedPlayer: null,
  items: {
    [rawPlayerOne.id]: rawPlayerOne,
    [rawPlayerTwo.id]: rawPlayerTwo,
    [rawPlayerThree.id]: rawPlayerThree,
    [rawPlayerFour.id]: rawPlayerFour
  }
}

export const reportingToolDummyPlayerState: PlayersState = {
  rawData: {
    ...rawArsenalPlayers,
    ...rawPittsburghSteelersPlayers
  },
  fetched: true,
  hasValidData: true,
  selectedPlayer: null,
  items: {
    ...rawArsenalPlayers,
    ...rawPittsburghSteelersPlayers
  }
}
