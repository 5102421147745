'use client'

import React from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs'
import { LucideIcon } from 'lucide-react'

export type TabProps = {
  value: string
  label: string
  icon: LucideIcon
}

export function IconTabs({
  children,
  tabs,
  initialValue,
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  className?: string
  tabs: TabProps[]
  initialValue?: string
}) {
  return (
    <div className='w-full'>
      <Tabs defaultValue={initialValue || tabs[0].value} className='w-full'>
        <TabsList className='w-full h-12 justify-center gap-8 bg-transparent mb-8'>
          {tabs.map((tab) => (
            <TabsTrigger
              key={'tab-' + tab.value}
              value={tab.value}
              className='flex items-center gap-2 data-[state=active]:border-b-0 data-[state=active]:border-blue-500 data-[state=active]:border-b-4'
            >
              <tab.icon className='h-6 w-6' />
              <span className='text-2xl'>{tab.label}</span>
            </TabsTrigger>
          ))}
        </TabsList>
        {React.Children.map(children, (child, index) => (
          <TabsContent value={tabs[index].value}>{child}</TabsContent>
        ))}
      </Tabs>
    </div>
  )
}
