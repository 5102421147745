import React from 'react'
import { Button } from '../../../../components/Button/Button'
import { NotificationsBell } from '../../../../components/Icons/NotificationsBell/NotificationsBell'
import Scroller from '../../../../components/Scroller/Scroller'
import { NotificationsListItem } from './NotificationsListItem/NotificationsListItem'
import { useNotifications, useNotificationVisible } from '../../hooks'
import { badgeStyle, getScrollHeight } from '../../functions'
import styles from './NotificationsList.module.scss'
import { useAppDispatch } from '../../../../store/hooks'
import { clearNotificationsHistory } from '../../actions'
import { useMockNotificationsInterval } from '../../data'

export const NotificationsList = () => {
  const dispatch = useAppDispatch()

  const notifications = useNotifications()

  const numberOfAlerts = notifications.allNotificationsHistory.length

  const { ref, isNotificationVisible, toggleNotificationVisibility } =
    useNotificationVisible(false)

  // For testing notifications //
  // useMockNotificationsInterval()

  return (
    <div className={styles.notificationContainer} ref={ref}>
      <div onClick={toggleNotificationVisibility}>
        <NotificationsBell
          isVisible={isNotificationVisible}
          style={{ marginTop: '5px' }}
          scale={1.2}
        />
        {numberOfAlerts > 0 && (
          <span className={styles.notificationBadge}>
            <span className={badgeStyle(numberOfAlerts)}></span>
          </span>
        )}
        {isNotificationVisible && (
          <div className={styles.notificationDropdown}>
            <div className={styles.notificationsHeader}>
              {numberOfAlerts >= 1 ? (
                <>
                  <h5>
                    {numberOfAlerts} {numberOfAlerts > 1 ? 'Alerts' : 'Alert'}
                  </h5>

                  <Button
                    id={'clearAll'}
                    disabled={false}
                    className={'btn--thin'}
                    handleClick={() => dispatch(clearNotificationsHistory())}
                  >
                    CLEAR ALERTS
                  </Button>
                </>
              ) : (
                <h5 style={{ padding: '5px' }}>No Alerts</h5>
              )}
            </div>
            <div
              style={{
                height: getScrollHeight(numberOfAlerts)
              }}
            >
              {numberOfAlerts >= 1 ? (
                <Scroller
                  scrollerId='scroller'
                  scrollerClass='notifications-scroller'
                >
                  <div className={styles.notificationScroller}>
                    {notifications.allNotificationsHistory
                      // Filter most recent 25 notifications:
                      .filter((item, index) => index < 25)
                      .slice(0)
                      .reverse()
                      .map((notification, index) => {
                        return (
                          <NotificationsListItem
                            key={`${
                              notification.timestamp || notification.arrivalTime
                            } + ${index}`}
                            notification={notification}
                          />
                        )
                      })}
                  </div>
                </Scroller>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
