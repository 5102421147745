import { addItemToGroup, getEmptyGroup } from '../functions'
import { OrganisationsState } from '../organisations/types'
import { formatPlayer } from '../players/functions'
import { FormattedPlayer, RawPlayerData } from '../players/types'
import { getSport } from '../sports/functions'
import {
  EMPTY_FORMATTED_TEAM,
  FormattedTeam,
  RawTeamData,
  Team,
  TeamsState
} from './types'

export const getTeamOptions = (teamsArray: Team[]) => {
  return Object.values(teamsArray).map((item) => {
    return {
      name: item.name,
      image: {
        logo: item.logo,
        color: item.color
      },
      value: item.id,
      id: item.id
    }
  })
}

export const getTeamFromOrganisations = (
  teamId: string,
  organisations: OrganisationsState
) => {
  if (organisations?.checkedOrganisation?.teams) {
    const team = organisations.checkedOrganisation.teams.find((team) => {
      return teamId === team.id
    })
    if (!team) return null
    return team
  } else {
    return null
  }
}

export const getTeamById = (
  teamId: string,
  teams: TeamsState,
  organisations: OrganisationsState
) => {
  const team = teams.items[teamId]
  if (!team) {
    return getTeamFromOrganisations(teamId, organisations)
  } else {
    return team
  }
}

// New Format Functions //
export function formatTeams(teams: RawTeamData[], players: RawPlayerData[]) {
  const formattedTeams = getEmptyGroup<FormattedTeam>()
  teams.forEach((team) => {
    const formattedTeam = formatTeam(team, players)
    addItemToGroup(
      formattedTeams,
      formattedTeam,
      formattedTeam.id,
      'id',
      formattedTeam.name
    )
  })
  return formattedTeams
}

export function formatTeam(
  team: RawTeamData,
  players: RawPlayerData[]
): FormattedTeam {
  try {
    const sport = getSport(team.sportType)
    const formattedPlayers = getEmptyGroup<FormattedPlayer>()
    players.forEach((player) => {
      if (player.teamId === team.id) {
        const formattedPlayer = formatPlayer(player)
        addItemToGroup(
          formattedPlayers,
          formattedPlayer,
          formattedPlayer.id,
          'id',
          formattedPlayer.fullName
        )
      }
    })
    const formattedTeam = {
      ...team,
      rawData: team,
      players: formattedPlayers,
      sport
    }
    return formattedTeam
  } catch (e) {
    console.log(e)
    return EMPTY_FORMATTED_TEAM
  }
}
