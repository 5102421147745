'use client'

import * as React from 'react'
import { useMemo } from 'react'

interface MetricBarProps {
  value: number | undefined
  markerValue: number | undefined
  maxValue: number
  label: string
  displayValue: string
  className?: string
}

export function MetricBar({
  value,
  markerValue,
  maxValue,
  label,
  displayValue,
  className = ''
}: MetricBarProps) {
  const barHeight = '175px'

  const barProps = useMemo(() => {
    let markerPercent = null,
      barColor = null,
      valuePercent = null

    if (!value || !maxValue)
      return {
        valuePercent,
        markerPercent,
        barColor
      }

    valuePercent = (value / maxValue) * 100

    if (markerValue) {
      markerPercent = (markerValue / maxValue) * 100

      // Determine color based on value compared to average
      barColor = 'bg-yellow-500'

      if (value >= markerValue) {
        barColor = 'bg-green-500'
      }
      const percentBelowAverage = ((markerValue - value) / markerValue) * 100
      if (percentBelowAverage > 50) {
        barColor = 'bg-red-500'
      }
    }

    return {
      valuePercent,
      markerPercent,
      barColor
    }
  }, [value, maxValue, markerValue])

  // // Calculate positions as percentages, handling undefined values
  // const valuePercent = value !== undefined ? (value / maxValue) * 100 : 0
  // const markerPercent =
  //   markerValue !== undefined ? (markerValue / maxValue) * 100 : 0

  return (
    <div className={`flex flex-col items-center gap-4 ${className}`}>
      <div className='relative w-2' style={{ height: barHeight }}>
        {/* Background bar */}
        <div className='absolute inset-0 bg-background rounded-full border-2 border-color-muted' />

        {/* Value bar */}
        <div
          className={`absolute bottom-0 left-0 right-0 rounded-full transition-all duration-500 ${barProps.barColor}`}
          style={{ height: `${barProps.valuePercent}%` }}
        />

        {/* Marker */}
        <div
          className='absolute left-1/2 w-3 h-3 bg-foreground rounded-full -translate-x-1/2 transition-all duration-500 border shadow-sm'
          style={{
            bottom: `${barProps.markerPercent}%`,
            transform: `translateX(-50%) translateY(50%)`
          }}
        />
      </div>

      {/* Value display */}
      <div className='flex flex-col items-center'>
        <span className='text-base font-bold tabular-nums font-condensed'>
          {displayValue !== undefined ? displayValue : '-'}
        </span>
        <span className='text-xs text-foreground text-center font-condensed'>
          {label}
        </span>
      </div>
    </div>
  )
}
