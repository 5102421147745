import { TableRow } from '../../../components/Table/Table.types'
import { RawFlightEventData } from './types'
import { ProcessedRawEventData } from '../types'
import { parentEventTypes } from '../data_types'
import { eventTypes } from '../types/data_types'

export const getMostRecentKick = (
  events: ProcessedRawEventData[]
): ProcessedRawEventData => {
  if (events.length === 0) return undefined

  return events.reduce((p, v) => {
    // If the event is not a flight event, return the previous event //
    if (p.eventTypeValue !== parentEventTypes.items.flight.value) return v
    // If the event is not a kick event, return the previous event //
    if (p.rawValues.type !== eventTypes.items.kick.value) return v
    // If the event is a kick event, compare the start times and return the most recent //
    if ('startTime' in v) {
      return p.rawValues.startTime > v.rawValues.startTime ? p : v
    } else {
      return p
    }
  })
}

// Get latest kick
export const getLatestKick = (eventsArray: ProcessedRawEventData[]) => {
  let latestKick: ProcessedRawEventData
  if (eventsArray.length > 0) {
    const orderedFlights = eventsArray.sort((a, b) => dec(a, b, 'startTime'))
    latestKick = orderedFlights.find((event) => {
      if (event.eventTypeValue !== parentEventTypes.items.flight.value)
        return false
      return (
        event.id &&
        event.rawValues.type === eventTypes.items.kick.value &&
        !event.rawValues.ignore
      )
    })
  }
  return latestKick
}

// TODO: add to utils
function dec(a: TableRow, b: TableRow, key: string): number {
  if (a[key] < b[key]) return 1
  if (a[key] > b[key]) return -1
  return 0
}

export const isKick = (flight: RawFlightEventData) => {
  return flight.type === eventTypes.items.kick.value
}

export const isPass = (flight: RawFlightEventData) => {
  return flight.type === eventTypes.items.pass.value
}

// update flight object with touch metrics
export const evaluateBouncedToTouch = (flight: RawFlightEventData) => {
  const allowableTimeForStraightOutDetection = 0.058

  const madeTouch = !!flight.timeAtTouch
  let bounceTouchTime: number = null
  let bouncedToTouch = false

  if (madeTouch && flight.type === eventTypes.items.kick.value) {
    if (parseFloat(flight.timeAtTouch) > flight.timeEnd) {
      const timeOffsetFromEndOfFlight =
        parseFloat(flight.timeAtTouch) - flight.timeEnd
      if (timeOffsetFromEndOfFlight > allowableTimeForStraightOutDetection) {
        bouncedToTouch = true
        bounceTouchTime = timeOffsetFromEndOfFlight
      }
    } else {
      bouncedToTouch = false
    }
  }

  return {
    madeTouch,
    bounceTouchTime,
    bouncedToTouch
  }
}
