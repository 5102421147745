import React, { useEffect, useRef } from 'react'
import animations from '../animations'
import styles from './Form.module.scss'
import { FormController } from './FormController'
import { Form, FormViewProps } from './Form.types'
import { PitchLinesBackground } from '../../PitchLinesBackground/PitchLinesBackground'
import { useRouter } from '../../../ui/router/hooks'
import { Route } from '../../../App/App.types'
import { useDefaultForm } from './hooks'

interface Location {
  pathname: string
}

export interface AppProps {
  location: Location
  history: string[]
}

export const FormView: React.FC<
  AppProps & Route<FormViewProps<{ [key: string]: Form<string, string> }>>
> = (props) => {
  const router = useRouter()

  const { view, history } = props

  const { useForm = useDefaultForm } = view

  const animationContainer = useRef()
  const formContainer = useRef()

  const formProps = useForm(view.formConfig)

  useEffect(() => {
    animateComponentIn()
  }, [])

  useEffect(() => {
    const { redirect, action } = router
    if (redirect && action === 'PUSH' && redirect !== props.location.pathname) {
      animateComponentOut(() => {
        console.log('animating form out')
        history.push(redirect)
      })
    }
  }, [router.redirect, router.action])

  const animateComponentIn = () => {
    const animation =
      view.side === 1
        ? animations.slideInLeftBackground
        : animations.slideInRightBackground
    animation(animationContainer.current, () => {
      animations.showContent(formContainer.current, 0.5)
    })
  }

  const animateComponentOut = (cb) => {
    cb()
    animations.hideContent(formContainer.current, 0.5, () => {
      // const animation =
      //   view.side === 1
      //     ? animations.slideOutLeftBackground
      //     : animations.slideOutRightBackground
      // animation(animationContainer.current, () => {
      //   cb()
      // })
    })
  }

  return (
    <div className='flex min-h-screen'>
      <FormController formMatrix={view.formMatrix} {...formProps} />
    </div>
  )

  return (
    <div className={styles.formViewContainer}>
      {view.side === 1 ? (
        <React.Fragment>
          {/* <div style={backgroundStyle} /> */}
          <PitchLinesBackground />
          <div style={{ position: 'relative' }}>
            <div
              className={styles.animationContainerLeft}
              ref={animationContainer}
            />
            <div
              className={`${styles.formContainer} ${styles.formLeft}`}
              ref={formContainer}
            >
              <FormController formMatrix={view.formMatrix} {...formProps} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <div
              className={styles.animationContainerRight}
              ref={animationContainer}
            />
            <div
              className={`${styles.formContainer} ${styles.formRight}`}
              ref={formContainer}
            >
              <FormController formMatrix={view.formMatrix} {...formProps} />
            </div>
          </div>
          {/* <div id='animation' style={backgroundStyle} /> */}
          <PitchLinesBackground id={'animation'} />
        </React.Fragment>
      )}
    </div>
  )
}
