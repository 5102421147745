import { useState, useEffect, useRef, useCallback } from 'react'
import { useAppSelector } from '../../store/hooks'

export const useNotifications = () => {
  const notifications = useAppSelector((state) => state.notifications)
  return notifications
}

export const useNotificationVisible = (initialIsVisible: boolean) => {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState(initialIsVisible)
  const [clickedOutside, setClickedOutside] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const handleClickPosition = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement
    const notificationCloseButton = target.id === 'notificationClose'
    const clearAllButton = target.id === 'clearAll'

    if (
      ref.current &&
      !ref.current.contains(target) &&
      !notificationCloseButton &&
      !clearAllButton
    ) {
      setIsNotificationVisible(false)
      setClickedOutside(true)
    } else {
      setClickedOutside(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickPosition)
    return () => {
      document.removeEventListener('mousedown', handleClickPosition)
    }
  }, [handleClickPosition])

  const toggleNotificationVisibility = useCallback(() => {
    setIsNotificationVisible((prev) => !prev)
    setClickedOutside(false)
  }, [])

  return {
    ref,
    isNotificationVisible,
    clickedOutside,
    setIsNotificationVisible,
    toggleNotificationVisibility
  }
}
