import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../data_types'
import {
  MetricTypeGroup,
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../metrics/data_types'
import {
  OutcomeTypeGroup,
  getOutcomeTypeGroup,
  getOutcomeTypesConfigFromJson,
  outcomeTypesConfig
} from '../outcomes/data_types'
import { EventFeatures } from './features'
import {
  defaultEventFeatures,
  EventTypeConfig,
  EventTypeGroup,
  EventTypeKeys,
  eventTypesConfig,
  getEventTypeGroup
} from './types/data_types'
import {
  EventSubTypeConfig,
  EventSubTypeKeys,
  eventSubTypesConfig,
  getEventSubTypeGroup
} from './subTypes/data_types'

export const parentEventTypesConfig = {
  flight: {
    key: 'flight',
    value: 0,
    name: 'Flight',
    props: null
  },
  time: {
    key: 'time',
    value: 1,
    name: 'Time',
    props: null
  },
  game: {
    key: 'game',
    value: 6,
    name: 'Game',
    props: null
  },
  aussieRules: {
    key: 'aussieRules',
    value: 7,
    name: 'Aussie Rules',
    props: null
  }
} as const

export type ParentEventTypes = DataTypes<typeof parentEventTypesConfig>

export type ParentEventTypeProps<SubTypes> = {
  types: SubTypes
  metricTypes: MetricTypeGroup
  outcomeTypes: OutcomeTypeGroup
  features: EventFeatures
}

export type ParentEventTypeKey = DataTypeKey<ParentEventTypes>

export type ParentEventTypeKeys = DataTypeKeys<ParentEventTypeKey>

export type ParentEventTypeValues = DataTypeValues<ParentEventTypeKey>

export const getParentEventTypeGroup = (items: {
  [key in ParentEventTypeKeys]?: ParentEventTypes[key]
}) => {
  return getDataTypeGroup<
    ParentEventTypeKeys,
    ParentEventTypeValues,
    ParentEventTypes,
    ParentEventTypeProps<EventTypeGroup>
  >(items)
}

export type ParentEventTypeGroup = ReturnType<typeof getParentEventTypeGroup>

export type ParentEventTypeConfig = DataTypeConfig<
  ParentEventTypeKeys,
  ParentEventTypeValues,
  ParentEventTypeProps<EventTypeGroup>
>

export const parentEventTypes = getParentEventTypeGroup(parentEventTypesConfig)

export const isParentEventType = generateTypeChecks<
  ParentEventTypeKeys,
  ParentEventTypeGroup
>(parentEventTypes)

export type ParentEventType = ReturnType<typeof parentEventTypes.getTypeByValue>

export type EventTypeJson<EventSubTypesJson = NonNullable<unknown>> = {
  name?: string
  types?: EventSubTypesJson
  metrics?: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
        decimal?: number
      }
  )[]
  outcomes?: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
      }
  )[]
  features?: Partial<EventFeatures>
}

export const emptyEventJson: EventTypeJson = {
  types: {},
  metrics: [],
  outcomes: [],
  features: {}
}

export function getEventTypeGroupFromJson(
  dataTypesJson: {
    events: {
      [key in ParentEventTypeKeys]?: EventTypeJson
    }
  },
  sport
): ParentEventTypeGroup {
  const eventTypes = {}

  for (const key in dataTypesJson.events) {
    const sportEventTypesConfig = {} as {
      [key: string]: EventTypeConfig
    }

    let jsonEvent = dataTypesJson.events[key]

    if (!jsonEvent) {
      jsonEvent = emptyEventJson
    }

    const eventMetricTypesConfig = getMetricTypesConfigFromJson(
      jsonEvent.metrics,
      metricTypesConfig
    )

    const eventOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
      jsonEvent.outcomes,
      outcomeTypesConfig
    )

    for (const key in jsonEvent.types) {
      const typeKey = key as EventTypeKeys
      const type = jsonEvent.types[typeKey]

      const sportSubEventTypesConfig = {} as {
        [key: string]: EventSubTypeConfig
      }

      const eventTypeMetricTypesConfig = getMetricTypesConfigFromJson(
        type.metrics,
        eventMetricTypesConfig
      )

      const eventTypeOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
        type.outcomes,
        eventOutcomeTypesConfig
      )

      for (const subKey in type.types) {
        const subTypeKey = subKey as EventSubTypeKeys
        const eventSubType = type.types[subTypeKey]

        const eventSubTypeMetricTypesConfig = getMetricTypesConfigFromJson(
          eventSubType.metrics,
          eventTypeMetricTypesConfig
        )

        const eventSubTypeOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
          eventSubType.outcomes,
          eventTypeOutcomeTypesConfig
        )

        sportSubEventTypesConfig[subTypeKey] = {
          ...eventSubTypesConfig[subTypeKey],
          props: {
            metricTypes: getMetricTypeGroup(eventSubTypeMetricTypesConfig),
            outcomeTypes: getOutcomeTypeGroup(eventSubTypeOutcomeTypesConfig),
            types: null,
            features: {
              ...(eventSubTypesConfig[subTypeKey]?.props?.features ||
                defaultEventFeatures),
              ...(jsonEvent.features || {}),
              ...(type.features || {}),
              ...(eventSubType.features || {})
            }
          }
        }
      }

      sportEventTypesConfig[typeKey] = {
        ...eventTypesConfig[typeKey],
        name: type?.name || eventTypesConfig[typeKey].name,
        props: {
          metricTypes: getMetricTypeGroup(eventTypeMetricTypesConfig),
          outcomeTypes: getOutcomeTypeGroup(eventTypeOutcomeTypesConfig),
          types: getEventSubTypeGroup(sportSubEventTypesConfig),
          features: {
            ...(eventTypesConfig[typeKey]?.props?.features ||
              defaultEventFeatures),
            ...(jsonEvent.features || {}),
            ...(type.features || {})
          }
        }
      }
    }

    eventTypes[key] = {
      ...parentEventTypesConfig[key],
      props: {
        metricTypes: getMetricTypeGroup(eventMetricTypesConfig),
        outcomeTypes: getOutcomeTypeGroup(eventOutcomeTypesConfig),
        types: getEventTypeGroup(sportEventTypesConfig),
        features: {
          ...(parentEventTypesConfig[key]?.props?.features ||
            defaultEventFeatures),
          ...(jsonEvent.features || {})
        }
      }
    }
  }

  const sportEventTypesConfig = {
    ...eventTypes
  } as {
    [key in ParentEventTypeKeys]?: ParentEventTypes[key]
  }

  console.log('event type config for: ' + sport, sportEventTypesConfig)

  return getParentEventTypeGroup(sportEventTypesConfig)
}
