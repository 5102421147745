import {
  rawArsenalPlayers,
  rawPlayerFour,
  rawPlayerOne,
  rawPlayerThree,
  rawPlayerTwo
} from '../../players/mocks/api'
import { formatSessionData } from '../../sessions/functions'
import {
  rawSessionArsenalTrainingSession,
  rawSessionOne
} from '../../sessions/mocks/api'
import { soccerTeam } from '../../teams/mocks/api'
import { formatDrill, processDrill } from '../functions'
import { RawDrill, DrillExtraInfo } from '../types'

export const mockExtraInfo: DrillExtraInfo = {
  region: {
    P1: {
      x: -48.107083446425314,
      y: 68.12500350144894,
      z: 0
    },
    P2: {
      x: 48.107083446425314,
      y: 68.12500350144894,
      z: 0
    },
    P3: {
      x: 48.107083446425314,
      y: 0,
      z: 0
    },
    P4: {
      x: -48.107083446425314,
      y: 0,
      z: 0
    },
    P5: {
      x: -48.107083446425314,
      y: 37.722501750724476,
      z: 0
    },
    P6: {
      x: 48.107083446425314,
      y: 37.722501750724476,
      z: 0
    },
    P7: {
      x: -48.107083446425314,
      y: 30.402501750724472,
      z: 0
    },
    P8: {
      x: 48.107083446425314,
      y: 30.402501750724472,
      z: 0
    }
  },
  bibs: {
    '1': {
      colour: '',
      name: 'No Bib'
    },
    '2': {
      colour: '#f64645',
      name: 'Red'
    },
    '3': {
      colour: '#0099cc',
      name: 'Blue'
    }
  },
  bibTeamDirection: [2, 3]
}

export const playerBibs = {
  [rawPlayerOne.id]: 1,
  [rawPlayerTwo.id]: 1,
  [rawPlayerThree.id]: 1,
  [rawPlayerFour.id]: 1
}

export const rawDrillPending: RawDrill = {
  id: 'drill-pending',
  name: 'Pending Drill',
  sessionId: rawSessionOne.id,
  startTime: 0,
  timeEnd: 0,
  type: 2,
  extraInfo: mockExtraInfo,
  playerBibs
}

export const rawDrillActive: RawDrill = {
  id: 'drill-active',
  name: 'Active Drill',
  sessionId: rawSessionOne.id,
  startTime: rawSessionOne.startTime + 1200, // Drill started 2 minutes after session starting
  timeEnd: 0,
  type: 2,
  extraInfo: mockExtraInfo,
  playerBibs
}

export const rawDrillFinished: RawDrill = {
  id: 'drill-finished',
  name: 'Finished Drill',
  sessionId: rawSessionOne.id,
  startTime: rawSessionOne.endTime - 300, // Drill started 5 minutes before session ending
  timeEnd: rawSessionOne.endTime - 150, // Drill ended 2 minutes before session ending
  type: 2,
  extraInfo: mockExtraInfo,
  playerBibs
}

export const mockPlayerBreakdowns = {
  [rawDrillActive.id]: {
    totals: {
      player: rawPlayerOne,
      totalInvolvements: '15',
      passes: {
        totalInvolvements: '15',
        allPasses: {
          total: '15',
          completion: {
            count: '0',
            total: '15'
          }
        },
        forwardPasses: {
          total: '7'
        },
        sidewaysPasses: {
          total: '5'
        },
        backwardPasses: {
          total: '3'
        }
      },
      dribbling: {
        total: '3',
        averageRecycleTime: {
          total: '6',
          count: '3',
          value: '2'
        },
        totalInPossession: '6'
      },
      shooting: {
        total: '4',
        totalGoals: '2'
      },
      corners: {
        total: '1'
      },
      freeKicks: {
        total: '1'
      }
    },
    players: {
      [rawPlayerOne.id]: {
        player: rawPlayerOne,
        totalInvolvements: '9',
        passes: {
          totalInvolvements: '9',
          allPasses: {
            total: '9',
            completion: {
              count: '0',
              total: '9'
            }
          },
          forwardPasses: {
            total: '4'
          },
          sidewaysPasses: {
            total: '3'
          },
          backwardPasses: {
            total: '2'
          }
        },
        dribbling: {
          total: '2',
          averageRecycleTime: {
            total: '3',
            count: '2',
            value: '1.5'
          }
        },
        shooting: {
          total: '3',
          totalGoals: '1'
        }
      }
    }
  }
}

// Mock Arsenal Drills //
const formattedArsenalTrainingSession = formatSessionData(
  rawSessionArsenalTrainingSession,
  {
    [soccerTeam.id]: soccerTeam
  },
  rawArsenalPlayers,
  false,
  rawSessionArsenalTrainingSession.pitch,
  null
)

const rawArsenalDrillOne: RawDrill = {
  id: 'arsenal-drill-1',
  name: 'Arsenal Drill 1',
  sessionId: rawSessionArsenalTrainingSession.id,
  startTime: rawSessionArsenalTrainingSession.startTime + 1200,
  timeEnd: rawSessionArsenalTrainingSession.startTime + 1800,
  type: 2,
  extraInfo: mockExtraInfo,
  playerBibs
}

export const processedArsenalDrillOne = processDrill(
  rawArsenalDrillOne,
  formattedArsenalTrainingSession,
  'red'
)

const rawArsenalDrillTwo: RawDrill = {
  id: 'arsenal-drill-2',
  name: 'Arsenal Drill 2',
  sessionId: rawSessionArsenalTrainingSession.id,
  startTime: rawSessionArsenalTrainingSession.startTime + 2400,
  timeEnd: rawSessionArsenalTrainingSession.startTime + 3000,
  type: 2,
  extraInfo: {
    ...mockExtraInfo,
    region: {
      ...mockExtraInfo.region,
      // Add 20 to the y value of each point
      P1: {
        ...mockExtraInfo.region.P1,
        y: mockExtraInfo.region.P1.y + 20
      },
      P2: {
        ...mockExtraInfo.region.P2,
        y: mockExtraInfo.region.P2.y + 20
      },
      P3: {
        ...mockExtraInfo.region.P3,
        y: mockExtraInfo.region.P3.y + 20
      },
      P4: {
        ...mockExtraInfo.region.P4,
        y: mockExtraInfo.region.P4.y + 20
      },
      P5: {
        ...mockExtraInfo.region.P5,
        y: mockExtraInfo.region.P5.y + 20
      },
      P6: {
        ...mockExtraInfo.region.P6,
        y: mockExtraInfo.region.P6.y + 20
      },
      P7: {
        ...mockExtraInfo.region.P7,
        y: mockExtraInfo.region.P7.y + 20
      },
      P8: {
        ...mockExtraInfo.region.P8,
        y: mockExtraInfo.region.P8.y + 20
      }
    }
  },
  playerBibs
}

export const processedArsenalDrillTwo = processDrill(
  rawArsenalDrillTwo,
  formattedArsenalTrainingSession,
  'red'
)

const rawArsenalDrillThree: RawDrill = {
  id: 'arsenal-drill-3',
  name: 'Arsenal Drill 3',
  sessionId: rawSessionArsenalTrainingSession.id,
  startTime: rawSessionArsenalTrainingSession.startTime + 3600,
  timeEnd: rawSessionArsenalTrainingSession.startTime + 4200,
  type: 2,
  extraInfo: {
    ...mockExtraInfo,
    region: {
      ...mockExtraInfo.region,
      // Add 20 to the y value of each point
      P1: {
        ...mockExtraInfo.region.P1,
        y: mockExtraInfo.region.P1.y + 70
      },
      P2: {
        ...mockExtraInfo.region.P2,
        y: mockExtraInfo.region.P2.y + 70
      },
      P3: {
        ...mockExtraInfo.region.P3,
        y: mockExtraInfo.region.P3.y + 70
      },
      P4: {
        ...mockExtraInfo.region.P4,
        y: mockExtraInfo.region.P4.y + 70
      },
      P5: {
        ...mockExtraInfo.region.P5,
        y: mockExtraInfo.region.P5.y + 70
      },
      P6: {
        ...mockExtraInfo.region.P6,
        y: mockExtraInfo.region.P6.y + 70
      },
      P7: {
        ...mockExtraInfo.region.P7,
        y: mockExtraInfo.region.P7.y + 70
      },
      P8: {
        ...mockExtraInfo.region.P8,
        y: mockExtraInfo.region.P8.y + 70
      }
    }
  },
  playerBibs
}

export const processedArsenalDrillThree = processDrill(
  rawArsenalDrillThree,
  formattedArsenalTrainingSession,
  'red'
)

const rawArsenalDrillFour: RawDrill = {
  id: 'arsenal-drill-4',
  name: 'Arsenal Drill 4',
  sessionId: rawSessionArsenalTrainingSession.id,
  startTime: rawSessionArsenalTrainingSession.startTime + 4800,
  timeEnd: rawSessionArsenalTrainingSession.startTime + 5400,
  type: 2,
  extraInfo: mockExtraInfo,
  playerBibs
}

export const processedArsenalDrillFour = processDrill(
  rawArsenalDrillFour,
  formattedArsenalTrainingSession,
  'red'
)

export const mockArsenalDrills = {
  [rawArsenalDrillOne.id]: processedArsenalDrillOne,
  [rawArsenalDrillTwo.id]: processedArsenalDrillTwo,
  [rawArsenalDrillThree.id]: processedArsenalDrillThree,
  [rawArsenalDrillFour.id]: processedArsenalDrillFour
}
