import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  getTypesConfigFromJson,
  Option,
  Options,
  TypesJson
} from '../data_types'
import { MetricHandler } from './metric'
import { EMPTY_FORMATTED_PLAYER, FormattedPlayer } from '../players/types'
import { EMPTY_FORMATTED_TEAM, FormattedTeam } from '../teams/types'
import { FilterTypes } from '../../hooks/filter'
import {
  OutcomeType,
  outcomeTypes,
  OutcomeTypeValues
} from '../outcomes/data_types'
import { RawFlightEventData } from '../events/flight/types'

export type FlightTypeMap = {
  [key in keyof RawFlightEventData]: RawFlightEventData[key]
}

export type RawMetricTypeValues = {
  [key in keyof typeof metricTypesConfig]: (typeof metricTypesConfig)[key]['props']['type'] extends 'number'
    ? number
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'text'
    ? string
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'boolean'
    ? boolean
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'option'
    ? key extends keyof FlightTypeMap
      ? FlightTypeMap[key]
      : string
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'enum'
    ? key extends keyof FlightTypeMap
      ? FlightTypeMap[key]
      : number
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'player'
    ? string
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'team'
    ? string
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'outcome'
    ? OutcomeTypeValues
    : unknown
}

export type FormattedMetricTypeValues = {
  [K in keyof typeof metricTypesConfig]: (typeof metricTypesConfig)[K]['props']['type'] extends 'number'
    ? number
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'text'
    ? string
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'boolean'
    ? string // Assuming you want to map boolean to string
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'option'
    ? string
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'enum'
    ? string
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'player'
    ? string
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'team'
    ? string
    : (typeof metricTypesConfig)[K]['props']['type'] extends 'outcome'
    ? string
    : unknown // Fallback type if none of the conditions match
}

export type ProcessedMetricTypeValues = {
  [key in keyof typeof metricTypesConfig]: (typeof metricTypesConfig)[key]['props']['type'] extends 'number'
    ? number
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'text'
    ? string
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'boolean'
    ? Option<boolean>
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'option'
    ? Option<key extends keyof FlightTypeMap ? FlightTypeMap[key] : string>
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'enum'
    ? Option<key extends keyof FlightTypeMap ? FlightTypeMap[key] : number>
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'player'
    ? FormattedPlayer
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'team'
    ? FormattedTeam
    : (typeof metricTypesConfig)[key]['props']['type'] extends 'outcome'
    ? OutcomeType
    : unknown
}

export type GeneralMetricTypeProps<v, k, d> = {
  abbr?: string
  readonly?: boolean
  disableInTrainingMode?: boolean
  officiatingModeOnly?: boolean
  hideOnDetailCard?: boolean
  getTag?: (event: Partial<RawMetricTypeValues>) => string
  disable?: (event: Partial<RawMetricTypeValues>) => boolean
  tagOnClick?: (callback) => void
  defaultFilterType: FilterTypes<v, k, d>
  unitType?: string
  typeInferredName?:
    | string
    | {
        en: string
        fr: string
      }
}

export type OutcomeMetricTypeProps<k, d> = GeneralMetricTypeProps<
  string,
  k,
  d
> & {
  type: 'outcome'
}

export type TeamMetricTypeProps<k, d> = GeneralMetricTypeProps<string, k, d> & {
  type: 'team'
}

export type PlayerMetricTypeProps<k, d> = GeneralMetricTypeProps<
  string,
  k,
  d
> & {
  type: 'player'
}

export type NumberMetricTypeProps<k, d> = GeneralMetricTypeProps<
  number,
  k,
  d
> & {
  type: 'number'
  decimal?: number
  unitType?: 'distance' | 'time' | 'speed' | 'revs' | 'percentage' | 'angle'
  getValue?: (event: Partial<RawMetricTypeValues>) => number
}

export type TextMetricTypeProps<k, d> = GeneralMetricTypeProps<string, k, d> & {
  type: 'text'
}

export type OptionMetricTypeProps<k, d> = GeneralMetricTypeProps<
  string,
  k,
  d
> & {
  type: 'option'
  options: Options<string>
}

export type EnumMetricTypeProps<k, d> = GeneralMetricTypeProps<number, k, d> & {
  type: 'enum'
  options: Options<number>
}

export type BooleanMetricTypeProps<k, d> = GeneralMetricTypeProps<
  boolean,
  k,
  d
> & {
  type: 'boolean'
  options: Options<boolean>
}

export type MetricTypeProps<k, d> =
  | TeamMetricTypeProps<k, d>
  | PlayerMetricTypeProps<k, d>
  | TextMetricTypeProps<k, d>
  | NumberMetricTypeProps<k, d>
  | OptionMetricTypeProps<k, d>
  | EnumMetricTypeProps<k, d>
  | BooleanMetricTypeProps<k, d>
  | OutcomeMetricTypeProps<k, d>

export const metricTypesConfig = {
  outcome: {
    key: 'outcome' as const,
    value: 'Outcome',
    name: 'Outcome',
    props: {
      type: 'outcome' as const,
      defaultFilterType: {
        key: 'outcome' as const,
        label: 'Outcome',
        type: 'single' as const,
        options: [],
        value: null
      }
    }
  },
  team: {
    key: 'team' as const,
    value: 'Team',
    name: 'Team',
    props: {
      type: 'team' as const,
      defaultFilterType: {
        key: 'team' as const,
        label: 'Team',
        type: 'singleWithAll' as const,
        options: [],
        value: 'All'
      }
    }
  },
  player: {
    key: 'player' as const,
    value: 'Player',
    name: 'Player',
    props: {
      type: 'player' as const,
      defaultFilterType: {
        key: 'player' as const,
        label: 'Player',
        type: 'singleWithAll' as const,
        options: [],
        value: 'All'
      }
    }
  },
  dist: {
    key: 'dist' as const,
    value: 'Distance',
    name: 'Distance',
    props: {
      abbr: 'Dist',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'dist' as const,
        label: 'Distance',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  passDirection: {
    key: 'passDirection' as const,
    value: 'PassDirection',
    name: 'Pass Direction',
    props: {
      abbr: 'Direction',
      type: 'option' as const,
      options: [
        { name: 'Right', value: 'Right' },
        { name: 'Left', value: 'Left' },
        { name: 'Backwards', value: 'Backwards' },
        { name: 'Forwards', value: 'Forwards' },
        { name: 'Sideways', value: 'Sideways' },
        { name: 'Middle', value: 'Middle' }
      ],
      defaultFilterType: {
        key: 'passDirection' as const,
        label: 'Pass Direction',
        type: 'singleWithAll' as const,
        options: [],
        value: 'All'
      }
    }
  },
  success: {
    key: 'success' as const,
    value: 'Success',
    name: 'Success',
    props: {
      abbr: 'Success',
      type: 'boolean' as const,
      options: [
        {
          name: 'Success',
          value: true
        },
        {
          name: 'Failure',
          value: false
        }
      ],
      defaultFilterType: {
        key: 'success' as const,
        label: 'Success',
        type: 'single' as const,
        options: [],
        value: null
      }
    }
  },
  distanceToCorner: {
    key: 'distanceToCorner' as const,
    value: 'DistanceToCornerFlag',
    name: 'Distance to corner flag',
    props: {
      abbr: 'Dist to corner',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'distanceToCorner' as const,
        label: 'Distance to corner flag',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  hangTime: {
    key: 'hangTime' as const,
    value: 'HangTime',
    name: 'Hang time',
    props: {
      abbr: 'Hang time',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 1,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'hangTime' as const,
        label: 'Hang time',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  timeToTarget: {
    key: 'timeToTarget' as const,
    value: 'TimeToTarget',
    name: 'Time To Target',
    props: {
      abbr: 'TTT',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'timeToTarget' as const,
        label: 'Time To Target',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  speed: {
    key: 'speed' as const,
    value: 'Speed',
    name: 'Speed',
    props: {
      abbr: 'Speed',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'speed' as const,
        label: 'Speed',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  maxHeight: {
    key: 'maxHeight' as const,
    value: 'MaxHeight',
    name: 'Max Height',
    props: {
      abbr: 'Height',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'maxHeight' as const,
        label: 'Max Height',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  spin: {
    key: 'spin' as const,
    value: 'Spin',
    name: 'Spin',
    props: {
      abbr: 'Spin',
      type: 'number' as const,
      unitType: 'revs' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'spin' as const,
        label: 'Spin',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  efficiency: {
    key: 'efficiency' as const,
    value: 'Efficiency',
    name: 'Spiral efficiency',
    props: {
      abbr: 'Spiral',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'efficiency' as const,
        label: 'Spiral efficiency',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  endOverEndEfficiency: {
    key: 'endOverEndEfficiency' as const,
    value: 'EndOverEndEfficiency',
    name: 'End over end efficiency',
    props: {
      abbr: 'EoE',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'endOverEndEfficiency' as const,
        label: 'End over end efficiency',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  groundReloadTime: {
    key: 'groundReloadTime' as const,
    value: 'GroundReloadTime',
    name: 'Ground reload time',
    props: {
      abbr: 'Ground reload',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'groundReloadTime' as const,
        label: 'Ground reload time',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  reloadTime: {
    key: 'reloadTime' as const,
    value: 'ReloadTime',
    name: 'Reload time',
    props: {
      abbr: 'Reload time',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'reloadTime' as const,
        label: 'Reload time',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  confirmed: {
    key: 'confirmed' as const,
    value: 'Confirmed',
    name: 'Confirm',
    props: {
      abbr: 'Confirmed',
      type: 'boolean' as const,
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
        { name: 'Unset', value: null }
      ],
      defaultFilterType: {
        key: 'confirmed' as const,
        label: 'Confirm',
        type: 'single' as const,
        options: [],
        value: null
      }
    }
  },
  deviationFromCenterOfPoles: {
    key: 'deviationFromCenterOfPoles' as const,
    value: 'DistanceToPoleCentre',
    name: 'Distance to pole centre',
    props: {
      abbr: 'Dist to pole centre',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'deviationFromCenterOfPoles' as const,
        label: 'Distance to pole centre',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  lineoutDeviation: {
    key: 'lineoutDeviation' as const,
    value: 'LineoutDeviation',
    name: 'Lineout Deviation',
    props: {
      abbr: 'Deviation',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'lineoutDeviation' as const,
        label: 'Lineout Deviation',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  territorialGain: {
    key: 'territorialGain' as const,
    value: 'TerritorialGain',
    name: 'Territorial Gain',
    props: {
      abbr: 'Gain',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'territorialGain' as const,
        label: 'Territorial Gain',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  inPitchHangTime: {
    key: 'inPitchHangTime' as const,
    value: 'InPitchHangTime',
    name: 'In pitch hang time',
    props: {
      abbr: '*Hang time',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 2,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'inPitchHangTime' as const,
        label: 'In pitch hang time',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      getValue: (rawMetrics) => {
        // Check if inPitchHangTime exists, if not use hangTime //
        return rawMetrics.inPitchHangTime
          ? rawMetrics.inPitchHangTime
          : rawMetrics.hangTime
      }
    }
  },
  inPitchDistance: {
    key: 'inPitchDistance' as const,
    value: 'InPitchDistance',
    name: 'In pitch distance',
    props: {
      abbr: '*Distance',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      hideOnDetailCard: true,
      defaultFilterType: {
        key: 'inPitchDistance' as const,
        label: 'In pitch distance',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      getValue: (rawMetrics) => {
        // Check if inPitchDistance exists, if not use distance //
        return rawMetrics.inPitchDistance
          ? rawMetrics.inPitchDistance
          : rawMetrics.dist
      }
    }
  },
  bouncedToTouch: {
    key: 'bouncedToTouch' as const,
    value: 'BouncedToTouch',
    name: 'Bounced into touch',
    props: {
      abbr: '*Bounced into touch',
      type: 'boolean' as const,
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
      ],
      defaultFilterType: {
        key: 'bouncedToTouch' as const,
        label: 'Bounced into touch',
        type: 'single' as const,
        options: [],
        value: null
      },
      disable: (rawMetrics) => {
        if (!rawMetrics.madeTouch) return true
      }
    }
  },
  bounceTouchTime: {
    key: 'bounceTouchTime' as const,
    value: 'BounceTouchTime',
    name: 'Bounce-touch time',
    props: {
      abbr: '*Bounce-touch time',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'bounceTouchTime' as const,
        label: 'Bounce-touch time',
        type: 'single' as const,
        options: [],
        value: null
      },
      disable: (rawMetrics) => {
        if (!rawMetrics.madeTouch || !rawMetrics.bouncedToTouch) return true
      }
    }
  },
  madeTouch: {
    key: 'madeTouch' as const,
    value: 'MadeTouch',
    name: 'Made touch',
    props: {
      abbr: '*Made touch',
      type: 'boolean' as const,
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
      ],
      defaultFilterType: {
        key: 'madeTouch' as const,
        label: 'Made touch',
        type: 'singleWithAll' as const,
        options: [],
        value: null
      },
      disable: (rawMetrics) => {
        if (!rawMetrics.madeTouch) return true
      }
    }
  },
  probabilityForward: {
    key: 'probabilityForward' as const,
    value: 'ProbabilityForward',
    name: 'Probability Forward',
    props: {
      abbr: 'Probability Fwd',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'probabilityForward' as const,
        label: 'Probability Forward',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      disable: (rawMetrics) => {
        if (!rawMetrics.forward) return true
      }
    }
  },
  forward: {
    key: 'forward' as const,
    value: 'Forward',
    name: 'Forward Pass',
    props: {
      abbr: 'Forward Pass',
      type: 'boolean' as const,
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
        { name: 'Unset', value: null }
      ],
      defaultFilterType: {
        key: 'forward' as const,
        label: 'Forward Pass',
        type: 'singleWithAll' as const,
        options: [],
        value: null
      }
    }
  },
  carryXVelocity: {
    key: 'carryXVelocity' as const,
    value: 'CarryXVelocity',
    name: 'Carry X Velocity',
    props: {
      abbr: 'Carry X',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'carryXVelocity' as const,
        label: 'Carry X Velocity',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  passXVelocity: {
    key: 'passXVelocity' as const,
    value: 'PassXVelocity',
    name: 'Pass X Velocity',
    props: {
      abbr: 'Pass X',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'passXVelocity' as const,
        label: 'Pass X Velocity',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      disable: (rawMetrics) => {
        if (!rawMetrics.forward) return true
      }
    }
  },
  relativeVelX: {
    key: 'relativeVelX' as const,
    value: 'RelativeVelX',
    name: 'Relative Velocity',
    props: {
      abbr: 'Relative Vel',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'relativeVelX' as const,
        label: 'Relative Velocity',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      disable: (rawMetrics) => {
        if (!rawMetrics.forward) return true
      }
    }
  },
  lineoutDeviated: {
    key: 'lineoutDeviated' as const,
    value: 'LineoutDeviated',
    name: 'Lineout Deviated',
    props: {
      abbr: 'Lineout Deviated',
      type: 'enum' as const,
      options: [
        { name: 'Straight', value: 0 },
        { name: 'Deviated', value: 1 }
      ],
      defaultFilterType: {
        key: 'lineoutDeviated' as const,
        label: 'Lineout Deviated',
        type: 'singleWithAll' as const,
        options: [],
        value: 'All' as const
      }
    }
  },
  lineoutDeviationAngle: {
    key: 'lineoutDeviationAngle' as const,
    value: 'LineoutDeviationAngle',
    name: 'Lineout Deviation Angle',
    props: {
      abbr: 'Deviation Angle',
      type: 'number' as const,
      unitType: 'angle' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'lineoutDeviationAngle' as const,
        label: 'Lineout Deviation Angle',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  distanceToOppositionLine: {
    key: 'distanceToOppositionLine' as const,
    value: 'DistanceToOppositionLine',
    name: 'Distance to opposition line',
    props: {
      abbr: 'Dist to opp line',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'distanceToOppositionLine' as const,
        label: 'Distance to opposition line',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  positionX: {
    key: 'positionX' as const,
    value: 'PositionX',
    name: 'Position X',
    props: {
      abbr: 'Position X',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'positionX' as const,
        label: 'Position X',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  positionY: {
    key: 'positionY' as const,
    value: 'PositionY',
    name: 'Position Y',
    props: {
      abbr: 'Position Y',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'positionY' as const,
        label: 'Position Y',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  throwSpeed: {
    key: 'throwSpeed' as const,
    value: 'ThrowSpeed',
    name: 'Throw Speed',
    props: {
      abbr: 'Thr. Spd.',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'throwSpeed' as const,
        label: 'Throw Speed',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  targetPlayerId: {
    key: 'targetPlayerId' as const,
    value: 'TargetPlayerId',
    name: 'Target Player',
    props: {
      abbr: 'Target Player',
      type: 'player' as const,
      defaultFilterType: {
        key: 'targetPlayerId' as const,
        label: 'Target Player',
        type: 'multiple' as const,
        options: [],
        value: null
      }
    }
  },
  toPlayerId: {
    key: 'toPlayerId' as const,
    value: 'ToPlayerId',
    name: 'Receiver',
    props: {
      abbr: 'To Player',
      type: 'player' as const,
      defaultFilterType: {
        key: 'toPlayerId' as const,
        label: 'Receiver',
        type: 'multiple' as const,
        options: [],
        value: null
      }
    }
  },
  rotation: {
    key: 'rotation' as const,
    value: 'rotation',
    name: 'Rotation',
    props: {
      abbr: 'Rotation',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'rotation' as const,
        label: 'Rotation',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      getValue: (rawMetrics) => {
        return rawMetrics.endOverEndEfficiency > rawMetrics.efficiency
          ? rawMetrics.endOverEndEfficiency
          : rawMetrics.efficiency
      },
      getTag: (rawMetrics) => {
        return rawMetrics.endOverEndEfficiency > rawMetrics.efficiency
          ? 'EoE'
          : 'Spiral'
      }
    }
  },
  detailCardDistance: {
    key: 'detailCardDistance' as const,
    value: 'detailCardDistance',
    name: 'Distance',
    props: {
      abbr: 'New Distance',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'detailCardDistance' as const,
        label: 'Distance',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      getValue: (event) => {
        return event.dist
      },
      getTag: (rawMetrics) => {
        if (
          rawMetrics.inPitchDistance &&
          rawMetrics.inPitchDistance !== rawMetrics.dist
        )
          return rawMetrics.inPitchDistance.toFixed(1)
      }
    }
  },
  detailCardHangTime: {
    key: 'detailCardHangTime' as const,
    value: 'detailCardHangTime',
    name: 'Hang Time',
    props: {
      abbr: 'New Hang Time',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'detailCardHangTime' as const,
        label: 'Hang Time',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      },
      getValue: (rawMetrics) => {
        return rawMetrics.hangTime
      },
      getTag: (rawMetrics) => {
        if (rawMetrics.inPitchHangTime)
          return rawMetrics.inPitchDistance.toFixed(1)
      }
    }
  },
  snapToPass: {
    key: 'snapToPass' as const,
    value: 'SnapToPass',
    name: 'Snap To Pass',
    props: {
      abbr: 'Snap To Pass',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'snapToPass' as const,
        label: 'Snap To Pass',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalPasses: {
    key: 'totalPasses' as const,
    value: 'TotalPasses',
    name: {
      en: 'Total Passes',
      fr: 'Passes totales'
    },
    props: {
      abbr: 'No. Passes',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalPasses' as const,
        label: 'Total Passes',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalPassCompletion: {
    key: 'totalPassCompletion' as const,
    value: 'TotalPassCompletion',
    name: {
      en: 'Passes Completed',
      fr: 'Passes réussies'
    },
    props: {
      abbr: 'Passes Completed',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalPassCompletion' as const,
        label: 'Passes Completed',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalForwardPasses: {
    key: 'totalForwardPasses' as const,
    value: 'TotalForwardPasses',
    name: {
      en: 'Forward Passes',
      fr: "Passes vers l'avant"
    },
    props: {
      abbr: 'No. Forward Passes',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalForwardPasses' as const,
        label: 'Forward Passes',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalBackwardPasses: {
    key: 'totalBackwardPasses' as const,
    value: 'TotalBackwardPasses',
    name: {
      en: 'Backward Passes',
      fr: "Passes vers l'arrière"
    },
    props: {
      abbr: 'No. Backward Passes',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalBackwardPasses' as const,
        label: 'Backward Passes',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalSidewaysPasses: {
    key: 'totalSidewaysPasses' as const,
    value: 'TotalSidewaysPasses',
    name: {
      en: 'Sideways Passes',
      fr: 'Passes latérales'
    },
    props: {
      abbr: 'No. Sideways Passes',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalSidewaysPasses' as const,
        label: 'Sideways Passes',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalShotsAtGoal: {
    key: 'totalShotsAtGoal' as const,
    value: 'TotalShotsAtGoal',
    name: {
      en: 'Shots At Goal',
      fr: 'Tirs au but'
    },
    props: {
      abbr: 'No. Shots At Goal',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalShotsAtGoal' as const,
        label: 'Shots At Goal',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  averageRecycleTime: {
    key: 'averageRecycleTime' as const,
    value: 'AverageRecycleTime',
    name: {
      en: 'Average Recycle Time',
      fr: 'Temps moyen de recyclage'
    },
    props: {
      abbr: 'Avg Recycle Time',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'averageRecycleTime' as const,
        label: 'Average Recycle Time',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  totalGoalsScored: {
    key: 'totalGoalsScored' as const,
    value: 'TotalGoalsScored',
    name: {
      en: 'Goals Scored',
      fr: 'Buts marqués'
    },
    props: {
      abbr: 'No. Goals Scored',
      type: 'number' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalGoalsScored' as const,
        label: 'Goals Scored',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  averageThrowDistance: {
    key: 'averageThrowDistance' as const,
    value: 'AverageThrowDistance',
    name: 'Avg Throw Distance',
    props: {
      abbr: 'Avg Throw Dist',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'averageThrowDistance' as const,
        label: 'Avg Throw Distance',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  averageSpeed: {
    key: 'averageSpeed' as const,
    value: 'AverageSpeed',
    name: 'Average Speed',
    props: {
      abbr: 'Avg Speed',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'averageSpeed' as const,
        label: 'Average Speed',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  averageEfficiency: {
    key: 'averageEfficiency' as const,
    value: 'AverageEfficiency',
    name: 'Avg Efficiency',
    props: {
      abbr: 'Avg Efficiency',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'averageEfficiency' as const,
        label: 'Average Efficiency',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  averageSpin: {
    key: 'averageSpin' as const,
    value: 'AverageSpin',
    name: 'Avg Spin',
    props: {
      abbr: 'Avg Spin',
      type: 'number' as const,
      unitType: 'revs' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'averageSpin' as const,
        label: 'Average Spin',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },
  initialVelocity: {
    key: 'initialVelocity' as const,
    value: 'InitialVelocity',
    name: 'Initial Velocity',
    props: {
      abbr: 'Initial Velocity',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'initialVelocity' as const,
        label: 'Initial Velocity',
        type: 'range' as const,
        options: [],
        value: [0, 100] as [number, number]
      }
    }
  },

  // New Metrics //
  totalRuns: {
    key: 'totalRuns' as const,
    value: 'Total Runs',
    name: {
      en: 'Total Runs',
      fr: 'Courses totales'
    },
    props: {
      abbr: 'Tot Runs',
      type: 'number' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'totalRuns' as const,
        label: 'Total Runs',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 200 }
        ],
        value: [0, 200] as [number, number]
      }
    }
  },
  totalRunDistance: {
    key: 'totalRunDistance' as const,
    value: 'Total Run Distance',
    name: {
      en: 'Total Run Distance',
      fr: 'Distance totale parcourue'
    },
    props: {
      abbr: 'Tot Run Dist',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'totalRunDistance' as const,
        label: 'Total Run Distance',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 2000 }
        ],
        value: [0, 2000] as [number, number]
      }
    }
  },
  runIntensity: {
    key: 'runIntensity' as const,
    value: 'Run Intensity',
    name: {
      en: 'Run Intensity',
      fr: 'Intensité des courses'
    },
    props: {
      abbr: 'Run Intensity',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'runIntensity' as const,
        label: 'Run Intensity',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  shotConversion: {
    key: 'shotConversion' as const,
    value: 'Shot Conversion',
    name: {
      en: 'Shot Conversion',
      fr: 'Conversion des tirs'
    },
    props: {
      abbr: 'Shot Conversion',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'shotConversion' as const,
        label: 'Shot Conversion',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  shotsOnTargetRatio: {
    key: 'shotsOnTargetRatio' as const,
    value: 'Shots On Target Ratio',
    name: {
      en: 'Shots On Target Ratio',
      fr: 'Ratio de tirs cadrés'
    },
    props: {
      abbr: 'Shots On Target Ratio',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'shotsOnTargetRatio' as const,
        label: 'Shots On Target Ratio',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  totalDistance: {
    key: 'totalDistance' as const,
    value: 'TotalDistance',
    name: {
      en: 'Total Distance',
      fr: 'Distance totale'
    },
    props: {
      abbr: 'Tot Dist',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'totalDistance' as const,
        label: 'Total Distance',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 5000 }
        ],
        value: [0, 5000] as [number, number]
      }
    }
  },
  averageMaxSpeed: {
    key: 'averageMaxSpeed' as const,
    value: 'AverageMaxSpeed',
    name: {
      en: 'Average Max Speed',
      fr: 'Vitesse maximale moyenne'
    },
    props: {
      abbr: 'Avg Max Speed',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'averageMaxSpeed' as const,
        label: 'Average Max Speed',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 30 }
        ],
        value: [0, 30] as [number, number]
      }
    }
  },
  xGImpacts: {
    key: 'xGImpacts' as const,
    value: 'xGImpacts',
    name: {
      en: 'xG Impacts',
      fr: 'Impacts xG'
    },
    props: {
      abbr: 'xG Impacts',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'xGImpacts' as const,
        label: 'xG Impacts',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 1 }
        ],
        value: [0, 1] as [number, number]
      }
    }
  },
  avgInvolvements: {
    key: 'avgInvolvements' as const,
    value: 'Average Involvements',
    name: {
      en: 'Average Involvements',
      fr: 'Implications moyennes'
    },
    props: {
      abbr: 'Avg Involvements',
      type: 'number' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'avgInvolvements' as const,
        label: 'Average Involvements',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  totalCarries: {
    key: 'totalCarries' as const,
    value: 'TotalCarries',
    name: {
      en: 'Total Carries',
      fr: 'Portées totales'
    },
    props: {
      abbr: 'Tot Carries',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'totalCarries' as const,
        label: 'Total Carries',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 200 }
        ],
        value: [0, 200] as [number, number]
      }
    }
  },
  completedPasses: {
    key: 'completedPasses' as const,
    value: 'Completed Passes',
    name: {
      en: 'Completed Passes',
      fr: 'Passes réussies'
    },
    props: {
      abbr: 'Completed Passes',
      type: 'number' as const,
      unitType: 'revs' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'completedPasses' as const,
        label: 'Completed Passes',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  totalShots: {
    key: 'totalShots' as const,
    value: 'Total Shots',
    name: {
      en: 'Total Shots',
      fr: 'Tirs totaux'
    },
    props: {
      abbr: 'Tot Shots',
      type: 'number' as const,
      unitType: 'revs' as const,
      decimal: 0,
      defaultFilterType: {
        key: 'totalShots' as const,
        label: 'Total Shots',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },
  averageTimeOnBall: {
    key: 'averageTimeOnBall' as const,
    value: 'Average Time on Ball',
    name: {
      en: 'Average Time on Ball',
      fr: 'Temps moyen en possession'
    },
    props: {
      abbr: 'Avg Time on Ball',
      type: 'number' as const,
      unitType: 'time' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'averageTimeOnBall' as const,
        label: 'Average Time on Ball',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 10 }
        ],
        value: [0, 10] as [number, number]
      }
    }
  },
  runDistance: {
    key: 'runDistance' as const,
    value: 'RunDistance',
    name: {
      en: 'Run Distance',
      fr: 'Distance de course'
    },
    props: {
      abbr: 'Avg Time on Ball',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'runDistance' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 10 }
        ],
        value: [0, 10] as [number, number]
      }
    }
  },

  involvements: {
    key: 'involvements' as const,
    value: 'Involvements',
    name: {
      en: 'Involvements',
      fr: 'Participations'
    },
    props: {
      abbr: 'Involvements',
      type: 'number' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'involvements' as const,
        label: 'ivolvements',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  involvementsPerMinute: {
    key: 'involvementsPerMinute' as const,
    value: 'InvolvementsPerMin',
    name: {
      en: 'Involvements per Minute',
      fr: 'Participations par minute'
    },
    props: {
      abbr: 'Involvements / Min',
      type: 'number' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'involvementsPerMinute' as const,
        label: 'Involvements / Min',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },

  // More new metrics - physical //

  distance: {
    key: 'distance' as const,
    value: 'Distance',
    name: {
      en: 'Distance',
      fr: 'Distance'
    },
    props: {
      abbr: 'Total Distance',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'distance' as const,
        label: 'Distance',
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 20 }
        ],
        value: [0, 20] as [number, number]
      }
    }
  },
  distancePerMin: {
    key: 'distancePerMin' as const,
    value: 'DistancePerMin',
    name: {
      en: 'Distance Per Minute',
      fr: 'Distance par minute'
    },
    props: {
      abbr: 'Dist/Min',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'distancePerMin' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 5 }
        ],
        value: [0, 5] as [number, number]
      }
    }
  },
  highSpeedRunningDistance: {
    key: 'highSpeedRunningDistance' as const,
    value: 'HighSpeedRunningDistance',
    name: {
      en: 'High-Speed Running Distance',
      fr: 'Distance de course à grande vitesse'
    },
    props: {
      abbr: 'HSR Distance',
      type: 'number' as const,
      unitType: 'distance' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'highSpeedRunningDistance' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 15 }
        ],
        value: [0, 15] as [number, number]
      }
    }
  },
  highSpeedRunningDistancePerMin: {
    key: 'highSpeedRunningDistancePerMin' as const,
    value: 'HighSpeedRunningDistancePerMin',
    name: {
      en: 'High-Speed Running Distance Per Minute',
      fr: 'Distance de course à grande vitesse par minute'
    },
    props: {
      abbr: 'HSR Dist/Min',
      type: 'number' as const,
      unitType: 'speed' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'highSpeedRunningDistancePerMin' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 3 }
        ],
        value: [0, 3] as [number, number]
      }
    }
  },
  accelCount: {
    key: 'accelCount' as const,
    value: 'AccelCount',
    name: {
      en: 'Acceleration Count',
      fr: 'Nombre d’accélérations'
    },
    props: {
      abbr: 'Accel Count',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'accelCount' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },
  decelCount: {
    key: 'decelCount' as const,
    value: 'DecelCount',
    name: {
      en: 'Deceleration Count',
      fr: 'Nombre de décélérations'
    },
    props: {
      abbr: 'Decel Count',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'decelCount' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },
  totalWork: {
    key: 'totalWork' as const,
    value: 'TotalWork',
    name: {
      en: 'Total Work',
      fr: 'Travail total'
    },
    props: {
      abbr: 'Work',
      type: 'number' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'totalWork' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 1000 }
        ],
        value: [0, 1000] as [number, number]
      }
    }
  },

  // Technical Metrics //
  forwardPassesComplete: {
    key: 'forwardPassesComplete' as const,
    value: 'ForwardPassesComplete',
    name: {
      en: 'Forward Passes Complete',
      fr: 'Passes vers l’avant complétées'
    },
    props: {
      abbr: 'Fwd Pass Comp',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'forwardPassesComplete' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },
  backwardPassesComplete: {
    key: 'backwardPassesComplete' as const,
    value: 'BackwardPassesComplete',
    name: {
      en: 'Backward Passes Complete',
      fr: 'Passes vers l’avant complétées'
    },
    props: {
      abbr: 'Backward Pass Comp',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'backwardPassesComplete' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },
  sidewaysPassesComplete: {
    key: 'sidewaysPassesComplete' as const,
    value: 'SidewaysPassesComplete',
    name: {
      en: 'Sideways Passes Complete',
      fr: 'Passes vers l’avant complétées'
    },
    props: {
      abbr: 'Sideways Pass Comp',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'sidewaysPassesComplete' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },
  shots: {
    key: 'shots' as const,
    value: 'Shots',
    name: {
      en: 'Shots',
      fr: 'Tirs'
    },
    props: {
      abbr: 'Shots',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'shots' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 20 }
        ],
        value: [0, 20] as [number, number]
      }
    }
  },
  shotsConversion: {
    key: 'shotsConversion' as const,
    value: 'ShotsConversion',
    name: {
      en: 'Shots Conversion',
      fr: 'Conversion des tirs'
    },
    props: {
      abbr: 'Shots Conv',
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 1,
      defaultFilterType: {
        key: 'shotsConversion' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  duelsWon: {
    key: 'duelsWon' as const,
    value: 'DuelsWon',
    name: {
      en: 'Duels Won',
      fr: 'Duels gagnés'
    },
    props: {
      abbr: 'Duels Won',
      type: 'number' as const,

      decimal: 0,
      defaultFilterType: {
        key: 'duelsWon' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 50 }
        ],
        value: [0, 50] as [number, number]
      }
    }
  },

  // New Test Metrics //
  forwardPassesCompletionRate: {
    key: 'forwardPassesCompletionRate' as const,
    value: 'ForwardPassesCompletionRate',
    name: {
      en: 'Forward Pass Completion Rate',
      fr: 'Taux de passes vers l’avant complétées'
    },
    props: {
      abbr: 'Completion',
      typeInferredName: {
        en: 'Completion Rate',
        fr: 'Taux de réussite'
      },
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'forwardPassesCompletionRate' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  backwardPassesCompletionRate: {
    key: 'backwardPassesCompletionRate' as const,
    value: 'BackwardPassesCompletionRate',
    name: {
      en: 'Backward Pass Completion Rate',
      fr: 'Taux de passes en arrière complétées'
    },
    props: {
      abbr: 'Bwd Pass Comp',
      typeInferredName: {
        en: 'Completion Rate',
        fr: 'Taux de réussite'
      },
      type: 'number' as const,
      unitType: 'percentage' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'backwardPassesCompletionRate' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  },
  sidewaysPassesCompletionRate: {
    key: 'sidewaysPassesCompletionRate' as const,
    value: 'SidewaysPassesCompletionRate',
    name: {
      en: 'Sideways Pass Completion Rate',
      fr: 'Taux de passes latérales complétées'
    },
    props: {
      abbr: 'Side Pass Comp',
      type: 'number' as const,
      typeInferredName: {
        en: 'Completion Rate',
        fr: 'Taux de réussite'
      },
      unitType: 'percentage' as const,
      decimal: 2,
      defaultFilterType: {
        key: 'sidewaysPassesCompletionRate' as const,
        type: 'range' as const,
        options: [
          { name: 'Min', value: 0 },
          { name: 'Max', value: 100 }
        ],
        value: [0, 100] as [number, number] as [number, number]
      }
    }
  }
}

export type MetricTypes = DataTypes<typeof metricTypesConfig>

export type MetricTypeKey = DataTypeKey<MetricTypes>

export type MetricTypeKeys = DataTypeKeys<MetricTypeKey>

export type MetricTypeValues = DataTypeValues<MetricTypeKey>

export const getMetricTypeGroup = (items: {
  [key in MetricTypeKeys]?: MetricTypes[key]
}) => {
  return getDataTypeGroup<
    MetricTypeKeys,
    MetricTypeValues,
    MetricTypes,
    MetricTypeProps<MetricTypeKeys, MetricTypeKeys>
  >(items)
}

export type MetricTypeGroup = ReturnType<typeof getMetricTypeGroup>

export type MetricTypeConfig = DataTypeConfig<
  MetricTypeKeys,
  MetricTypeValues,
  MetricTypeProps<MetricTypeKeys, MetricTypeKeys>
>

export const metricTypes = getMetricTypeGroup(metricTypesConfig)

export type MetricType = ReturnType<(typeof metricTypes)['getTypeByValue']>

export function generateMetricTypeClass(dataTypeConfig: MetricTypeConfig) {
  if (!dataTypeConfig) return null
  return new MetricHandler(dataTypeConfig)
}

export type MetricTypeJson = {
  key: MetricTypeKeys
  name: string | { en: string; fr: string }
  abbr: string
  decimal: number
}

export type MetricTypesJson = TypesJson<MetricTypeKeys, MetricTypeJson>

export function getMetricTypesConfigFromJson(
  typesJson: (
    | string
    | {
        key: string
        name?: string | { en: string; fr: string }
        abbr?: string
      }
  )[] = [],
  typesConfig: Partial<typeof metricTypesConfig> = {}
) {
  return getTypesConfigFromJson<string, typeof typesConfig>(
    typesJson,
    typesConfig,
    metricTypesConfig
  )
}

// Properly type emptyRawMetricTypeValues as mutable
export const emptyRawMetricTypeValues: Record<
  keyof typeof metricTypesConfig,
  undefined
> = {} as Record<keyof typeof metricTypesConfig, undefined>

// Dynamically populate emptyRawMetricTypeValues
Object.keys(metricTypesConfig).forEach((key) => {
  emptyRawMetricTypeValues[key as keyof typeof metricTypesConfig] = undefined
})

export const emptyProcessedMetricTypeValues = {} as {
  [key in keyof typeof metricTypesConfig]: ProcessedMetricTypeValues[key]
}
export const emptyFormattedMetricTypeValues = {} as {
  [key in keyof typeof metricTypesConfig]: FormattedMetricTypeValues[key]
}

// Dynamically populate emptyRawMetricTypeValues
Object.keys(metricTypesConfig).forEach((key) => {
  const metricType = metricTypesConfig[key as keyof typeof metricTypesConfig]
  if (metricType.props.type === 'outcome') {
    emptyProcessedMetricTypeValues[key] = outcomeTypes.getTypeByValue(null)
    emptyFormattedMetricTypeValues[key] = null
  } else if (metricType.props.type === 'team') {
    emptyProcessedMetricTypeValues[key] = EMPTY_FORMATTED_TEAM
    emptyFormattedMetricTypeValues[key] = null
  } else if (metricType.props.type === 'player') {
    emptyProcessedMetricTypeValues[key] = EMPTY_FORMATTED_PLAYER
    emptyFormattedMetricTypeValues[key] = null
  } else if (
    metricType.props.type === 'option' ||
    metricType.props.type === 'enum' ||
    metricType.props.type === 'boolean'
  ) {
    emptyProcessedMetricTypeValues[key] = {
      name: '',
      value: null
    }
    emptyFormattedMetricTypeValues[key] = null
  } else {
    emptyProcessedMetricTypeValues[key] = undefined
    emptyFormattedMetricTypeValues[key] = undefined
  }
})
