import { useMemo } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { useAuthentication } from '../../ui/authentication/hooks'
import { useRouter } from '../../ui/router/hooks'
import { AuthFormConfig, formConfig } from './form_config'
import { useEffect } from 'react'
import {
  authenticationError,
  setForgotPassword
} from '../../ui/authentication/actions'
import { setUrlSearchParams } from '../../ui/router/actions'
import { UseForm } from '../../components/Views/Form/Form.types'

export const useAuthenticationForm: UseForm<AuthFormConfig> = (viewProps) => {
  const dispatch = useAppDispatch()
  const authentication = useAuthentication()
  const router = useRouter()

  const initialFormKey = useMemo(() => {
    // Check if the user is trying to reset their password //
    if (authentication.forgotPassword) return formConfig.requestPassword.key

    // Check if the user is trying to verify their email //
    if (router.urlSearchParams?.['verify']) return formConfig.createPassword.key

    // Check if the user is trying to reset their password //
    if (router.urlSearchParams?.['forgot_password'])
      return formConfig.confirmNewPassword.key

    // Default to the signin form //
    return formConfig.signin.key
  }, [])

  // Reset the forgot password state when the component unmounts //
  useEffect(() => {
    return () => {
      dispatch(setForgotPassword(false))
      dispatch(setUrlSearchParams({}))
    }
  }, [])

  const onSwitchForm = (formKey: keyof AuthFormConfig) => {
    dispatch(authenticationError(''))
  }

  return { initialFormKey, onSwitchForm }
}
