import * as React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { ScrollArea, ScrollBar } from '../../ui/scroll-area'
import { Button } from '../../ui/button'
import { cn } from '../../../lib/utils'
import { TabProps } from '.'

interface ScrollableTabsProps {
  className?: string
  tabs: TabProps[]
}

export function ScrollableTabs({ className, tabs }: ScrollableTabsProps) {
  const [selectedType, setSelectedType] = React.useState('all')
  const scrollContainerRef = React.useRef<HTMLDivElement>(null)

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200
      const currentScroll = scrollContainerRef.current.scrollLeft
      scrollContainerRef.current.scrollTo({
        left:
          direction === 'left'
            ? currentScroll - scrollAmount
            : currentScroll + scrollAmount,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className='relative'>
      <Button
        variant='outline'
        size='icon'
        className='absolute left-0 top-1/2 z-10 h-8 w-8 -translate-y-1/2 rounded-full'
        onClick={() => scroll('left')}
      >
        <ChevronLeft className='h-4 w-4' />
        <span className='sr-only'>Scroll left</span>
      </Button>
      <ScrollArea
        viewportRef={scrollContainerRef}
        className='w-full whitespace-nowrap rounded-md px-8'
      >
        <div className='flex w-max space-x-4 p-4'>
          {tabs.map((tab) => {
            const Icon = tab.icon
            return (
              <button
                key={tab.value}
                onClick={() => setSelectedType(tab.value)}
                className={cn(
                  'text-foreground/60 inline-flex items-center justify-center space-x-2 px-2 py-2 text-lg font-medium transition-colors hover:bg-muted',
                  selectedType === tab.value &&
                    'text-foreground hover:bg-muted border-b-4 border-blue-500'
                )}
              >
                <Icon className='h-5 w-5' />
                <span>{tab.label}</span>
              </button>
            )
          })}
        </div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <Button
        variant='outline'
        size='icon'
        className='absolute right-0 top-1/2 z-10 h-8 w-8 -translate-y-1/2 rounded-full'
        onClick={() => scroll('right')}
      >
        <ChevronRight className='h-4 w-4' />
        <span className='sr-only'>Scroll right</span>
      </Button>
    </div>
  )
}
