import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { formatTeam, formatTeams } from './functions'
import { Option } from './types'
import { usePlayers } from '../players/hooks'
import { useOrganisations } from '../organisations/hooks'

export const useTeams = () => {
  const teams = useAppSelector((state) => state.teams)
  return teams
}

export const useFormattedTeam = (teamId) => {
  const teams = useTeams()
  const players = usePlayers()
  const formattedTeam = useMemo(() => {
    console.log(
      'formattedTeam===========================',
      teams.rawData,
      teamId,
      teams.rawData[teamId]
    )
    return formatTeam(teams.rawData[teamId], Object.values(players.rawData))
  }, [players.rawData, teamId])
  return formattedTeam
}

export const useSelectedFormattedTeam = () => {
  const teams = useTeams()
  const formattedTeam = useFormattedTeam(teams.selectedTeam)
  return formattedTeam
}

export const useTeamOptions = (
  teamAValue?: string,
  teamBValue?: string,
  isTrainingMode?: boolean
) => {
  const teams = useTeams()

  const sortedTeams = teams.options.sort((a: Option, b: Option) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  const [teamOptions, setTeamOptions] = useState(sortedTeams)

  useEffect(() => {
    setTeamOptions(teams.options)
  }, [teams.options])

  const teamAOptions = useMemo(() => {
    return teamOptions.filter((teamOption) => {
      // only filter out teamBValue when session is not training
      if (!isTrainingMode) {
        return teamOption.value !== teamBValue
      } else {
        return teamOption
      }
    })
  }, [teamOptions, teamBValue, isTrainingMode])

  const teamBOptions = useMemo(() => {
    return teamOptions.filter((teamOption) => {
      return teamOption.value !== teamAValue
    })
  }, [teamOptions, teamAValue])

  return { teamOptions, teamAOptions, teamBOptions }
}

// Formatted Teams //
export const useUserFormattedTeams = () => {
  const teams = useTeams()
  const players = usePlayers()

  const formattedTeams = useMemo(() => {
    return formatTeams(
      Object.values(teams.rawData),
      Object.values(players.rawData)
    )
  }, [teams.rawData, players.rawData])

  return formattedTeams
}

export const useOrganisationFormattedTeams = () => {
  const organisations = useOrganisations()
  const players = usePlayers()

  const formattedTeams = useMemo(() => {
    return formatTeams(
      organisations.checkedOrganisation.teams,
      Object.values(players.rawData)
    )
  }, [organisations.checkedOrganisation, players.rawData])

  return formattedTeams
}

export const useOfficiationTeam = () => {
  const teams = useTeams()
  return useMemo(() => {
    return Object.values(teams.rawData).find(
      (team) => team?.type === 'match_officials'
    )
  }, [teams.rawData])
}
