import { handleName } from '../data_types'
import { outcomeTypes } from '../outcomes/data_types'
import { EMPTY_FORMATTED_PLAYER } from '../players/types'
import { EMPTY_FORMATTED_TEAM } from '../teams/types'
import {
  FormattedMetricTypeValues,
  metricTypesConfig,
  ProcessedMetricTypeValues,
  RawMetricTypeValues
} from './data_types'

const distance = 20
const hangTime = 5

// export const RAW_MOCK_EVENT_METRICS: {
//   [key in keyof typeof metricTypesConfig]: RawMetricTypeValues[key]
// } = {
export const RAW_MOCK_EVENT_METRICS: any = {
  team: null,
  player: null,

  hangTime: hangTime,
  detailCardHangTime: hangTime,

  dist: distance,
  detailCardDistance: distance,
  inPitchDistance: 10,

  efficiency: 0.9,
  endOverEndEfficiency: 0.1,
  rotation: 90,

  speed: 10,
  maxHeight: 10,
  spin: 5,
  success: false,
  reloadTime: 2,
  groundReloadTime: 3,
  territorialGain: 10,
  lineoutDeviation: 3,
  passDirection: 'Middle',
  forward: true,
  carryXVelocity: 10,
  passXVelocity: 10,
  probabilityForward: 50,
  inPitchHangTime: 3,
  distanceToCorner: 10,
  lineoutDeviationAngle: 5,
  timeToTarget: 5,
  targetPlayerId: null,
  snapToPass: 3,
  confirmed: false,
  outcome: 0,
  madeTouch: true,
  relativeVelX: 10,
  lineoutDeviated: 0,
  distanceToOppositionLine: 10,
  positionX: 10,
  positionY: 10,
  throwSpeed: 10,
  bouncedToTouch: true,
  toPlayerId: null,
  deviationFromCenterOfPoles: 10,
  bounceTouchTime: 2,

  totalPasses: 10,
  totalBackwardPasses: 2,
  totalForwardPasses: 8,
  totalSidewaysPasses: 1,
  totalGoalsScored: 1,
  totalPassCompletion: 80,
  totalShotsAtGoal: 1,
  averageThrowDistance: 10,
  averageRecycleTime: 2,
  averageSpeed: 10,

  averageEfficiency: 90,
  averageSpin: 5
}

// export const PROCESSED_MOCK_EVENT_METRICS: {
//   [key in keyof typeof metricTypesConfig]: ProcessedMetricTypeValues[key]
// } = {
export const PROCESSED_MOCK_EVENT_METRICS: any = {
  team: EMPTY_FORMATTED_TEAM,
  player: EMPTY_FORMATTED_PLAYER,

  hangTime: hangTime,
  detailCardHangTime: hangTime,

  dist: distance,
  detailCardDistance: distance,
  inPitchDistance: 10,

  efficiency: 90,
  endOverEndEfficiency: 10,
  rotation: 90,

  speed: 10,
  maxHeight: 10,
  spin: 5,
  success: {
    name: 'Failure',
    value: false
  },
  reloadTime: 2,
  groundReloadTime: 3,
  territorialGain: 10,
  lineoutDeviation: 3,
  passDirection: {
    name: 'Middle',
    value: 'Middle'
  },
  forward: {
    name: 'Yes',
    value: true
  },
  carryXVelocity: 10,
  passXVelocity: 10,
  probabilityForward: 50,
  inPitchHangTime: 3,
  distanceToCorner: 10,
  lineoutDeviationAngle: 5,
  timeToTarget: 5,
  targetPlayerId: null,
  snapToPass: 3,
  confirmed: {
    name: 'No',
    value: false
  },
  outcome: outcomeTypes.items.none,
  madeTouch: {
    name: 'Yes',
    value: true
  },
  relativeVelX: 10,
  lineoutDeviated: {
    name: 'Straight',
    value: 0
  },
  distanceToOppositionLine: 10,
  positionX: 10,
  positionY: 10,
  throwSpeed: 10,
  bouncedToTouch: {
    name: 'Yes',
    value: true
  },
  toPlayerId: null,
  deviationFromCenterOfPoles: 10,
  bounceTouchTime: 2,

  totalPasses: 10,
  totalBackwardPasses: 2,
  totalForwardPasses: 8,
  totalSidewaysPasses: 1,
  totalGoalsScored: 1,
  totalPassCompletion: 80,
  totalShotsAtGoal: 1,
  averageThrowDistance: 10,
  averageRecycleTime: 2,
  averageSpeed: 10,
  averageEfficiency: 90,
  averageSpin: 5
}

// export const FORMATTED_MOCK_EVENT_METRICS: {
//   [key in keyof typeof metricTypesConfig]: FormattedMetricTypeValues[key]
// } = {
export const FORMATTED_MOCK_EVENT_METRICS: any = {
  team: EMPTY_FORMATTED_TEAM.name,
  player: EMPTY_FORMATTED_PLAYER.fullName,

  hangTime: hangTime,
  detailCardHangTime: hangTime,

  dist: distance,
  detailCardDistance: distance,
  inPitchDistance: 10,

  efficiency: 90,
  endOverEndEfficiency: 10,
  rotation: 90,

  speed: 10,
  maxHeight: 10,
  spin: 5,
  success: 'Failure',
  reloadTime: 2,
  groundReloadTime: 3,
  territorialGain: 10,
  lineoutDeviation: 3,
  passDirection: 'Middle',
  forward: 'Yes',
  carryXVelocity: 10,
  passXVelocity: 10,
  probabilityForward: 50,
  inPitchHangTime: 3,
  distanceToCorner: 10,
  lineoutDeviationAngle: 5,
  timeToTarget: 5,
  targetPlayerId: null,
  snapToPass: 3,
  confirmed: 'No',
  outcome: handleName(outcomeTypes.items.none.name),
  madeTouch: 'Yes',
  relativeVelX: 10,
  lineoutDeviated: 'Straight',
  distanceToOppositionLine: 10,
  positionX: 10,
  positionY: 10,
  throwSpeed: 10,
  bouncedToTouch: 'Yes',
  toPlayerId: null,
  deviationFromCenterOfPoles: 10,
  bounceTouchTime: 2,

  totalPasses: 10,
  totalBackwardPasses: 2,
  totalForwardPasses: 8,
  totalSidewaysPasses: 1,
  totalGoalsScored: 1,
  totalPassCompletion: 80,
  totalShotsAtGoal: 1,
  averageThrowDistance: 10,
  averageRecycleTime: 2,
  averageSpeed: 10,
  averageEfficiency: 90,
  averageSpin: 5
}

export const getMockEventMetrics = (
  toPlayerId?: string,
  targetPlayerId?: string
) => {
  const metrics = { ...RAW_MOCK_EVENT_METRICS }

  if (toPlayerId) {
    metrics.toPlayerId = toPlayerId
  }

  if (targetPlayerId) {
    metrics.targetPlayerId = targetPlayerId
  }

  return metrics
}
