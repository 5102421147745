import { containsNumber } from '../../utils/helpers'
import {
  RawPlayerData,
  FormattedPlayer,
  ValidatePlayerValues,
  EMPTY_FORMATTED_PLAYER
} from './types'

// Format player data for display
export function formatPlayer(player: RawPlayerData): FormattedPlayer {
  if (!player) {
    return EMPTY_FORMATTED_PLAYER
  }

  return {
    ...player,
    rawData: player,
    fullName: `${player.firstName} ${player.lastName}`,
    initials: `${player.firstName[0]}.${player.lastName[0]}`,
    abbreviatedName: `${player.firstName[0]}. ${player.lastName}`,
    nameAndNumber: `${player.firstName} ${player.lastName} - ${player.number}`
  }
}

export const validatePlayer = (values: ValidatePlayerValues) => {
  const errors = {
    firstName: undefined,
    lastName: undefined
  }
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (containsNumber(values.firstName)) {
    errors.firstName = 'Contains a number'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (containsNumber(values.lastName)) {
    errors.lastName = 'Contains a number'
  }

  return errors
}
