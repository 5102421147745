import React, { useMemo } from 'react'
import { CardList } from '../../../components/CardList/CardList'
import {
  PlayerPassTable,
  PlayerPassData
} from '../../../metrics_server/players/components/PlayerPassCard/PlayerPassCard'
import { Table } from '../../../components/Table/Table'
import multiplePlayers from '../../../assets/img/multiple-players.svg'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import {
  emptyRawMetricTypeValues,
  generateMetricTypeClass
} from '../../../metrics_server/metrics/data_types'
import { aggregateAndFormatMetricsForFlights } from '../../../metrics_server/metrics/functions'
import styles from './PassSummary.module.scss'
import { useEventsFilter } from '../../../metrics_server/events/filter'

export const PassSummary = (props) => {
  // Session //
  const formattedSession = useSelectedFormattedSession()
  const { players, flightTypes, sport, isTrainingMode } = formattedSession
  const passMetrics = flightTypes.items.pass.props.metricTypes
  // ===== //

  const unitSystem = useUnitsSystem(sport)

  const passEventsFilters = useEventsFilter({}, ['pass'])

  // Filtering ignored - with the new event refactor you should be able to pass this to events filter as an initial value //
  const validPassEvents = useMemo(() => {
    return passEventsFilters.filteredEvents.filter(
      (event) => !event.rawValues.ignore
    )
  }, [passEventsFilters.filteredEvents])

  const tableHeaders = useMemo(() => {
    const headers = players.All.list
      .filter((player) => {
        return validPassEvents.some(
          (pass) => pass.rawValues.toPlayerId === player.id
        )
      })
      .map((player) => {
        let headerName = `To - ${player.initials}`

        const distanceMetricType = generateMetricTypeClass(
          isTrainingMode
            ? passMetrics.items.dist
            : passMetrics.items.territorialGain
        )

        const relevantModeMetric = isTrainingMode
          ? passMetrics.items.dist
          : passMetrics.items.territorialGain

        if (relevantModeMetric) {
          headerName = `${headerName} | Total ${distanceMetricType.name}`
        }

        return {
          name: headerName,
          key: player.id
        }
      })
    return [{ name: '', key: 'name', width: 20 }, ...headers]
  }, [formattedSession, validPassEvents])

  const tableData = useMemo(() => {
    const playerStats = players.All.list.map((player, i) => {
      const otherPlayers = {}
      players.All.list.forEach((otherPlayer) => {
        otherPlayers[otherPlayer.id] = {
          count: 0,
          [isTrainingMode ? 'dist' : 'territorialGain']: 0
        }
      })
      return {
        name: `FROM - ${player.abbreviatedName}`,
        id: player.id,
        stats: otherPlayers
      }
    })

    validPassEvents.forEach((pass) => {
      const fromPlayerStat = playerStats.find(
        (playerStat) => playerStat.id === pass.rawValues.player
      )

      if (fromPlayerStat && players.All.map[pass.rawValues.toPlayerId]) {
        // If training mode, territorial gain is always positive
        const metricKey = isTrainingMode ? 'dist' : 'territorialGain'
        let metricValue = pass[metricKey]
        if (isTrainingMode) {
          metricValue = Math.abs(metricValue)
        }
        fromPlayerStat.stats[pass.rawValues.toPlayerId].count++
        if (metricValue !== undefined && !isNaN(metricValue)) {
          fromPlayerStat.stats[pass.rawValues.toPlayerId][metricKey] =
            (fromPlayerStat.stats[pass.rawValues.toPlayerId][metricKey] || 0) +
            metricValue
        }
      }
    })

    // Format data for table //
    const data = playerStats
      .filter((player) => {
        return Object.keys(player.stats).some((playerId) => {
          return player.stats[playerId].count > 0
        })
      })
      .map((playerStat) => {
        const row = {
          name: playerStat.name
        }

        for (const playerId in playerStat.stats) {
          const stat = playerStat.stats[playerId]

          const metricType = generateMetricTypeClass(
            passMetrics.items.territorialGain
          )

          const metricTypeValue = metricType.getMetricValueWithUnits(
            stat[isTrainingMode ? 'dist' : 'territorialGain'],
            unitSystem,
            formattedSession.teams,
            formattedSession.players.All,
            emptyRawMetricTypeValues,
            null
          )

          row[playerId] = `${stat.count} | ${metricTypeValue}`
        }

        return row
      })

    return data
  }, [validPassEvents, unitSystem, formattedSession])

  const playerPassData = useMemo(() => {
    return formattedSession.players.All.list
      .filter((player) => {
        return validPassEvents.some(
          (pass) => pass.rawValues.player === player.id
        )
      })
      .map((player) => {
        // Filter passes by player
        const filteredPasses = validPassEvents.filter((pass) => {
          return pass.rawValues.player === player.id
        })

        // Set initial data
        const data: PlayerPassData = {
          id: player.id,
          player: player,
          count: filteredPasses.length,
          metrics: {}
        }

        // Calculate max, min and average for each metric
        data.metrics = aggregateAndFormatMetricsForFlights(
          flightTypes.items.pass.props.metricTypes,
          filteredPasses,
          unitSystem,
          formattedSession
        )
        return data
      })
  }, [validPassEvents, unitSystem.name])

  return (
    <>
      <div className={styles.tableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <>
            <div className={styles.passComboHeader}>
              <h5>Pass Combinations</h5>
            </div>
            {players.All.count < 2 ? (
              <div className={styles.notEnoughPlayers}>
                <img src={multiplePlayers} alt='' />
                <h5>
                  Multiple players required in session for pass combinations
                </h5>
              </div>
            ) : (
              <div className={styles.passComboTable}>
                <div className='card-table-container'>
                  <Table
                    // Row props
                    // Table props
                    options={{
                      initialOrder: 'asc',
                      initialSortBy: 'name',
                      sortActive: true
                    }}
                    headerFont={13}
                    tableClass={'minimalistBlack'}
                    className={'container'}
                    data={tableData}
                    headers={tableHeaders}
                    id={'pass-summary-table'}
                  />
                </div>
              </div>
            )}
          </>
        </CardList>
      </div>
      <div className={styles.statsContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <>
            <div className={styles.playerPassSummaryHeader}>
              <h5>Player Pass Summary</h5>
            </div>
            <div className={styles.playerPassSummaryTable}>
              <PlayerPassTable
                items={playerPassData}
                passMetricTypes={flightTypes.items.pass.props.metricTypes}
              />
            </div>
          </>
        </CardList>
      </div>
    </>
  )
}
