import { sportscasterRouteOptions } from './data'
import { GenericTableRow } from '../../components/Table/Table.types'
import { BrokerConfig } from '../mqtt/types'
import { RawFlightEventData } from '../events/flight/types'

export interface SportscasterState {
  selectedSportscasterHost: string
  selectedBrokerHost: string
  connectionsEstablished: string[]
  locationNames: string[]
  locationName: string
  brokers: []
  status: SportscasterStatus[]
  config: BrokerConfig[]
  health: {
    [host: string]: {
      http: {
        error: string
      }
      mqtt: boolean
      version: string
      healthy: boolean
    }
  }
  data: {
    payload: any
  }
  events: RawFlightEventData[]
  selectedEventId: string
  sessionId: string
  routeOptions: typeof sportscasterRouteOptions
  fetched: boolean
}

export interface SportscasterStatus {
  host: string
  cloud: boolean
  status: boolean
  locationName: string
  name: string
}

export interface SportscasterConfig {
  WSSHost: string
  Username: string
  Password: string
  SportscasterHost: string
  Host: string
}

export type SportscasterTableRow = GenericTableRow<SportscasterStatus> & {
  id: string
  statusColor: string
  cloudColor: string
  selected: boolean
}

export type SelectedSportscaster = SportscasterStatus & SportscasterConfig

export interface SportscasterHealth {
  http: {
    error: string
  }
  mqtt: boolean
  version: string
  healthy: boolean
}

export namespace SportscasterActionTypes {
  export const GET_SPORTSCASTER_CONFIG = 'GET_SPORTSCASTER_CONFIG'
  export const SET_SPORTSCASTER_HEALTH = 'SET_SPORTSCASTER_HEALTH'
  export const GET_SPORTSCASTER_FLIGHTS = 'GET_SPORTSCASTER_FLIGHTS'
  export const CLEAR_SPORTSCASTER = 'CLEAR_SPORTSCASTER'
  export const ADD_SPORTSCASTER_FLIGHT = 'ADD_SPORTSCASTER_FLIGHT'
  export const SET_SPORTSCASTER_EVENT = 'SET_SPORTSCASTER_EVENT'
  export const SET_SPORTSCASTER_STATUS = 'SET_SPORTSCASTER_STATUS'
  export const SET_SELECTED_SPORTSCASTER = 'SET_SELECTED_SPORTSCASTER'
  export const SET_SELECTED_BROKER = 'SET_SELECTED_BROKER'
  export const SET_CONNECTION_ESTABLISHED = 'SET_CONNECTION_ESTABLISHED'
  export const SET_LOCATION_NAME = 'SET_LOCATION_NAME'
  export const SET_SPORTSCASTER_LOCATION_NAMES =
    'SET_SPORTSCASTER_LOCATION_NAMES'
}
