import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  GroupSelectorGrid,
  LabelledInput
} from '@frontend/ui'
import { useEffect, useMemo, useState } from 'react'
import {
  sessionTypes,
  sportTypes,
  useAppDispatch,
  useFormattedSession,
  useFormattedTeam,
  useLanguage,
  useSessionFilter,
  useTeams,
  useUnitsSystem
} from '@frontend/sportable'
import { PlayerComparisonTable } from './player-comparison-table'

const textData = {
  showStatsBy: {
    en: 'Show stats by',
    fr: 'Afficher les statistiques par'
  },
  lastSession: {
    en: 'Last session',
    fr: 'Dernière session'
  },
  training: {
    en: 'Training',
    fr: 'Entraînement'
  },
  match: {
    en: 'Match',
    fr: 'Match'
  },
  sessionName: {
    en: 'Session name',
    fr: 'Nom de la session'
  },
  timeframe: {
    en: 'Timeframe',
    fr: 'Plage horaire'
  },
  physicalMetricParameter: {
    en: 'Physical Metric Parameter',
    fr: 'Paramètre de métrique physique'
  },
  groupSelectorGrid: {
    en: 'Select up to 8 players',
    fr: "Sélectionnez jusqu'à 8 joueurs"
  },
  playerComparisonTable: {
    en: 'Player Comparison',
    fr: 'Comparaison des joueurs'
  }
}

export interface GroupSelectItem {
  id: string
  label: string
  labelTwo: string
  image: string
}

export function PlayerCompare({
  className,
  selectedPlayerId,
  ...props
}: React.HTMLAttributes<HTMLElement> & { selectedPlayerId: string }) {
  const dispatch = useAppDispatch()

  const language = useLanguage()

  const [showBy, setShowBy] = useState('lastSession')
  const [selectedSessionId, setSelectedSessionId] = useState(null)

  const formattedSession = useFormattedSession(selectedSessionId)

  console.log(formattedSession)

  useEffect(() => {
    if (formattedSession.id) {
      // dispatch(eventActions.getSessionEvents(formattedSession.id))
      // dispatch(subSessionActions.getSubSessions(formattedSession.id))
    }
  }, [formattedSession])

  const teamsState = useTeams()
  const formattedTeam = useFormattedTeam(teamsState.selectedTeam)

  const sport = formattedTeam?.sport || sportTypes.items.soccer

  const unitSystem = useUnitsSystem(sport)

  const {
    sortedSessions: allTeamSessions,
    updateFilterValue: updateAllTeamSessionsFilter
  } = useSessionFilter({
    team: {
      options: teamsState.options,
      value: teamsState.selectedTeam
    },
    sportType: {
      options: [],
      value: sport.value
    }
  })

  const allTeamSessionOptions = useMemo(() => {
    return allTeamSessions.map((session) => ({
      name: session.name,
      value: session.id
    }))
  }, [allTeamSessions])

  useEffect(() => {
    if (formattedTeam) {
      updateAllTeamSessionsFilter('team', teamsState.selectedTeam)
      updateAllTeamSessionsFilter('sportType', sport.value)
    }
  }, [formattedTeam])

  useEffect(() => {
    if (showBy === 'lastSession') {
      updateAllTeamSessionsFilter('type', null)
    } else if (showBy === 'training') {
      updateAllTeamSessionsFilter('type', sessionTypes.items.training.value)
    } else if (showBy === 'match') {
      updateAllTeamSessionsFilter('type', sessionTypes.items.match.value)
    }
  }, [showBy, updateAllTeamSessionsFilter])

  useEffect(() => {
    console.log(allTeamSessionOptions, '=================')
    if (
      !allTeamSessionOptions.some(
        (sessionOption) => sessionOption.value === selectedSessionId
      )
    ) {
      setSelectedSessionId(allTeamSessionOptions[0]?.value)
    }
  }, [allTeamSessionOptions])

  const [selectedPlayerIds, setSelectedPlayerIds] = useState<string[]>([])

  const availableItems = useMemo(() => {
    return formattedTeam.players.list.map((player) => ({
      id: player.id,
      label: player.number ? String(player.number) : '',
      labelTwo: player.fullName,
      image: new URL(`/src/assets/players/${player.img}.png`, import.meta.url)
        .href
    }))
  }, [formattedTeam])

  useEffect(() => {
    setSelectedPlayerIds([])
  }, [formattedTeam.id])

  return (
    <>
      <div className='relative w-[calc(100%+4rem)] -left-8 px-8 py-7 bg-muted'>
        <div className='flex'>
          {/* Stats By Select */}
          <LabelledInput
            label={textData.showStatsBy[language]}
            className='mr-6'
          >
            <Select value={showBy} onValueChange={(value) => setShowBy(value)}>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='lastSession'>
                  {textData.lastSession[language]}
                </SelectItem>
                <SelectItem value='training'>
                  {textData.training[language]}
                </SelectItem>
                <SelectItem value='match'>
                  {textData.match[language]}
                </SelectItem>
              </SelectContent>
            </Select>
          </LabelledInput>
          {/* Session Select */}
          {(showBy === 'training' || showBy === 'match') && (
            <LabelledInput
              label={textData.sessionName[language]}
              className='mr-6'
            >
              <Select
                value={selectedSessionId || '0'}
                onValueChange={(value) => setSelectedSessionId(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder='' />
                </SelectTrigger>
                <SelectContent>
                  {allTeamSessionOptions.map((session) => (
                    <SelectItem key={session.value} value={session.value}>
                      {session.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </LabelledInput>
          )}
          {/* Timeframe Select */}
          {/* <LabelledInput label={textData.timeframe[language]} className='mr-6'>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent></SelectContent>
            </Select>
          </LabelledInput> */}
          {/* Physical Metric Parameter Select */}
          {/* <LabelledInput
            label={textData.physicalMetricParameter[language]}
            className='mr-6'
          >
            <Select>
              <SelectTrigger>
                <SelectValue placeholder='' />
              </SelectTrigger>
              <SelectContent></SelectContent>
            </Select>
          </LabelledInput> */}
        </div>
      </div>
      <div className='flex-1 p-8 pt-16'>
        <GroupSelectorGrid
          selectedItemIds={selectedPlayerIds}
          availableItems={availableItems}
          maxSelectedItems={8}
          onChange={setSelectedPlayerIds}
        />
      </div>
      <div className='flex-1 space-y-4 p-8 pt-6'>
        <PlayerComparisonTable
          sessionId={formattedSession.id}
          comparisonPlayerIds={selectedPlayerIds}
          playerId={selectedPlayerId}
        />
      </div>
    </>
  )
}
