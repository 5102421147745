import { useMemo } from 'react'
import { useEvents } from '../hooks'
import { getLatestKick } from './functions'

export function useLatestKick() {
  const events = useEvents()
  return useMemo(() => {
    const eventsArray = Object.values(events.rawData)
    return getLatestKick(eventsArray)
  }, [events.rawData])
}
