import React, { useMemo } from 'react'
import { Card, CardContent } from '../../ui/card'

type MetricCardProps = {
  displayValue: string
  label: string
  value?: number
  maxValue?: number
  markerValue?: number
}

export function MetricCard({
  displayValue,
  label,
  value,
  maxValue,
  markerValue
}: MetricCardProps) {
  const barProps = useMemo(() => {
    let markerPercent = null,
      barColor = null,
      valuePercent = null

    if (!value || !maxValue)
      return {
        valuePercent,
        markerPercent,
        barColor
      }

    valuePercent = (value / maxValue) * 100

    if (markerValue) {
      markerPercent = (markerValue / maxValue) * 100

      // Determine color based on value compared to average
      barColor = 'bg-yellow-500'

      if (value >= markerValue) {
        barColor = 'bg-green-500'
      }
      const percentBelowAverage = ((markerValue - value) / markerValue) * 100
      if (percentBelowAverage > 50) {
        barColor = 'bg-red-500'
      }
    }

    return {
      valuePercent,
      markerPercent,
      barColor
    }
  }, [value, maxValue, markerValue])

  return (
    <Card className='relative'>
      <CardContent className='p-6 space-y-4'>
        <div className='space-y-3'>
          <p className='text-lg'>{label}</p>
          <p className='text-2xl'>{displayValue}</p>
        </div>
      </CardContent>
      {barProps.valuePercent && (
        <div className='absolute bottom-0 w-full h-2 bg-zinc-800 bg-background'>
          <div
            className={`absolute left-0 top-0 h-full ${barProps.barColor}`}
            style={{ width: `${barProps.valuePercent}%` }}
          />
          {barProps.markerPercent && (
            <div
              className='absolute top-0 w-0.5 h-full bg-red-500'
              style={{ left: `${barProps.markerPercent}%` }}
            />
          )}
        </div>
      )}
    </Card>
  )
}
