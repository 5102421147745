import React from 'react'
import styles from './Scoreboard.module.scss'
import {
  useFormattedSession,
  useScoreboard
} from '../../metrics_server/sessions/hooks'
import { TeamLogo } from '../TeamLogo/TeamLogo'

export interface ScoreboardProps {
  sessionId: string
}

export const Scoreboard = ({ sessionId }: ScoreboardProps) => {
  const { homeTeam, awayTeam } = useFormattedSession(sessionId)

  const { scoreboard } = useScoreboard(sessionId)

  if (!homeTeam || !awayTeam) {
    return null
  }

  const teamAPoints = scoreboard.firstTeam.scoreSum
  const teamBPoints = scoreboard.secondTeam.scoreSum

  return (
    <div>
      <div className={styles.scoreboardContainer}>
        <div className={styles.scoreboardTeam}>
          <TeamLogo logo={homeTeam.logo} color={homeTeam.color} size={20} />
        </div>
        <div className={styles.scoreboardScore}>
          {' '}
          {teamAPoints} - {teamBPoints}
        </div>
        <div className={styles.scoreboardTeam}>
          <TeamLogo logo={awayTeam.logo} color={awayTeam.color} size={20} />
        </div>
      </div>
    </div>
  )
}
