import { parentEventTypes } from './data_types'
import {
  EMPTY_PROCESSED_EVENT,
  FormattedEventData,
  ProcessedEventValues,
  ProcessedRawEventData,
  RawEventData,
  RawEventValues
} from './types'
import { RawGameEventData } from './game/types'
import { RawFlightEventData } from './flight/types'
import { RawTimeEventData } from './time/types'
import { RawAussieRulesEventData } from './aussie_rules/types'
import { FormattedSession } from '../sessions/types'
import { UnitSystem, UnitSystemTypeValues } from '../units/types'
import { TableRow } from '../../components/Table/Table.types'
import { outcomeTypes } from '../outcomes/data_types'
import {
  emptyFormattedMetricTypeValues,
  emptyProcessedMetricTypeValues,
  emptyRawMetricTypeValues,
  generateMetricTypeClass,
  MetricTypeGroup
} from '../metrics/data_types'
import { formatMetrics } from '../metrics/functions'
import { eventTypes } from './types/data_types'

export const isEventTypeData = {
  game: (eventData): eventData is RawGameEventData => {
    if (
      eventData.event &&
      eventData.event.type === parentEventTypes.items.game.value
    ) {
      return true
    }
    return false
  },
  flight: (eventData): eventData is RawFlightEventData => {
    if (
      eventData.event &&
      eventData.event.type === parentEventTypes.items.flight.value
    ) {
      return true
    }
    return false
  },
  time: (eventData): eventData is RawTimeEventData => {
    if (
      eventData.event &&
      eventData.event.type === parentEventTypes.items.time.value
    ) {
      return true
    }
    return false
  },
  aussieRules: (eventData): eventData is RawAussieRulesEventData => {
    if (
      eventData.event &&
      eventData.event.type === parentEventTypes.items.aussieRules.value
    ) {
      return true
    }
    return false
  }
}

export const generateEventTableData = (
  formattedEventData: FormattedEventData[],
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
) => {
  const tableData = formattedEventData.map((formattedEvent) => {
    const row = generateEventRowData(
      formattedEvent,
      formattedSession,
      unitSystem
    )
    return row
  })
  return tableData
}

export const generateEventRowData = (
  formattedEventData: FormattedEventData,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
) => {
  const { isMatchMode } = formattedSession
  const {
    metrics,
    player,
    team,
    type,
    subType,
    outcome,
    startTime,
    endTime,
    operatorNotes,
    id,
    ignore
  } = formattedEventData
  const row = {} as TableRow
  row.id = id
  if (metrics) {
    for (const metric in metrics) {
      row[metric] = metrics[metric].formattedValue
    }
  }

  // Player Row
  if (player?.selected) {
    const { number, firstName, lastName } = player.selected
    if (isMatchMode) {
      row.fromPlayer = `${number ? number : ''}. ${firstName} ${lastName}`
    } else {
      row.fromPlayer = `${firstName.split('')[0]}. ${lastName}`
    }
  } else {
    row.fromPlayer = ``
  }

  // Team Row
  if (team?.selected) {
    const { color, logo } = team.selected
    row.teamLogo = { color, logo }
  } else {
    row.teamLogo = {}
  }

  // Type Row
  let typeName
  if (subType?.selected.name === 'Unclassified') {
    typeName = type.selected.name
  } else if (subType?.selected.name) {
    typeName = subType?.selected.name
  } else {
    typeName = type.selected.name
  }
  row.typeName = typeName

  // Outcome Row
  if (outcome) {
    row.outcome = outcome.selected?.name
  }

  // Operator Row
  if (operatorNotes) {
    row.highlight = operatorNotes.highlight
  }

  row.sessionStartTime = startTime * 1000 - formattedSession.startTime.unixMil
  row.startTime = startTime
  row.startTimeMil = startTime * 1000
  row.endTime = endTime

  row.ignore = ignore
  if (ignore) {
    row.__color = '#D3D3D3'
    row.__backgroundColor = undefined
  }

  return row
}

export const formatEventData = (
  event: ProcessedRawEventData,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
): FormattedEventData => {
  const { teams, players } = formattedSession
  // Metrics
  const metrics = formatMetrics(
    event.primaryType.props.metricTypes,
    event.rawValues,
    formattedSession.teams,
    formattedSession.players.All,
    unitSystem,
    formattedSession.isOfficiatingMode,
    formattedSession.isTrainingMode,
    formattedSession.teamsSessions
  )

  const team = event.processedValues.team

  const playerOptions = players[team ? team.id : 'All'].options

  return {
    id: event.id,
    rawData: event.rawData,
    ballSerial: event.rawValues.ballSerial,
    sessionId: event.sessionId,
    sessionStartTime: event.rawValues.sessionStartTime,
    startTime: event.rawValues.startTime,
    endTime: event.rawValues.endTime,
    ignore: event.rawValues.ignore,
    eventTypeValue: event.eventTypeValue,
    eventType: event.processedValues.parentType.value,
    typeName: event.primaryType.name,
    type: {
      selected: event.processedValues.type,
      options: event.processedValues.parentType.props.types?.options || []
    },
    subType: {
      selected: event.processedValues.subType,
      options: event.processedValues.type.props.types?.options || []
    },
    operator: event.operatorNotes,
    team: {
      selected: event.processedValues.team,
      options: teams?.optionsWithNull
    },
    player: {
      selected: event.processedValues.player,
      options: playerOptions
    },
    metrics: metrics,
    outcome: {
      selected: event.processedValues.outcome,
      options: event.primaryType.props.outcomeTypes.options
    },

    goalLineGraph:
      'goalLineGraph' in event.rawData
        ? event.rawData.goalLineGraph
        : {
            spinGraph: [],
            impactGraph: [],
            impactEvents: [],
            hitPost: false
          },

    features: event.primaryType.props.features,

    unitSystem,

    data: 'data' in event.rawData ? event.rawData.data : [],

    // TODO: Needs to be updated - shouldn't be null here //
    potentialEvent: null
  }
}

export function modifyFlightBasedOnNewFields(
  oldFlight: RawFlightEventData,
  newFlight: RawFlightEventData
): RawFlightEventData {
  // If the outcome has changed, update confirmed and toPlayerId
  if (oldFlight.outcome !== newFlight.outcome) {
    newFlight.confirmed = true
    if (
      !(oldFlight.outcome === outcomeTypes.items.interception.value) &&
      newFlight.outcome === outcomeTypes.items.interception.value
    ) {
      newFlight.toPlayerId = null
    } else if (
      oldFlight.outcome === outcomeTypes.items.interception.value &&
      !(newFlight.outcome === outcomeTypes.items.interception.value)
    ) {
      newFlight.toPlayerId = null
    } else if (newFlight.outcome === outcomeTypes.items.incomplete.value) {
      newFlight.toPlayerId = null
    }
  }
  return newFlight
}

export function processEventValues(
  metricTypes: MetricTypeGroup,
  parentType: ProcessedEventValues['parentType'],
  type: ProcessedEventValues['type'],
  subType: ProcessedEventValues['subType'],
  event: ProcessedRawEventData['rawData'],
  formattedSession: FormattedSession,
  ignore: boolean,
  highlighted: boolean,
  startTime: number,
  endTime: number,
  teamKey: string,
  playerKey: string,
  ballSerial: string
) {
  // Time //
  const sessionStartTime =
    (startTime - formattedSession.startTime.unixSeconds) * 1000

  // Metrics //
  const processedMetrics = emptyProcessedMetricTypeValues
  const rawMetrics = emptyRawMetricTypeValues
  const formattedMetrics = emptyFormattedMetricTypeValues

  for (const key in metricTypes.items) {
    let eventKey = key

    // Handle the fact that the API uses different versions of 'team' and 'player' keys for different event types //
    if (key === 'team') eventKey = teamKey
    if (key === 'player') eventKey = playerKey

    const value = event[eventKey]
    rawMetrics[key] = value
  }

  for (const key in metricTypes.items) {
    const metricType = metricTypes.items[key]
    const metricClass = generateMetricTypeClass(metricType)

    // Do custom raw values //
    if (metricType.props.type === 'number' && metricType.props.getValue) {
      rawMetrics[key] = metricType.props.getValue(rawMetrics)
    }

    const processedMetricValue = metricClass.processMetric(
      rawMetrics[key],
      rawMetrics,
      formattedSession.teams,
      formattedSession.players.All
    )

    const formattedMetricValue =
      metricClass.getFormattedValue(processedMetricValue)

    processedMetrics[key] = processedMetricValue
    formattedMetrics[key] = formattedMetricValue
  }

  const rawEventValues: RawEventValues = {
    ignore,
    highlighted,

    sessionId: formattedSession.id,
    startTime,
    endTime,
    sessionStartTime,

    parentType: parentType.value,
    parentTypeName: parentType.name,

    type: type.value,
    typeName: type.name,

    subType: subType?.value,
    subTypeName: subType?.name,

    ballSerial
  }

  const processedEventValues: ProcessedEventValues = {
    ignore: rawEventValues.ignore,
    highlighted: rawEventValues.highlighted,

    sessionId: rawEventValues.sessionId,
    startTime: rawEventValues.startTime,
    endTime: rawEventValues.endTime,
    sessionStartTime: rawEventValues.sessionStartTime,

    parentType,
    parentTypeName: parentType.name,

    type,
    typeName: type.name,

    subType,
    subTypeName: subType?.name,

    ballSerial: rawEventValues.ballSerial
  }

  return {
    rawEventValues,
    processedEventValues,
    rawMetrics,
    processedMetrics,
    formattedMetrics
  }
}

// TODO: this need fixing - this function detect the event type and adds the eventType to the event object at the root - this helps with typescript
// This also adds bounced to touch to the flight event - do we still need this?
export function processEvent(
  event: RawEventData,
  formattedSession: FormattedSession,
  unitSystemValue: UnitSystemTypeValues
): ProcessedRawEventData {
  const { parentEventTypes, flightTypes } = formattedSession

  // Check for flight event - hacky
  if (parentEventTypes.items.flight && 'dist' in event && 'hangTime' in event) {
    const parentType = parentEventTypes.items.flight

    const flightEvent = {
      ...event
    }

    // Types //
    const type = flightTypes.getTypeByValue(flightEvent.type)
    const subType = type.props.types.getTypeByValue(flightEvent.subType)

    let primaryType:
      | typeof parentEventTypes.items.flight
      | typeof type
      | typeof subType = parentEventTypes.items.flight

    if (subType && !subType.isUnknown()) {
      primaryType = subType
    } else if (type && !type.isUnknown()) {
      primaryType = type
    }

    // Values //
    const {
      rawEventValues,
      processedEventValues,
      rawMetrics,
      processedMetrics,
      formattedMetrics
    } = processEventValues(
      primaryType.props.metricTypes,
      parentType,
      type,
      subType,
      flightEvent,
      formattedSession,
      flightEvent.ignore,
      flightEvent.operatorNotes?.highlight,
      flightEvent.startTime,
      flightEvent.timeEnd,
      'teamID',
      'fromPlayerId',
      formattedSession.playersSessions?.byHardwareId?.map[flightEvent.ballId]
        ?.hardware?.serial
    )

    const processedEvent: ProcessedRawEventData = {
      id: flightEvent.id,
      sessionId: formattedSession.id,

      unitSystemValue,

      eventTypeValue: parentEventTypes.items.flight.value,

      primaryType,

      rawData: flightEvent,

      operatorNotes: flightEvent.operatorNotes || {
        id: null,
        notes: null,
        highlight: null,
        matchTime: null
      },

      rawValues: { ...rawEventValues, ...rawMetrics },

      processedValues: { ...processedEventValues, ...processedMetrics },

      formattedMetrics
    }

    return processedEvent
  }

  // Hacky way to tell if it's a time event - change to any to make the check work
  const timeEvent = event as RawTimeEventData
  if (
    (parentEventTypes.items.time &&
      'event' in timeEvent &&
      timeEvent.event.type === parentEventTypes.items.time.value) ||
    (!('event' in timeEvent) && !('dist' in timeEvent))
  ) {
    const { timeEventTypes } = formattedSession

    const parentType = parentEventTypes.items.time

    // Types //
    const type = timeEventTypes.getTypeByValue(timeEvent.type)
    const subType = type?.props.types?.getTypeByValue(null)

    let primaryType:
      | typeof parentEventTypes.items.time
      | typeof type
      | typeof subType = parentEventTypes.items.time

    if (subType && !subType.isUnknown()) {
      primaryType = subType
    } else if (type && !type.isUnknown()) {
      primaryType = type
    }

    // Values //
    const {
      rawEventValues,
      processedEventValues,
      rawMetrics,
      processedMetrics,
      formattedMetrics
    } = processEventValues(
      primaryType.props.metricTypes,
      parentType,
      type,
      subType,
      timeEvent,
      formattedSession,
      false,
      false,
      timeEvent.startTime,
      timeEvent.timeEnd,
      'teamID',
      'fromPlayerId',
      null
    )

    const processedEvent: ProcessedRawEventData = {
      id: timeEvent.id,
      sessionId: formattedSession.id,

      unitSystemValue,

      eventTypeValue: parentEventTypes.items.time.value,

      primaryType,

      rawData: timeEvent,

      operatorNotes: {
        id: null,
        notes: null,
        highlight: null,
        matchTime: null
      },

      rawValues: { ...rawEventValues, ...rawMetrics },

      processedValues: { ...processedEventValues, ...processedMetrics },

      formattedMetrics
    }

    return processedEvent
  }

  // Check for game event
  if (
    parentEventTypes.items.game &&
    'event' in event &&
    event.event.type === parentEventTypes.items.game.value
  ) {
    const { gameEventTypes } = formattedSession

    const gameEvent = event as RawGameEventData

    const parentType = parentEventTypes.items.game

    // Types //
    const type = gameEventTypes.getTypeByValue(gameEvent.type)
    const subType = type?.props.types?.getTypeByValue(gameEvent.subType)

    let primaryType:
      | typeof parentEventTypes.items.game
      | typeof type
      | typeof subType = parentEventTypes.items.game

    if (subType && !subType.isUnknown()) {
      primaryType = subType
    } else if (type && !type.isUnknown()) {
      primaryType = type
    }

    // Values //
    const {
      rawEventValues,
      processedEventValues,
      rawMetrics,
      processedMetrics,
      formattedMetrics
    } = processEventValues(
      primaryType.props.metricTypes,
      parentType,
      type,
      subType,
      gameEvent,
      formattedSession,
      gameEvent.ignore,
      gameEvent.operatorNotes?.highlight,
      gameEvent.event.startTime,
      gameEvent.event.timeEnd,
      'teamId',
      'playerId',
      null
    )

    const processedEvent: ProcessedRawEventData = {
      id: gameEvent.id,
      sessionId: formattedSession.id,

      unitSystemValue,

      eventTypeValue: parentEventTypes.items.game.value,

      primaryType,

      rawData: gameEvent,

      operatorNotes: gameEvent.operatorNotes || {
        id: null,
        notes: null,
        highlight: null,
        matchTime: null
      },

      rawValues: { ...rawEventValues, ...rawMetrics },

      processedValues: { ...processedEventValues, ...processedMetrics },

      formattedMetrics
    }

    return processedEvent
  }

  // Check for aussie rules event
  if (
    parentEventTypes.items.aussieRules &&
    'event' in event &&
    event.event.type === parentEventTypes.items.aussieRules.value
  ) {
    const { australianRulesEventTypes } = formattedSession

    const aussieRulesEvent = event as RawAussieRulesEventData

    const parentType = parentEventTypes.items.aussieRules

    // Types //
    const type = australianRulesEventTypes.getTypeByValue(aussieRulesEvent.type)
    const subType = type?.props.types?.getTypeByValue(
      aussieRulesEvent.crossSection
    )

    let primaryType:
      | typeof parentEventTypes.items.aussieRules
      | typeof type
      | typeof subType = parentEventTypes.items.aussieRules

    if (subType && !subType.isUnknown()) {
      primaryType = subType
    } else if (type && !type.isUnknown()) {
      primaryType = type
    }

    // Values //
    const {
      rawEventValues,
      processedEventValues,
      rawMetrics,
      processedMetrics,
      formattedMetrics
    } = processEventValues(
      primaryType.props.metricTypes,
      parentType,
      type,
      subType,
      aussieRulesEvent,
      formattedSession,
      aussieRulesEvent.ignore,
      aussieRulesEvent.operatorNotes?.highlight,
      aussieRulesEvent.event.startTime,
      aussieRulesEvent.event.timeEnd,
      'teamId',
      'playerId',
      null
    )

    const processedEvent: ProcessedRawEventData = {
      id: aussieRulesEvent.id,
      sessionId: formattedSession.id,

      unitSystemValue,

      eventTypeValue: parentEventTypes.items.aussieRules.value,

      primaryType,

      rawData: aussieRulesEvent,

      operatorNotes: aussieRulesEvent.operatorNotes || {
        id: null,
        notes: null,
        highlight: null,
        matchTime: null
      },

      rawValues: { ...rawEventValues, ...rawMetrics },

      processedValues: { ...processedEventValues, ...processedMetrics },

      formattedMetrics
    }
    return processedEvent
  }

  console.log(event)

  return EMPTY_PROCESSED_EVENT
}

export function processEvents(
  events: RawEventData[],
  formattedSession: FormattedSession,
  unitSystemValue: UnitSystemTypeValues
) {
  const processedEvents: {
    [key: string]: ProcessedRawEventData
  } = {}

  for (const key in events) {
    const event = events[key]
    if (event.id) {
      processedEvents[event.id] = processEvent(
        event,
        formattedSession,
        unitSystemValue
      )
    }
  }
  return processedEvents
}
// =========== //

export function modifyEventForUpdate(
  newData: Partial<RawEventData> & { id: string } & Partial<{
      teamId: string
      playerId: string
    }>,
  processedEvent: ProcessedRawEventData
): RawEventData {
  // Update flight event type //
  if (
    processedEvent.rawValues.parentType ===
      parentEventTypes.items.flight.value &&
    isEventTypeData.flight(processedEvent.rawData)
  ) {
    // Modify field data based on new fields //
    // TODO: modifications like this should be handled on the BE //
    let modifiedFlightEvent = { ...processedEvent.rawData, ...newData }

    if (newData.playerId) {
      modifiedFlightEvent.fromPlayerId = newData.playerId
    }

    // If flight event team has changed, update player and metrics accordingly //
    if (
      (newData.teamId && newData.teamId !== processedEvent.rawValues.team) ||
      (modifiedFlightEvent.teamID &&
        modifiedFlightEvent.teamID !== processedEvent.rawValues.team)
    ) {
      modifiedFlightEvent.teamID = newData.teamId || modifiedFlightEvent.teamID

      modifiedFlightEvent.fromPlayerId = null
      modifiedFlightEvent.toPlayerId = null
      modifiedFlightEvent.targetPlayerId = null

      // If pass event, update pass direction and velocity //
      if (processedEvent.rawValues.type === eventTypes.items.pass.value) {
        modifiedFlightEvent.forward = !processedEvent.rawData.forward
        modifiedFlightEvent.passDirection =
          processedEvent.rawData.passDirection === 'Right' ? 'Left' : 'Right'
        modifiedFlightEvent.passXVelocity =
          -processedEvent.rawData.passXVelocity
        modifiedFlightEvent.carryXVelocity =
          -processedEvent.rawData.carryXVelocity
        modifiedFlightEvent.probabilityForward =
          100 - processedEvent.rawData.probabilityForward
      }
    }

    // If flight event player has changed, update player and metrics accordingly //
    if (
      newData.playerId &&
      newData.playerId !== processedEvent.rawValues.player
    ) {
      modifiedFlightEvent.fromPlayerId =
        newData.playerId || modifiedFlightEvent.fromPlayerId
    }

    modifiedFlightEvent = modifyFlightBasedOnNewFields(
      processedEvent.rawData,
      modifiedFlightEvent
    )

    return modifiedFlightEvent
  }

  // Update game event type //
  if (
    processedEvent.rawValues.parentType === parentEventTypes.items.game.value &&
    isEventTypeData.game(processedEvent.rawData)
  ) {
    const modifiedGameEvent = {
      ...processedEvent.rawData,
      ...newData
    } as RawGameEventData

    // If team has changed, update player and metrics accordingly //
    if (newData.teamId && newData.teamId !== processedEvent.rawValues.team) {
      modifiedGameEvent.teamId = newData.teamId || modifiedGameEvent.teamId
      modifiedGameEvent.playerId = null
    }

    return modifiedGameEvent
  }

  const modifiedEvent = {
    ...processedEvent.rawData,
    ...newData
  } as RawEventData

  return modifiedEvent
}
