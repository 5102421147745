import { useMemo } from 'react'
import {
  CustomFilterState,
  FilterTypes,
  getFilterState,
  runCheck,
  useFilterReducer
} from '../../hooks/filter'
import { addItemToGroup, getEmptyGroup } from '../functions'
import { RawPlayerData } from './types'
import { useFormattedSession } from '../sessions/hooks'
import { usePlayers } from './hooks'
import { FormattedSession } from '../sessions/types'

export type PlayerFilterTypeKeys = 'name' | 'teamId' | 'assigned'

export type PlayerFilters = {
  name: FilterTypes<string, 'name', PlayerFilterTypeKeys>
  teamId: FilterTypes<string, 'teamId', PlayerFilterTypeKeys>
  assigned: FilterTypes<
    'assigned' | 'notAssigned',
    'assigned',
    PlayerFilterTypeKeys
  >
}

export const defaultPlayerFilterState: PlayerFilters = {
  name: {
    key: 'name',
    label: 'Name',
    type: 'searchStr' as const,
    value: '',
    options: []
  },
  teamId: {
    key: 'teamId',
    label: 'Team',
    type: 'single' as const,
    options: [],
    value: 'All'
  },
  assigned: {
    key: 'assigned',
    label: 'Assigned',
    type: 'singleWithAll' as const,
    options: [
      { name: 'Assigned', value: 'assigned' },
      { name: 'Not Assigned', value: 'notAssigned' }
    ],
    value: 'All'
  }
}

export function getFilterPlayerData(
  player: RawPlayerData,
  formattedSession?: FormattedSession
) {
  const playerSession =
    formattedSession?.playersSessions.byPlayerId.map[player.id] || null

  return {
    ...player,
    assigned: playerSession ? 'assigned' : 'notAssigned',
    name: player.firstName + ' ' + player.lastName
  }
}

export const usePlayerFilter = (
  customFilterState: CustomFilterState<PlayerFilters>,
  sessionId?: string
) => {
  const formattedSession = useFormattedSession(sessionId)

  const players = usePlayers()

  const filterState = useMemo(
    () => getFilterState(customFilterState, defaultPlayerFilterState),
    []
  )

  const { filters, updateFilterValue } = useFilterReducer(filterState)

  const filteredPlayers = useMemo(() => {
    // Filter by team //

    const filteredPlayers = getEmptyGroup<RawPlayerData>()

    Object.values(players.rawData)
      .filter((player) => {
        const data = getFilterPlayerData(player, formattedSession)

        return Object.keys(filters).every((filterKey) => {
          const filter = filters[filterKey as keyof typeof filters]
          const itemValue = data[filterKey]
          return runCheck(filter, itemValue)
        })
      })
      // Add item to group
      .forEach((player) => {
        addItemToGroup(
          filteredPlayers,
          player,
          player.id,
          'id',
          player.lastName
        )
      })

    return filteredPlayers
  }, [filters, formattedSession, players.rawData])

  return {
    filteredPlayers,
    filters,
    updateFilterValue
  }
}
