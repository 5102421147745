import React from 'react'
import { Button } from '../../../components/Button/Button'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { FormValidationErrors } from '../../../metrics_server/user/types'
import TextInput from '../../../components/Forms/Inputs/TextInput/TextInput'
import styles from '../../../components/Forms/Form/Form.module.scss'
import { JoinOrganisationAndTeamFormProps } from '../config'
import { checkOrganisation } from '../../../metrics_server/organisations/actions'
import { useAppDispatch } from '../../../store/hooks'
import { signoutUser } from '../../../metrics_server/user/actions'

const validate = (values) => {
  const errors: FormValidationErrors = {}
  if (!values.firstWord) {
    errors.firstWord = 'First word is required'
  }
  if (!values.secondWord) {
    errors.secondWord = 'Second word is required'
  }
  if (!values.thirdWord) {
    errors.thirdWord = 'Third word is required'
  }

  return errors
}

export const JoinOrganisationForm = ({
  switchForm,
  change
}: JoinOrganisationAndTeamFormProps) => {
  const dispatch = useAppDispatch()

  const handleSubmit = (values) => {
    const threeWords = [values.firstWord, values.secondWord, values.thirdWord]
    dispatch(checkOrganisation({ threeWords }, () => switchForm('joinTeam')))
  }

  const fields = [
    {
      name: 'firstWord',
      type: 'text',
      label: 'First word',
      component: TextInput
    },
    {
      name: 'secondWord',
      type: 'text',
      label: 'Second word',
      component: TextInput
    },
    {
      name: 'thirdWord',
      type: 'text',
      label: 'Third word',
      component: TextInput
    }
  ]

  return (
    <>
      <FormContainer
        name={'Join Organisation'}
        fields={fields}
        validate={validate}
        onSubmit={(values) => handleSubmit(values)}
        submitText={'Find'}
        title={'Join An Organisation'}
        message={'Enter the 3-word password to join an organisation'}
      />
      <div className={styles.buttonsContainer}>
        <Button
          handleClick={() => dispatch(signoutUser())}
          className='btn--plain'
          style={{ overflow: 'hidden' }}
        >
          Sign in with different account
        </Button>
      </div>
    </>
  )
}
