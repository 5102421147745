/* Contains all authentication routes */
import React from 'react'
import { UPDATE_USER, SET_USER, REMOVE_USER_DATA } from './action_types'
import {
  setIsLoading,
  setIsSignedIn,
  authenticationError,
  setForgotPassword
} from '../../ui/authentication/actions'
import { getToken } from '../../ui/authentication/functions'
import { setError } from '../../ui/error/actions'
import { setInfo } from '../../ui/info/actions'
import { setSuccess } from '../../ui/success/actions'
import {
  updateUserInLocalStorage,
  updateTokenInLocalStorage
} from '../../ui/authentication/functions'
import { API_ROOT_URL } from '../../const'
import axios from 'axios'
import { blacklistedWords } from '../../ui/authentication/data'

export const setUser = (userData, token) => async (dispatch) => {
  updateUserInLocalStorage(userData)
  updateTokenInLocalStorage(token)
  dispatch(setIsSignedIn(!!userData.id))
  dispatch({ type: SET_USER, payload: userData })
}

/*================== API Endpoint ====================*/

export const updateUser = (userId, userData) => async (dispatch) => {
  const url = `${API_ROOT_URL}users/${userId}`,
    body = userData
  try {
    const response = await axios.put(url, body, {
      withCredentials: true,
      headers: {
        Accept: '*/',
        Authorization: getToken()
      }
    })
    dispatch({
      type: UPDATE_USER,
      payload: response.data
    })
  } catch (e) {
    dispatch(setError({ message: 'Unable to update user' }))
  }

  // insert API call here and setUser
}

/*================== Authentication ==================*/

export type ResgisterRequestBody = {
  email: string
}

export const requestEmailVerification =
  (data: ResgisterRequestBody, cb?: () => void) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const url = `${API_ROOT_URL}register`
    try {
      await axios.post(url, data)
      dispatch({
        type: UPDATE_USER,
        payload: { verificationEmail: data.email }
      })
      dispatch(setIsLoading(false))
      cb()
    } catch (e) {
      console.log(e)
      if (e.response) {
        if (e.response.status === 400) {
          dispatch(authenticationError('User already exists'))
        }
      }
      dispatch(setIsLoading(false))
      console.log(e.status)
    }
  }

export type SignupRequestBody = {
  firstName: string
  lastName: string
  password: string
  password_confirmation: string
}

export const signupUser = (data: SignupRequestBody, cb) => async (dispatch) => {
  dispatch(setIsLoading(true))
  const url = `${API_ROOT_URL}verify_email`
  try {
    await axios.post(url, data)
    dispatch(
      setSuccess({
        message: 'User successfully created. Returning to login.',
        proceed: cb
      })
    )
    dispatch(setIsLoading(false))
  } catch (e) {
    console.log(e)
    dispatch(setError({ message: 'Unable to create user' }))
    dispatch(authenticationError('Signup error'))
    dispatch(setIsLoading(false))
  }
}

export type SigninRequestBody = {
  email: string
  password: string
}

export const signinUser =
  (data: SigninRequestBody, cb) => async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    const url = `${API_ROOT_URL}login`
    try {
      const headers = {
        Accept: '*/',
        Authorization: localStorage.getItem('authorization')
      }
      const response = await axios.post(url, data, {
        headers
      })
      dispatch(
        setUser(
          {
            ...response.data,
            email: data.email
          },
          response.headers['authorization']
        )
      )
      dispatch(setIsLoading(false))
      cb()
    } catch (e) {
      console.log(e)
      dispatch(setIsLoading(false))
      if (e.response) {
        if (e.response.status === 400) {
          const webAppDomain = getState().version.webAppDomain
          dispatch(
            setInfo({
              header: 'Almost there...',
              proceed: () => {
                window.open(`https://${webAppDomain}`)
              },
              message:
                "Looks like you don't belong to an organisation yet. Please login through our web app to get started."
            })
          )
        } else if (e.response.status) {
          dispatch(authenticationError('Invalid email or password'))
        } else {
          dispatch(setError({ message: 'Login failed' }))
        }
      } else {
        dispatch(setError({ message: 'Login failed' }))
      }
    }
  }

export const signoutUser = () => async (dispatch) => {
  console.log('signing out')
  const url = `${API_ROOT_URL}logout`
  try {
    const headers = {
      Accept: '*/',
      Authorization: localStorage.getItem('authorization')
    }
    await axios.delete(url, {
      headers
    })
    dispatch(setUser({}, null))
    dispatch(setIsSignedIn(false))
  } catch (e) {
    console.log(e)
    dispatch(authenticationError('Signout error'))
  }
}

export const removeUserData = () => {
  return {
    type: REMOVE_USER_DATA
  }
}

export type RemoveUserDataAction = ReturnType<typeof removeUserData>

export type ForgotPasswordRequestBody = {
  email: string
}

export const requestNewPassword =
  (data: ForgotPasswordRequestBody, cb) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const url = `${API_ROOT_URL}forgot_password`,
      body = { email: data.email }
    try {
      const response = await axios.post(url, body)
      dispatch(setIsLoading(false))
      cb(true)
      console.log(response)
    } catch (e) {
      console.log(e)
      dispatch(setIsLoading(false))
      cb(false)
      dispatch(authenticationError('Invalid email verification'))
      console.log(e.status)
    }
  }

export type NewPasswordRequestBody = {
  password: string
  password_confirmation: string
}

export const confirmNewPassword =
  (data: NewPasswordRequestBody, cb) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const url = `${API_ROOT_URL}reset_password`,
      body = data
    try {
      await axios.post(url, body)
      dispatch(setForgotPassword(false))
      dispatch(setIsLoading(false))
      cb()
    } catch (e) {
      dispatch(setIsLoading(false))
      dispatch(
        setError({
          message: `Unable to confirm password. Ensure the password differs from the current one and does not contain blacklisted words: ${blacklistedWords.join(
            ','
          )}}`
        })
      )
      console.log(e.status)
    }
  }
