import { Coordinate } from '../pitches/types'
import { FormattedPlayerSession } from '../sessions/types'
import { Group } from '../types'
import { DeviceTypeValues, DeviceType, DeviceTypeKeys } from './data_types'

export type HardwareProductNames =
  | 'Junco'
  | 'Swift'
  | 'Anamo'
  | 'Civet'
  | 'WilsonCFL'
  | 'Unknown'

export type HardwareStates = 'online' | 'offline' | 'sleep'
export type HardwareStatusTypeKeys = 'balls' | 'anchors' | 'tags'

export interface HardwareState {
  tagIdsIgnoredForSleepAndWakeAll: number[]
  selectedDevice: number
  formattedHardware: FormattedHardware
  diagnostics: HardwareDiagnosticsPacket
  collapsedHardwareIds: {
    [id: string]: boolean
  }

  // Seat swapping state //
  isSeatSwappingAlgoEnabled: boolean | null

  // A list of devices that have a service action (promote / demote / standby) pending //
  serviceActionPendingDeviceList: number[]

  // Configuration
  configuration: SeatConfiguration
}

export type HardwareDiagnosticsPacket = {
  availableSeats: number
  hardwareIndex: number[]
  encodedHardwareIndex?: string[]
  countMatrix: number[][]
  rssiMatrix: number[][]
  imuLenMatrix: number[][]
  distanceMatrix: number[][]
  status: {
    [key in HardwareStates]: {
      [key in HardwareStatusTypeKeys]: number[] | null
    }
  }
  hardwareState: Devices
  finalAnchorIndex: number
  masterSignalStrength: number[]
}

export type RawPackets = {
  [id: number]: RawPacket
}

export interface RawPacket {
  id: number
  serial: string
  isBallInPlay: boolean
  time: number
  packetArrivalTime: number
  position: {
    x: number
    y: number
    z: number
  }
  type: string
  ranges: { source: number; range: number; rssi: number }[]
  powerMode: 'low' | 'half' | 'full' | 'unknown'
}

export interface Devices {
  [id: string]: DeviceState
}

export interface DeviceState {
  accStdDeviation: number
  uwbFwVersion: string
  dataMetrics: {
    imu: {
      quality: string
      efficiency: string
    }
    network: {
      quality: string
      efficiency: string
    }
    ranging: {
      quality: string
      efficiency: string
    }
    location: {
      inPitch: boolean
      ballInPlay: boolean
      location: {
        x: number
        y: number
        z: number
      }
    } | null
  }
  greyListed: boolean
  id: number
  imuCalibration: {
    calibratedAt: number
    calibration: number | null
  }
  lastOnline: number
  lastOnlineUnseated: number
  masterRSSI: number
  offline: boolean
  unseated: boolean
  positionDataRate: number
  productName: HardwareProductNames
  radioCalibration: {
    txDelay: number
    rxDelay: number
    calibratedAt: number
  }
  reports: QAReports
  serial: string
  slowSensors: SlowSensor
  state: string
  seatState: string
  type: DeviceTypeValues
  appFwVersion: string
  inSession: boolean
}

export type QAReportItemType = {
  note?: string
  passed: boolean | string
  updatedAt?: string
  updatedBy?: string
}

export type QAReports = {
  fieldQA: QAReportItemType
  hwQA: QAReportItemType
  investigate?: QAReportItemType
  retired?: QAReportItemType
}

export interface SlowSensor {
  appUptime: number
  avgCurrent: number
  battery: number
  deviceId: number
  dwTemp: number
  mcuTemp: number
  state: {
    machine: string
    state: string
  }
  timestamp: number
  uwbRole: string
  uwbUptime: number
  voltage: number
}

export type FormattedHardware = {
  types: {
    [key in DeviceTypeKeys]: {
      type: DeviceType
      status: FormattedHardwareStatus
      devices: Group<FormattedDevice, number>
    }
  }
  status: FormattedHardwareStatus
  devices: Group<FormattedDevice, number>
}

export type FormattedHardwareStatusItem = {
  name: string
  devices: Group<FormattedDevice, number>
}

export type FormattedHardwareStatus = {
  [key in HardwareStates]: FormattedHardwareStatusItem
}

export type FormattedDeviceMetric<v> = {
  value: v
  colour: string
  display: string
}

export interface FormattedDevice {
  id: number
  serial: string
  productName: HardwareProductNames
  battery: FormattedDeviceMetric<number>
  chargingState: ChargingStates
  isInPlay: boolean
  inPitch: DeviceState['dataMetrics']['location']['inPitch']
  type: DeviceType
  status: FormattedDeviceMetric<'online' | 'sleep' | 'offline'>
  positionDataRate: FormattedDeviceMetric<number>
  accelerationStdDev: FormattedDeviceMetric<number>
  positionHealth: FormattedDeviceMetric<number>
  rssi: {
    [id: string]: FormattedDeviceMetric<number>
  }
  imuLength: FormattedDeviceMetric<number>
  anchorMeasurements: {
    [id: string]: FormattedDeviceMetric<number>
  }
  slowSensor: SlowSensor
  master: boolean
  greyListed: boolean
  position: Coordinate
  reports: QAReports
  masterSignalStrength: number
  lastOnline: number
  state: string
  seatState: string
  inSession: boolean
  distanceToMaster: number
  hasFluctuated: boolean
}

export interface FormattedSessionDevice {
  id: number
  playerSession: FormattedPlayerSession
  device: FormattedDevice
}

export interface FormattedSessionHardware {
  teams: {
    [id: string]: Group<FormattedSessionDevice, number>
  }
  playerTags: Group<FormattedSessionDevice, number>
  balls: Group<FormattedSessionDevice, number>
  All: Group<FormattedSessionDevice, number>
}
export interface Tag {
  x: number
  y: number
  z: number
  n: number
  t: string
  playerId: number | null
  serial: string
  ball: boolean
  isBallInPlay: boolean
  time: number
  id: number
  isLowPowerMode: boolean
  isOnBench?: boolean
}

export interface SeatConfiguration {
  total: number
  ball: number
  playerTag: number
  anchor: number
  lowPower: number
}

export type ChargingStates = 'notCharging' | 'trickleCharging' | 'charging'

// Action types
export const TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL =
  'TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL' as const
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE' as const
export const TOGGLE_COLLAPSED_HARDWARE = 'TOGGLE_COLLAPSED_HARDWARE' as const
export const TOGGLE_COLLAPSE_ALL_HARDWARE =
  'TOGGLE_COLLAPSE_ALL_HARDWARE' as const
export const SET_SEAT_SWAPPING_ALGO_ENABLED =
  'SET_SEAT_SWAPPING_ALGO_ENABLED' as const
export const ADD_SERVICE_ACTION_PENDING_FOR_DEVICES =
  'ADD_SERVICE_ACTION_PENDING_FOR_DEVICES' as const
export const REMOVE_SERVICE_ACTION_PENDING_FOR_DEVICES =
  'REMOVE_SERVICE_ACTION_PENDING_FOR_DEVICES' as const
export const SET_DIAGNOSTICS = 'SET_DIAGNOSTICS' as const
export const SET_FORMATTED_HARDWARE = 'SET_FORMATTED_HARDWARE' as const
export const SET_SEAT_CONFIGURATION = 'SET_SEAT_CONFIGURATION' as const
