import React, { useState } from 'react'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { useAppDispatch } from '../../../store/hooks'
import { useAuthentication } from '../../../ui/authentication/hooks'
import { useUser } from '../../../metrics_server/user/hooks'
import { requestEmailVerification } from '../../../metrics_server/user/actions'
import { AuthFormProps } from '../config'

export const VerifyEmailForm = (props: AuthFormProps) => {
  const dispatch = useAppDispatch()
  const authentication = useAuthentication()
  const user = useUser()

  const [fields, setFields] = useState(generateFields())

  function generateFields() {
    return []
  }

  const handleSubmit = () => {
    dispatch(requestEmailVerification({ email: user.data.verificationEmail }))
  }

  return (
    <FormContainer
      name={'Verify Email'}
      fields={fields}
      onSubmit={handleSubmit}
      submitText={'Resend'}
      loading={authentication.isLoading}
    >
      <div className='section'>
        <div className='section'>
          <div className='row'>
            <p>
              We've sent an email to {user.data.verificationEmail}, to complete
              the verification process please follow the link contained within
              the email.
            </p>
          </div>
        </div>
      </div>
    </FormContainer>
  )
}
