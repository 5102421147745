import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { teamActions } from '../../metrics_server/teams'
import * as unitSystems from '../../metrics_server/units/unit_systems'
import { MqttContext } from '../../metrics_server/mqtt/Provider'
import * as challengeActions from '../../metrics_server/targets/actions'
import { eventActions } from '../../metrics_server/events'
import * as sessionActions from '../../metrics_server/sessions/actions'
import * as subSessionActions from '../../metrics_server/sub_sessions/actions'
import { setRedirect } from '../../ui/router/actions'
import { useSelectedFormattedSession } from '../../metrics_server/sessions/hooks'
import { useEventsFilter } from '../../metrics_server/events/filter'
import { useSportscaster } from '../../metrics_server/sportscaster/hooks'
import { useUnits, useUnitsSystem } from '../../metrics_server/units/hooks'
import { UnitSystemNames } from '../../metrics_server/units/types'
import {
  subscribeToEventTopics,
  unsubscribeFromEventTopics
} from '../../metrics_server/mqtt/functions'
import { getSelectedBroker } from '../../metrics_server/sportscaster/functions'
import { useBroadcastIntegrationStatusCheck } from '../../metrics_server/broadcast_integration/hooks'
import { broadcastingActions } from '../../metrics_server/broadcasting'
import { SortingState } from '@tanstack/react-table'
import { getDrillsForSession } from '../../metrics_server/drills/thunks'

export const SessionContainer = (View: React.ComponentType<any>) => {
  const ComposedComponent: React.FC<any> = (props) => {
    // Redux Store //
    const dispatch = useAppDispatch()
    const sportscaster = useSportscaster()
    const units = useUnits()
    // ===== //

    const [highlightedRow, setHighlightedRow] = useState(null)

    // Session //
    const formattedSession = useSelectedFormattedSession()
    const { sessionData, live, sport } = formattedSession
    // ======= //

    // Units //
    const unitSystem = useUnitsSystem(sport)
    // ===== //

    // Broadcast Integration //
    useBroadcastIntegrationStatusCheck()
    // ====================== //

    // Fetch session data //
    useEffect(() => {
      dispatch(getDrillsForSession(sessionData.id))
      dispatch(eventActions.getSessionEvents(sessionData.id))
      dispatch(challengeActions.getSessionChallenges(sessionData.id))
      dispatch(subSessionActions.getSubSessions(sessionData.id))
      sessionData.teamsSessions.forEach((teamSession) => {
        dispatch(teamActions.getTeamPlayers(teamSession.teamId))
      })

      if (live) {
        dispatch(broadcastingActions.getBroadcastState())
      }
    }, [units.selected])
    // ================== //

    // Manage MQTT //
    // Record current location & unit system to unsubscribe from topics when location or unit system is changed
    const unitRef = useRef<UnitSystemNames>(unitSystem.key)
    const mqttContext = useContext(MqttContext)

    useEffect(() => {
      const selectedBroker = getSelectedBroker(sportscaster)

      const client = selectedBroker ? mqttContext[selectedBroker.WSSHost] : null

      if (client) {
        // If unit system changes unsubscribe from old topic
        const prevUnitSystem = unitSystems[unitRef.current]
        if (prevUnitSystem && unitSystem.key !== prevUnitSystem.key)
          unsubscribeFromEventTopics(
            client,
            prevUnitSystem,
            sessionData.locationName
          )

        // Subscribe to new flight topic
        subscribeToEventTopics(
          client,
          unitSystem,
          sessionData.locationName,
          (data, topic) => {
            let eventType = 'Flight'
            if (data.event?.type === 7) {
              eventType = 'AussieRules'
            } else if (data.event?.type === 6) {
              eventType = 'Game'
            }

            console.log(new Date(), `New ${eventType} Event Received`, data)
            dispatch(eventActions.handleNewEventOnTopic(data))
          }
        )
      }

      unitRef.current = unitSystem.key

      return () => {
        if (client) {
          unsubscribeFromEventTopics(
            client,
            unitSystem,
            sessionData.locationName
          )
        }
      }
    }, [sportscaster.selectedBrokerHost, unitSystem])
    // ================== //

    // Event Filters //

    // Manage validation events filter //

    const validationEventsFilters = useEventsFilter(
      {
        team: {
          options: formattedSession.teams.options
        }
      },
      [
        'flight',
        'aussieRules',
        'snapGameEvent',
        'handOff',
        'kickOffGameEvent',
        'twoPointConversion',
        'touchDown',
        'penaltyGameEvent',
        'touch',
        'try',
        'tackle',
        'safety',
        'defensiveTwoPoint',
        'goalLineCrossed',
        'soccerGoal',
        'soccerBallOverLine',
        'manualPass',
        'fumble'
      ]
    )

    // Validation table sort //

    const [timeColumn, setTimeColumn] = useState<
      'sessionStartTime' | 'startTimeMil'
    >('sessionStartTime')

    const [validationTableSorting, setValidationTableSorting] =
      useState<SortingState>([
        {
          id: timeColumn,
          desc: live
        }
      ])

    useEffect(() => {
      if (validationTableSorting.length === 0) return
      if (
        validationTableSorting[0].id === 'sessionStartTime' ||
        validationTableSorting[0].id === 'startTimeMil'
      ) {
        setValidationTableSorting([
          {
            id: timeColumn,
            desc: validationTableSorting[0].desc
          }
        ])
      }
    }, [timeColumn])

    // ================== //

    // Session actions //
    const stopSession = (sessionId) => {
      dispatch(
        sessionActions.stopSession(sessionId, () => {
          dispatch(setRedirect('/activitylist'))
        })
      )
    }
    // ============= //

    return (
      <View
        {...props}
        live={live}
        // Selected Events
        setHighlightedRow={setHighlightedRow}
        highlightedRow={highlightedRow}
        // Events
        validationEventsFilters={validationEventsFilters}
        validationTableSorting={validationTableSorting}
        setValidationTableSorting={setValidationTableSorting}
        timeColumn={timeColumn}
        setTimeColumn={setTimeColumn}
        // To move down to components or actions
        stopSession={stopSession}
      />
    )
  }

  return ComposedComponent
}
