import React, { useRef, useState, useEffect } from 'react'
import { EditIcon } from '../Icons/EditIcon/EditIcon'
import { SettingsIcon } from '../Icons/SettingsIcon/SettingsIcon'
import styles from './Options.module.scss'

type OptionsProps = {
  type?: string
  backgroundColor?: string
  color?: string
  options: Array<{ name: string; handle: () => void }>
  dropdownLeft?: boolean
  scale?: number
}

export const Options: React.FC<OptionsProps> = ({
  type,
  backgroundColor,
  color,
  options,
  dropdownLeft = true,
  scale = 1
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const isVisible = (elem: HTMLElement | null) =>
    elem &&
    (elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)

  const handleOutsideClick = (event: any) => {
    if (
      !dropdownRef.current?.contains(event.target) &&
      isVisible(dropdownRef.current)
    ) {
      setExpanded(false)
    }
  }

  const handleToggleExpand = (event: React.MouseEvent<HTMLInputElement>) => {
    event?.stopPropagation()
    setExpanded(!isExpanded)
  }

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isExpanded])

  const renderOption = (
    option: { name: string; handle: () => void },
    index: number
  ) => {
    return (
      <div
        onClick={() => {
          option.handle()
          const rootElement = document.getElementById('root')
          if (rootElement) {
            rootElement.click()
          }
        }}
        key={index}
      >
        {option.name}
      </div>
    )
  }

  const defaultEditIcon = () => (
    <EditIcon
      id='edit-btn'
      backgroundColor={backgroundColor}
      color={color}
      scale={scale}
    />
  )

  const getOptionIcon = () => {
    switch (type) {
      case 'edit':
        return defaultEditIcon()
      case 'settings':
        return <SettingsIcon scale={0.9} active={isExpanded} color='white' />
      default:
        return defaultEditIcon()
    }
  }

  if (!options || options.length === 0) {
    return null
  }

  return (
    <div onClick={handleToggleExpand} className={styles.optionContainer}>
      {getOptionIcon()}
      {isExpanded && (
        <div
          ref={dropdownRef}
          className={`${styles.optionDropdown} bg-background hover:text-primary-background`}
          style={{
            right: dropdownLeft ? '15px' : undefined,
            left: dropdownLeft ? undefined : '15px'
          }}
        >
          {options.map(renderOption)}
        </div>
      )}
    </div>
  )
}
