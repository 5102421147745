import React from 'react'
import styles from './HardwareTag.module.scss'
import { FormattedDevice } from '../../../../types'
import { removeTagFromPlayer } from '../../../../../sessions/actions'
import {
  useFormattedSession,
  useTagAssignmentDragAndDrop
} from '../../../../../sessions/hooks'
import { BatteryIcon } from '../../../../../../components/Icons/BatteryIcon/BatteryIcon'
import { HardwareCommon } from '../HardwareCommon/HardwareCommon'
import { QaToolTip } from '../QAInfo/QaToolTip'
import { useAppDispatch } from '../../../../../../store/hooks'

export interface HardwareTagProps {
  device: FormattedDevice
  isSessionAssignment: boolean
}

export const HardwareTag: React.FC<HardwareTagProps> = (props) => {
  const dispatch = useAppDispatch()
  const { isSessionAssignment, device } = props

  const {
    onDragLeave,
    onDragOver,
    onTagDrop,
    onTagDrag,
    dropZone,
    isDragged,
    currentTagId
  } = useTagAssignmentDragAndDrop('setup')

  const { playersSessions } = useFormattedSession('setup')

  if (!isSessionAssignment) return <HardwareCommon device={device} />

  // Get player session
  const playerSession = playersSessions.byHardwareId.map[device.id]
  const handleDragStart = (e) => {
    // Check if the target or its ancestor is the shared container
    const isTagOrChild =
      e.target.closest(`.${styles['hardware-tag-sharedContainer']}`) !== null

    if (isTagOrChild) {
      const id = e.target.id
      onTagDrag({
        ...e,
        target: {
          ...e.target,
          id: id ? id : device.id // Use device.id instead of e.target.id
        }
      })
    } else {
      e.preventDefault()
    }
  }
  if (!playerSession) {
    if (isDragged && parseInt(currentTagId) === device.id) {
      return (
        <div
          id={`${device.id}`}
          className={`${styles['hardware-tag-container']}`}
        >
          <div
            style={{ backgroundColor: device.status.colour }}
            className={`${styles['hardware-tag-sharedContainer']}`}
            draggable={true}
            onDragStart={(e) => handleDragStart(e)}
          >
            <div className={styles['hardware-tag-encodedId']} draggable={false}>
              <div className={styles['hardware-tag-batteryContainer']}>
                <BatteryIcon device={device} />
              </div>
              {device.serial}
              <QaToolTip deviceId={device.id} qaReports={device.reports} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`${styles['hardware-tag-container']}`}>
          <div
            style={{ backgroundColor: device.status.colour }}
            className={`${styles['hardware-tag-sharedContainer']}`}
            id={`${device.id}`}
            draggable={true}
            onDragStart={(e) => handleDragStart(e)}
          >
            <div className={styles['hardware-tag-encodedId']}>
              <div className={styles['hardware-tag-batteryContainer']}>
                <BatteryIcon device={device} />
              </div>
              {device.serial}
              <QaToolTip deviceId={device.id} qaReports={device.reports} />
            </div>
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className={`${styles['hardware-tag-container']}`}>
        <div
          style={{ backgroundColor: device.status.colour }}
          onDragOver={(e) => onDragOver(e)}
          onDragLeave={(e) => onDragLeave(e)}
          onDrop={(e) => onTagDrop(e, playerSession.playerId, null)}
          onClick={() => dispatch(removeTagFromPlayer(playerSession))}
          className={`${styles['hardware-tag-sharedContainer']}
              ${styles['hardware-tag-linkedBorder']}
              ${dropZone === device.id && styles['hardware-tag-activeDrop']}`}
        >
          <div
            id={`${device.id}`}
            data-playerid={playerSession.playerId}
            className={`${styles['hardware-tag-onDrop']} tagDropZone`}
          ></div>
          <div className={styles['hardware-tag-encodedId']}>
            <div className={styles['hardware-tag-batteryContainer']}>
              <BatteryIcon device={device} />
            </div>
            {device.serial}
            <QaToolTip deviceId={device.id} qaReports={device.reports} />
          </div>
        </div>
        <div id='player-name' className={styles['hardware-tag-playerName']}>
          <div className={styles['hardware-tag-tagInfo']}>
            {playerSession.player?.abbreviatedName}
          </div>
        </div>
      </div>
    )
  }
}
