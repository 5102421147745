import { DrillsState } from '../slice'
import { mockArsenalDrills } from './api'

export const mockDrillsState: DrillsState = {
  processedData: {
    ...mockArsenalDrills
  },
  drillCount: 1,
  playerBreakdowns: {},
  loading: false,
  error: null,
  selectedId: 'drill-active',
  goals: true
}

// Reporting tool mock state //
export const mockDrillsStateReportingTool: DrillsState = {
  processedData: {
    ...mockArsenalDrills
  },
  drillCount: 1,
  playerBreakdowns: {},
  loading: false,
  error: null,
  selectedId: 'drill-active',
  goals: true
}
