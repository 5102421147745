import React from 'react'
import ReactTooltip from 'react-tooltip'
import { QAReports } from '../../../../types'
import { QAWarningUnknownIcon } from '../../../../../../components/Icons/QAWarningUnknownIcon/QAWarningUnknownIcon'
import { sportableColors } from '../../../../../../const'
import { QAWarningIcon } from '../../../../../../components/Icons/QAWarningIcon/QAWarningIcon'
import styles from './QaInfo.module.scss'

export interface QaToolTipProps {
  qaReports: QAReports
  deviceId: number
}

export const QaToolTip: React.FC<QaToolTipProps> = ({
  qaReports,
  deviceId
}) => {
  const { colorError, colorSuccess, colorWarning } = sportableColors.colors

  // if qaReports is undefined then show unknown QA warning
  if (!qaReports) {
    return (
      <div className={styles.qaWarning}>
        <div
          data-tip
          data-for={`qaWarning-${deviceId}`}
          data-testid='qaWarningUnknown'
        >
          <QAWarningUnknownIcon scale={0.7} color={colorError} />
          <ReactTooltip id={`qaWarning-${deviceId}`} aria-haspopup='true'>
            <p data-testid='fieldQAText'>
              Field and Hardware QA:{' '}
              <span
                data-testid='fieldQATextStyle'
                style={{
                  color: colorError
                }}
              >
                Unknown
              </span>
            </p>
          </ReactTooltip>
        </div>
      </div>
    )
  }

  const { fieldQA, hwQA, retired, investigate } = qaReports

  const isQAPassed =
    fieldQA.passed &&
    hwQA.passed &&
    !!retired.passed === false &&
    !!investigate.passed === false

  // if both pass, then don't show anything
  if (isQAPassed) {
    return null
  }

  return (
    <div className={styles.qaWarning}>
      {' '}
      <div data-tip data-for={`qaWarning-${deviceId}`} data-testid='qaWarning'>
        <QAWarningIcon
          scale={0.7}
          color={
            investigate.passed ||
            retired.passed ||
            (!fieldQA.passed && !hwQA.passed)
              ? colorError
              : colorWarning
          }
        />
        <ReactTooltip id={`qaWarning-${deviceId}`} aria-haspopup='true'>
          <p data-testid='fieldQAText'>
            Field QA passed:{' '}
            <span
              data-testid='fieldQATextStyle'
              style={{
                color: fieldQA.passed ? colorSuccess : colorError
              }}
            >{`${fieldQA.passed !== '' ? !!fieldQA.passed : 'Unknown'}`}</span>
          </p>
          <p data-testid='hardwareQAText'>
            Hardware QA passed:{' '}
            <span
              data-testid='hardwareQATextStyle'
              style={{
                color: hwQA.passed ? colorSuccess : colorError
              }}
            >{`${hwQA.passed !== '' ? !!hwQA.passed : 'Unknown'}`}</span>
          </p>
          <p data-testid='investigateQAText'>
            Investigate:{' '}
            <span
              data-testid='investigateQATextStyle'
              style={{
                color:
                  investigate.passed !== '' && !investigate.passed
                    ? colorSuccess
                    : colorError
              }}
            >{`${
              investigate.passed !== ''
                ? investigate.passed === false
                  ? 'No'
                  : 'Yes'
                : 'Unknown'
            }`}</span>
          </p>
          <p data-testid='retiredQAText'>
            Retired:{' '}
            <span
              data-testid='retiredQATextStyle'
              style={{
                color:
                  retired.passed !== '' && !retired.passed
                    ? colorSuccess
                    : colorError
              }}
            >{`${
              retired.passed !== ''
                ? retired.passed === false
                  ? 'No'
                  : 'Yes'
                : 'Unknown'
            }`}</span>
          </p>
        </ReactTooltip>
      </div>{' '}
    </div>
  )
}
