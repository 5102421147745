import { Pitch } from '../../pitches/types'
import {
  rawPlayerFour,
  rawPlayerOne,
  rawPlayerThree,
  rawPlayerTwo
} from '../../players/mocks/api'
import { reportingToolDummyPlayerState } from '../../players/mocks/state'
import { RawPlayerData } from '../../players/types'
import { sportTypes, SportTypeValues } from '../../sports/data_types'
import {
  americanFootballTeam,
  rawTeamOne,
  rawTeamTwo,
  soccerTeam
} from '../../teams/mocks/api'
import { reportingToolDummyTeamState } from '../../teams/mocks/state'
import { RawTeamData } from '../../teams/types'
import { SessionTypeValues } from '../data_types'
import { SessionModeTypeValues } from '../modes/data_types'
import { SessionStateTypeValues } from '../states/data_types'
import { SubSessionTypeValues } from '../sub_sessions/data_types'
import {
  RawPlayerSessionData,
  RawPlayerSessionTag,
  RawSessionData,
  RawTeamSessionData
} from '../types'

// Mock Players Sessions //

const mockRawPlayerSession = (
  player: RawPlayerData,
  sessionId: string,
  tag: RawPlayerSessionData['tag'] = null,
  number?: number
): RawPlayerSessionData => {
  // Remove teamId from player object (this is how we get it from the MS) //
  const playerSessionPlayer = { ...player }
  playerSessionPlayer.teamId = undefined
  return {
    id: `${player.id}-${sessionId}`,
    playerId: player.id,
    player: playerSessionPlayer,
    teamId: player.teamId,
    sessionId,
    number: number || player.number,
    tag: tag
  }
}

const mockRawPlayerSessionBall = (
  sessionId: string,
  tag: RawPlayerSessionData['tag']
): RawPlayerSessionData => {
  return {
    id: `${tag.serial}-${sessionId}`,
    sessionId,
    playerId: null,
    player: undefined,
    teamId: null,
    number: undefined,
    tag
  }
}

// Mock Team Sessions //

const mockRawTeamSession = (
  team: RawTeamData,
  sessionId: string,
  homeAway: RawTeamSessionData['homeAway']
): RawTeamSessionData => {
  return {
    id: `${team.id}-${sessionId}`,
    teamId: team.id,
    team,
    sessionId,
    homeAway
  }
}

// Mock Session //

const defaultSportType = sportTypes.items.australianRules

export const mockRawSession = (
  {
    id = 'session-1',
    startTime = 1721301595.174677,
    endTime = 1721309250.678947,
    name = 'Session One',
    type = 3 as SessionTypeValues,
    subType = 1 as SubSessionTypeValues,
    mode = 1 as SessionModeTypeValues,
    sportType = defaultSportType.value as SportTypeValues,
    state = 'Finished' as SessionStateTypeValues,
    pitchId = '0',
    pitch = defaultSportType.props.pitch.default as Pitch,
    womensMode = false,
    locationName = 'rorys-laptop',
    hostname = 'sportable-rory',
    officiatingAlgosEnable = false
  },
  teams: RawTeamData[] = [],
  homeTeamPlayers: {
    player: RawPlayerData
    tag?: RawPlayerSessionTag
    number?: number
  }[] = [],
  awayTeamPlayers: {
    player: RawPlayerData
    tag?: RawPlayerSessionTag
    number?: number
  }[] = [],
  balls: RawPlayerSessionTag[] = [],
  officiatingTeamPlayers: {
    player: RawPlayerData
    tag?: RawPlayerSessionTag
    number?: number
  }[] = []
) => {
  const rawTeamSessions = [] as RawTeamSessionData[]

  const homeTeam = teams[0]
  const awayTeam = teams[1]

  teams.forEach((team, index) => {
    const homeAway = index === 0 ? 'HOME' : 'AWAY'
    rawTeamSessions.push(mockRawTeamSession(team, id, homeAway))
  })

  const homeTeamPlayerSessions = [] as RawPlayerSessionData[]
  const homeTeamPlayerSessionsWithTag = [] as RawPlayerSessionData[]

  homeTeamPlayers.forEach(({ player, tag, number }) => {
    homeTeamPlayerSessions.push(mockRawPlayerSession(player, id, tag, number))
    if (tag) {
      homeTeamPlayerSessionsWithTag.push(
        mockRawPlayerSession(player, id, tag, number)
      )
    }
  })

  const awayTeamPlayerSessions = [] as RawPlayerSessionData[]
  const awayTeamPlayerSessionsWithTag = [] as RawPlayerSessionData[]

  awayTeamPlayers.forEach(({ player, tag, number }) => {
    awayTeamPlayerSessions.push(mockRawPlayerSession(player, id, tag, number))
    if (tag) {
      awayTeamPlayerSessionsWithTag.push(
        mockRawPlayerSession(player, id, tag, number)
      )
    }
  })

  const officiatingTeamPlayerSessions = [] as RawPlayerSessionData[]
  const officiatingTeamPlayerSessionsWithTag = [] as RawPlayerSessionData[]

  officiatingTeamPlayers.forEach(({ player, tag, number }) => {
    officiatingTeamPlayerSessions.push(
      mockRawPlayerSession(player, id, tag, number)
    )
    if (tag) {
      officiatingTeamPlayerSessionsWithTag.push(
        mockRawPlayerSession(player, id, tag, number)
      )
    }
  })

  const ballPlayerSessions = [] as RawPlayerSessionData[]

  balls.forEach((tag, index) => {
    ballPlayerSessions.push(mockRawPlayerSessionBall(id, tag))
  })

  const rawSessionData: RawSessionData = {
    id,
    startTime,
    endTime,
    name,
    type,
    subType,
    mode,
    sportType,
    state,
    pitchId,
    pitch,
    womensMode,
    locationName,
    hostname,
    officiatingAlgosEnable,
    teamsSessions: rawTeamSessions,
    playersSessions: [
      ...ballPlayerSessions,
      ...homeTeamPlayerSessions,
      ...awayTeamPlayerSessions,
      ...officiatingTeamPlayerSessions
    ],
    uploadStatus: null
  }

  return {
    rawSessionData,
    rawTeamSessions,
    homeTeam,
    homeTeamPlayerSessions,
    homeTeamPlayerSessionsWithTag,
    awayTeam,
    awayTeamPlayerSessions,
    awayTeamPlayerSessionsWithTag,
    officiatingTeamPlayerSessions,
    officiatingTeamPlayerSessionsWithTag,
    ballPlayerSessions
  }
}

// Mock Session One //

const id = 'session-1'

export const rawPlayerSessionOne = mockRawPlayerSession(
  rawPlayerOne,
  id,
  null,
  11
)
export const rawPlayerSessionTwo = mockRawPlayerSession(
  rawPlayerTwo,
  id,
  null,
  12
)
export const rawPlayerSessionThree = mockRawPlayerSession(
  rawPlayerThree,
  id,
  null,
  13
)
export const rawPlayerSessionFour = mockRawPlayerSession(
  rawPlayerFour,
  id,
  null,
  14
)

export const rawPlayerSessionBallOne = mockRawPlayerSessionBall(id, {
  id: 615613973,
  serial: 'JB31GN',
  type: 'Ball',
  productName: 'Unknown'
})
export const rawPlayerSessionBallTwo = mockRawPlayerSessionBall(id, {
  id: 615614514,
  serial: 'JB321J',
  type: 'Ball',
  productName: 'Unknown'
})

export const rawTeamSessionOne = mockRawTeamSession(rawTeamOne, id, 'HOME')
export const rawTeamSessionTwo = mockRawTeamSession(rawTeamTwo, id, 'AWAY')

export const rawSessionOne: RawSessionData = {
  id,
  startTime: new Date().getTime() / 1000,
  endTime: new Date().getTime() / 1000 + 4500,
  name: 'Session One',
  type: 3,
  subType: 1,
  mode: 1,
  state: 'Finished',
  pitchId: '0',
  pitch: sportTypes.items.australianRules.props.pitch.default,
  womensMode: false,
  locationName: 'rorys-laptop',
  hostname: 'sportable-rory',
  officiatingAlgosEnable: false,
  sportType: sportTypes.items.australianRules.value,
  teamsSessions: [rawTeamSessionOne, rawTeamSessionTwo],
  playersSessions: [
    rawPlayerSessionBallOne,
    rawPlayerSessionBallTwo,
    rawPlayerSessionOne,
    rawPlayerSessionTwo,
    rawPlayerSessionThree,
    rawPlayerSessionFour
  ],
  uploadStatus: null
}

export const rawSessionTwo: RawSessionData = {
  ...rawSessionOne,
  pitch: sportTypes.items.soccer.props.pitch.default,
  sportType: sportTypes.items.soccer.value
}

// Mock Arsenal Session //

const rawPlayerSessionsMatch = Object.values(
  reportingToolDummyPlayerState.rawData
)
  .filter((rawPlayer) => {
    return rawPlayer.teamId === 'team-1'
  })
  .map((rawPlayer) => {
    return mockRawPlayerSession(rawPlayer, 'session-1')
  })

const rawPlayerSessionsTraining = Object.values(
  reportingToolDummyPlayerState.rawData
)
  .filter((rawPlayer) => {
    return rawPlayer.teamId === 'team-1'
  })
  .map((rawPlayer) => {
    return mockRawPlayerSession(rawPlayer, 'session-2')
  })

export const rawSessionArsenalMatch: RawSessionData = {
  ...rawSessionOne,
  id: 'session-1',
  name: 'Arsenal Match',
  type: 3,
  teamsSessions: [mockRawTeamSession(soccerTeam, 'session-1', 'HOME')],
  playersSessions: rawPlayerSessionsMatch,
  pitch: sportTypes.items.soccer.props.pitch.default,
  sportType: sportTypes.items.soccer.value
}

export const rawSessionArsenalTrainingSession: RawSessionData = {
  ...rawSessionArsenalMatch,
  id: 'session-2',
  name: 'Arsenal Training',
  type: 4,
  teamsSessions: [mockRawTeamSession(soccerTeam, 'session-2', 'HOME')],
  playersSessions: rawPlayerSessionsTraining,
  pitch: sportTypes.items.soccer.props.pitch.default,
  sportType: sportTypes.items.soccer.value
}

// Mock Pittsburgh Steelers Session //

const rawPlayerSessionsPitMatch = Object.values(
  reportingToolDummyPlayerState.rawData
)
  .filter((rawPlayer) => {
    return rawPlayer.teamId === 'team-2'
  })
  .map((rawPlayer) => {
    return mockRawPlayerSession(rawPlayer, 'session-3')
  })

const rawPlayerSessionsPitTraining = Object.values(
  reportingToolDummyPlayerState.rawData
)
  .filter((rawPlayer) => {
    return rawPlayer.teamId === 'team-2'
  })
  .map((rawPlayer) => {
    return mockRawPlayerSession(rawPlayer, 'session-4')
  })

export const rawPittsburghSteelersMatch: RawSessionData = {
  ...rawSessionOne,
  id: 'session-3',
  name: 'Steelers Match',
  type: 3,
  teamsSessions: [
    mockRawTeamSession(americanFootballTeam, 'session-3', 'HOME')
  ],
  playersSessions: rawPlayerSessionsPitMatch,
  pitch: sportTypes.items.americanFootball.props.pitch.default,
  sportType: sportTypes.items.americanFootball.value
}

export const rawPittsburghSteelersTrainingSession: RawSessionData = {
  ...rawSessionArsenalMatch,
  id: 'session-4',
  name: 'Steelers Training',
  type: 4,
  teamsSessions: [
    mockRawTeamSession(americanFootballTeam, 'session-4', 'HOME')
  ],
  playersSessions: rawPlayerSessionsPitTraining,
  pitch: sportTypes.items.americanFootball.props.pitch.default,
  sportType: sportTypes.items.americanFootball.value
}

console.log(rawSessionArsenalMatch, rawSessionArsenalTrainingSession)
