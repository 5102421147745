import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import {
  formatSessionDate,
  getBlankSession,
  isTraining
} from '../../../metrics_server/sessions/functions'
import * as ActivityListScreen from '../../../views/ActivityList/config'
import * as SessionSetupScreen from '../../../views/SessionSetup/config'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { Option } from '../../../metrics_server/teams/types'
import { useTeamOptions, useTeams } from '../../../metrics_server/teams/hooks'
import {
  RawSessionData,
  RawTeamSessionData,
  SessionSetup
} from '../../../metrics_server/sessions/types'
import {
  isSessionType,
  sessionTypes,
  SessionTypeValues
} from '../../../metrics_server/sessions/data_types'
import {
  sessionModeTypes,
  SessionModeTypeValues
} from '../../../metrics_server/sessions/modes/data_types'
import { SubSessionTypeValues } from '../../../metrics_server/sessions/sub_sessions/data_types'
import {
  sportTypesConfig,
  SportTypeValues
} from '../../../metrics_server/sports/data_types'
import { isMatch } from '../../../metrics_server/sessions/functions'
import { sportTypes } from '../../../metrics_server/sports/data_types'
import {
  useBroadcastIntegration,
  useBroadcastIntegrationFixtureCheck,
  useBroadcastIntegrationSessionState
} from '../../../metrics_server/broadcast_integration/hooks'
import {
  useFormattedSession,
  useSessions
} from '../../../metrics_server/sessions/hooks'
import {
  setCustomName,
  updateSession,
  setSession,
  updateUseCustomName,
  getTeamsMostRecentSession,
  addPlayerSession
} from '../../../metrics_server/sessions/actions'
import { useAppDispatch } from '../../../store/hooks'
import {
  setDisabled,
  setFixtureDate
} from '../../../metrics_server/broadcast_integration/slice'
import Loader from '../../../components/Loader/Loader'
import styles from './CreateSessionForm.module.scss'
import { sportableColors } from '../../../const'
import { useAppTypeCheck } from '../../../metrics_server/user/hooks'
import { setRedirect } from '../../../ui/router/actions'
import {
  useFormattedHardware,
  useHardware
} from '../../../metrics_server/hardware/hooks'
import {
  postSeatConfigurationToDaemon,
  updateSeatConfiguration
} from '../../../metrics_server/hardware/actions'
import { environment } from '../../../metrics_server/env'
import { getTeamPlayers } from '../../../metrics_server/teams/actions'
import { usePlayers } from '../../../metrics_server/players/hooks'
import { usePitches } from '../../../metrics_server/pitches/hooks'
import { CreateSessionFormProps } from '../config'

const validate = (values) => {
  const errors = {} as any

  return errors
}

// Check if b team is selected, if not select the next available team
const getInitialBTeam = (teams) => {
  let bTeamId
  if (!teams.selectedBTeam) {
    for (const id in teams.items) {
      if (id !== teams.selectedTeam) {
        bTeamId = id
        break
      }
    }
  } else {
    bTeamId = teams.selectedBTeam
  }
  return bTeamId
}

export interface SessionSetupFormValues {
  officiatingAlgosEnable: boolean
  noDaemon: boolean
  useCustomName: boolean
  name: SessionSetup['name']
  customName: SessionSetup['name']
  teamA: string
  teamB: string
  type: SessionTypeValues
  subType: SubSessionTypeValues
  mode: SessionModeTypeValues
  sportType: SportTypeValues
  broadcastIntegrationDisabled: boolean
  fixtureDate: Date
}

function getTeamOptionByValue<v>(options: Option[], value: v) {
  return options.find((option) => option.value === value)
}

export const CreateSessionForm = (props: CreateSessionFormProps) => {
  const dispatch = useAppDispatch()

  const teams = useTeams()
  const players = usePlayers()
  const pitches = usePitches()

  const formattedSession = useFormattedSession('setup')

  const session = formattedSession.sessionData

  // Permissions //
  const appTypeCheck = useAppTypeCheck()
  const { isExternalSoccerTraining } = appTypeCheck
  // =================== //

  const { useCustomName, customName } = useSessions()
  const { configuration } = useHardware()

  const [noDaemon, setNoDaemon] = useState(false)

  useEffect(() => {
    // localStorage.setItem('noDaemon', noDaemon.toString())
  }, [noDaemon])

  const unixTime = useMemo(() => {
    const date = new Date()
    return date.getTime()
  }, [])

  // TODO: genius-integration - Check for integration
  const broadcastIntegration = useBroadcastIntegration()
  const broadcastIntegrationSessionState = useBroadcastIntegrationSessionState()
  // Check for integrated fixture if active
  useBroadcastIntegrationFixtureCheck()

  // Submit Session Form //
  const formattedHardware = useFormattedHardware()

  const submitSessionForm = useCallback(async () => {
    // Clear playersSessions and use each selected teams' most recent session's playersSessions
    // Use pitch in use if it matches the sportType

    if (!formattedSession) return

    const { sport, teams, sessionData, type } = formattedSession

    // Post seat configuration to daemon - skip if no deamon option selected //
    if (!noDaemon) {
      const response = await dispatch(
        postSeatConfigurationToDaemon(sessionData.mode)
      )
      if (!response) return
    }

    dispatch(
      updateSession({
        playersSessions: [],
        pitch: pitches.inUse.type === sport.value ? pitches.inUse : null
      })
    )

    await Promise.all(
      teams.list.map(({ id }) => {
        return dispatch(
          getTeamsMostRecentSession(
            id,
            type.value,
            sport.value,
            (lastSession: RawSessionData) => {
              // Validate and format previous sessions playerSessions before adding them to the new one ///
              lastSession.playersSessions.forEach((ps) => {
                const newPlayerSession = { ...ps }

                // Remove id and sessionId from new player session playerSession
                delete newPlayerSession.id
                delete newPlayerSession.sessionId

                // Check if player tag is available (online, sleep or offline) - if not remove tag
                const isHardwareAvailable =
                  !!formattedHardware.devices.map[newPlayerSession.tag.id]
                if (!isHardwareAvailable) {
                  delete newPlayerSession.tag
                }

                // Add player to session
                if (
                  newPlayerSession.teamId === id &&
                  !!players.items[newPlayerSession.playerId]
                ) {
                  dispatch(addPlayerSession(newPlayerSession))
                }
                // Add ball to session
                else if (!newPlayerSession.playerId && newPlayerSession.tag) {
                  dispatch(addPlayerSession(newPlayerSession))
                }
              })
            }
          )
        )
      })
    )

    dispatch(setRedirect(SessionSetupScreen.path))
  }, [pitches, formattedSession, dispatch])

  // Form values
  const formValues = useMemo<SessionSetupFormValues>(() => {
    return {
      name: session.name,
      useCustomName,
      customName: customName,
      officiatingAlgosEnable: formattedSession.isOfficiatingMode,
      teamA: formattedSession.homeTeam?.id || teams.selectedTeam,
      teamB: formattedSession.awayTeam?.id || getInitialBTeam(teams),
      type: session.type,
      subType: session.subType,
      mode: session.mode,
      sportType: session.sportType,
      broadcastIntegrationDisabled: broadcastIntegration.disabled,
      fixtureDate: broadcastIntegration.fixtureDate,
      noDaemon: noDaemon
    }
  }, [
    session,
    broadcastIntegration.disabled,
    broadcastIntegration.fixtureDate,
    useCustomName,
    customName,
    formattedSession.homeTeam,
    formattedSession.awayTeam,
    formattedSession.isOfficiatingMode,
    noDaemon
  ])

  // Initialize useRef to track previous values
  const prevFormValues = useRef({
    sportType: formValues.sportType,
    type: formValues.type,
    subType: formValues.subType
  })

  const { teamAOptions, teamBOptions } = useTeamOptions(
    formValues.teamA || teams.selectedTeam,
    formValues.teamB || getInitialBTeam(teams),
    isTraining(session.type)
  )

  // If session team changes fetch players for that team
  const teamIdsRef = useRef<string[]>([])
  useEffect(() => {
    if (formattedSession) {
      formattedSession.teams.list.forEach((team) => {
        if (teamIdsRef.current.includes(team.id)) return
        dispatch(getTeamPlayers(team.id))
      })
      teamIdsRef.current = formattedSession.teams.list.map((team) => team.id)
    }
  }, [formattedSession.teams])

  // If officiating mode is selected, fetch players for officiation team
  const officiationTeamIdRef = useRef<string>()
  useEffect(() => {
    if (
      formattedSession &&
      formattedSession.isOfficiatingMode &&
      formattedSession.officiationTeam &&
      !officiationTeamIdRef.current
    ) {
      officiationTeamIdRef.current = formattedSession.officiationTeam.id
      dispatch(getTeamPlayers(formattedSession.officiationTeam.id))
    }
  }, [formattedSession.officiationTeam])

  // Generate fields
  const fields = useMemo(() => {
    const teamA =
      getTeamOptionByValue(teamAOptions, formValues.teamA) ||
      teamAOptions[0] ||
      ''
    const teamB =
      getTeamOptionByValue(teamBOptions, formValues.teamB) ||
      teamBOptions[0] ||
      ''

    const sessionType = sessionTypes.getTypeByValue(formValues.type)

    const sessionSubType =
      sessionType.props.subTypes?.getTypeByValue(formValues.subType) ||
      sessionType.props.subTypes?.items.dataGathering

    const defaultName =
      isMatch(sessionType.value) && teamA && teamB
        ? `${teamA?.name} vs ${teamB?.name} ${formatSessionDate(unixTime)}`
        : `${teamA ? teamA.name : ''} ${formatSessionDate(unixTime)}`

    // Permissions //

    // Sport types //

    const sportTypesOptions = sportTypes.options.filter((option) => {
      if (isExternalSoccerTraining) {
        return option.value === sportTypesConfig.soccer.value
      } else {
        return true
      }
    })

    // Session types //

    const sessionSubTypeOptions =
      sessionType?.props.subTypes?.options.filter((option) => {
        if (isExternalSoccerTraining) {
          if (option.value === 2 || option.value === 3 || option.value === 4) {
            return false
          }
          return true
        } else {
          return true
        }
      }) || []

    // Mode types //

    const sessionModeTypeOptions = sessionModeTypes.options.filter((option) => {
      if (isExternalSoccerTraining) {
        return option.value !== 2
      } else {
        return true
      }
    })

    // =================== //

    const fields = [
      sportTypesOptions.length > 1 && {
        name: 'sportType',
        type: 'select',
        label: 'Sport',
        style: { marginBottom: '15px' },
        options: sportTypesOptions,
        initialValue: formValues.sportType,
        data: {
          value: formValues.sportType
        }
      },
      {
        name: 'type',
        type: 'toggle',
        label: 'Type',
        size: 's',
        width: '75%',
        options: sessionTypes.options,
        data: {
          value: sessionType.value
        }
      },
      sessionSubTypeOptions.length > 1 && {
        name: 'subType',
        type: 'toggle',
        label: 'Sub Type',
        width: '125%',
        size: 's',
        options: sessionSubTypeOptions,
        initialValue: sessionSubType?.value,
        data: {
          value: sessionSubType?.value
        }
      },
      {
        name: 'Device Profile',
        label: 'Device Profile',
        type: 'textarea',
        height: 'auto',
        width: '100%',
        data: {
          value: configuration
            ? `Total: ${configuration.total}\nBalls: ${configuration.ball}\nTags: ${configuration.playerTag}\nAnchor: ${configuration.anchor}\nLow Power: ${configuration.lowPower}`
            : 'No config'
        }
      },
      broadcastIntegrationSessionState.isAvailable &&
        !broadcastIntegrationSessionState.isBroadcastIntegrationRequired && {
          name: 'broadcastIntegrationDisabled',
          type: 'checkbox',
          label: 'Disable Broadcast Integration',
          initialValue: false,
          data: {
            value: broadcastIntegration.disabled
          }
        },
      broadcastIntegrationSessionState.isEnabled &&
        broadcastIntegrationSessionState.fixtureDateRequired && {
          name: 'fixtureDate',
          type: 'date',
          label: 'Set Fixture Date',
          initialValue: false,
          data: {
            value: broadcastIntegration.fixtureDate
          }
        },
      {
        name: 'teamA',
        type: 'searchable',
        label: isMatch(formValues.type) ? 'Home Team' : 'Team',
        options: teamAOptions,
        addOption: () => {
          console.log('add team and rerender fields')
        },
        initialValue: teamA ? teamA?.value : null,
        data: {
          value: teamA ? teamA?.value : null
        }
      },
      isMatch(formValues.type) && {
        name: 'teamB',
        type: 'searchable',
        label: 'Away Team',
        options: teamBOptions,
        error: !teamB,
        errorMessage: 'You must join at least one other team to start a match',
        addOption: () => {
          console.log('add team and rerender fields')
        },
        initialValue: teamB ? teamB?.value : null,
        data: {
          value: teamB ? teamB?.value : null
        }
      },
      // {
      //   name: 'useCustomName',
      //   type: 'checkbox',
      //   label: 'Use custom name',
      //   initialValue: false,
      //   data: {
      //     value: formValues.useCustomName
      //   }
      // },
      formValues.useCustomName
        ? {
            name: 'customName',
            type: 'text',
            label: 'Session name',
            initialValue: formValues.customName,
            required: true,
            disallowedCharacters: ['/']
          }
        : {
            name: 'name',
            type: 'text',
            label: 'Session name',
            initialValue: defaultName,
            data: {
              value: defaultName
            }
          },
      isTraining(formValues.type)
        ? null
        : {
            name: 'officiatingAlgosEnable',
            type: 'checkbox',
            label: 'Officiation',
            initialValue: false,
            data: {
              value: formValues.officiatingAlgosEnable
            }
          },
      environment.isDevelopment() && {
        name: 'noDaemon',
        type: 'checkbox',
        label: 'No Daemon',
        initialValue: false,
        data: { value: formValues.noDaemon }
      },
      sessionModeTypeOptions.length > 1 && {
        name: 'mode',
        type: 'toggle',
        label: 'Live',
        size: 's',
        width: '75%',
        options: sessionModeTypeOptions,
        initialValue: formValues.mode,
        data: {
          value: formValues.mode
        }
      }
    ]
    return fields.filter(Boolean)
  }, [
    formValues,
    teamAOptions,
    teamBOptions,
    broadcastIntegrationSessionState,
    unixTime,
    broadcastIntegration.disabled,
    broadcastIntegration.fixtureDate,
    configuration
  ])

  const handleChange = (values: SessionSetupFormValues) => {
    // update session state //
    const sessionData = getSessionDataFromFormValues(values)
    dispatch(updateSession(sessionData))
    // custom name //
    if (typeof values.useCustomName === 'boolean')
      dispatch(updateUseCustomName(values.useCustomName))
    if (values.customName) dispatch(setCustomName(values.customName))
    // =================== //
    // update broadcast integration state
    if (typeof values.broadcastIntegrationDisabled === 'boolean')
      dispatch(setDisabled(values.broadcastIntegrationDisabled))
    if (values.fixtureDate) dispatch(setFixtureDate(values.fixtureDate))
    // Set the local storage value for noDaemon
    if (typeof values.noDaemon === 'boolean') {
      setNoDaemon(values.noDaemon)
    }
    if (
      values.sportType !== prevFormValues.current.sportType ||
      values.type !== prevFormValues.current.type ||
      values.subType !== prevFormValues.current.subType
    ) {
      dispatch(
        updateSeatConfiguration({
          sportType: values.sportType,
          sessionType: values.type,
          sessionSubType: values.subType
        })
      )
    }

    // Update prevValues with current values
    prevFormValues.current = {
      sportType: values.sportType,
      type: values.type,
      subType: values.subType
    }
  }

  const getSessionDataFromFormValues = useCallback<
    (values: SessionSetupFormValues) => RawSessionData
  >(
    (values) => {
      // session type
      const sessionType = sessionTypes.getTypeByValue(values.type)

      // Use custom name
      if (values.customName) values.name = values.customName

      // Set teamsSessions
      const teamsSessions: RawTeamSessionData[] = []

      if (values.teamA) {
        // Get team A
        const team = teams.rawData[values.teamA]
        teamsSessions.push({
          id: null,
          sessionId: null,
          teamId: values.teamA,
          team,
          homeAway: 'HOME'
        })
      }
      if (isSessionType.match(sessionType) && values.teamB) {
        // Get team B
        const team = teams.rawData[values.teamB]
        teamsSessions.push({
          id: null,
          sessionId: null,
          teamId: values.teamB,
          team,
          homeAway: 'AWAY'
        })
      } else {
        delete values.teamB
      }

      // Set officiatingAlgosEnable to false if not match mode //
      if (!isSessionType.match(sessionType)) {
        values.officiatingAlgosEnable = false
      }

      const updatedSession = { ...session }
      delete updatedSession.pitch

      return {
        ...updatedSession,
        ...values,
        teamsSessions: teamsSessions,
        playersSessions: [],
        teamA: teams.items[values.teamA],
        teamB: teams.items[values.teamB]
      }
    },
    [session, teams]
  )

  return (
    <FormContainer
      name='sessionSetup'
      fields={fields}
      validate={validate}
      validateOnBlur={true}
      disableSubmit={
        teamAOptions.length < 1 ||
        teamBOptions.length < 1 ||
        (broadcastIntegrationSessionState.isEnabled &&
          !broadcastIntegrationSessionState.isValid)
      }
      onSubmit={() => submitSessionForm()}
      submitText={'Create'}
      secondaryButtonText={'Cancel'}
      secondaryButtonHandleClick={() => {
        dispatch(setRedirect(ActivityListScreen.path))
        dispatch(setSession(getBlankSession(appTypeCheck), 'setup'))
      }}
      onChange={(e) => {
        const { values, dirty } = e
        if (dirty) {
          handleChange(values)
        }
      }}
    >
      {broadcastIntegrationSessionState.isEnabled && (
        <div className={styles.broadcastIntegrationStatus}>
          {broadcastIntegrationSessionState.isValid ? (
            <>
              <h5>
                Fixture:{' '}
                {broadcastIntegrationSessionState.isFixtureMatched ? (
                  <span style={{ color: sportableColors.colors.colorSuccess }}>
                    matched
                  </span>
                ) : (
                  <span style={{ color: sportableColors.colors.colorError }}>
                    {broadcastIntegrationSessionState.fixtureErrors}
                  </span>
                )}
              </h5>
              <h5>
                {formattedSession.homeTeam?.name}:{' '}
                {broadcastIntegrationSessionState.isTeamAMatched ? (
                  <span style={{ color: sportableColors.colors.colorSuccess }}>
                    matched
                  </span>
                ) : (
                  <span style={{ color: sportableColors.colors.colorError }}>
                    {broadcastIntegrationSessionState.teamAErrors}
                  </span>
                )}
              </h5>
              <h5>
                {formattedSession.awayTeam?.name}:{' '}
                {broadcastIntegrationSessionState.isTeamBMatched ? (
                  <span style={{ color: sportableColors.colors.colorSuccess }}>
                    matched
                  </span>
                ) : (
                  <span style={{ color: sportableColors.colors.colorError }}>
                    {broadcastIntegrationSessionState.teamBErrors}
                  </span>
                )}
              </h5>
            </>
          ) : (
            <Loader small={true} />
          )}
        </div>
      )}
    </FormContainer>
    // TODO: genius-integration - Add note if integration has failed - error code etc...
  )
}
