import { JSXElementConstructor } from 'react'
import { StrackPitch } from '../Strack/Strack.types'
import { FormattedSession } from '../../metrics_server/sessions/types'
import { Anchors } from '../../metrics_server/pitches/types'

export namespace ViewType {
  export const MAIN = 'MAIN'
  export const FORM = 'FORM'
  export const SIDEBAR = 'SIDEBAR'
  export const DASHBOARD = 'DASHBOARD'
  export const PAGE = 'PAGE'
}
export type ViewTypes = keyof typeof ViewType

export interface View {
  name: string
  type: ViewTypes
  backPath?: string
  Container?:
    | ((
        ChildComponent: JSXElementConstructor<any>,
        props: any
      ) => JSXElementConstructor<any>)
    | ((props: any) => JSX.Element)
  Content?: JSXElementConstructor<any>
  Header?: JSXElementConstructor<any>
  useProps?: (view: View) => any
  useOptions?: (props: any) => Option[]
  useNavbarProps?: (view: View) => ViewNavbarProps
}

export interface Option {
  name: string
  handle: () => void
  hidden?: boolean
}

export interface ViewHeaderProps {
  title: string | JSX.Element
  iconClick?: () => void
  backButton?: {
    route?: string
    text?: string
  }
  ToolbarInner?: JSXElementConstructor<any>
}

export interface ViewStrackProps {
  enabled: boolean
  pitch: StrackPitch
  anchorConfig: Anchors
  session?: FormattedSession
  hiddenCanvases: string[]
}

export interface ViewNavbarProps {
  enabled: boolean
  centerButton: {
    visible: boolean
    callback: () => void
    text: string
    icon: string
    loading: boolean
    disabled: boolean
  }
}

export interface SideBarView {
  id: string
  Component: JSXElementConstructor<any>
  title: string
  props: any
  reqPermissionLevel: number
}
